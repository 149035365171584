import React from "react";
import { Container, Card, Accordion } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';

function Help() {
    return (
        <Container fluid style={{ margin: 0, padding: 0 }}>
            <Card style={{ border: 0, borderRadius: 0 }}>
                <Card.Header>
                    <Card.Title>Help</Card.Title>
                </Card.Header>
                <Card.Body>
                    <ul>
                        <li>
                            <a href="https://forms.gle/LbTLooyfCL1CTUu59" target="_blank" rel="nofollow">Ask Questions & Make Suggestions</a>
                        </li>
                        <li>
                            <a href="https://calendly.com/sybrtms/intro-call" target="_blank" rel="nofollow">Request a meeting</a>
                        </li>
                    </ul>
                </Card.Body>
            </Card>
        </Container>
    );
}

export default Help