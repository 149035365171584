import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect } from 'react'
import { Container, Card, Button, Spinner, Row, Col, } from "react-bootstrap";
import Api from '../../utils/Api';
import { FaEdit, FaPlay, FaPlusCircle } from 'react-icons/fa'
import { useAuth } from '../../context/auth';
import DataDisplay from '../../components/DataDisplay';
import AlertDisplay from '../../components/AlertDisplay';
import TrafficSignal from '../../components/TrafficSignal';
import { enGB } from 'date-fns/locale';
import { Link } from 'react-router-dom';
import { formatDateFromDateStr, formatDateStrToMMDDYYHHMM, formatDateStrToMMDDYYHHMMTZ } from '../../utils/DateTimeFormat.helper';


function Job() {
    const { idToken } = useAuth();
    const [set1, didSet1] = useState();
    const [set2, didSet2] = useState();
    const [set3, didSet3] = useState();
    const [controllerStatus, setControllerStatus] = useState();
    const [reports, setReports] = useState();
    const [alerts, setAlerts] = useState([]);
    const [status, setStatus] = useState();
    const alertState = { alerts, setAlerts }

    const runJob = async (id) => {
        try {
            await Api.get(`/jobs/agenda/${id}/run`, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Job ran successfully' }])
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: JSON.stringify(error) }])
        } finally {
            didSet2(false)
            didSet3(false)
        }
    }

    const fetchJobControllerStatus = async () => {
        try {
            const data = await Api.get('/jobs/controller-status', idToken);
            setControllerStatus(data)
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'Error fetching agenda', }])
        } finally {
            didSet1(true)
        }
    }

    const fetchAgendaJobs = async () => {
        try {
            const data = await Api.get('/jobs/agenda', idToken);
            const cleanData = data.map((job, i) => {
                return {
                    _id: job?._id || '',
                    name: job?.name || '',
                    type: job?.type || '',
                    endDate: job?.endDate || '',
                    lastModifiedBy: job?.lastModifiedBy || '',
                    nextRunAt: job?.nextRunAt || '',
                    priority: job?.priority || '',
                    repeatInterval: job?.repeatInterval || '',
                    startDate: job?.startDate || '',
                    lockedAt: job?.lockedAt || '',
                    lastRunAt: job?.lastRunAt || '',
                    lastFinishedAt: job?.lastFinishedAt || ''
                }
            })
            setReports(cleanData)
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'Error fetching agenda', }])
        } finally {
            didSet2(true)
        }
    }

    const fetchAgendaStatus = async () => {
        try {
            const data = await Api.get('/jobs/status', idToken);
            setStatus(data)
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'Error fetching agenda status', }])
        } finally {
            didSet3(true)
        }
    }

    useEffect(() => {
        if (!set1) {
            fetchJobControllerStatus();
        }
        if (!set2) {
            fetchAgendaJobs()
        }
        if (!set3) {
            fetchAgendaStatus();
        }
    }, [idToken, set1, set2, set3, alerts])

    useEffect(() => {
        fetchAgendaStatus();
        const intervalId = setInterval(fetchAgendaStatus, 15000);
        return () => clearInterval(intervalId);
    }, []);

    return (
        <Container fluid style={{ margin: 0, padding: 0 }}>
            <AlertDisplay alertState={alertState} />
            <Card style={{border:0}}>
                <Card.Header>
                    <Card.Title>
                        Jobs
                    </Card.Title>
                </Card.Header>
                <Card.Body>
                    <Card.Body>
                        {set3 ? status?.jobs?.map((job, i) => (
                            <Row key={i} style={{ margin: '0.5em', border: '1px solid grey', padding: '0.25em 0.1em' }}>
                                <Col xs="auto"><TrafficSignal status={job?.executing ? 'true' : 'false'} /></Col>
                                <Col xs="auto">
                                    <Button onClick={() => runJob(job?._id)}><FaPlay /></Button>
                                </Col>
                                <Col xs="auto">
                                    <Link to={`/job/${job?._id}`}><FaEdit /></Link>
                                </Col>
                                <Col xs={12} md="auto">
                                    <Row>
                                        <Col xs={12} md="auto"><b>{job?.name}</b></Col>
                                        <Col xs={6} md="auto">{job?.status}</Col>
                                        <Col xs={6} md="auto">{job?.status === 'Scheduled' && formatDateStrToMMDDYYHHMMTZ(job?.nextRunAt)}</Col>
                                    </Row>
                                </Col>
                            </Row>
                        )) ?? <h4>No jobs in queue.</h4> : <Spinner />}
                    </Card.Body>
                </Card.Body>
            </Card>
        </Container>
    )
}

export default Job;