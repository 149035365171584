import React from 'react'
import { Card, Col, Container, ListGroup, Row } from 'react-bootstrap'
import { D3PieChart } from './D3PieChart'
import { D3StackedBarChart } from './D3StackedBarChart'

export const LoadTenderFinancialSummary = ({financialSummary, customerContractSummary }) => {
    
  return (
    <Container>
      <Row xs={1} sm={2} lg={4}>
          <Col>
            <Card className='mt-5'>
              <Card.Header className='cardheader'>
                Total Load Tenders
              </Card.Header>
              <Card.Body>
                <Card.Text className='cardtext'>
                  {financialSummary?.uniqueLoads}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className='mt-5'>
              <Card.Header className='cardheader'>
                Gross Revenue
              </Card.Header>
              <Card.Body>
                <Card.Text className='cardtext'>
                  {financialSummary?.grossRevenue.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className='mt-5'>
              <Card.Header className='cardheader'>
                Net Revenue
              </Card.Header>
              <Card.Body>
                <Card.Text className='cardtext'>
                  {financialSummary?.netRevenue.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className='mt-5'>
              <Card.Header className='cardheader'>
                Margin
              </Card.Header>
              <Card.Body>
                <Card.Text className='cardtext' style={{fontWeight: 'bold', color: financialSummary?.margin >= 0 ? 'green' : 'red'}}>
                  {financialSummary?.margin}%
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
      </Row>

      <Row md={1} lg={2}>
      <Col>
        <Card className='mt-5'>
          <Card.Header className='cardheader'>
            Estimated Operation Costs
          </Card.Header>
          <Card.Body className="d-flex flex-column">
              <D3PieChart data={financialSummary}/>
          </Card.Body>
        </Card>
      </Col>

        <Col>
          <Card className='mt-5'>
            <Card.Header className='cardheader'>
              Expense Breakdown
            </Card.Header>
            <Card.Body>
                
              <ListGroup>
                <ListGroup.Item>
                  <strong>Wages: </strong> {financialSummary?.wageSum.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Ratecon: </strong> {financialSummary?.rateconSum.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Other: </strong> {financialSummary?.otherSum.toLocaleString('en-US', { style: 'currency', currency: 'USD' })} 
                </ListGroup.Item>
              </ListGroup>
              <br />
              <ListGroup>
                <ListGroup.Item>
                    <strong>Total Fuel Cost:</strong> {financialSummary?.fuelSum.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                </ListGroup.Item>
                <ListGroup.Item>
                    <strong>- Gallons of Fuel Purchased:</strong> {financialSummary?.fuelGallons} gallons
                </ListGroup.Item>
                <ListGroup.Item>
                    <strong>- Cost per Gallon:</strong> {financialSummary?.fuelCpg.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                </ListGroup.Item>
              </ListGroup>
              <br />
              <ListGroup>
                <ListGroup.Item>
                    <strong>Total Rate Changes:</strong> {financialSummary?.grossRateChanges.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                </ListGroup.Item>
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card className='mt-5'>
            <Card.Header className='cardheader'>
              Contract Breakdown
            </Card.Header>
            <Card.Body>
              <D3StackedBarChart data={customerContractSummary}/>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}