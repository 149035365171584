import React, { useEffect, useState } from 'react';
import { Form, Button, Container, Row, Col, Card, OverlayTrigger, Tooltip, Spinner, Table } from 'react-bootstrap';
import { useAuth } from '../../context/auth';
import AlertDisplay from '../../components/AlertDisplay';
import Api from '../../utils/Api';
import { FaInfoCircle } from 'react-icons/fa';
import { Link, useParams } from 'react-router-dom';
import TrafficSignal from '../../components/TrafficSignal';

const initialCarrier = {
    "name": "",
    "address": "",
    "scac": "",
    "dotNumber": "",
    "mcNumber": "",
    "contacts": [{
        "phone": "",
        "email": "",
        "website": ""
    }],
    "insurancePolicies": [{
        "insurance_provider": "",
        "policy_number": "",
        "coverage_type": "",
        "expiry_date": ""
    }],
    "tradingPartner": false,
};

const FreightCarrier = ({ feature, method }) => {
    const { idToken } = useAuth();
    const { id } = useParams();
    const [didFetch, setDidFetch] = useState();
    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts };

    const [carrier, setCarrier] = useState(initialCarrier);
    const [carrierNotFound, setCarrierNotFound] = useState();


    const handleInputChange = (e, index, type) => {
        const { name, value } = e.target;
        const updatedCarrier = { ...carrier };
        if (type === 'contacts' || type === 'insurancePolicies') {
            updatedCarrier[type][index][name] = value;
        } else if (type === 'address') {
            updatedCarrier[type][name] = value;
        } else {
            updatedCarrier[name] = value;
        }
        setCarrier(updatedCarrier);
    };

    const addEntry = (type) => {
        const updatedCarrier = { ...carrier };
        updatedCarrier[type].push({});
        setCarrier(updatedCarrier);
    };

    const removeEntry = (index, type) => {
        const updatedCarrier = { ...carrier };
        updatedCarrier[type].splice(index, 1);
        setCarrier(updatedCarrier);
    };

    async function handleCreate() {
        try {
            await Api.post('/carriers', carrier, idToken);
            setAlerts([...alerts, { variant: 'success', message: 'Success' }])
            setCarrier({ ...initialCarrier });
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured.' }])
        }
    }

    async function handleUpdate() {
        try {
            await Api.patch(`/carrier/${id}`, carrier, idToken);
            setAlerts([...alerts, { variant: 'success', message: 'Success' }])

        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured.' }])
        }
    }

    async function fetchCarrier() {
        try {
            const data = await Api.get(`/carrier/${id}`, idToken);
            setCarrier(data)
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured fetching carrier.' }])
            setCarrierNotFound(true)
        } finally {
            setDidFetch(true)
        }
    }

    useEffect(() => {
        if (id && !didFetch) {
            fetchCarrier()
        }
    }, [id, didFetch])

    return (
        <Container fluid style={{ margin: 0, padding: 0 }}>
            <AlertDisplay alertState={alertState} />

            <Card style={{ border: 0, borderRadius: 0 }}>
                <Card.Header>
                    <Row>
                        <Col>
                            <Card.Title>{`${method} Carrier`.toUpperCase()}</Card.Title>
                        </Col>
                    </Row>
                </Card.Header>
                {carrierNotFound ?
                    <Card.Body>
                        Carrier Not Found <Link to={'/data/carrier'} onClick={() => setCarrierNotFound(false)}>Create Carrier</Link>
                    </Card.Body>
                    :
                    (method === 'update' && !didFetch) ?
                        <Spinner />
                        :
                        <>
                            <Card.Body>
                                <Form.Group controlId="name">
                                    <Form.Label>Carrier Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="name"
                                        value={carrier.name}
                                        onChange={(e) => handleInputChange(e)}
                                    />
                                </Form.Group>
                                <Form.Group controlId="address">
                                    <Form.Label>Address</Form.Label>
                                    <Row>
                                        <Col>
                                            <Form.Control
                                                type="text"
                                                name="address"
                                                placeholder="Address"
                                                value={carrier.address}
                                                onChange={(e) => handleInputChange(e)}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Form.Group controlId="address">
                                    <Form.Label>Website</Form.Label>
                                    <Row>
                                        <Col>
                                            <Form.Control
                                                type="text"
                                                name="website"
                                                placeholder="Website"
                                                value={carrier.website}
                                                onChange={(e) => handleInputChange(e)}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Group>
                                <Row>
                                    <Col xs="auto">
                                        <Form.Group controlId="dotNumber">
                                            <Form.Label>DOT Number
                                                {carrier?.dotNumber.length === 7 &&
                                                    <a target="_blank" without rel="noreferrer" href={`https://safer.fmcsa.dot.gov/query.asp?searchtype=ANY&query_type=queryCarrierSnapshot&query_param=USDOT&query_string=${carrier.dotNumber}`}>
                                                        FMCSA Safer
                                                    </a>
                                                }
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="dotNumber"
                                                value={carrier.dotNumber}
                                                onChange={(e) => handleInputChange(e)}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs="auto">
                                        <Form.Group controlId="mcNumber">
                                            <Form.Label>MC Number</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="mcNumber"
                                                value={carrier.mcNumber}
                                                onChange={(e) => handleInputChange(e)}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs="auto">
                                        <Form.Group controlId="scac">
                                            <Form.Label>SCAC</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="scac"
                                                value={carrier.scac}
                                                onChange={(e) => handleInputChange(e)}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs="auto">
                                        <Form.Group controlId="tradingPartner">
                                            <Form.Label>Trading Partner</Form.Label>
                                            <Form.Check
                                                name="tradingPartner"
                                                checked={carrier?.tradingPartner}
                                                onChange={(e) => setCarrier({ ...carrier, tradingPartner: !carrier.tradingPartner })}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <br />
                                {carrier?.tradingPartner === true ?
                                    <Row>
                                        <Col xs="auto">
                                            <TrafficSignal status={"true"} />
                                        </Col>
                                        <Col>
                                            Business to business messaging is enabled. Loads assigned to this carrier will be sent over Electronic Data Interchange (EDI)
                                        </Col>
                                    </Row>
                                    :
                                    <Row>
                                        <Col xs="auto">
                                            <TrafficSignal status={"false"} />
                                        </Col>
                                        <Col>
                                            Loads sent to this carrier will be sent via Rate Confirmation email.
                                        </Col>
                                    </Row>
                                }
                                <br />
                                <Form.Group controlId="contacts">
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>Contact's Name</th>
                                                <th>Email</th>
                                                <th>Phone</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {carrier?.contacts && carrier?.contacts.map((contact, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        <Form.Control
                                                            type="text"
                                                            name="name"
                                                            placeholder="Name"
                                                            value={contact.name}
                                                            onChange={(e) => handleInputChange(e, index, 'contacts')}
                                                        />
                                                    </td>
                                                    <td>
                                                        <Form.Control
                                                            type="text"
                                                            name="email"
                                                            placeholder="Email"
                                                            value={contact.email}
                                                            onChange={(e) => handleInputChange(e, index, 'contacts')}
                                                        />
                                                    </td>
                                                    <td>
                                                        <Form.Control
                                                            type="text"
                                                            name="phone"
                                                            placeholder="Phone"
                                                            value={contact.phone}
                                                            onChange={(e) => handleInputChange(e, index, 'contacts')}
                                                        />
                                                    </td>
                                                    <td>
                                                        <Button variant="danger" onClick={() => removeEntry(index, 'contacts')}>Remove</Button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                    <Button variant="secondary" onClick={() => addEntry('contacts')}>Add Contact</Button>
                                </Form.Group>
                                <br />
                                <Form.Group controlId="insurancePolicies">
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>Insurance Provider</th>
                                                <th>Policy Number</th>
                                                <th>Coverage Type</th>
                                                <th>Experation Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {carrier?.insurancePolicies && carrier?.insurancePolicies.map((insurance, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        <Form.Control
                                                            type="text"
                                                            name="insurance_provider"
                                                            placeholder="Insurance Provider"
                                                            value={insurance.insurance_provider}
                                                            onChange={(e) => handleInputChange(e, index, 'insurancePolicies')}
                                                        />
                                                    </td>
                                                    <td>
                                                        <Form.Control
                                                            type="text"
                                                            name="policy_number"
                                                            placeholder="Policy Number"
                                                            value={insurance.policy_number}
                                                            onChange={(e) => handleInputChange(e, index, 'insurancePolicies')}
                                                        />
                                                    </td>
                                                    <td>
                                                        <Form.Control
                                                            type="text"
                                                            name="coverage_type"
                                                            placeholder="Coverage Type"
                                                            value={insurance.coverage_type}
                                                            onChange={(e) => handleInputChange(e, index, 'insurancePolicies')}
                                                        />
                                                    </td>
                                                    <td>
                                                        <Form.Control
                                                            type="date"
                                                            name="expiry_date"
                                                            placeholder="Expiry Date"
                                                            value={insurance.expiry_date}
                                                            onChange={(e) => handleInputChange(e, index, 'insurancePolicies')}
                                                        />
                                                    </td>
                                                    <td>
                                                        <Button variant="danger" onClick={() => removeEntry(index, 'insurancePolicies')}>Remove</Button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                    <Button variant="secondary" onClick={() => addEntry('insurancePolicies')}>Add Insurance</Button>
                                </Form.Group>
                            </Card.Body>
                            <Card.Body>
                                <Row>

                                    {method === 'create' &&
                                        <Col xs="auto">
                                            <Button variant="primary" onClick={() => handleCreate()}>Create</Button>
                                        </Col>
                                    }
                                    {method === 'update' &&
                                        <>
                                            <Col xs="auto">
                                                <Button variant="primary" onClick={() => handleUpdate()}>Update</Button>
                                            </Col>
                                            <Col xs="auto">
                                                <OverlayTrigger
                                                    placement="top"
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={
                                                        <Tooltip id="button-tooltip">
                                                            Active - determines whether or not the record should be
                                                            used by the software application.
                                                        </Tooltip>
                                                    }
                                                >
                                                    <div>

                                                        Active <FaInfoCircle /> : <Button variant={carrier.isActive ? 'success' : 'danger'} onClick={() => { setCarrier({ ...carrier, isActive: (carrier.isActive ? false : true) }) }}>{carrier.isActive ? 'True' : 'False'}</Button>
                                                    </div>
                                                </OverlayTrigger>
                                            </Col>
                                        </>

                                    }
                                </Row>
                            </Card.Body>
                        </>
                }
            </Card>

        </Container>
    );
};

export default FreightCarrier;
