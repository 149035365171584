import { Container, Card, Row, Col, Form, Button, Alert, Tooltip, OverlayTrigger } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect } from 'react'
import Api from "../../utils/Api";
import { useParams } from 'react-router-dom';
import CalendarChart from "../../components/CalendarChart";
import { Spinner } from 'react-bootstrap';
import { FaInfoCircle } from "react-icons/fa";
import { useAuth } from '../../context/auth';
import AlertDisplay from "../../components/AlertDisplay";

function FrequencyUpdate({ feature }) {
    const { id } = useParams();
    const { idToken } = useAuth();

    const [set, didSet] = useState()
    const [reload, shouldReload] = useState()
    const [frequency, setFrequency] = useState({});
    const [specialops, setSpecialOps] = useState();
    const [rules, setRules] = useState([]);
    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts }

    const [ops, setOps] = useState([]);
    //
    const date = new Date()
    const yyyy = date.getUTCFullYear();
    const [startop, setStartOp] = useState(`${yyyy}-01-01`)
    const [endop, setEndOp] = useState(`${yyyy}-12-31`)

    function updatePeriod(key, value) {
        if (key === 'start') {
            setStartOp(value)
        } else {
            setEndOp(value)
        }
        shouldReload(true)
    }

    function setRule(i, k, v) {
        rules[i][k] = v
        setFrequency({ ...frequency, rules: [...rules] })
    }

    function removeRule(index) {
        const filtered = rules.filter((_, i) => {
            return i !== index
        })
        setRules([...filtered])
        setFrequency({ ...frequency, rules: [...filtered] })
    }

    async function handleUpdate() {
        try {
            await Api.patch(`/frequency/${id}`, frequency, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Success', }])
            didSet(false)
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'Error updating', }])
        }
    }

    const calculateTotalRows = (text) => {
        const newlineCount = (text.match(/\n/g) || []).length + 1;
        return newlineCount;
    };

    useEffect(() => {
        const fetchChart = async () => {
            try {
                if (Date.parse(startop) <= Date.parse(endop)) {
                    const operations = await Api.get(`/frequency/${id}/digest/${startop}/${endop}`, idToken);
                    setOps(operations)
                } else {
                    setAlerts([...alerts, { variant: 'warning', message: 'Operations end date must be greater than start operation date.' }])
                }
            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: 'Request error' }])
            }
            shouldReload(false)
        }
        const fetchData = async () => {
            try {
                const freq = await Api.get(`/frequency/${id}`, idToken)
                setFrequency(freq)
                setRules(freq.rules)
                const specialops = await Api.get('/specialops/live', idToken)
                setSpecialOps(specialops)
                if (Date.parse(startop) <= Date.parse(endop)) {
                    const operations = await Api.get(`/frequency/${id}/digest/${startop}/${endop}`, idToken);
                    setOps(operations)
                } else {
                    setAlerts([...alerts, { variant: 'warning', message: 'Operations end date must be greater than start operation date.' }])
                }
            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: 'Request error' }])
            }
            didSet(true)
        }
        if (!set) {
            fetchData()
        }

        if (reload) {
            fetchChart()
        }
    }, [id, reload, idToken, set, startop, endop, alerts])

    return (
        <Container fluid style={{ margin: 0, padding: 0 }}>
            <AlertDisplay alertState={alertState} />
            <Card style={{ borderRadius: 0, border: 0 }}>
                <Card.Header><b>Frequency Update</b></Card.Header>
                {set ?
                    <Card.Body>
                        <Row>
                            <Col>
                                ID : {frequency._id}
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col>
                                Name
                                <Form.Control type="text" value={frequency.name} onChange={(e) => { setFrequency({ ...frequency, name: e.target.value }) }} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                Detail
                                <Form.Control
                                    as="textarea"
                                    rows={calculateTotalRows(frequency.detail) || 3}
                                    value={frequency.detail}
                                    onChange={(e) => { setFrequency({ ...frequency, detail: e.target.value }) }}
                                />
                            </Col>
                        </Row>
                        <br />
                        <Card.Header>
                            <Card.Title>Routine</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                    <Form.Check label={'Sunday'} checked={frequency.sunday} onChange={() => { setFrequency({ ...frequency, sunday: (frequency.sunday ? false : true) }) }} />
                                </Col>
                                <Col>
                                    <Form.Check label={'Monday'} checked={frequency.monday} onChange={() => { setFrequency({ ...frequency, monday: (frequency.monday ? false : true) }) }} />
                                </Col>
                                <Col>
                                    <Form.Check label={'Tuesday'} checked={frequency.tuesday} onChange={() => { setFrequency({ ...frequency, tuesday: (frequency.tuesday ? false : true) }) }} />
                                </Col>
                                <Col>
                                    <Form.Check label={'Wednesday'} checked={frequency.wednesday} onChange={() => { setFrequency({ ...frequency, wednesday: (frequency.wednesday ? false : true) }) }} />
                                </Col>
                                <Col>
                                    <Form.Check label={'Thursday'} checked={frequency.thursday} onChange={() => { setFrequency({ ...frequency, thursday: (frequency.thursday ? false : true) }) }} />
                                </Col>
                                <Col>
                                    <Form.Check label={'Friday'} checked={frequency.friday} onChange={() => { setFrequency({ ...frequency, friday: (frequency.friday ? false : true) }) }} />
                                </Col>
                                <Col>
                                    <Form.Check label={'Saturday'} checked={frequency.saturday} onChange={() => { setFrequency({ ...frequency, saturday: (frequency.saturday ? false : true) }) }} />
                                </Col>
                            </Row>
                        </Card.Body>
                        <br />
                        <Card.Body>
                            <Form.Check label={'Special operations'} checked={frequency.hasSpecialOps} onChange={() => { setFrequency({ ...frequency, hasSpecialOps: (frequency.hasSpecialOps ? false : true) }) }} />
                        </Card.Body>
                        <br />
                        {frequency.hasSpecialOps &&
                            <>
                                <Card.Header>
                                    <Row>
                                        <Col>
                                            <Card.Title>Special Operation Rules</Card.Title>
                                        </Col>
                                        <Col>
                                            <Button variant="primary" className="float-end" onClick={() => setRules(rules ? [...rules, { specialop: '', rule: '' }] : [{ specialop: '', rule: '' }])}>Add Rule</Button>
                                        </Col>
                                    </Row>
                                </Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col> Special Operation </Col>
                                        <Col> Rule </Col>
                                        <Col xs={6}>{' '}</Col>
                                    </Row>
                                    {(rules && rules.length > 0) && rules.map((rule, i) => (
                                        <Row key={i}>
                                            <Col>
                                                <Form.Select value={JSON.stringify(rule.specialop)} onChange={(e) => setRule(i, 'specialop', JSON.parse(e.target.value))}>
                                                    <option>Select</option>
                                                    {specialops && specialops.map(specialop => (
                                                        <option key={specialop._id} value={JSON.stringify(specialop)}>{specialop.name}</option>
                                                    ))}
                                                </Form.Select>
                                            </Col>
                                            <Col>
                                                <Form.Select value={rule.rule} onChange={e => setRule(i, 'rule', e.target.value)}>
                                                    <option>Select</option>
                                                    <option value="dnr">Does Not Run</option>
                                                    <option value="run">Run</option>
                                                </Form.Select>
                                            </Col>
                                            <Col xs={6}>
                                                <Button variant="danger" onClick={() => removeRule(i)}>X</Button>
                                            </Col>
                                        </Row>
                                    ))}
                                </Card.Body>
                            </>
                        }
                        <Card.Header>
                            <Row>
                                <Col>
                                    <Card.Title>Frequency Forecast</Card.Title>
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                    <Form.Control type="date" value={startop} onChange={(e) => updatePeriod('start', e.target.value)} />
                                </Col>
                                <Col>
                                    <Form.Control type="date" value={endop} onChange={(e) => updatePeriod('end', e.target.value)} />
                                </Col>
                                <Col>
                                    {ops.reduce((a, b) => { return a + b[1] }, 0)}/{ops.reduce((a, b) => { return a + 1 }, 0)} (Ops/Days)
                                </Col>
                            </Row>
                        </Card.Body>
                        {ops.length > 0 &&
                            <Card.Body>
                                <CalendarChart
                                    title={`${frequency.name}`}
                                    opdata={ops}
                                    adjust={Math.ceil(ops.length / 365)} />
                            </Card.Body>
                        }
                        <br />
                        <Row>
                            <Col xs="auto">
                                <Button variant="primary" onClick={() => handleUpdate()}>Update</Button>
                            </Col>
                            <Col xs="auto">
                                <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={
                                        <Tooltip id="button-tooltip">
                                            Active - determines whether or not the record should be
                                            used by the software application.
                                        </Tooltip>
                                    }
                                >
                                    <div>
                                        Active <FaInfoCircle /> : <Button variant={frequency.isActive ? 'success' : 'danger'} onClick={() => { setFrequency({ ...frequency, isActive: (frequency.isActive ? false : true) }) }}>{frequency.isActive ? 'True' : 'False'}</Button>
                                    </div>
                                </OverlayTrigger>
                            </Col>
                        </Row>
                    </Card.Body>
                    :
                    <Card.Body>
                        <Spinner />
                    </Card.Body>
                }

            </Card>
        </Container>
    );
}

export default FrequencyUpdate;