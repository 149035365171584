import { Container, Card, Spinner, Row, Col, Badge, } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import Api from '../../utils/Api';
import React, { useState, useEffect } from 'react'
import { FaPlusCircle, } from 'react-icons/fa'
import { useAuth } from '../../context/auth';
import { Link } from "react-router-dom";
import AlertDisplay from "../../components/AlertDisplay";
import DataDisplayV3 from "../../components/DataDisplayV3";
import DataDisplay from "../../components/DataDisplay";
import VirtualizedTable from "../../components/VirtualizeTable";

function Location({ feature }) {
    const { idToken } = useAuth();
    const [set1, didSet1] = useState();
    const [set2, didSet2] = useState();
    const [toggle, setToggle] = useState(true);
    const [locations, setLocations] = useState();
    const [uspsLocations, setUSPSLocations] = useState();
    const [alerts, setAlerts] = useState([]);
    const [query, setQuery] = useState({
        isActive: true
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const queryParams = new URLSearchParams(query);
                const data = await Api.query('/locations', queryParams, idToken);
                if (!data || !Array.isArray(data)) {
                    throw new Error("Received invalid data from server.");
                }
                const cleanLocations = data.map(location => {
                    return {
                        _id: location._id,
                        'Name': location.name,
                        'Type': location.type,
                        'Address': `${location.address1} ${location.address2} ${location.city} ${location.state} ${location.zipcode}`,
                        'Provider': location.provider,
                        'Active': location.isActive ? '✅' : '❌',
                    }
                })

                setLocations(cleanLocations)
            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: 'Request error' }]);
            } finally {
                didSet1(true)
            }
        }

        const fetchUSPSData = async () => {
            try {
                const data = await Api.get('/usps/servicepoints', idToken);
                if (!data || !Array.isArray(data)) {
                    throw new Error("Received invalid data from server.");
                }
                setUSPSLocations(data)
            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: 'Request error' }]);
            } finally {
                didSet2(true)
            }
        }

        fetchData()
        fetchUSPSData()

    }, [set1, set2, idToken, alerts, query])


    return (
        <Container fluid style={{ margin: 0, padding: 0 }}>
            <Card style={{ borderRadius: 0, border: 0 }}>
                <Card.Header>
                    <Card.Title>
                        <Row>
                            <Col>
                                <Card.Title>
                                    <Row>
                                        <Col xs="auto">
                                            <Badge
                                                bg={'success'}
                                                onClick={() => setToggle(true)}
                                            >
                                                {'Locations'}
                                            </Badge>
                                        </Col>
                                        <Col xs="auto">
                                            <Badge
                                                bg={'primary'}
                                                onClick={() => setToggle(false)}
                                            >
                                                {'USPS'}
                                            </Badge>
                                        </Col>
                                    </Row>
                                </Card.Title>
                            </Col>
                            {toggle &&
                                <>
                                    <Col style={{ textAlign: 'center' }}>
                                        <Badge
                                            bg={query.isActive ? 'success' : 'danger'}
                                            onClick={() => { setQuery({ ...query, isActive: !query.isActive }); didSet1(false) }}
                                        >
                                            {query.isActive ? 'Active' : 'Inactive'}
                                        </Badge>
                                    </Col>
                                    <Col>
                                        <Link className="float-end" to={`/${feature}/location-form`}><FaPlusCircle /> Location</Link>
                                    </Col>
                                </>
                            }
                        </Row>
                    </Card.Title>
                </Card.Header>
                {alerts.length > 0 &&
                    <AlertDisplay alertState={{ alerts, setAlerts }} />
                }
                {(set1) ?
                    (
                        toggle &&
                        <Card.Body>
                            {locations.length > 0 ?
                                <>
                                    <DataDisplayV3
                                        dataSource={locations}
                                        lsKey={'@mctms-locations-display'}
                                        urlPath={`/${feature}/location/`}
                                        urlKey={'_id'}
                                        popKeys={['_id']}
                                    />
                                </>
                                :
                                <>
                                    No data.
                                </>
                            }
                        </Card.Body>
                    )
                    :
                    <Card.Body>
                        <Spinner />
                    </Card.Body>
                }
                {(set2) ?
                    (
                        !toggle &&
                        <Card.Body>
                            {uspsLocations.length > 0 ?
                                <VirtualizedTable uspsLocations={uspsLocations} headers={[]} />
                                :
                                <>
                                    No data.
                                </>
                            }
                        </Card.Body>
                    )
                    :
                    <Card.Body>
                        <Spinner />
                    </Card.Body>
                }
            </Card>
        </Container>
    );
}

export default Location;