import React, { useEffect, useState, } from 'react';
import * as d3 from 'd3';

export const D3StackedBarChart = ({data}) => {
    const [screenSize, setScreenSize] = useState(getScreenSize());

    function getScreenSize() {
        return window.innerWidth < 992 ? 'sm' : 'lg';
    }

    useEffect(() => {
        function handleResize() {
            setScreenSize(getScreenSize());
        }
        console.log(screenSize);

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {

        const renderChart = () => {
        // set the dimensions and margins of the graph
        const margin = {top: 20, right: 30, bottom: 90, left: 60};
        const container = document.getElementById('stackedBarChart');
        const containerWidth = container.offsetWidth;
        const width = containerWidth - margin.left - margin.right;
        const height = 400 - margin.top - margin.bottom;
        let svg;

        //remove existing svg elements
        d3.select("#stackedBarChart").selectAll("*").remove();

        // Data
        const customers = data.map(d => d.customer)
        const keys = ["wageSum", "rateconSum", "fuelSum", "otherSum"]
        const labels = {wageSum: "Wages", rateconSum: "Rate Con", fuelSum: "Fuel", otherSum: "Other"}
        const legendData = [
            { key: "wageSum", label: "Wages" },
            { key: "rateconSum", label: "Rate Con" },
            { key: "fuelSum", label: "Fuel" },
            { key: "otherSum", label: "Other" }
        ];

        // Three function that change the tooltip when user hover / move / leave a cell
        const mouseover = function (event, d) {
            d3.select(this)
              .style("opacity", 1)
              .style('cursor', "pointer")
            tooltip.transition().duration(50).style("opacity", 1);
          }

        const mousemove = function(event, d) {
            const subgroupName = d3.select(event.target.parentNode).datum().key;
            const subgroupValue = d.data[subgroupName];
            const label = labels[subgroupName] || subgroupName;
            const customerName = d.data.customer;
            tooltip
                .html(customerName + "<br>" + label + "<br>" + subgroupValue.toLocaleString('en-US', { style: 'currency', currency: 'USD' }))
                .style("left",(event.offsetX + 40) +"px")
                .style("top",(event.offsetY + 40) +"px")
        }

        const mouseleave = function(event, d) {
            tooltip.transition().duration(50).style("opacity", 0);
            d3.select(this)
                .style("opacity", 0.8)
                .style('cursor', "default");
        }

        if (screenSize === 'sm') {
            const svgHeight = height + margin.top + margin.bottom;
            const longestLabel = d3.max(customers, d => d.length);
            const svgWidth = width + margin.left + margin.right + (longestLabel * 7);

            // svg for horizontal layout
            svg = d3.select("#stackedBarChart")
                .append("svg")
                .attr("viewBox", `0 0 ${svgWidth} ${svgHeight}`)
                .attr("preserveAspectRatio", "xMidYMid meet")
                .attr("width", svgWidth)
                .attr("height", '100%')
                .append("g")
                .attr("transform", `translate(${margin.left},${margin.top})`);

            // x axis
            const x = d3.scaleLinear().domain([0, d3.max(data, d => d3.sum(keys.map(key => d[key])))]).range([0, width]);
            svg.append("g")
                .call(d3.axisTop(x));

            // y axis
            const y = d3.scaleBand().domain(customers).range([0, height]).padding([0.2]);  
            svg.append("g")
                .call(d3.axisLeft(y).tickSizeOuter(0))
                .selectAll("text")
                .style("text-anchor", "end")
                .attr("dx", "-.8em")
                .attr("dy", ".15em")
                .attr("transform", "rotate(-45)")
                .style("font-size", "10px")
                .style("max-width", "100px")
                .style("overflow", "hidden")
                .style("text-overflow", "ellipsis");

            // Color palette
            const color = d3.scaleOrdinal()
                .domain(keys)
                .range(["#C7EFCF", "#FE5F55", "#FFB03D", "#9D8189"]);

            // Stack the data
            const stackedData = d3.stack().keys(keys)(data);

            // Show the bars
            svg.append("g")
                .selectAll("g")
                .data(stackedData)
                .enter()
                .append("g")
                .attr("fill", d => color(d.key))
                .selectAll("rect")
                .data(d => d)
                .join("rect")
                .attr("x", d => x(d[0]))
                .attr("y", d => y(d.data.customer))
                .attr("height", y.bandwidth())
                .attr("width", d => x(d[1]) - x(d[0]))
                .attr("stroke", "grey")
                .style("opacity", 0.8)
                .on("mouseover", mouseover)
                .on("mousemove", mousemove)
                .on("mouseleave", mouseleave);

            // Render legend
            const legendRectSize = 18;
            const legendSpacing = 4;
            
            const legend = svg.append("g")
                .attr("class", "legend")
                .attr("transform", `translate(0, ${height + margin.top + 20})`); // Position the legend below the chart

            // Render rectangles and labels in legend
            legend.selectAll('.legend-item')
                .data(legendData)
                .enter()
                .append('g')
                .attr('class', 'legend-item')
                .attr('transform', (d, i) => `translate(${i * (legendRectSize + legendSpacing * 18)}, 30)`)
                .each(function (d) {
                    const group = d3.select(this);
                    group.append('rect')
                        .attr('x', 0)
                        .attr('y', 0)
                        .attr('width', legendRectSize)
                        .attr('height', legendRectSize)
                        .style('fill', color(d.key));

                    group.append('text')
                        .attr('x', legendRectSize + legendSpacing)
                        .attr('y', legendRectSize / 2)
                        .attr('dy', '0.35em')
                        .text(d.label);
                });
    
        } else {
            const longestLabel = d3.max(customers, d => d.length);
            const svgHeight = height + margin.top + margin.bottom + (longestLabel * 1.2);
    
            // append the svg object to the body of the page
            svg = d3.select("#stackedBarChart")
                .append("svg")
                .attr("viewBox", `0 0 ${containerWidth} ${svgHeight}`)
                .attr("preserveAspectRatio", "xMidYMid meet")
                .attr("width", "100%")
                .attr("height", svgHeight)
                .append("g")
                .attr("transform",`translate(${margin.left},${margin.top})`);
                
            // Add X axis
            const x = d3.scaleBand().domain(customers).range([0, width]).padding([0.2])
            
            svg.append("g")
                .attr("transform", `translate(0, ${height})`)
                .call(d3.axisBottom(x).tickSizeOuter(0))
                .selectAll("text")
                    .style("text-anchor", "end") // Align text to the end (right) of the tick
                    .attr("dx", "-.8em") // Add a small offset to position the text
                    .attr("dy", ".15em") // Add a small offset to position the text
                    .attr("transform", "rotate(-45)") // Rotate the text for better readability
                    .style("font-size", "10px") // Set the font size
                    .style("max-width", "100px") // Set the maximum width for the text
                    .style("overflow", "hidden") // Hide overflowing text
                    .style("text-overflow", "ellipsis");
    
            // Add Y axis
            const y = d3.scaleLinear().domain([0, d3.max(data, (d) => d3.sum(keys.map((key) => d[key])))]).range([height, 0]);
            
            svg.append("g")
                .call(d3.axisLeft(y));

            // color palette = one color per subgroup
            const color = d3.scaleOrdinal()
            .domain(keys)
            .range(["#C7EFCF", "#FE5F55", "#FFB03D", "#9D8189"]);

            //stack the data
            const stackedData = d3.stack().keys(keys)(data);

            // Show the bars
            svg
                .append("g")
                .selectAll("g")
                // Enter in the stack data = loop key per key = group per group
                .data(stackedData)
                .enter()
                .append("g")
                .attr("fill", d => color(d.key))
                .selectAll("rect")
                // enter a second time = loop subgroup per subgroup to add all rectangles
                .data(d => d)
                .join("rect")
                .attr("x", d =>  x(d.data.customer))
                .attr("y", d => y(d[1]))
                .attr("height", d => y(d[0]) - y(d[1]))
                .attr("width",x.bandwidth())
                .attr("stroke", "grey")
                .style("opacity", 0.8)
                .on("mouseover", mouseover)
                .on("mousemove", mousemove)
                .on("mouseleave", mouseleave);

                // Render legend
                const legendRectSize = 18;
                const legendSpacing = 5;
                
                const legend = svg.append("g")
                    .attr("class", "legend")
                    .attr("transform", `translate(0, ${height + margin.top + 40})`); // Position the legend below the chart

                // Render rectangles and labels in legend
                legend.selectAll('.legend-item')
                    .data(legendData)
                    .enter()
                    .append('g')
                    .attr('class', 'legend-item')
                    .attr('transform', (d, i) => `translate(${i * (legendRectSize + legendSpacing * 18)}, 30)`)
                    .each(function (d) {
                        const group = d3.select(this);
                        group.append('rect')
                            .attr('x', 0)
                            .attr('y', 0)
                            .attr('width', legendRectSize)
                            .attr('height', legendRectSize)
                            .style('fill', color(d.key));

                        group.append('text')
                            .attr('x', legendRectSize + legendSpacing)
                            .attr('y', legendRectSize / 2)
                            .attr('dy', '0.35em')
                            .text(d.label);
                    });
        }

        // Create a tooltip
        const tooltip = d3.select("#stackedBarChart")
            .append("div")
            .style("opacity", 0)
            .attr("class", "tooltip")
            .style("background-color", "white")
            .style("border", "solid")
            .style("border-width", "1px")
            .style("border-radius", "5px")
            .style("padding", "10px")
            .style("position", "absolute") 
        }        

        renderChart();

    }, [data, screenSize]);

    return (
        <div id='stackedBarChart' class="svg-container"></div>
    );
}