import React, { useState, useEffect } from 'react';
import { Accordion, Card, Col, Container, Row, Spinner, Table } from 'react-bootstrap'
import AlertDisplay from '../../components/AlertDisplay'
import { useAuth } from '../../context/auth'
import Api from '../../utils/Api'
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';


const initialQuery = {
    page: 1,
    pageSize: 10
}

function objectToQueryString(obj) {
    return Object.keys(obj)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
        .join('&');
}

function NotificationLog() {
    const { idToken } = useAuth()
    const [set, didSet] = useState();
    const [logs, setLogs] = useState();

    const [alerts, setAlerts] = useState([])
    const alertState = { alerts, setAlerts }

    const [totalRecords, setTotalRecords] = useState(0);
    const [totalPages, setTotalPages] = useState(0);

    const [query, setQuery] = useState(() => {
        const savedQuery = localStorage.getItem(`@mctms-notification-dashboard-query`);
        return savedQuery !== null ? JSON.parse(savedQuery) : initialQuery;
    });

    async function fetchNotifications() {
        didSet(false)
        try {
            const queryStr = objectToQueryString(query)
            const data = await Api.get(`/logs/notifications?${queryStr}`, idToken)
            setTotalRecords(data.totalRecords)
            setTotalPages(data.totalPages)
            setLogs(data.records)
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured fetching notifications' }]);
        } finally {
            didSet(true)
        }
    }

    useEffect(() => {
        fetchNotifications()
    }, [query])

    return (
        <Container fluid>
            <AlertDisplay alertState={alertState} />
            <Row>
                <Col xs="auto">
                    Records {totalRecords}
                </Col>
                <Col xs="auto">
                    Page {query.page} / {totalPages}
                    <button onClick={() => setQuery({ ...query, page: query.page - 1 >= 1 ? query.page - 1 : query.page })}><FaArrowLeft /></button>
                    <button onClick={() => setQuery({ ...query, page: query.page + 1 <= totalPages ? query.page + 1 : query.page })}><FaArrowRight /></button>
                </Col>
                <Col xs="auto">
                    Page Size
                    <select value={query.pageSize} onChange={(e) => setQuery({ ...query, pageSize: e.target.value })}>
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                    </select>
                </Col>
            </Row>
            {!set ?
                <Spinner />
                :
                <Accordion>
                    {logs.map((log, i) => (
                        <Accordion.Item eventKey={`${i}`}>
                            <Accordion.Header>#{i + 1} | {log.subject}</Accordion.Header>
                            <Accordion.Body>
                                <pre>
                                    {log.message}
                                </pre>
                            </Accordion.Body>
                        </Accordion.Item>
                    ))}
                </Accordion>
            }
        </Container>)
}

export default NotificationLog;