import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect } from "react";
import { Navbar, Nav, Offcanvas, Dropdown, Button, Image, Row, Col, Tab, Tabs, Container, NavDropdown } from 'react-bootstrap';
import {
    FaRoute, FaCalendarAlt, FaRegIdCard, FaTrailer, FaBookmark, FaTruck, FaSearchLocation, FaFileContract, FaLink,
    FaGasPump, FaInfo, FaFileExcel, FaWpforms, FaMoneyCheck, FaUserLock, FaFilePdf, FaUserPlus, FaCloud, FaBell, FaWifi, FaBug, FaChartPie, FaArrowLeft, FaArrowRight, FaTrafficLight, FaCalendar, FaUser, FaChartBar, FaExclamationTriangle, FaUserCog, FaMicrochip, FaClipboard, FaHandshake, FaDollarSign, FaMapPin, FaFileInvoiceDollar, FaEye, FaDashcube, FaBlog, FaArrowDown, FaCaretDown, FaCaretRight, FaExclamation, FaUserClock, FaUpload, FaMailBulk, FaPassport, FaTag, FaFileInvoice, FaShippingFast, FaShoppingCart, FaStore, FaDatabase, FaPlusCircle,
    FaVest,
    FaMap,
    FaUserInjured,
    FaAtlas,
    FaIdBadge,
    FaCog
} from 'react-icons/fa';
import { FiSettings } from 'react-icons/fi';
import { AiFillHome, AiFillSchedule, AiOutlineSchedule, } from 'react-icons/ai';
import { TbReportAnalytics } from 'react-icons/tb';
import { BsBoxes, BsCalendar, BsCalendar3, BsChatLeft } from 'react-icons/bs';
import { MdAlarm, MdGroupAdd, MdHelp, MdNotificationAdd, MdOutlineSensors } from 'react-icons/md';
import { TbNetwork } from 'react-icons/tb'
import { CgProfile } from "react-icons/cg";
import { useRole } from "../context/role";
import { FiRepeat, } from 'react-icons/fi';
import { FaCubes } from 'react-icons/fa';
import { FaUmbrellaBeach, FaPlaneDeparture, FaRoad, FaCarCrash } from 'react-icons/fa';
import { GoReport } from 'react-icons/go';
import { BsCashCoin } from 'react-icons/bs'
import { AiOutlineAlert } from 'react-icons/ai'
import { TbPlusMinus } from 'react-icons/tb';
import { HiOutlineTag } from 'react-icons/hi';
import { FaDownload, FaHome, } from 'react-icons/fa';
import { ImFilePdf } from 'react-icons/im';
import { BsCashStack } from 'react-icons/bs';
import { MdAttachMoney } from 'react-icons/md';
import { BsActivity, BsCheckLg, BsInbox, BsXLg } from "react-icons/bs";
import { FaTruckLoading } from "react-icons/fa";
import { Link, useNavigate } from 'react-router-dom';
import { HiUserGroup } from "react-icons/hi";
import { MdOutlineBadge } from "react-icons/md";
import { useAuth } from '../context/auth';
import { AiOutlineLogout } from "react-icons/ai";
import ConfirmationModal from '../components/ConfirmationModal';
import MCTMS_LOGO from '../assets/mctms-logo.png'
import { FaBuildingFlag, FaMagnifyingGlass, FaTruckFront } from 'react-icons/fa6';
import { HiOutlineDocumentReport } from "react-icons/hi";
import { CiBoxList, CiSquareQuestion, CiViewTable } from "react-icons/ci";
import { MdOutlinePersonalInjury } from "react-icons/md";
import { GiPoliceBadge } from "react-icons/gi";
import { FaBuildingUser } from "react-icons/fa6";
import { FaUsps } from "react-icons/fa";
import { GoContainer } from "react-icons/go";
import { CgTemplate } from "react-icons/cg";
import { GiWoodenCrate } from "react-icons/gi";
import { RiDashboard3Line, RiMoneyDollarCircleLine } from "react-icons/ri";
import { CiPercent } from "react-icons/ci";
import { HiOutlineIdentification } from "react-icons/hi2";
import { useLocation } from 'react-router-dom';
import { GrHomeOption, GrScorecard } from 'react-icons/gr';
import { IoBusiness } from 'react-icons/io5';
import { multidimensionalGroupBy } from '../utils/Array.helper';
import LOGO from '../assets/logo.png'
import SYBR_LOGO from '../assets/sybr-logo.png'

import { appFeatureGroups } from '../context/appfeaturegroups'

function FeatureBar() {
    const { user, logout } = useAuth();
    const [showModal, setShowModal] = useState();
    const { userRole, checkNavPermissions } = useRole();

    const featureGroups = appFeatureGroups;

    const allowedFeatures = checkNavPermissions(userRole, featureGroups);

    function filterFeatures(data) {
        const filteredData = [];
        const length = data.length;

        for (let i = 0; i < length; i++) {
            const currentItem = data[i];
            const currentFeature = currentItem.feature;
            const prevItem = i > 0 ? data[i - 1] : null;
            const prevFeature = prevItem?.feature ?? null;
            const nextItem = i < length - 1 ? data[i + 1] : null;
            const nextFeature = nextItem?.feature ?? null;

            // Check if currentItem has an href
            if (currentItem.href) {
                filteredData.push(currentItem);
            } else {
                if (currentFeature === prevFeature && currentFeature === nextFeature) {
                    // Check if there is a prevItem or nextItem with an href
                    if ((prevItem && prevItem.href) || (nextItem && nextItem.href)) {
                        filteredData.push(currentItem);
                    }
                }
            }
        }

        return filteredData;
    }

    const filteredFeatures = filterFeatures(allowedFeatures);

    const groups = multidimensionalGroupBy(filteredFeatures, ['feature'])

    const containerStyle = {
        backgroundColor: '#f9f9f9',
        borderBottom: '1px solid #ccc'
    }

    const navigate = useNavigate();
    const navigateTo = (path) => {
        navigate(path);
    }

    async function handleLogout() {
        await logout()
    }


    return (
        <Container fluid style={containerStyle}>
            <Navbar expand="lg" >
                <Container fluid>
                    <Navbar.Brand>
                        <Link to="/">
                            <Image src={SYBR_LOGO} alt={'SYBR Logo'} style={{ minHeight: '3vh', maxHeight: '5vh', margin: '0 0.25em' }} />
                        </Link>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            {Object.keys(groups).map((feature,i) => (
                                <NavDropdown key={i} title={feature} id="basic-nav-dropdown">
                                    {groups[feature].map((endpoint,j) => (
                                        (endpoint?.href) ?
                                            <NavDropdown.Item key={j} onClick={() => navigateTo(endpoint?.href)}>{endpoint.icon} {endpoint.text}</NavDropdown.Item>
                                            :
                                            <NavDropdown.Divider key={j} />
                                    ))}
                                </NavDropdown>
                            ))}
                        </Nav>
                    </Navbar.Collapse>
                    <Navbar.Collapse className="justify-content-end">
                        <Navbar.Text>
                            {userRole.name} : {user.email}
                        </Navbar.Text>
                        <Image src={LOGO} alt={'Logo'} style={{ minHeight: '3vh', maxHeight: '5vh', margin: '0 0.25em' }} onClick={() => setShowModal(true)} />
                        <ConfirmationModal show={showModal} onHide={() => setShowModal(false)} onConfirm={() => handleLogout()} message={'Are you sure you want to logout?'} />
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </Container>
    )
}

export default FeatureBar;

