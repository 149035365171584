import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { useNavigate } from 'react-router-dom';
import { Modal, Button, Table, Container, Card } from 'react-bootstrap';


const ScatterPlot = ({ redirect, data, width, height }) => {
  const navigate = useNavigate();

  useEffect(() => {
    //remove existing svg to prevent duplication
    d3.select("#scatter-plot").selectAll("*").remove();

    //setting graph dimensions and margins
    const margin = { top: 80, right: 200, bottom: 50, left: 60 };
    const legendX = width + margin.left + margin.right - 250;
    const legendY = margin.top / 4;

    // append svg object to the body of the page
    const svg = d3.select("#scatter-plot")
      .append('svg')
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    // add x axis
    const x = d3.scaleLinear()
      .domain([-120, 120])
      .range([0, width]);
    svg.append("g")
      .attr("transform", "translate(0," + height / 2 + ")")
      .call(d3.axisBottom(x));

    // add y axis
    const y = d3.scaleLinear()
      .domain([-120, 120])
      .range([height, 0]);
    svg.append("g")
      .attr("transform", "translate(" + width / 2 + ", 0)")
      .call(d3.axisLeft(y));

    // add tooltip
    const tooltip = d3.select("#scatter-plot")
      .append("div")
      .style("opacity", 0)
      .attr("class", "tooltip")
      .style("background-color", "white")
      .style("border", "solid")
      .style("border-width", "1px")
      .style("border-radius", "5px")
      .style("padding", "10px")
      .style('position', 'absolute')
      .style("font-size", "12px");

    // hover effect
    const mouseover = function (event, d) {
      tooltip.transition().duration(50).style("opacity", 1);
      d3.select(this)
        .style("opacity", 1)
        .style('cursor', "pointer")
    }

    const mousemove = function (event, d) {
      tooltip
        .html(`
            Shipment ID: ${d.shipmentId}<br/>
            Date: ${d?.date}<br>
            Contact Number: ${d.contractNumber}<br>
            Trip Number: ${d.tripNumber}<br/>
            Arrival Time Difference: ${d.arrivalTimeDifference} min<br/>
            Departure Time Difference: ${d.departureTimeDifference} min
            `)
        .style("left", (event.offsetX + 10) + "px")
        .style("top", (event.offsetY + 10) + "px")
        .style("opacity", 1);
    }

    // hover off
    const mouseleave = function (event, d) {
      tooltip.transition().duration(50).style("opacity", 0);
      d3.select(this)
        .style("opacity", 0.5)
        .style('cursor', "default");
    }

    // click dot to open new tab with specific details
    const mouseclick = function (event, d) {
      tooltip.transition().duration(50).style("opacity", 0);
      window.open(`${redirect}/${d.shipmentId}`, '_blank');
    }

    // add dots
    svg.append("g")
      .selectAll('dot')
      .data(data)
      .enter()
      .append("circle")
      .attr("cx", d => x(d.arrivalTimeDifference))
      .attr("cy", d => y(d.departureTimeDifference))
      .attr("r", 7)
      .style("fill", d => {
        if (d.arrivalTimeDifference >= 0 && d.departureTimeDifference >= 0) {
          return "green";
        } else if (d.arrivalTimeDifference < 0 && d.departureTimeDifference < 0) {
          return "red";
        } else if (d.arrivalTimeDifference >= 0 && d.departureTimeDifference < 0) {
          return "blue";
        } else if (d.arrivalTimeDifference < 0 && d.departureTimeDifference >= 0) {
          return "orange";
        }
      })
      .style("stroke", "white")
      .style('opacity', 0.5)
      .on("mouseover", mouseover)
      .on("mousemove", mousemove)
      .on("mouseleave", mouseleave)
      .on('click', mouseclick);

    // add legend
    const legend = svg.append('g')
      .attr('class', 'legend')
      .attr("transform", "translate(" + legendX + "," + legendY + ")")
      .style("font-size", "12px")

    legend.append('circle')
      .attr('cx', 0)
      .attr('cy', 0)
      .attr('r', 5)
      .style('fill', 'green')

    legend.append('text')
      .attr('x', 10)
      .attr('y', 5)
      .text("On-time arrival and departure")

    legend.append('circle')
      .attr('cx', 0)
      .attr('cy', 20)
      .attr('r', 5)
      .style('fill', 'blue')

    legend.append('text')
      .attr('x', 10)
      .attr('y', 25)
      .text("On-time arrival, late departure")

    legend.append('circle')
      .attr('cx', 0)
      .attr('cy', 40)
      .attr('r', 5)
      .style('fill', 'orange')

    legend.append('text')
      .attr('x', 10)
      .attr('y', 45)
      .text("Late arrival, on-time departure")

    legend.append('circle')
      .attr('cx', 0)
      .attr('cy', 60)
      .attr('r', 5)
      .style('fill', 'red')

    legend.append('text')
      .attr('x', 10)
      .attr('y', 65)
      .text("Late arrival and departure")


  }, [])

  return (
    <Card style={{ textAlign: 'center' }}>
      <div id='scatter-plot'></div>
    </Card>
  );
};

export default ScatterPlot;