import { Card, Container } from "react-bootstrap";
import { useAuth } from "../../context/auth";



function DriverScoreCard() {
    const { user, idToken } = useAuth();

    return (
        <Container fluid style={{ margin: 0, padding: 0 }}>
            <Card style={{ border: 0, borderRadius: 0 }}>
                <Card.Header>
                    Timecards
                </Card.Header>
                <Card.Body>

                </Card.Body>
            </Card>
        </Container>
    )
}

export default DriverScoreCard;