import { useEffect, useMemo, useRef, useState } from "react";
import { Accordion, Badge, Button, Card, Col, Container, Form, OverlayTrigger, Row, Spinner, Table, Tooltip } from "react-bootstrap";
import { FaArrowDown, FaArrowLeft, FaArrowRight, FaArrowUp, FaBox, FaCalendar, FaCheck, FaCross, FaInfo, FaPlus, FaSearch, FaTrash, FaXing } from 'react-icons/fa'
import AlertDisplay from "../../components/AlertDisplay";
import Api from "../../utils/Api";
import { useAuth } from "../../context/auth";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import ConfirmationDangerModal from "../../components/ConfirmationDangerModal";
import { MdClear, MdDragIndicator } from "react-icons/md";
import { FaXmark } from "react-icons/fa6";
import { useDrag, useDrop } from 'react-dnd';
import { formatDateStrToMMDDYY } from "../../utils/DateTimeFormat.helper";
import { RiCheckboxBlankLine, RiCheckboxLine } from "react-icons/ri";

const initialSchedule = {
    name: '',
    effectiveDate: '',
    weekStartDOW: '',
    type: '',
    schedules: [],
    routes: [],
    routesOverMidnight: [],
    overmidnight: 'No',
    defaultDriverPool: '',
}


// if weekStarts w/ Sunday keys should be ordered
const sunWeeklySchedules = {
    sunday: [],
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: []
}
const sunWeeklyRoutes = { ...sunWeeklySchedules }
const sunWeeklyOvermidnight = { ...sunWeeklySchedules }

// if weekStarts w/ Monday keys should be ordered
const monWeeklySchedules = {
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
    sunday: []
}
const monWeeklyRoutes = { ...monWeeklySchedules }
const monWeeklyOvermignight = { ...monWeeklySchedules }

// const satWeeklySchedules = {
//     saturday: [],
//     sunday: [],
//     monday: [],
//     tuesday: [],
//     wednesday: [],
//     thursday: [],
//     friday: [],
// }
// const satWeeklyRoutes = { ...satWeeklySchedules }
// const satWeeklyOvermignight = { ...satWeeklySchedules }

// Function to format date to yyyy-mm-dd
function formatDate(date) {
    let year = date.getFullYear();
    let month = (date.getMonth() + 1).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
}

const today = new Date();
const futureDate = new Date();
futureDate.setDate(futureDate.getDate() + 7);

const initialQuery = {
    contract: 'All',
    startdate: formatDate(today),
    enddate: formatDate(futureDate),
    weekStartDOW: 'Sunday'
};


function LoadTenderScheduler({ feature, form }) {
    const { id } = useParams()
    const navigate = useNavigate();
    const { user, idToken } = useAuth();
    const [load, didLoad] = useState();
    const [didForecast, setDidForecast] = useState();
    const [set1, didSet1] = useState(false);
    const [set2, didSet2] = useState(false);
    const [effectiveDate, setEffectiveDate] = useState();
    const [weekStartDOW, setWeekStartDOW] = useState('Sunday');
    const [trySchedule, setTrySchedule] = useState(false)
    const [scheduleName, setScheduleName] = useState();
    const [schedule, setSchedule] = useState(initialSchedule);
    const [scheduleForecast, setScheduleForecast] = useState();
    const [alerts, setAlerts] = useState([])
    const alertState = { alerts, setAlerts };
    const [routes, setRoutes] = useState([]);
    const [schedules, setSchedules] = useState([])
    const [routesOverMidnight, setRoutesOverMidnight] = useState([]);
    const [currentContract, setCurrentContract] = useState('');
    const [contracts, setContracts] = useState([])
    const [contractTrips, setContractTrips] = useState([])
    const [contractTripStops, setContractTripStops] = useState('')
    const [currentTrip, setCurrentTrip] = useState('');
    const [changeDetected, setChangeDetected] = useState(false)
    const [changesDetected, setChangesDetected] = useState([])
    const [type, setType] = useState('')
    const [overmidnight, setOverMidnight] = useState('')

    const [fetchingTrips, setFetchingTrips] = useState(false)

    const [book, setBook] = useState([]);

    function handleBookAll() {
        const dates = scheduleForecast.map((sched) => sched.date)
        setBook(dates)
    }

    function handleUnbookAll() {
        setBook([])
    }

    function handleBooking(date) {
        book.includes(date)
            ? setBook([...book.filter((val) => val !== date)])
            : setBook([...book, date])
    }

    // samsara block
    const [operatorlocation, setOperatorLocation] = useState('All');
    const [samsaraDrivers, setSamsaraDrivers] = useState();
    const [samsaraDriversByLocation, setSamsaraDriversByLocation] = useState();

    const [showDeleteModal, setShowDeleteModal] = useState()

    const [loadingTrip, setLoadingTrip] = useState(false)

    function handleAddSchedule() {
        
        let schedWeekly, routesWeekly, routesOverMidnightWeekly;

        if (schedule.weekStartDOW === 'Sunday') {
            schedWeekly = sunWeeklySchedules;
            routesWeekly = sunWeeklyRoutes;
            routesOverMidnightWeekly = sunWeeklyOvermidnight;
        } else {
            schedWeekly = monWeeklySchedules;
            routesWeekly = monWeeklyRoutes;
            routesOverMidnightWeekly = monWeeklyOvermignight;
        }

        setSchedules([...schedules, schedWeekly]);
        setRoutes([...routes, routesWeekly]);
        setRoutesOverMidnight([...routesOverMidnight, routesOverMidnightWeekly]);
    }

    function handleContractChange(value) {
        setCurrentContract(value)
        setContractTrips([])
        setCurrentTrip('')
    }

    function handleDriverRemove(index, key, jndex) {
        const updatedSchedules = [...schedules];

        updatedSchedules[index] = {
            ...updatedSchedules[index],
            [key]: updatedSchedules[index][key].filter((_, i) => i !== jndex)
        };

        setSchedules(updatedSchedules)
    }

    function handleRemoveSchedule(index) {
        const updatedSchedules = schedules.filter((_, i) => i !== index)
        const updatedRoutes = routes.filter((_, i) => i !== index)
        const updatedRoutesOverMidnight = routesOverMidnight.filter((_, i) => i !== index)
        setSchedules([...updatedSchedules])
        setRoutes([...updatedRoutes])
        setRoutesOverMidnight([...updatedRoutesOverMidnight])
    }

    async function handleCreateSchedule() {
        try {
            const newSchedule = {
                name: scheduleName,
                effectiveDate: effectiveDate,
                weekStartDOW: weekStartDOW,
                type: type,
                routes: routes,
                overmidnight: overmidnight,
                routesOverMidnight: routesOverMidnight,
                schedules: schedules,
                createdBy: user.email,
            }
            const createDoc = await Api.post('/loadtenders/schedules', { ...newSchedule }, idToken);
            setAlerts([...alerts, { variant: 'success', message: 'Success' }])
            navigate(`/${feature}/schedule/${createDoc._id}`)
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: `An error occured. ${JSON.stringify(error.error)}` }])
        }
    }

    async function handleUpdateSchedule() {
        try {
            const updateSchedule = {
                name: scheduleName,
                effectiveDate: effectiveDate,
                weekStartDOW: weekStartDOW,
                type: type,
                routes: routes,
                overmidnight: overmidnight,
                routesOverMidnight: routesOverMidnight,
                schedules: schedules,
                updatedBy: user.email,
            }
            await Api.patch(`/loadtender/schedule/${id}`, { ...updateSchedule }, idToken);
            setAlerts([...alerts, { variant: 'success', message: 'Success' }])
            setDidForecast(false)
            setChangeDetected(false)
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: JSON.stringify(error.error) }])
        }
    }

    async function handleForecastSchedule() {
        try {
            const queryStr = new URLSearchParams(query).toString()
            const forecastedSchedule = await Api.get(`/loadtender/schedule-forecast-v2/${id}?${queryStr}`, idToken)
            setScheduleForecast(forecastedSchedule)
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'Failed to forecast.' }])
        } finally {
            setDidForecast(true)
        }
    }

    async function handleScheduleNow() {
        setTrySchedule(true)
        try {
            await Api.post(`/loadtender/schedule-now/${id}`, { useremail: user.email, book: book }, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Scheheduling complete.' }])
            setDidForecast(false)
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'Failed to run scheduler.' }])
        } finally {
            setTrySchedule(false)
        }
    }

    async function handleDeleteSchedule() {
        try {
            await Api.delete(`/loadtender/schedule/${id}`, idToken);
            setAlerts([...alerts, { variant: 'success', message: 'Success' }])
            setShowDeleteModal(false)
            navigate(`/${feature}/schedules`)
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'Failed to save.' }])
        }
    }

    const [scheduleFetched, setScheduleFetched] = useState();
    async function fetchScheduleId() {
        try {
            const data = await Api.get(`/loadtender/schedule/${id}?useremail=${user?.email}`, idToken);
     
            if (data) {
                setType(data.type)
                setEffectiveDate(data?.effectiveDate)
                setSchedules(data?.schedules)
                setRoutes(data?.routes)
                setOverMidnight(data?.overmidnight)
                setRoutesOverMidnight(data?.routesOverMidnight)
                setScheduleName(data?.name)
                setWeekStartDOW(data?.weekStartDOW)
                setScheduleFetched(true)
            }
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'Failed to fetch Schedule.' }])
        }
    }

    const prevEffectiveDate = useRef();
    const prevWeekStartDOW = useRef();
    const prevOverMidnight = useRef();
    const prevSchedules = useRef();
    const prevRoutes = useRef();
    const prevRoutesOverMidnight = useRef();

    useEffect(() => {
        let change = '';
        if (scheduleFetched) {
            // Check if effectiveDate has changed
            if (prevEffectiveDate.current !== undefined && prevEffectiveDate.current !== effectiveDate) {
                // console.log('Effective date has changed');
                change = 'Effective date has changed.'
                !changesDetected.includes(change) && setChangesDetected([...changesDetected, change]);
                setChangeDetected(true)
            }

            if (prevWeekStartDOW.current !== undefined && prevWeekStartDOW.current !== weekStartDOW) {
                // console.log('Week Start Change');
                setChangesDetected([...changesDetected, 'Effective date has changed.']);
                !changesDetected.includes(change) && setChangesDetected([...changesDetected, change]);
                setChangeDetected(true)
            }

            if (prevOverMidnight.current !== undefined && prevOverMidnight.current !== overmidnight) {
                // console.log('Over Midnight Change');
                change = 'Trips overmidnight changed.'
                !changesDetected.includes(change) && setChangesDetected([...changesDetected, change]);
                setChangeDetected(true)
            }


            if (prevSchedules.current !== undefined && prevSchedules.current !== JSON.stringify(schedules)) {
                // console.log('Schedules Change');
                change = 'Coverage changed'
                !changesDetected.includes(change) && setChangesDetected([...changesDetected, change]);
                setChangeDetected(true)
            }

            if (prevRoutes.current !== undefined && prevRoutes.current !== JSON.stringify(routes)) {
                // console.log('Routes Change');
                change = 'Routes changed'
                !changesDetected.includes(change) && setChangesDetected([...changesDetected, change]);
                setChangeDetected(true)
            }

            if (prevRoutesOverMidnight.current !== undefined && prevRoutesOverMidnight.current !== JSON.stringify(routesOverMidnight)) {
                // console.log('Routes Over Midnight Change');
                change = 'Routes over midnight changed.'
                !changesDetected.includes(change) && setChangesDetected([...changesDetected, change]);
                setChangeDetected(true)
            }

            // Store the current effectiveDate for future comparisons
            prevEffectiveDate.current = effectiveDate;
            prevWeekStartDOW.current = weekStartDOW;
            prevOverMidnight.current = overmidnight
            prevSchedules.current = JSON.stringify(schedules)
            prevRoutes.current = JSON.stringify(routes)
            prevRoutesOverMidnight.current = JSON.stringify(routesOverMidnight)
        }
    }, [scheduleFetched, effectiveDate, weekStartDOW, overmidnight, schedules, routes, routesOverMidnight]);

    async function fetchUspsContracts() {
        try {
            const data = await Api.get('/loadtender/usps/contracts', idToken);
            setContracts(data)
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'Failed to fetch Contract Trips' }])
        } finally {
            didSet2(true)
        }
    }

    async function fetchUspsContractTrips() {
        setFetchingTrips(true)
        try {
            if (currentContract !== '') {
                const data = await Api.get(`/loadtender/usps/contract/${currentContract}/trips`, idToken);
                // console.log(data)
                const sortedData = data.sort((a, b) => a._id - b._id)
                setContractTrips(sortedData)
            }
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'Failed to fetch Contract\'s Trips' }])
        } finally {
            setFetchingTrips(false)
        }

    }

    // async function fetchUspsContractTripStops() {
    //     try {
    //         const data = await Api.get(`/loadtender/usps/contract/${currentContract}/trip/${currentTrip}/stops`, idToken);
    //         setContractTripStops(data)
    //     } catch {
    //         setAlerts([...alerts, { variant: 'warning', message: 'Failed to fetch Contract\'s Trips' }])
    //     }
    // }

    const fetchSamsaraDrivers = async () => {
        try {
            const driversdata = await Api.get(`/samsara/drivers-active`, idToken);
            const drivers = driversdata?.data;
            const groupedData = drivers.reduce((groups, record) => {
                const homeTerminalName = record?.carrierSettings?.homeTerminalName;
                if (!groups[homeTerminalName]) {
                    groups[homeTerminalName] = [];
                }
                groups[homeTerminalName].push(record);
                return groups;
            }, {});

            const sortedKeys = Object.keys(groupedData).sort();

            const sortedGroupedData = {};
            for (const key of sortedKeys) {
                sortedGroupedData[key] = groupedData[key];
            }

            setSamsaraDrivers(drivers)
            setSamsaraDriversByLocation(sortedGroupedData)
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'Error fetching active drivers' }])
        } finally {
            didSet1(true)
        }
    }

    const [query, setQuery] = useState(() => {
        const savedQuery = localStorage.getItem(`@sybrtms-edischeduler-query`);
        return savedQuery !== null ? JSON.parse(savedQuery) : initialQuery;
    });

    function setQueryForecastDates(val) {
        if (val === 0) {
            setQuery({ ...query, startdate: formatDate(today), enddate: formatDate(today), })
        } else {
            const newEnd = new Date(query.enddate);
            newEnd.setDate(newEnd.getDate() + val);
            const ed = formatDate(newEnd)
            setQuery({ ...query, enddate: ed, })
        }

    }


    function setQueryForecastForward() {
        const newStart = new Date(query.startdate);
        newStart.setDate(newStart.getDate() + 2);
        const newEnd = new Date(query.enddate);
        newEnd.setDate(newEnd.getDate() + 2);
        const sd = formatDate(newStart)
        const ed = formatDate(newEnd)
        setQuery({ ...query, startdate: sd, enddate: ed });
    }

    function setQueryForecastBackward() {
        const newStart = new Date(query.startdate);
        newStart.setDate(newStart.getDate() + 0);
        const newEnd = new Date(query.enddate);
        newEnd.setDate(newEnd.getDate() + 0);
        const sd = formatDate(newStart)
        const ed = formatDate(newEnd)
        setQuery({ ...query, startdate: sd, enddate: ed });
    }

    useEffect(() => {
        setDidForecast(false)
    }, [schedule, effectiveDate, weekStartDOW])

    useEffect(() => {
        if (id) {
            fetchScheduleId();
        }
    }, [id])

    useEffect(() => {
        if (currentContract.trim() !== '') {
            fetchUspsContractTrips();
        }
    }, [currentContract])

    // useEffect(() => {
    //     if (currentTrip.trim() !== '') {
    //         fetchUspsContractTrips();
    //         fetchUspsContractTripStops()
    //     }
    // }, [currentTrip])


    useEffect(() => {
        if (!set1) {
            fetchSamsaraDrivers();
        }
        if (!set2) {
            fetchUspsContracts();
        }

        didLoad(true)
    }, [set1, set2])


    useEffect(() => {
        if (id && !didForecast) {
            handleForecastSchedule();
        }
    }, [id, didForecast])


    const timeoutRef = useRef(null);
    useEffect(() => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
            setDidForecast(false);
        }, 1500); // 1.5 second debounce

        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, [query.startdate, query.enddate]);

    useEffect(() => {
        switch (weekStartDOW) {
            case 'Sunday':
                setSchedules([...schedules].sort((a, b) => {
                    return Object.keys(sunWeeklySchedules).indexOf(a) - Object.keys(sunWeeklySchedules).indexOf(b);
                }));
                setRoutes([...routes].sort((a, b) => {
                    return Object.keys(sunWeeklyRoutes).indexOf(a) - Object.keys(sunWeeklyRoutes).indexOf(b);
                }));
                Array.isArray(routesOverMidnight) && setRoutesOverMidnight([...routesOverMidnight].sort((a, b) => {
                    return Object.keys(sunWeeklyOvermidnight).indexOf(a) - Object.keys(sunWeeklyOvermidnight).indexOf(b);
                }));
                break;

            case 'Monday':
                setSchedules([...schedules].sort((a, b) => {
                    return Object.keys(monWeeklySchedules).indexOf(a) - Object.keys(monWeeklySchedules).indexOf(b);
                }));
                setRoutes([...routes].sort((a, b) => {
                    return Object.keys(monWeeklyRoutes).indexOf(a) - Object.keys(monWeeklyRoutes).indexOf(b);
                }));
                Array.isArray(routesOverMidnight) && setRoutesOverMidnight([...routesOverMidnight].sort((a, b) => {
                    return Object.keys(monWeeklyOvermignight).indexOf(a) - Object.keys(monWeeklyOvermignight).indexOf(b);
                }));
                break;
            default:
                break;
        }
    }, [weekStartDOW])


    function capitalizeFirstLetter(s) {
        return s.charAt(0).toUpperCase() + s.slice(1);
    }

    function collapseUSPSContractTripData(data) {
        try {
            const items = data.split(', ').map(item => {
                const [prefix, number] = item.split(' ');
                return { prefix, number };
            });

            const result = [];
            let currentPrefix = items[0].prefix;
            let currentNumbers = [];

            items.forEach(({ prefix, number }, index) => {
                if (prefix !== currentPrefix) {
                    if (currentNumbers.length) {
                        result.push(`${currentPrefix} (${currentNumbers.join(' -> ')})`);
                    }
                    currentPrefix = prefix;
                    currentNumbers = [number];
                } else {
                    currentNumbers.push(number);
                }

                if (index === items.length - 1) {
                    result.push(`${currentPrefix} (${currentNumbers.join(' -> ')})`);
                }
            });

            return result.join('\n');
        } catch (error) {
            // console.error('An error occurred:', error);
            return '';
        }
    }

    // drag drop route
    const handleDragTripStart = (trip) => {
        setCurrentTrip(trip)
        setCurrentDraggingElement('trip')
    };

    const [draggingDriver, setDraggingDriver] = useState();

    const handleDragDriverStart = (e, driver) => {
        setDraggingDriver(driver)
        setCurrentDraggingElement('driver')
    };

    const handleOverMidnightDragOver = (e) => {
        e.preventDefault();
        setCurrentDropZone('overmidnight')
    };

    const [draggingCopy, setDraggingCopy] = useState();
    const handleDragCopyStart = (index, dayOfWeek) => {
        setDraggingCopy([index, dayOfWeek])
    };

    const handleTripDragOver = (e) => {
        e.preventDefault();
        setCurrentDropZone('trip')
    };

    const handleDriverDragOver = (e) => {
        e.preventDefault();
        setCurrentDropZone('driver')
    };

    const handleCopyDragOver = (e) => {
        e.preventDefault();
        setCurrentDropZone('copy')
    };

    const [currentDraggingElement, setCurrentDraggingElement] = useState();
    const [currentDropZone, setCurrentDropZone] = useState();

    const handleDropTrip = async (e) => {
        console.log('Drop trip')
        e.preventDefault();
        if (!currentContract || !currentTrip) {
            setAlerts([...alerts, { variant: 'warning', message: 'Drag and drop trips here.', }])
            return;
        }
        if (currentDraggingElement !== 'trip') return;
        if (currentDropZone !== 'trip') return;
        setLoadingTrip(true)
        try {
            const { id } = e.currentTarget;
            const [week, dow, type] = id.split('-');
            // const stops = await Api.get(`/loadtender/usps/contract/${currentContract}/trip/${currentTrip}/stops`, idToken);
            if (type === 'trip') {
                const route = {
                    contract: currentContract,
                    trip: currentTrip,
                    // stops: stops,
                }

                const updateRoutesDropTrip = [...routes];

                if (!updateRoutesDropTrip[week]) {
                    updateRoutesDropTrip[week] = {};
                }
                if (!updateRoutesDropTrip[week][dow]) {
                    updateRoutesDropTrip[week][dow] = [];
                }

                updateRoutesDropTrip[week][dow] = [...updateRoutesDropTrip[week][dow], route];

                setRoutes([...updateRoutesDropTrip]);
            }

        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured loading trip.', }])
        } finally {
            setLoadingTrip(false)
            setCurrentTrip()
        }

    };

    const handleDropOverMidnight = async (e) => {
        console.log('Drop overnight')
        e.preventDefault();
        if (!currentContract || !currentTrip) {
            setAlerts([...alerts, { variant: 'warning', message: 'Drag and drop trips here.', }])
            return;
        }
        if (currentDraggingElement !== 'trip') return;
        if (currentDropZone !== 'overmidnight') return;
        setLoadingTrip(true)
        try {
            const { id } = e.currentTarget;
            const [week, dow, type] = id.split('-');
            // const stops = await Api.get(`/loadtender/usps/contract/${currentContract}/trip/${currentTrip}/stops`, idToken);

            if (type === 'overmidnight') {
                const route = {
                    contract: currentContract,
                    trip: currentTrip,
                    // stops: stops,
                }

                const updatedRoutesOverMidnight = [...routesOverMidnight];

                if (!updatedRoutesOverMidnight[week]) {
                    updatedRoutesOverMidnight[week] = {};
                }
                if (!updatedRoutesOverMidnight[week][dow]) {
                    updatedRoutesOverMidnight[week][dow] = [];
                }

                updatedRoutesOverMidnight[week][dow] = [...updatedRoutesOverMidnight[week][dow], route];

                setRoutesOverMidnight([...updatedRoutesOverMidnight]);
            }

        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured loading trip over midnight.', }])
        } finally {
            setLoadingTrip(false)
            setCurrentTrip()
        }

    };

    const handleDropDriver = async (e) => {
        console.log('Drop driver')
        e.preventDefault();
        if (!draggingDriver?.name || !draggingDriver.name) {
            setAlerts([...alerts, { variant: 'warning', message: 'Drag and drop drivers here.', }])
            return;
        }
        if (currentDraggingElement !== 'driver') return;
        if (currentDropZone !== 'driver') return;
        try {
            const { id } = e.currentTarget;
            const [week, dow, type] = id.split('-');
            if (type === 'coverage' && draggingDriver?.name) {
                const updatedSchedules = [...schedules];
                updatedSchedules[week] = {
                    ...updatedSchedules[week],
                    [dow]: [...updatedSchedules[week][dow], draggingDriver]
                };
                setSchedules(updatedSchedules)
            }
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured.', }])
        } finally {
            setDraggingDriver()
        }

    };

    const handleDropCopy = async (e) => {
        e.preventDefault();

        try {
            const [week, dow] = draggingCopy;
            const { id } = e.currentTarget;
            const [dropWeek, dropDow] = id.split('-');
            //
            const draggingSchedule = schedules[week][dow]
            const updatedSchedules = [...schedules];
            if (!updatedSchedules[dropWeek]) {
                updatedSchedules[dropWeek] = {};
            }
            if (!updatedSchedules[dropWeek][dropDow]) {
                updatedSchedules[dropWeek][dropDow] = [];
            }
            updatedSchedules[dropWeek][dropDow] = [...draggingSchedule];
            //
            const daggingRoute = routes[week][dow]
            const updateRoutes = [...routes];
            if (!updateRoutes[dropWeek]) {
                updateRoutes[dropWeek] = {};
            }
            if (!updateRoutes[dropWeek][dropDow]) {
                updateRoutes[dropWeek][dropDow] = [];
            }
            updateRoutes[dropWeek][dropDow] = [...daggingRoute];
            //
            const daggingRouteOverMignight = routesOverMidnight[week][dow]
            const updateRoutesOverMignight = [...routesOverMidnight];
            if (!updateRoutesOverMignight[dropWeek]) {
                updateRoutesOverMignight[dropWeek] = {};
            }
            if (!updateRoutesOverMignight[dropWeek][dropDow]) {
                updateRoutesOverMignight[dropWeek][dropDow] = [];
            }

            updateRoutesOverMignight[dropWeek][dropDow] = [...daggingRouteOverMignight];

            setSchedules([...updatedSchedules])
            setRoutes([...updateRoutes])
            setRoutesOverMidnight([...updateRoutesOverMignight])
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'An error copying.', }])
        } finally {
            setDraggingCopy()
        }

    };

    function clearWeekDow(index, dayOfWeek) {
        const updateSchedules = [...schedules];
        updateSchedules[index][dayOfWeek] = [];
        setSchedules(updateSchedules);
        //
        const updateRoutes = [...routes];
        updateRoutes[index][dayOfWeek] = [];
        setRoutes(updateRoutes);
        //
        const updateRoutesOverMidnight = [...routesOverMidnight];
        updateRoutesOverMidnight[index][dayOfWeek] = [];
        setRoutesOverMidnight(updateRoutesOverMidnight);
    }

    const [searchingTrip, setSearchingTrip] = useState()
    const [searchedTripStops, setSearchedTrip] = useState()
    const handleSearchTrip = async (trip) => {
        setCurrentTrip()
        setSearchingTrip(true)
        try {
            const stops = await Api.get(`/loadtender/usps/contract/${currentContract}/trip/${trip}/stops`, idToken);
            setCurrentTrip(trip)
            setSearchedTrip(stops)
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured loading trip.', }])
        }
        setSearchingTrip(false)
    }

    function removeRouteWkDay(index, key, jndex) {
        const updatedRoutes = [...routes];
        updatedRoutes[index] = {
            ...updatedRoutes[index],
            [key]: updatedRoutes[index][key].filter((_, i) => i !== jndex)
        };
        setRoutes(updatedRoutes)
    }

    function removeOverMidnightWkDay(index, key, jndex) {
        const updatedRoutesOverMidnight = [...routesOverMidnight];
        updatedRoutesOverMidnight[index] = {
            ...updatedRoutesOverMidnight[index],
            [key]: updatedRoutesOverMidnight[index][key].filter((_, i) => i !== jndex)
        };
        setRoutesOverMidnight(updatedRoutesOverMidnight);
    }

    function resetUI() {
        setSchedule(initialSchedule)
        setScheduleName('')
        setEffectiveDate('')
        setType('')
        setEffectiveDate('')
        setSchedules([])
        setRoutes([])
        setRoutesOverMidnight([])
        setCurrentContract('')
    }

    const [visibleGroups, setVisibleGroups] = useState([]);
    const toggleGroupVisibility = (groupIndex) => {
        if (visibleGroups.includes(groupIndex)) {
            setVisibleGroups(visibleGroups.filter((index) => index !== groupIndex));
        } else {
            setVisibleGroups([...visibleGroups, groupIndex]);
        }
    };

    const groupedTrips = [];
    let groupSize = 20;
    if (contractTrips.length <= 40) {
        groupSize = contractTrips.length
    }

    for (let i = 0; i < contractTrips.length; i += groupSize) {
        groupedTrips.push(contractTrips.slice(i, i + groupSize));
    }


    const cellStyle = {
        border: '1px solid #ccc'
    }

    const evenCellStyle = {
        backgroundColor: '#3366cc',
        border: '1px solid #ccc'
    }

    const oddCellStyle = {
        backgroundColor: '#385e2b',
        border: '1px solid #ccc'
    }

    const evenCellStyleLight = {
        backgroundColor: '#a1b8e7',
        border: '1px solid #ccc'
    }

    const oddCellStyleLight = {
        backgroundColor: '#c5e0bb',
        border: '1px solid #ccc'
    }


    return (
        <Container fluid style={{ margin: 0, padding: 0 }}>
            <AlertDisplay alertState={alertState} />
            <ConfirmationDangerModal
                show={showDeleteModal}
                onHide={() => setShowDeleteModal(false)}
                onConfirm={() => handleDeleteSchedule()}
                message={'Are you sure you want to delete this schedule?'}
            />
            <Card style={{ borderRadius: 0, border: 0 }}>
                <Card.Header>
                    <Row>
                        <Col>
                            <b>{capitalizeFirstLetter(form)} Schedule</b>
                        </Col>
                        <Col xs="auto">
                            <Link to={'/loadtender/quickcover'}>Unscheduled</Link>
                        </Col>
                        <Col xs="auto">
                            <Link to={'/loadtender/schedules'}>Schedules</Link>
                        </Col>
                        <Col xs="auto">
                            <Link to={'/loadtender/scheduler'}>New Schedule</Link>
                        </Col>
                    </Row>
                </Card.Header>
                {(!set1 || !set2) ? <Spinner />
                    :
                    <Card.Body>
                        <Card>
                            <Card.Header>
                                <Row>
                                    <Col>
                                        <Card.Title>Schedule's Route</Card.Title>
                                    </Col>
                                    <Col xs="auto">
                                        <Button variant="warning" onClick={() => resetUI()}>Reset</Button>
                                    </Col>
                                </Row>
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col xs="12" lg="6">
                                        <Card.Title>Enter a Name for the Schedule</Card.Title>
                                        <Form.Control
                                            type="text"
                                            value={scheduleName}
                                            onChange={(e) => setScheduleName(e.target.value)}
                                        />
                                    </Col>
                                    <Col xs="12" lg="6">
                                        <Row>
                                            <Col xs="auto">
                                                <Card.Title>Schedule Type</Card.Title>
                                                <Form.Select
                                                    style={type === "" ? { borderColor: 'red' } : { borderColor: '#dee2e6' }}
                                                    value={type}
                                                    onChange={(e) => setType(e.target.value)}>
                                                    <option value=""></option>
                                                    <option>Solo</option>
                                                    <option>Team</option>
                                                    <option>Relay</option>
                                                </Form.Select>
                                            </Col>
                                            <Col xs="auto">
                                                <Card.Title>Over Midnight</Card.Title>
                                                <Form.Select
                                                    style={overmidnight === "" ? { borderColor: 'red' } : { borderColor: '#dee2e6' }}
                                                    defaultValue={overmidnight}
                                                    onChange={(e) => setOverMidnight(e.target.value)}>
                                                    <option value=""></option>
                                                    <option value="No">No</option>
                                                    <option value="Yes">Yes</option>
                                                </Form.Select>
                                            </Col>
                                            <Col xs="auto">
                                                <Card.Title>Effective Date</Card.Title>
                                                <Form.Control
                                                    style={!effectiveDate ? { borderColor: 'red' } : { borderColor: '#dee2e6' }}
                                                    value={effectiveDate} type="date" onChange={(e) => setEffectiveDate(e.target.value)} />
                                            </Col>
                                            <Col xs="auto">
                                                <Card.Title>Week Starts On</Card.Title>
                                                <Form.Select value={weekStartDOW} onChange={(e) => setWeekStartDOW(e.target.value)}>
                                                    <option value="Sunday">Sunday</option>
                                                    <option value="Monday">Monday</option>
                                                </Form.Select>
                                            </Col>
                                            {loadingTrip &&
                                                <Col xs="auto">
                                                    <Spinner size="lg" /> Loading trip...
                                                </Col>
                                            }
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="auto">
                                        <Card.Title>Driver Pool</Card.Title>
                                        <Form.Select
                                            // defaultValue={schedule?.defaultDriverPool}
                                            onChange={(e) => {
                                                setOperatorLocation(e.target.value);
                                                setSchedule({ ...schedule, defaultDriverPool: e.target.value })
                                            }}>
                                            <option>All</option>
                                            {samsaraDriversByLocation && Object.keys(samsaraDriversByLocation).map((key, k) => (
                                                <option key={k + '-drivers'} value={key}>{key ? key : 'Undefined'}</option>
                                            ))}
                                        </Form.Select>
                                    </Col>
                                    <Col>
                                        <br />
                                        <Row>
                                            {samsaraDriversByLocation[operatorlocation] && samsaraDriversByLocation[operatorlocation].sort((a, b) => a.name.localeCompare(b.name)).map((driver, di) => (
                                                <OverlayTrigger
                                                    key={di}
                                                    placement="auto"
                                                    overlay={
                                                        <Tooltip id={`tooltip-${di}`}>
                                                            Drag and drop into trips. Click on magnifier to lookup trip.
                                                        </Tooltip>
                                                    }
                                                >
                                                    <Col xs="auto">
                                                        <Row style={{ padding: '3px', border: '1px solid black', margin: '0px', cursor: 'grab' }}>
                                                            <Col xs="auto"
                                                                draggable
                                                                onDragStart={(e) => handleDragDriverStart(e, driver)}
                                                                onDragEnd={() => setDraggingDriver()}
                                                                style={{ margin: '0.1em', padding: '0.1em' }}
                                                            >
                                                                <MdDragIndicator /> {driver.name}
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </OverlayTrigger>
                                            ))}
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="auto">
                                        <Card.Title>Contract</Card.Title>
                                        {contracts.length > 0
                                            &&
                                            <Form.Select onChange={(e) => handleContractChange(e.target.value)}>
                                                <option selected={currentContract === '' ? true : false}>Select Contract...</option>
                                                {contracts.map((c, i) => (
                                                    <option key={i + '-contracts'} value={c._id}>
                                                        {c._id}
                                                    </option>
                                                ))}
                                            </Form.Select>
                                        }
                                    </Col>
                                    <Col>
                                        {currentContract !== '' &&
                                            <Row>
                                                <Card.Title>Trips</Card.Title>
                                                {currentContract !== '' && fetchingTrips && <Spinner />}
                                                {currentContract !== '' && contractTrips.length > 0 &&
                                                    <>
                                                        <Row>
                                                            {groupedTrips.length > 3
                                                                ? groupedTrips.map((group, index) => (
                                                                    <Col xs="auto" key={index}>
                                                                        <Badge bg={visibleGroups.includes(index) ? "primary" : "secondary"} onClick={() => toggleGroupVisibility(index)}>
                                                                            {visibleGroups.includes(index) ? 'Hide ' : 'Show '} {group[0]._id} <FaArrowRight /> {group[group.length - 1]._id}
                                                                        </Badge>
                                                                        {visibleGroups.includes(index) && (
                                                                            <Row>
                                                                                {group.map((trip) => (
                                                                                    <OverlayTrigger
                                                                                        key={trip._id}
                                                                                        placement="auto"
                                                                                        overlay={
                                                                                            <Tooltip id={`tooltip-${trip._id}`}>
                                                                                                Drag and drop into trips. Click on magnifier to lookup trip.
                                                                                            </Tooltip>
                                                                                        }
                                                                                    >
                                                                                        <Col xs="auto">
                                                                                            <Row style={{ padding: '3px', border: '1px solid black', margin: '0px', cursor: 'grab' }}>
                                                                                                <Col xs="auto"
                                                                                                    draggable
                                                                                                    onDragStart={() => handleDragTripStart(trip._id)}
                                                                                                    onDragEnd={() => setCurrentTrip()}
                                                                                                    style={{ margin: '0.1em', padding: '0.1em' }}
                                                                                                >
                                                                                                    <MdDragIndicator /> {trip._id}
                                                                                                </Col>
                                                                                                <Col xs="auto" style={{ margin: '0.1em', padding: '0.1em' }}>
                                                                                                    <FaSearch onClick={() => handleSearchTrip(trip._id)} />
                                                                                                </Col>
                                                                                            </Row>
                                                                                        </Col>
                                                                                    </OverlayTrigger>
                                                                                ))}
                                                                            </Row>
                                                                        )}
                                                                    </Col>

                                                                ))
                                                                :
                                                                <Col xs="auto" >
                                                                    <Row>
                                                                        {contractTrips.map((trip) => (
                                                                            <OverlayTrigger
                                                                                key={trip._id}
                                                                                placement="auto"
                                                                                overlay={
                                                                                    <Tooltip id={`tooltip-${trip._id}`}>
                                                                                        Drag and drop into trips. Click on magnifier to lookup trip.
                                                                                    </Tooltip>
                                                                                }
                                                                            >
                                                                                <Col xs="auto">
                                                                                    <Row style={{ padding: '3px', border: '1px solid black', margin: '0px', cursor: 'grab' }}>
                                                                                        <Col xs="auto"
                                                                                            draggable
                                                                                            onDragStart={() => handleDragTripStart(trip._id)}
                                                                                            onDragEnd={() => setCurrentTrip()}
                                                                                            style={{ margin: '0.1em', padding: '0.1em' }}
                                                                                        >
                                                                                            <MdDragIndicator /> {trip._id}
                                                                                        </Col>
                                                                                        <Col xs="auto" style={{ margin: '0.1em', padding: '0.1em' }}>
                                                                                            <FaSearch onClick={() => handleSearchTrip(trip._id)} />
                                                                                        </Col>
                                                                                    </Row>
                                                                                </Col>
                                                                            </OverlayTrigger>
                                                                        ))}
                                                                    </Row>
                                                                </Col>
                                                            }
                                                        </Row>
                                                    </>
                                                }
                                            </Row>
                                        }
                                    </Col>
                                </Row>
                                {searchedTripStops &&
                                    <Row>
                                        <Col>
                                            Search Result : <br />
                                            {currentContract} {currentTrip} <br />
                                            {searchingTrip
                                                ? <Spinner />
                                                : <pre>{searchedTripStops}</pre>
                                            }
                                        </Col>
                                    </Row>
                                }
                                <Table responsive>
                                    <thead>
                                        <tr>
                                            <th>
                                                <Button variant="secondary" onClick={() => handleAddSchedule()} style={{ backgroundColor: '#575a5f', fontSize: '.75em', color: '#fbb514', margin: '0', padding: '0.1em 1em' }}>
                                                    <FaPlus />Week
                                                </Button>
                                            </th>
                                            {Object.keys(weekStartDOW === 'Sunday' ? sunWeeklySchedules : monWeeklySchedules).map((dow, dk) => (
                                                <th key={dk}>{dow.toUpperCase()}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Array.from({ length: schedules.length }, (_, i) => i).map(i => (
                                            <tr key={i + '-schedules'} >
                                                <td style={i % 2 === 0 ? evenCellStyle : oddCellStyle}><h3 style={{ color: '#eee', whiteSpace: 'nowrap' }}>WK {i + 1}</h3></td>
                                                {Object.keys(weekStartDOW === 'Sunday' ? sunWeeklySchedules : monWeeklySchedules).map((dow, j) => (
                                                    <td key={i + '-' + j + '-dow'} style={i % 2 === 0 ? evenCellStyleLight : oddCellStyleLight}>
                                                        <Card.Body style={{ padding: '2px' }} >
                                                            <div>
                                                                <Row>
                                                                    <Col xs="auto">
                                                                        <div style={{ cursor: 'pointer' }}
                                                                            draggable
                                                                            onDragStart={() => handleDragCopyStart(i, dow)}
                                                                        >
                                                                            <MdDragIndicator />
                                                                        </div>
                                                                    </Col>
                                                                    <Col>
                                                                        <div
                                                                            id={`${i}-${dow}-copy`}
                                                                            onDragOver={handleCopyDragOver}
                                                                            onDrop={handleDropCopy}
                                                                            style={{ height: '100%', border: '1px dashed #ccc ', textAlign: 'center', backgroundColor: '#eee', alignContent: 'center' }}>
                                                                            Drop Copy
                                                                        </div>
                                                                    </Col>
                                                                    <Col xs="auto">
                                                                        <Badge
                                                                            bg="warning"
                                                                            onClick={() => clearWeekDow(i, dow)}
                                                                        ><FaXmark /></Badge>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </Card.Body>
                                                        <Card.Body
                                                            id={`${i}-${dow}-coverage`}
                                                            onDragOver={handleDriverDragOver}
                                                            onDrop={handleDropDriver}
                                                            onDragEnd={() => setCurrentDropZone()}
                                                            style={{ border: '1px dashed #ccc ', minHeight: '100px', textAlign: 'center', backgroundColor: '#fff' }}
                                                        >
                                                            <Card.Subtitle><i>Coverage</i></Card.Subtitle>
                                                            {schedules[i][dow].length > 0 ?
                                                                schedules[i][dow].map((driver, k) => (
                                                                    <Row key={i + '-' + j + '-' + k + '-driver'}
                                                                        style={{
                                                                            alignItems: 'center',
                                                                            border: '1px solid #ccc',
                                                                            margin: '0',
                                                                            padding: '1px',
                                                                            backgroundColor: '#fff'
                                                                        }}>
                                                                        <Col>{driver.name}</Col>
                                                                        <Col xs="auto">
                                                                            <Badge bg="warning" onClick={() => handleDriverRemove(i, dow, k)}><MdClear /></Badge>
                                                                        </Col>
                                                                    </Row>
                                                                ))
                                                                :
                                                                <Badge bg="warning" style={{ margin: '0.25em 0' }}> No one is scheduled. </Badge>
                                                            }
                                                        </Card.Body>
                                                        <Card.Body
                                                            id={`${i}-${dow}-trip`}
                                                            onDragOver={handleTripDragOver}
                                                            onDragEnd={() => setCurrentDropZone()}
                                                            onDrop={handleDropTrip}
                                                            style={{ border: '1px dashed #ccc ', minHeight: '100px', textAlign: 'center', backgroundColor: '#fff' }}
                                                        >
                                                            <Card.Subtitle><i>Trips</i></Card.Subtitle>
                                                            {(routes[i] && routes[i][dow] && routes[i][dow].length > 0) ?
                                                                Object.keys(routes[i][dow]).map((route, k) => (
                                                                    <Row
                                                                        key={i + '-' + j + '-' + k + '-trip'}
                                                                        style={{
                                                                            alignItems: 'center',
                                                                            border: '1px solid #ccc',
                                                                            margin: '0',
                                                                            padding: '1px',
                                                                            backgroundColor: '#fff'
                                                                        }}>
                                                                        <Col xs="auto">
                                                                            {routes[i][dow][route].contract} {routes[i][dow][route].trip}
                                                                        </Col>
                                                                        <Col>
                                                                        </Col>
                                                                        <Col xs="auto">
                                                                            <Badge bg="warning" onClick={() => removeRouteWkDay(i, dow, k)}>X</Badge>
                                                                        </Col>
                                                                    </Row>
                                                                ))
                                                                :
                                                                <Badge bg="warning" style={{ margin: '0.25em 0' }}> No trips are scheduled. </Badge>
                                                            }
                                                        </Card.Body>
                                                        {overmidnight === 'Yes' &&
                                                            <Card.Body
                                                                id={`${i}-${dow}-overmidnight`}
                                                                onDragOver={handleOverMidnightDragOver}
                                                                onDrop={handleDropOverMidnight}
                                                                onDragExit={() => setCurrentDropZone()}
                                                                onDragEnd={() => setCurrentDropZone()}
                                                                style={{ border: '1px dashed #ccc ', minHeight: '100px', textAlign: 'center', backgroundColor: '#fff' }}
                                                            >
                                                                <Card.Subtitle><i>Over Midnight</i></Card.Subtitle>
                                                                {(routesOverMidnight && routesOverMidnight.length > 0 && routesOverMidnight[i] && routesOverMidnight[i][dow] && routesOverMidnight[i][dow].length > 0) ?
                                                                    Object.keys(routesOverMidnight[i][dow]).map((routeOvermid, k) => (
                                                                        <Row
                                                                            key={i + '-' + j + '-' + k + '-overmidnight'}
                                                                            style={{
                                                                                alignItems: 'center',
                                                                                border: '1px solid #ccc',
                                                                                margin: '0',
                                                                                padding: '1px',
                                                                                backgroundColor: '#fff'
                                                                            }}>
                                                                            <Col xs="auto">
                                                                                {routesOverMidnight[i][dow][routeOvermid].contract} {routesOverMidnight[i][dow][routeOvermid].trip}
                                                                            </Col>
                                                                            <Col>
                                                                            </Col>
                                                                            <Col xs="auto">
                                                                                <Badge bg="warning" onClick={() => removeOverMidnightWkDay(i, dow, k)}>X</Badge>
                                                                            </Col>
                                                                        </Row>
                                                                    ))
                                                                    :
                                                                    <Badge bg="warning" style={{ margin: '0.25em 0' }}> No trips are scheduled <br /> to start past midnight. </Badge>
                                                                }
                                                            </Card.Body>
                                                        }
                                                    </td>
                                                ))}
                                                <td style={cellStyle}>
                                                    <Badge bg="danger" onClick={() => handleRemoveSchedule(i)}><FaTrash /></Badge>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                {form === 'update' &&
                                    <Card style={{ borderRadius: 0 }}>
                                        <Card.Header>
                                            <Card.Title>
                                                Schedule's Forecast
                                            </Card.Title>
                                        </Card.Header>
                                        <Card.Header>
                                            <Row>
                                                <Col xs="auto">
                                                    <Button variant="primary" style={{ padding: 3, magrin: 3, alignItems: 'center' }} onClick={() => handleBookAll()}>
                                                        <RiCheckboxLine /> Check All
                                                    </Button>
                                                </Col>
                                                <Col xs="auto">
                                                    <Button variant="secondary" style={{ padding: 3, magrin: 3, alignItems: 'center' }} onClick={() => handleUnbookAll()}>
                                                        <RiCheckboxBlankLine /> Uncheck All
                                                    </Button>
                                                </Col>
                                                <Col xs="auto">
                                                    {/* <button onClick={() => setQueryForecastBackward()}><FaArrowLeft /></button> */}
                                                    Start
                                                    <input
                                                        type="date"
                                                        style={{ backgroundColor: '#fff' }}
                                                        value={query?.startdate}
                                                        onChange={(e) => setQuery({ ...query, startdate: e.target.value })}
                                                    // readOnly
                                                    // disabled
                                                    />
                                                    End
                                                    <input
                                                        style={{ backgroundColor: '#fff' }}
                                                        type="date" value={query?.enddate}
                                                        onChange={(e) => setQuery({ ...query, enddate: e.target.value })}
                                                    // readOnly
                                                    // disabled
                                                    />
                                                    {/* <button onClick={() => setQueryForecastForward()}><FaArrowRight /></button> */}

                                                </Col>
                                                {!didForecast &&
                                                    <Col xs="auto">
                                                        <Spinner />
                                                    </Col>
                                                }
                                                <Col xs="auto">
                                                    <button onClick={() => setQueryForecastBackward()}><FaArrowLeft /></button>
                                                    <button onClick={() => setQueryForecastForward()}><FaArrowRight /></button>
                                                    <button onClick={() => setQueryForecastDates(0)}><FaCalendar /></button>
                                                    <button onClick={() => setQueryForecastDates(2)}>+1</button>
                                                    <button onClick={() => setQueryForecastDates(3)}>+3</button>
                                                    <button onClick={() => setQueryForecastDates(7)}>+7</button>
                                                    <button onClick={() => setQueryForecastDates(14)}>+14</button>
                                                    <button onClick={() => setQueryForecastDates(28)}>+28</button>
                                                </Col>

                                            </Row>
                                        </Card.Header>
                                        {/* <Accordion style={{ borderRadius: 0 }}>
                                            <Accordion.Item eventKey="0" style={{ borderRadius: 0 }}>
                                                <Accordion.Header><b>How to read Schedule Forecast?</b></Accordion.Header>
                                                <Accordion.Body>
                                                    <Card.Subtitle>
                                                        Forecasting beings based on the schedule effective date.
                                                    </Card.Subtitle>
                                                    <br />
                                                    <Card.Subtitle>
                                                        Schedule Coverage = the coverage based on "Schedule Coverage" above.
                                                    </Card.Subtitle>
                                                    <br />
                                                    <Card.Subtitle>
                                                        Current Coverage = shows the coverage currently covering the shipment(s).
                                                    </Card.Subtitle>
                                                    <br />
                                                    <Card.Subtitle>
                                                        Contract (Trip) = shows the USPS contract number with it's associated (trips).
                                                    </Card.Subtitle>
                                                    <br />
                                                    <Card.Subtitle>
                                                        Mismatch = shows schedule missing shipments. This means a a Shipment does not exist on the scheduled date. Example, if you combind multiple trips, and only one tender exists, a mistmatch will show designating mising service.
                                                    </Card.Subtitle>
                                                    <br />
                                                    <Card.Subtitle>
                                                        Shipments = provides a reference to each shipment for the scheduled date.
                                                    </Card.Subtitle>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion> */}
                                        <Card.Body>
                                            {!changeDetected &&
                                                <Table responsive>
                                                    <thead>
                                                        <tr>
                                                            <th>Book</th>
                                                            <th>Date</th>
                                                            <th>Week</th>
                                                            <th>Day of Week</th>
                                                            <th>Schedule Coverage</th>
                                                            <th>Current Coverage</th>
                                                            <th>Coverage Change</th>
                                                            <th>Contract (Trips)</th>
                                                            {overmidnight === 'Yes' &&
                                                                <th>Over Midnight</th>
                                                            }
                                                            <th>Missing Trips</th>
                                                            <th>Shipment IDs</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {scheduleForecast && scheduleForecast.map((row, i) => (
                                                            <tr key={i}>
                                                                <td style={row?.shipments.length > 0 ? { backgroundColor: 'inherit' } : { backgroundColor: '#f39c9c' }}>
                                                                    {row?.shipments.length > 0 &&
                                                                        <Form.Check
                                                                            checked={book.includes(row?.date)}
                                                                            onChange={() => handleBooking(row?.date)}
                                                                        />
                                                                    }
                                                                </td>
                                                                <td
                                                                    style={row?.shipments.length > 0 ? { backgroundColor: 'inherit' } : { backgroundColor: '#f39c9c' }}
                                                                >
                                                                    {formatDateStrToMMDDYY(row?.date)}
                                                                </td>
                                                                <td style={row?.shipments.length > 0 ? { backgroundColor: 'inherit' } : { backgroundColor: '#f39c9c' }}>
                                                                    {(row?.wkIndex + 1)}
                                                                </td>
                                                                <td style={row?.shipments.length > 0 ? { backgroundColor: 'inherit' } : { backgroundColor: '#f39c9c' }}>
                                                                    {(row?.dayOfWeek).toUpperCase()}
                                                                </td>
                                                                {row?.shipments.length > 0 &&
                                                                    <>
                                                                        <td style={row?.shipments.length > 0 ?
                                                                            row?.wkIndex % 2 === 0 ? evenCellStyleLight : oddCellStyleLight
                                                                            : { backgroundColor: '#f39c9c' }}>
                                                                            <pre>{row?.newDrivers.split(',').join('\n')}</pre>
                                                                        </td>
                                                                        <td style={row?.shipments.length > 0 ? { backgroundColor: '#eee' } : { backgroundColor: '#f39c9c' }}>
                                                                            {row?.curDrivers === 'NEEDS COVERAGE' ?
                                                                                <Badge bg="warning">
                                                                                    NEEDS COVERAGE
                                                                                </Badge>
                                                                                :
                                                                                <pre>{row?.curDrivers.split(',').join('\n')}</pre>
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            {row?.coverageAction}
                                                                        </td>
                                                                        <td style={row?.shipments.length > 0 ? { backgroundColor: 'inherit', } : { backgroundColor: '#f39c9c' }}>
                                                                            {collapseUSPSContractTripData(row?.routes)}
                                                                        </td>
                                                                        {overmidnight === 'Yes' &&
                                                                            <td>
                                                                                {collapseUSPSContractTripData(row?.routesOverMidnight)}
                                                                            </td>
                                                                        }
                                                                        <td style={row?.shipments.length > 0 ? { backgroundColor: 'inherit' } : { backgroundColor: '#f39c9c' }}>
                                                                            {row?.mismatch.length > 0 &&
                                                                                <Badge bg="warning">
                                                                                    Shipments DNE
                                                                                </Badge>
                                                                            }
                                                                            <pre>
                                                                                {collapseUSPSContractTripData(row?.mismatch)}
                                                                            </pre>
                                                                        </td>

                                                                    </>
                                                                }
                                                                <td
                                                                    colSpan={row?.shipments.length > 0 ? 1 : overmidnight === 'Yes' ? 8 : 7}
                                                                    style={row?.shipments.length > 0 ? { backgroundColor: 'inherit' } : { backgroundColor: '#f39c9c' }}>
                                                                    {row?.shipments.length === 0 ? <><FaXmark /> No Shipments </> :
                                                                        <>
                                                                            {row.shipmentIds.map((element) => (
                                                                                <div key={element} >
                                                                                    <Link
                                                                                        to={`/loadtender/${element}`}
                                                                                        target="_blank"
                                                                                        rel="noopener noreferrer"
                                                                                    >
                                                                                        {element}
                                                                                    </Link>
                                                                                </div>
                                                                            ))}
                                                                        </>
                                                                    }
                                                                </td>

                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                            }
                                        </Card.Body>
                                        <Card.Body>
                                            {trySchedule ?
                                                <>
                                                    <Spinner />
                                                </> :
                                                <>
                                                    {!changeDetected ?
                                                        <Col xs="auto">
                                                            {book.length > 0 ?
                                                                <OverlayTrigger
                                                                    placement="auto"
                                                                    overlay={
                                                                        <Tooltip id="tooltip-right">
                                                                            This will schedule coverage on loads marked with a check.
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <Button variant="success" onClick={() => handleScheduleNow()}> Schedule Now </Button>
                                                                </OverlayTrigger>
                                                                :
                                                                <Col xs="auto" >
                                                                    * Check boxes in column "Book" to cover loads.
                                                                </Col>
                                                            }

                                                        </Col>
                                                        :
                                                        <Col xs="auto" >
                                                            <h4>Change detected.</h4>
                                                            <p>Click "Update" to implement changes. Or click "Reload Page" to undo changes.</p>
                                                            <ul>
                                                                {changesDetected && changesDetected.map((change, i) => (
                                                                    <li key={i}>{change}</li>
                                                                ))}
                                                            </ul>
                                                        </Col>

                                                    }
                                                </>
                                            }

                                        </Card.Body>
                                    </Card>
                                }

                                {
                                    load ?
                                        <>
                                            {form === 'create' &&
                                                <Row>
                                                    <Col xs="auto">
                                                        <Button variant="primary" onClick={() => handleCreateSchedule()}> Create </Button>
                                                    </Col>
                                                    <Col xs="auto">
                                                        <Button variant="warning" onClick={() => window.location.reload()}> Reload Page </Button>
                                                    </Col>
                                                </Row>
                                            }
                                            <br />
                                            {form === 'update' &&
                                                <Row>
                                                    <Col xs="auto">
                                                        <Button variant="primary" onClick={() => handleUpdateSchedule()}> Update </Button>
                                                    </Col>
                                                    <Col xs="auto">
                                                        <Button variant="warning" onClick={() => window.location.reload()}> Reload Page </Button>
                                                    </Col>
                                                    {/* <Col xs="auto">
                                                        <OverlayTrigger
                                                            placement="right"
                                                            overlay={
                                                                <Tooltip id="tooltip-right">
                                                                    This will create a copy of the schedule.
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <Button variant="secondary" onClick={() => handleCreateScheduleCopy()}> Duplicate </Button>
                                                        </OverlayTrigger>
                                                    </Col> */}
                                                    <Col>
                                                    </Col>
                                                    <Col xs="auto" style={{ justifyContent: 'center' }}>
                                                        <OverlayTrigger
                                                            placement="auto"
                                                            overlay={
                                                                <Tooltip id="tooltip-right">
                                                                    Delete the schedule, this cannot be undone.
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <Button variant="danger" onClick={() => setShowDeleteModal(true)}> Delete </Button>
                                                        </OverlayTrigger>
                                                    </Col>
                                                </Row>
                                            }
                                        </>
                                        :
                                        <h3>
                                            Scheduling... <Spinner size="md" />
                                        </h3>
                                }
                            </Card.Body>
                        </Card>
                    </Card.Body>
                }
            </Card>
        </Container>
    )
}


export default LoadTenderScheduler;
