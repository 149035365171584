import Access from '../features/access/Access';
import AccessNav from '../features/access/Access.nav';
import AccessProfileForm from '../features/access/Access.profile.form';
import AccessProfileUpdate from '../features/access/Access.profile.update';
import AccessRoleForm from '../features/access/Access.role.form';
import AccessRoleUpdate from '../features/access/Access.role.update';
import Assignment from '../features/assignment/Assignment';
import AssignmentForm from '../features/assignment/Assignment.form';
import AssignmentUpdate from '../features/assignment/Assignment.update';
import Contract from '../features/contract/Contract';
import ContractForm from '../features/contract/Contract.form';
import ContractUpdate from '../features/contract/Contract.update';
import Dispatch from '../features/dispatch/Dispatch';
import DispatchDetail from '../features/dispatch/Dispatch.detail';
import DispatchForm from '../features/dispatch/Dispatch.form';
import DispatchNav from '../features/dispatch/Dispatch.nav';
import DispatchReports from '../features/dispatch/Dispatch.reports';
import DispatchSettings from '../features/dispatch/Dispatch.settings';
import DispatchUpdate from '../features/dispatch/Dispatch.update';
import IOT from '../features/telematics/IOT';
import FiftyFiveHundredNav from '../features/ffh/FiftyFivehundred.nav';
import FiftyFivehundredUpload from '../features/ffh/FiftyFiveHundred.upload';
import FiftyFiveHundredInfo from '../features/ffh/FiftyFiveHundred.info';
import FiftyFiveHundredTable from '../features/ffh/FiftyFiveHundred.table';
import Frequency from '../features/frequency/Frequency';
import FrequencyForm from '../features/frequency/Frequency.form';
import FrequencyUpdate from '../features/frequency/Frequency.update';
import Fuel from '../features/fuel/PADDFuel';
import HCR from '../features/hcr/HCR';
import Home from '../views/Home'
import LoadEye from '../features/loadeye/LoadEye';
import LoadTender from '../features/loadtender/LoadTender';
import LoadTenderNav from '../features/loadtender/LoadTender.nav';
import LoadTenderActive from '../features/loadtender/LoadTender.active';
import LoadTenderCompleted from '../features/loadtender/LoadTender.completed';
import LoadTenderDashboard from '../features/loadtender/LoadTender.dashboard';
import LoadTenderRejected from '../features/loadtender/LoadTender.rejected';
import LoadTenderRequests from '../features/loadtender/LoadTender.requests';
import LoadTenderResponse from '../features/loadtender/LoadTender.response';
import LoadTenderUpdate from '../features/loadtender/LoadTender.update';
import LoadTenderView from '../features/loadtender/LoadTender.view';
import Location from '../features/location/Location';
import LocationForm from '../features/location/Location.form';
import LocationUpdate from '../features/location/Location.update';
import Manifest from '../features/manifest/Manifest';
import Operator from '../features/operator/Operator';
import OperatorForm from '../features/operator/Operator.form';
import OperatorUpdate from '../features/operator/Operator.update';
import Plan from '../features/plan/Plan'
import RoutePlanIssues from '../features/routeplan/RoutePlan.issues';
import PlanNav from '../features/plan/Plan.nav';
import Profile from '../views/Profile';
import RoutePlan from '../features/routeplan/RoutePlan';
import RoutePlanForecast from '../features/routeplan/RoutePlan.forecast';
import RoutePlanForm from '../features/routeplan/RoutePlan.form';
import RoutePlanUpdate from '../features/routeplan/RoutePlan.update';

import Settings from '../features/settings/Settings'
import SpecialOp from '../features/specialop/SpecialOp';
import SpecialOpForm from '../features/specialop/SpecialOp.form';
import SpecialOpUpdate from '../features/specialop/SpecialOp.update';

import Trailer from '../features/trailer/Trailer';
import TrailerForm from '../features/trailer/Trailer.form';
import TrailerUpdate from '../features/trailer/Trailer.update';
import Vehicle from '../features/vehicle/Vehicle';
import VehicleForm from '../features/vehicle/Vehicle.form';
import VehicleUpdate from '../features/vehicle/Vehicle.update';
import FiftyFivehundredReports from '../features/ffh/FiftyFiveHundred.reports';
import EmployeeReimbursement from '../features/form/employeereimbursement/EmployeeReimbursement';
import EmployeeReimbursementForm from '../features/form/employeereimbursement/EmployeeReimbursement.form';
import EmployeeReimbursementUpdate from '../features/form/employeereimbursement/EmployeeReimbursement.update';
import AccessNewUsers from '../features/access/Access.newusers';
import AccessNewUserForm from '../features/access/Access.newuser.form';

import AccessGroup from '../features/alarms/Access.group';
import AccessGroupForm from '../features/alarms/Access.group.form';
import AccessGroupUpdate from '../features/alarms/Access.group.update';

import Weather from '../features/weather/Weather';
import DispatchDashboard from '../features/dispatch/Dispatch.dashboard';
import Samsara from '../features/eld/Samsara';
import Notifcation from '../features/notification/Notification';
import NotifcationForm from '../features/notification/Notification.form';
import Job from '../features/job/Job';
import JobForm from '../features/job/Job.form';
import JobUpdate from '../features/job/Job.update';
import JobNav from '../features/job/Job.nav';
import DispatchBuilder from '../features/dispatch/Dispatch.builder';
import Help from '../views/Help';
import AccessRole from '../features/access/Access.role';
import AccessProfile from '../features/access/Access.profile';
import GeoTab from '../features/eld/GeoTab';
import TimecardProfile from '../features/timecard/Timecard.profile';
import TimecardInfo from '../features/timecard/Timecard.info';
import TimecardPayPeriods from '../features/timecard/Timecard.payperiods';
import SafetyNav from '../features/safety/Safety.nav';
import Safety from '../features/safety/Safety.dashboard';
import ManifestNav from '../features/manifest/Manifest.nav';
import ManifestForm from '../features/manifest/Manifest.form';
import DispatchOpen from '../features/dispatch/Dispatch.open';
import DispatchCalendar from '../features/dispatch/Dispatch.calendar';
import AccessStale from '../features/access/Access.stale';
import LoadTenderIOT from '../features/loadtender/LoadTender.iot';
import Billing from '../features/billing/Billing';
import LoadTenderDashboardV3 from '../features/loadtender/LoadTender.dashboard.v3';
import LoadTenderReports from '../features/loadtender/LoadTender.reports';
import LoadTenderUpdateV3 from '../features/loadtender/LoadTender.update.v3';
import LoadTenderAdminReports from '../features/loadtender/LoadTender.reports.admin';
import Log from '../features/logs/Log';
import NotificationLog from '../features/logs/Notification';
import AccidentDataSection from '../features/safety/AccidentDataSection';
import AccidentFormSection from '../features/safety/AccidentFormSection';
import InjuryDataSection from '../features/safety/InjuryDataSection';
import InjurySection from '../features/safety/InjurySection';
import AccidentSection from '../features/safety/AccidentSection';
import InjuryFormSection from '../features/safety/InjuryFormSection';
import AccidentUpdate from '../features/safety/AccidentUpdate';
import InjuryUpdate from '../features/safety/InjuryUpdate';
import DriverProfile from '../features/driver/Driver.Profile';
import Manager from '../features/manager/Manager';
import LoadTenderBuilder from '../features/loadtender/LoadTender.builder';
import LoadTenderDashboardUSPS from '../features/loadtender/LoadTender.dashboard.usps';
import LoadTenderDashboardFreight from '../features/loadtender/LoadTender.dashboard.freight';
import DriverScoreCard from '../features/driver/Driver.ScoreCard';
import DriverAccident from '../features/driver/Driver.Accident';
import DriverInjury from '../features/driver/Driver.Injury';
import DriverLoadTenders from '../features/driver/Driver.LoadTenders';
import ManagerDriverScoreCard from '../features/manager/Manager.driverscorecard';
import LoadTenderTemplates from '../features/loadtender/LoadTender.templates';
import LoadTenderCustomer from '../features/loadtender/LoadTender.customer';
import AccessActive from '../features/access/Access.active';
import BugReport from '../views/BugReport';
import FeatureRequest from '../views/FeatureRequest';
import NotificationUpdate from '../features/notification/Notification.update';
import NotificationReports from '../features/notification/Notification.reports';
import AccidentUpload from '../features/safety/AccidentUpload';
import InjuryUpload from '../features/safety/InjuryUpload';
import LoadTenderScheduler from '../features/loadtender/LoadTender.Scheduler';
import LoadTenderSchedules from '../features/loadtender/LoadTender.Schedules';
import EDI204Component from '../features/loadtender/EDI204.component';
import EDI990Component from '../features/loadtender/EDI990.component';
import EDI214Component from '../features/loadtender/EDI214.component';
import EDI210Component from '../features/loadtender/EDI210.component';
import FreightDashboard from '../features/freight/Freight.Dashboard';
import BillsOfLading from '../features/freight/BillsOfLading';
import TradingPartners from '../features/shippers/Shippers';
import TradingPartner from '../features/shippers/Shipper';
import FreightLocations from '../features/freight/Locations';
import FreightDrivers from '../features/freight/Drivers';
import FreightAccounting from '../features/freight/Accounting';
import BillOfLading from '../features/freight/BillOfLading';
import ProofOfDelivery from '../features/freight/ProofOfDelivery';
import Carriers from '../features/carrier/Carriers';
import Carrier from '../features/carrier/Carrier';
import Customers from '../features/customer/Customers';
import Customer from '../features/customer/Customer';
import FreightLocation from '../features/freight/Location';
import FreightDriver from '../features/freight/Driver';
import FreightVehicle from '../features/freight/Vehicle';
import FreightTrailer from '../features/freight/Trailer';
import FreightRequests from '../features/freight/Requests';
import FreightVehicles from '../features/freight/Vehicles';
import FreightTrailers from '../features/freight/Trailers';
import BreakThroughFuel from '../features/breakthroughfuel/BreakThroughFuel';
import BreakThroughFuelForm from '../features/breakthroughfuel/BreakThroughFuel.form';
import LoadPayReconciliation from '../features/ileclear/LoadPayReconciliation';
import LoadPayReconciliationForm from '../features/ileclear/LoadPayReconciliation.form';
import FiftyFiveHundred from '../features/5500/FiftyFiveHundred';
import FiftyFiveHundredForm from '../features/5500/FiftyFiveHundred.form';
import PADDFuel from '../features/fuel/PADDFuel';
import WholesaleFuel from '../features/fuel/WholesaleFuel';
import RateConfirmation from '../features/freight/RateConfirmation';
import Company from '../features/company/Company';
import Companies from '../features/company/Companies';
import DataPage from '../views/DataPage';
import Tags from '../features/tags/Tags';
import LoadTenderRateCon from '../features/loadtender/LoadTender.ratecon';
import LoadTenderPreSigned from '../features/loadtender/LoadTender.presigned';
import LoadTenderForecast from '../features/loadtender/LoadTender.forecast';
import Dashboard from '../features/payroll/Dashboard';
import PayrollDashboard from '../features/payroll/Dashboard';
import Timecards from '../features/payroll/Timecards';
import Timecard from '../features/payroll/Timecard';
import LoadTenderNewLoad from '../features/loadtender/LoadTender.newload';
import LoadTenderGenerator from '../features/loadtender/LoadTender.generator';
import LoadTender204 from '../features/loadtender/LoadTender.204';
import LoadTenderRateConfirmation from '../features/loadtender/LoadTender.RateConfirmation';
import LoadTenderMobileTrack from '../features/loadtender/LoadTender.MobileTrack';
import LoadTenderDriverConfirmation from '../features/loadtender/LoadTender.DriverConfirmation';
import LoadTenderPage from '../views/LoadTenderPage';
import SafetyPage from '../views/SafetyPage';
import SafetyDashboard from '../features/safety/Safety.dashboard';
import DriverPortalPage from '../views/DriverPortalPage';
import CarrierPortalPage from '../views/CarrierPortalPage';
import NotificationPage from '../views/NotificationPage';
import ManagerCarrierScoreCard from '../features/manager/Manager.carrierscorecard';
import ManagerPage from '../views/ManagerPage';
import AlarmPage from '../views/AlarmPage';
import AlarmReports from '../features/alarms/Alarm.reports';
import Alarm from '../features/alarms/Alarm';
import AlarmForm from '../features/alarms/Alarm.form';
import AlarmUpdate from '../features/alarms/Alarm.update';
import DriverLoadTender from '../features/driver/Driver.LoadTender';
import DriverSchedule from '../features/driver/Driver.Schedule';
import ManagerContractScoreCard from '../features/manager/Manager.contractscorecard';
import ManagerCarrierSchedules from '../features/manager/Manager.carrierschedules';
import LoadTenderAtlas from '../features/loadtender/LoadTender.Atlas';
import LoadTenderQuickCover from '../features/loadtender/LoadTender.QuickCover';
import LoadTenderForwardingRules from '../features/loadtender/LoadTender.ForwardingRules';
import LoadTenderForwardingRule from '../features/loadtender/LoadTender.ForwardingRule';

const appRoutes = [
    { feature: 'Home', path: '/', name: 'Home', detail: 'Home page', element: <><Home /></> },
    { feature: 'Home', path: '/profile', name: 'Profile', detail: 'User profile', element: <><Profile /></> },
    { feature: 'Home', path: '/settings', name: 'Settings', detail: 'change app settings', element: <><Settings /></> },
    { feature: 'Home', path: '/help', name: 'Help', detail: 'View useful "How To" information.', element: <><Help /></> },
    { feature: 'Home', path: '/bug-report', name: 'Bug Report', detail: 'bug report', element: <><BugReport /></> },
    { feature: 'Home', path: '/feature-request', name: 'Feature Request', detail: 'feature request', element: <><FeatureRequest /></> },

    { feature: 'Data', path: '/data', name: 'Data Options', detail: '', element: <><DataPage /></> },
    { feature: 'Data', path: '/data/companies', name: 'Company', detail: '', element: <><Companies feature={'data'}/></> },
    { feature: 'Data', path: '/data/company', name: 'Create Company', detail: '', element: <><Company feature={'data'} method={'create'} /></> },
    { feature: 'Data', path: '/data/company/:id', name: 'Update Company', detail: '', element: <><Company feature={'data'} method={'update'} /></> },
    // { feature: 'Data', path: '/data/tradingpartners', name: 'Trading Partners', detail: '', element: <><TradingPartners feature={'data'} /></> },
    // { feature: 'Data', path: '/data/tradingpartner', name: 'Create Trading Partner', detail: '', element: <><TradingPartner feature={'data'} method={'create'} /></> },
    // { feature: 'Data', path: '/data/tradingpartner/:id', name: 'Update Trading Partner', detail: '', element: <><TradingPartner feature={'data'} method={'update'} /></> },
    { feature: 'Data', path: '/data/customers', name: 'Customers', detail: '', element: <><Customers feature={'data'} /></> },
    { feature: 'Data', path: '/data/customer', name: 'Create Customer', detail: '', element: <><Customer feature={'data'} method={'create'} /></> },
    { feature: 'Data', path: '/data/customer/:id', name: 'Update Customer', detail: '', element: <><Customer feature={'data'} method={'update'} /></> },
    { feature: 'Data', path: '/data/carriers', name: 'Carriers', detail: '', element: <><Carriers feature={'data'} /></> },
    { feature: 'Data', path: '/data/carrier', name: 'Create Carrier ', detail: '', element: <><Carrier feature={'data'} method={'create'} /></> },
    { feature: 'Data', path: '/data/carrier/:id', name: 'Update Carrier ', detail: '', element: <><Carrier feature={'data'} method={'update'} /></> },
    { feature: 'Data', path: '/data/location', name: 'Locations', detail: '', element: <><Location feature={'data'} /></> },
    { feature: 'Data', path: '/data/location-form', name: 'Location Form', detail: '', element: <><LocationForm feature={'data'} /></> },
    { feature: 'Data', path: '/data/location/:id', name: 'Location', detail: '', element: <><LocationUpdate feature={'data'} /></> },
    { feature: 'Data', path: '/data/location/usps/:nasscode', name: 'USPS Location Update', detail: '', element: <><LocationUpdate feature={'usps'} /></> },
    { feature: 'Data', path: '/data/drivers', name: 'Drivers', detail: '', element: <><Operator feature={'data'} /></> },
    { feature: 'Data', path: '/data/driver-form', name: 'Create Driver', detail: '', element: <><OperatorForm feature={'data'} /></> },
    { feature: 'Data', path: '/data/driver/:id', name: 'Update Driver', detail: '', element: <><OperatorUpdate feature={'data'} /></> },
    { feature: 'Data', path: '/data/vehicles', name: 'Vehicles', detail: '', element: <><Vehicle feature={'data'} /></> },
    { feature: 'Data', path: '/data/vehicle-form', name: 'Create Vehicle', detail: '', element: <><VehicleForm feature={'data'} /></> },
    { feature: 'Data', path: '/data/vehicle/:id', name: 'Update Vehicle', detail: '', element: <><VehicleUpdate feature={'data'} /></> },
    { feature: 'Data', path: '/data/trailers', name: 'Trailers', detail: '', element: <><Trailer feature={'data'} /></> },
    { feature: 'Data', path: '/data/trailer-form', name: 'Create Trailer', detail: '', element: <><TrailerForm feature={'data'} /></> },
    { feature: 'Data', path: '/data/trailer/:id', name: 'Update Trailer', detail: '', element: <><TrailerUpdate feature={'data'} /></> },
    { feature: 'Data', path: '/data/frequency', name: 'Frequencies', detail: '', element: <><Frequency feature={'data'} /></> },
    { feature: 'Data', path: '/data/frequency-form', name: 'Freq. Create', detail: '', element: <><FrequencyForm feature={'data'} /></> },
    { feature: 'Data', path: '/data/frequency/:id', name: 'Freq. Update', detail: '', element: <><FrequencyUpdate feature={'data'} /></> },
    { feature: 'Data', path: '/data/specialop', name: 'Special Ops', detail: '', element: <><SpecialOp feature={'data'} /></> },
    { feature: 'Data', path: '/data/specialop-form', name: 'Sops Create', detail: '', element: <><SpecialOpForm feature={'data'} /></> },
    { feature: 'Data', path: '/data/specialop/:id', name: 'SopsUpdate', detail: '', element: <><SpecialOpUpdate feature={'data'} /></> },
    { feature: 'Data', path: '/data/tags', name: 'Tags', detail: '', element: <><Tags feature={'data'} /></> },
    //
    //
    // { feature: '3) USPS Mail', path: '/mail/dashboard', name: 'Dashboard', detail: '', element: <><LoadTenderDashboardUSPS feature={'mail'} /></> },
    // { feature: '3) USPS Mail', path: '/mail/:ShipmentId', name: 'Load update', detail: '', element: <><LoadTenderUpdateV3 backPath={'/mail/dashboard'} /></> },
    // { feature: '3) USPS Mail', path: '/mail/accounting', name: 'Accounting', detail: '', element: <><LoadTenderAdminReports ReportType={'accounting'} /></> },
    // { feature: '3) USPS Mail', path: '/mail/performance', name: 'Performance', detail: '', element: <><LoadTenderAdminReports ReportType={'performance'} /></> },
    // { feature: '3) USPS Mail', path: '/mail/build', name: 'Build', detail: '', element: <><LoadTenderBuilder feature={'mail'} /></> },
    // { feature: '3) USPS Mail', path: '/mail/forecast', name: 'Forecast', detail: '', element: <><LoadTenderForecast feature={'mail'} /> </> },
    // { feature: '3) USPS Mail', path: '/mail/rateconfirmation', name: 'Rate Confirmation', detail: '', element: <><LoadTenderRateCon feature={'mail'} /> </> },
    // { feature: '3) USPS Mail', path: '/mail/presigned', name: 'Pre Signed Load', detail: '', element: <><LoadTenderPreSigned feature={'mail'} /> </> },
    // { feature: '3) USPS Mail', path: '/mail/schedules', name: 'Schedules', detail: '', element: <><LoadTenderSchedules feature={'mail'} /></> },
    // { feature: '3) USPS Mail', path: '/mail/scheduler', name: 'Create Scheduler', detail: '', element: <><LoadTenderScheduler feature={'mail'} form={'create'} /></> },
    // { feature: '3) USPS Mail', path: '/mail/schedule/:id', name: 'Update Scheduler', detail: '', element: <><LoadTenderScheduler feature={'mail'} form={'update'} /></> },
    // { feature: '3) USPS Mail', path: '/mail/manifest', name: 'Manifests', detail: 'usps manifest data', element: <><Manifest feature={'mail'} /></> },
    // { feature: '3) USPS Mail', path: '/mail/manifest-form', name: 'Upload Manifests', detail: 'Upload USPS Manifest', element: <><ManifestForm feature={'mail'} /></> },
    // { feature: '3) USPS Mail', path: '/mail/5500', name: '5500', detail: 'usps 5500 data', element: <><FiftyFiveHundred feature={'mail'} /></> },
    // { feature: '3) USPS Mail', path: '/mail/5500-form', name: 'Upload 5500', detail: 'Upload USPS 5500', element: <><FiftyFiveHundredForm feature={'mail'} /></> },
    // { feature: '3) USPS Mail', path: '/mail/loadreconciliation', name: 'Load Reconciliation', detail: 'usps loadreconciliation data', element: <><LoadPayReconciliation feature={'mail'} /></> },
    // { feature: '3) USPS Mail', path: '/mail/loadreconciliation-form', name: 'Upload Load Reconciliation', detail: 'Upload USPS Manifest', element: <><LoadPayReconciliationForm feature={'mail'} /></> },
    // { feature: '3) USPS Mail', path: '/mail/breakthroughfuel', name: 'Break Through Fuel', detail: 'usps break through fuel data', element: <><BreakThroughFuel feature={'mail'} /></> },
    // { feature: '3) USPS Mail', path: '/mail/breakthroughfuel-form', name: 'Upload Break Through Fuel', detail: 'Upload USPS Manifest', element: <><BreakThroughFuelForm feature={'mail'} /></> },
    // //
    //
    // { feature: '4) Freight', path: '/freight/bols', name: 'Bills of Lading', detail: '', element: <><BillsOfLading /></> },
    // { feature: '4) Freight', path: '/freight/requests', name: 'Requests', detail: '', element: <><FreightRequests /></> },
    // { feature: '4) Freight', path: '/freight/bol', name: 'Create Bill of Lading', detail: '', element: <><BillOfLading method={'create'} /></> },
    // { feature: '4) Freight', path: '/freight/bol/:id', name: 'Update Bill of Lading', detail: '', element: <><BillOfLading method={'update'} /></> },
    // { feature: '4) Freight', path: '/freight/rateconfirmation', name: 'Rate Confirmation', detail: '', element: <><RateConfirmation feature={'freight'} /></> },
    // { feature: '4) Freight', path: '/freight/proofofdelivery', name: 'Proof of Delivery', detail: '', element: <><ProofOfDelivery /></> },
    // { feature: '4) Freight', path: '/freight/accounting', name: 'Accounting', detail: '', element: <><FreightAccounting /></> },
    // { feature: '4) Freight', path: '/freight/dashboard', name: 'Dashboard', detail: '', element: <><FreightDashboard /></> },
    //
    //
    { feature: 'Load Tender', path: '/loadtender', name: 'Options', detail: 'load tender details', element: <><LoadTenderPage /></> },
    { feature: 'Load Tender', path: '/loadtender/dashboard', name: 'Dashboard', detail: 'Dashboard', element: <><LoadTenderDashboardUSPS feature={'loadtender'} /></> },
    { feature: 'Load Tender', path: '/loadtender/untracked', name: 'Untracked', detail: 'Untracked loads', element: <><LoadTenderAdminReports ReportType={'performance'} /></> },
    { feature: 'Load Tender', path: '/loadtender/:ShipmentId', name: 'Update Load Tender', detail: 'Update load tenders', element: <><LoadTenderUpdateV3 backPath={'/loadtender/dashboard'} /></> },
    { feature: 'Load Tender', path: '/loadtender/quickcover', name: 'Quick Cover', detail: '', element: <><LoadTenderQuickCover feature={'loadtender'} /></> },
    { feature: 'Load Tender', path: '/loadtender/atlas', name: 'Load Atlas', detail: 'All Routes Traveled', element: <><LoadTenderAtlas feature={'/loadtender/draft/'} /></> },
    { feature: 'Load Tender', path: '/loadtender/schedules', name: 'Schedules', detail: 'load tender schedules', element: <><LoadTenderSchedules feature={'loadtender'} /></> },
    { feature: 'Load Tender', path: '/loadtender/scheduler', name: 'Create Scheduler', detail: 'create load tender schedules', element: <><LoadTenderScheduler feature={'loadtender'} form={'create'} /></> },
    { feature: 'Load Tender', path: '/loadtender/schedule/:id', name: 'Update Scheduler', detail: 'update load tender schedules', element: <><LoadTenderScheduler feature={'loadtender'} form={'update'} /></> },
    { feature: 'Load Tender', path: '/loadtender/forwarding', name: 'Forward Rules', detail: '', element: <><LoadTenderForwardingRules feature={'loadtender'} form={'create'} /></> },
    { feature: 'Load Tender', path: '/loadtender/forwarding-new', name: 'New Forwarding Rule', detail: '', element: <><LoadTenderForwardingRule feature={'loadtender'} form={'create'} /></>},
    { feature: 'Load Tender', path: '/loadtender/forwarding/:id', name: 'Forward Rule', detail: '', element: <><LoadTenderForwardingRule feature={'loadtender'} form={'update'} /></>},
    { feature: 'Load Tender', path: '/loadtender/draft/:id', name: 'Load Template', detail: '', element: <><LoadTenderNewLoad form={'update'} /></> },
    { feature: 'Load Tender', path: '/loadtender/new-load', name: 'New Load', detail: '', element: <><LoadTenderNewLoad form={'create'} /></> },
    { feature: 'Load Tender', path: '/loadtender/brokered', name: 'Brokered Loads', detail: '3rd Party Coverage', element: <>Brokered Loads</> },
    { feature: 'Load Tender', path: '/loadtender/performance', name: 'Performance', detail: 'Coverage, EDI214 and Fourkites tracking reports', element: <><LoadTenderAdminReports ReportType={'performance'} /></> },
    { feature: 'Load Tender', path: '/loadtender/accounting', name: 'Accounting', detail: 'Coverage, EDI214 and Fourkites tracking reports with cost analysis', element: <><LoadTenderAdminReports ReportType={'accounting'} /></> },
    { feature: 'Load Tender', path: '/loadtender/customers', name: 'Customers', detail: 'Customers', element: <><LoadTenderCustomer /></> },
    { feature: 'Load Tender', path: '/loadtender/ratecon', name: 'Rate Confirmation', detail: '', element: <><LoadTenderRateConfirmation feature={'create'}/></> },
    { feature: 'Load Tender', path: '/loadtender/ratecon/:id', name: 'View Rate Confirmation', detail: '', element: <><LoadTenderRateConfirmation feature={'read'}/></> },
    { feature: 'Load Tender', path: '/loadtender/generator', name: 'Generator', detail: '', element: <><LoadTenderGenerator /></> },
    { feature: 'Load Tender', path: '/loadtender/templates', name: 'Templates', detail: 'Templates', element: <><LoadTenderTemplates /></> },
    // { feature: 'Load Tender', path: '/loadtender/iot', name: 'IoT Connections', detail: 'Load Tender IoT Connections', element: <><LoadTenderIOT /></> },
    // { feature: 'Load Tender', path: '/loadtender/dashboard/usps', name: 'USPS Dashboard', detail: 'USPS', element: <><LoadTenderDashboardUSPS feature={'loadtender'} /></> },
    // { feature: 'Load Tender', path: '/loadtender/dashboard/freight', name: 'Freight Dashboard', detail: 'Freight', element: <><LoadTenderDashboardFreight /></> },
    // { feature: '4) Load Tender', path: '/loadtender/frequency', name: 'Frequencies', detail: 'a table of frequencies ', element: <><Frequency feature={'loadtender'} /></> },
    // { feature: '4) Load Tender', path: '/loadtender/frequency-form', name: 'Freq. Create', detail: 'create a new freduency', element: <><FrequencyForm feature={'loadtender'} /></> },
    // { feature: '4) Load Tender', path: '/loadtender/frequency/:id', name: 'Freq. Update', detail: 'view/update a single frequency', element: <><FrequencyUpdate feature={'loadtender'} /></> },
    // { feature: '4) Load Tender', path: '/loadtender/specialop', name: 'Special Ops', detail: 'a table of special operations', element: <><SpecialOp feature={'loadtender'} /></> },
    // { feature: '4) Load Tender', path: '/loadtender/specialop-form', name: 'Sops Create', detail: 'create a new special operation', element: <><SpecialOpForm feature={'loadtender'} /></> },
    // { feature: '4) Load Tender', path: '/loadtender/specialop/:id', name: 'SopsUpdate', detail: 'view/update a special operation', element: <><SpecialOpUpdate feature={'loadtender'} /></> },
    // { feature: '4) Load Tender', path: '/loadtender/location', name: 'Locations', detail: 'a table of locations', element: <><Location feature={'loadtender'} /></> },
    // { feature: '4) Load Tender', path: '/loadtender/location-form', name: 'Location Form', detail: 'create a new location', element: <><LocationForm feature={'loadtender'} /></> },
    // { feature: '4) Load Tender', path: '/loadtender/location/:id', name: 'Location', detail: 'view/update a single location', element: <><LocationUpdate feature={'loadtender'} /></> },
     // 
    // { feature: 'Load Tender', path: '/loadtender/edi/:ShipmentId', name: 'Shipment', detail: 'shipment', element: <><EDI210Component /></> },
    // { feature: 'Load Tender', path: '/loadtender/204/:id', name: '204', detail: '', element: <><EDI204Component feature={'loadtender'} /></> },
    // { feature: 'Load Tender', path: '/loadtender/990/:id', name: '990', detail: '', element: <><EDI990Component feature={'loadtender'} /></> },
    // { feature: 'Load Tender', path: '/loadtender/214/:id', name: '214', detail: '', element: <><EDI214Component feature={'loadtender'} /></> },
    // { feature: 'Load Tender', path: '/loadtender/210/:id', name: '210', detail: '', element: <><EDI210Component feature={'loadtender'} /></> },
    //
    { feature:'Loadeye', path: '/loadeye', name: 'Loadeye', detail: 'trailer tracking prototype', element: <><LoadEye /></> },
    //
    { feature: 'Payroll', path: '/payroll', name: 'Payroll', detail: '', element: <><PayrollDashboard /></> },
    // { feature: 'Payroll', path: '/payroll/dashboard', name: 'Dashboard', detail: '', element: <><PayrollDashboard /></> },
    // { feature: 'Payroll', path: '/payroll/timecards', name: 'Timecards', detail: '', element: <><Timecards/></> },
    // { feature: 'Payroll', path: '/payroll/timecard', name: 'Create Timecard', detail: '', element: <><Timecard form={'create'}/></> },
    // { feature: 'Payroll', path: '/payroll/timecard/:id', name: 'Update Timecard', detail: '', element: <><Timecard form={'update'} /></> },
    // { feature: 'Payroll', path: '/payroll/payrates', name: 'Pay Rates', detail: '', element: <></> },
    // { feature: 'Payroll', path: '/payroll/payrate', name: 'Create Pay Rate', detail: '', element: <></> },
    // { feature: 'Payroll', path: '/payroll/payrate/:id', name: 'Update Pay Rate', detail: '', element: <></> },

    { feature: 'Safety', path: '/safety', name: 'Safety', detail: '', element: <><SafetyPage /></> },
    { feature: 'Safety', path: '/safety/dashboard', name: 'Dashboard', detail: 'Deashboard', element: <><SafetyDashboard /></> },
    { feature: 'Safety', path: '/safety/accidents/list-of-forms', name: 'Accident List', detail: 'List of Accidents', element: <><AccidentSection /></> },
    { feature: 'Safety', path: '/safety/accidents/data-output', name: 'Accident Data', detail: 'Accident Data Graphics', element: <><AccidentDataSection /></> },
    { feature: 'Safety', path: '/safety/accidents/new-form', name: 'New Accident', detail: 'New Accident Form', element: <><AccidentFormSection /></> },
    { feature: 'Safety', path: '/safety/accidents/update-form/:id', name: 'Update Accident', detail: 'Update Accident Form', element: <><AccidentUpdate /></> },
    { feature: 'Safety', path: '/safety/accidents/upload', name: 'Upload Accidents', detail: 'Upload Accidents from Template', element: <><AccidentUpload /></> },
    { feature: 'Safety', path: '/safety/injuries/list-of-forms', name: 'Injury List', detail: 'List of Injuries', element: <><InjurySection /></> },
    { feature: 'Safety', path: '/safety/injuries/data-output', name: 'Injury Data', detail: 'Injury Data Graphics', element: <><InjuryDataSection /></> },
    { feature: 'Safety', path: '/safety/injuries/new-form', name: 'New Injury', detail: 'New Injury Form', element: <><InjuryFormSection /></> },
    { feature: 'Safety', path: '/safety/injuries/update-form/:id', name: 'Update Injury', detail: 'Update Injury Form', element: <><InjuryUpdate /></> },
    { feature: 'Safety', path: '/safety/injuries/upload', name: 'Upload Injuries', detail: 'Upload Injuries from Template', element: <><InjuryUpload /></> },

    { feature: 'Manager Portal', path: '/manager', name: 'Manager', detail: 'Manager view of driver profile.', element: <><ManagerPage /></> },
    { feature: 'Manager Portal', path: '/manager/contract-score-cards', name: 'Contract Score Card', detail: '', element: <><ManagerContractScoreCard view={'manager'} feature={'/loadtender'}/></> },
    { feature: 'Manager Portal', path: '/manager/driver-score-cards', name: 'Driver Score Cards', detail: '', element: <><DriverLoadTenders view={'manager'} feature={'/loadtender'}/></> },
    { feature: 'Manager Portal', path: '/manager/driver-schedules', name: 'Driver Schedules', detail: '', element: <><DriverSchedule view={'manager'} feature={'/loadtender'}/></> },
    { feature: 'Manager Portal', path: '/manager/carrier-score-cards', name: 'Carrier Score Cards', detail: '', element: <><ManagerCarrierScoreCard view={'manager'} feature={'/loadtender'}/></> },
    { feature: 'Manager Portal', path: '/manager/carrier-schedules', name: 'Carrier Schedules', detail: '', element: <><ManagerCarrierSchedules view={'manager'} feature={'/loadtender'}/></> },

    { feature: 'Driver Portal', path: '/driverportal', name: 'Driver Portal', detail: 'Driver Portal Navigation', element: <><DriverPortalPage /></> },
    { feature: 'Driver Portal', path: '/driver/score-card', name: 'Driver Score Card', detail: 'Driver\'s score card', element: <><DriverLoadTenders view={'driver'} feature={'/driver/loadtender'}/></> },
    { feature: 'Driver Portal', path: '/driver/schedule', name: 'Driver\'s Schedule', detail: 'Schedule', element: <><DriverSchedule view={'driver'} feature={'/driver/loadtender'}/></> },
    { feature: 'Driver Portal', path: '/driver/loadtender/:ShipmentId', name: 'Load', detail: '', element: <><DriverLoadTender /></> },
    { feature: 'Driver Portal', path: '/driver/loadtender/:ShipmentId/confirm', name: 'Confirm Load', detail: '', element: <><LoadTenderDriverConfirmation feature={'confirm'}/></> },
    { feature: 'Driver Portal', path: '/driver/loadtender/:ShipmentId/confirm/:confirmationId', name: 'Load Confirmation', detail: '', element: <><LoadTenderDriverConfirmation feature={'read'}/></> },
    { feature: 'Driver Portal', path: '/driver/loadtender/:ShipmentId/track', name: 'Mobile Track Load Tender', detail: '', element: <><LoadTenderMobileTrack /></> },
    { feature: 'Driver Portal', path: '/driver/accident-form', name: 'Accident Form', detail: 'Submit an Accident Form', element: <><DriverAccident /></> },
    { feature: 'Driver Portal', path: '/driver/injury-form', name: 'Injury Form', detail: 'Submit and Injury From', element: <><DriverInjury /></> },

    { feature: 'Carrier Portal', path: '/carrierportal', name: 'Carrier Portal', detail: '', element: <><CarrierPortalPage /></> },
    { feature: 'Carrier Portal', path: '/carrier/loadtenders', name: 'Load Tenders', detail: '', element: <><LoadTenderRateConfirmation feature={'confirm'}/></> },
    { feature: 'Carrier Portal', path: '/carrier/loadtender/:ShipmentId/confirm', name: 'Rate Confirmation', detail: '', element: <><LoadTenderRateConfirmation feature={'confirm'}/></> },
    { feature: 'Carrier Portal', path: '/carrier/loadtender/:ShipmentId/confirm/:conrfirmationId', name: 'Rate Confirmation Id', detail: '', element: <><LoadTenderRateConfirmation feature={'read'}/></> },
    { feature: 'Carrier Portal', path: '/carrier/loadtender/:ShipmentId/confirm/:conrfirmationId/update', name: 'Rate Confirmation Update', detail: '', element: <><LoadTenderRateConfirmation feature={'confirm'}/></> },
    { feature: 'Carrier Portal', path: '/carrier/loadtender/:ShipmentId/track', name: 'Mobile Track Load Tender', detail: '', element: <><LoadTenderRateConfirmation feature={'conf'}/></> },

    { feature: 'Alarms', path: '/alarms/info', name: 'Info', detail: '', element: <><AlarmPage /></> },
    { feature: 'Alarms', path: '/alarms/reports', name: 'Reports', detail: 'Report data descriptions for alarms', element: <><AlarmReports /></> },
    { feature: 'Alarms', path: '/alarms', name: 'Alarms', detail: 'change alarms settings', element: <><Alarm /></> },
    { feature: 'Alarms', path: '/alarms-form', name: 'Alarm Form', detail: 'Create alarms', element: <><AlarmForm /></> },
    { feature: 'Alarms', path: '/alarms/:id', name: 'Alarm Update', detail: 'Update notifcation', element: <><AlarmUpdate /></> },
    { feature: 'Alarms', path: '/alarms/groups', name: 'Groups', detail: 'Groups are used to categories sets of users.', element: <><AccessGroup /></> },
    { feature: 'Alarms', path: '/alarms/group-form', name: 'Group Form', detail: 'New Group', element: <><AccessGroupForm /></> },
    { feature: 'Alarms', path: '/alarms/group/:id', name: 'Group', detail: 'Update Group', element: <><AccessGroupUpdate /></> },


    { feature: 'Notification', path: '/notification/info', name: 'Info', detail: '', element: <><NotificationPage /></> },
    { feature: 'Notification', path: '/notification/reports', name: 'Reports', detail: 'Report data descriptions for notifications', element: <><NotificationReports /></> },
    { feature: 'Notification', path: '/notification', name: 'Notifications', detail: 'change notification settings', element: <><Notifcation /></> },
    { feature: 'Notification', path: '/notification-form', name: 'Notification Form', detail: 'Create notification', element: <><NotifcationForm /></> },
    { feature: 'Notification', path: '/notification/:id', name: 'Notification View', detail: 'Update notifcation', element: <><NotificationUpdate /></> },
    { feature: 'Notification', path: '/notification/groups', name: 'Groups', detail: 'Groups are used to categories sets of users.', element: <><AccessGroup /></> },
    { feature: 'Notification', path: '/notification/group-form', name: 'Group Form', detail: 'New Group', element: <><AccessGroupForm /></> },
    { feature: 'Notification', path: '/notification/group/:id', name: 'Group', detail: 'Update Group', element: <><AccessGroupUpdate /></> },

    { feature: 'IoT Integrations', path: '/iot', name: 'IoT', detail: 'Internet of Things connections', element: <><IOT /></> },
    { feature: 'IoT Integrations', path: '/eld/samsara', name: 'Samsara ELD Provider', detail: 'Samsara ELD', element: <><Samsara /></> },
    { feature: 'IoT Integrations', path: '/eld/geotab', name: 'Geotab ELD Provider', detail: 'Geotab ELD', element: <><GeoTab /></> },
    { feature: 'IoT Integrations', path: '/edi/kleinschmidt', name: 'Kleinschmidt EDI', detail: 'Kleinschmidt EDI', element: <><Weather /></> },
    { feature: 'IoT Integrations', path: '/fourkites', name: 'Four Kites', detail: 'Four Kites', element: <><LoadEye /></> },
    { feature: 'IoT Integrations', path: '/loadeye', name: 'Loadeye', detail: 'Loadeye trailer tracking prototype.', element: <><LoadEye /></> },
    { feature: 'IoT Integrations', path: '/fuel/padd', name: 'PADD Fuel', detail: 'See EIA fuel data.', element: <><PADDFuel /></> },
    { feature: 'IoT Integrations', path: '/fuel/wholesale', name: 'Wholesale Fuel', detail: 'See EIA fuel data.', element: <><WholesaleFuel /></> },
    { feature: 'IoT Integrations', path: '/weather', name: 'Weather', detail: 'View Weather', element: <><Weather /></> },

    { feature: 'Jobs', path: '/job', name: 'Jobs', detail: 'View a list of jobs', element: <><Job /></> },
    { feature: 'Jobs', path: '/job-form', name: 'Job Form', detail: 'Send app job', element: <><JobForm /></> },
    { feature: 'Jobs', path: '/job/:id', name: 'Job Update', detail: 'Update a job', element: <><JobUpdate /></> },

    { feature: 'Logs', path: '/log', name: 'System Logs', detail: 'view system logs', element: <><Log /></> },
    { feature: 'Logs', path: '/log/notifications', name: 'System Notifications', detail: 'view system notifications', element: <><NotificationLog /></> },

    { feature: 'Access', path: '/access', name: 'Access', detail: 'Control user access', element: <><Access /></> },
    { feature: 'Access', path: '/access/roles', name: 'Roles', detail: 'Roles grant access to features within the application.', element: <><AccessRole /></> },
    { feature: 'Access', path: '/access/profiles', name: 'Profiles', detail: 'People with access to the application.', element: <><AccessProfile /></> },
    { feature: 'Access', path: '/access/active-users', name: 'Active Users', detail: 'See a list of active users accounts.', element: <><AccessActive /></> },
    { feature: 'Access', path: '/access/new-users', name: 'New User Onboarding', detail: 'See a list of new users.', element: <><AccessNewUsers /></> },
    { feature: 'Access', path: '/access/stale-users', name: 'Stale Users', detail: 'See a list of stale users accounts.', element: <><AccessStale /></> },
    { feature: 'Access', path: '/access/new-user-form/:uid', name: 'Grant user access.', detail: 'Give new users a role.', element: <><AccessNewUserForm /></> },
    { feature: 'Access', path: '/access/profile-form/:uid', name: 'Profile Form', detail: 'New User Profile', element: <><AccessProfileForm /></> },
    { feature: 'Access', path: '/access/profile/:id', name: 'Profile', detail: 'Update Profile', element: <><AccessProfileUpdate /></> },
    { feature: 'Access', path: '/access/role-form', name: 'Role Form', detail: 'New Role', element: <><AccessRoleForm /></> },
    { feature: 'Access', path: '/access/role/:id', name: 'Role', detail: 'Update Role', element: <><AccessRoleUpdate /></> },


    // { feature: '10) USPS Documents', path: '/ffh', name: '5500', detail: '5500 Info', element: <><FiftyFiveHundredNav /><FiftyFiveHundredInfo /></> },
    // { feature: '10) USPS Documents', path: '/ffh/table', name: '5500 Table', detail: '5500 record table', element: <><FiftyFiveHundredNav /><FiftyFiveHundredTable /></> },
    // { feature: '10) USPS Documents', path: '/ffh/reports', name: '5500 Reports', detail: '5500 Report', element: <><FiftyFiveHundredNav /><FiftyFivehundredReports /></> },
    // { feature: '10) USPS Documents', path: '/ffh/upload', name: '5500 Upload', detail: 'Upload 5500 records', element: <><FiftyFiveHundredNav /><FiftyFivehundredUpload /></> },
    // { feature: '10) USPS Documents', path: '/hcr', name: 'HCRs', detail: 'Highway Contract Routes', element: <><HCR /></> },

    // { feature: '14) Billing', path: '/billing', name: 'Billing', detail: 'Billing Dashboard', element: <><Billing /></> },

    // { feature: '15) Forms', path: '/form/employeereimbursement', name: 'Emp Reimbursements', detail: 'Employee Reimbursements', element: <><EmployeeReimbursement /></> },
    // { feature: '15) Forms', path: '/form/employeereimbursement-form', name: 'Emp Reimbursement Form', detail: 'Employee Reimbursement Form', element: <><EmployeeReimbursementForm /></> },
    // { feature: '15) Forms', path: '/form/employeereimbursement/:id', name: 'Emp Reimbursement', detail: 'Employee Reimbursement', element: <><EmployeeReimbursementUpdate /></> },

    // todo add general freight dashboard
    // { feature:'2) Load Tender', path: '/loadtender/update/:id', name: 'Update Load Tender', detail: 'update a load tender', element: <><LoadTenderUpdate /></> },
    // { feature:'2) Load Tender', path: '/loadtender/live', name: 'Load Tenders Live', detail: 'live look at load tenders', element: <><LoadTenderNav /><LoadTenderLive /></> },
    // { feature:'2) Load Tender', path: '/loadtender/active', name: 'Active Load Tenders', detail: 'a table of active load tenders', element: <><LoadTenderNav /><LoadTenderActive /></> },
    // { feature:'2) Load Tender', path: '/loadtender/requests', name: 'Load Tender Requests', detail: 'a table of load tender requests that need responses', element: <><LoadTenderNav /><LoadTenderRequests /></> },
    // { feature:'2) Load Tender', path: '/loadtender/rejected', name: 'Rejected Load Tenders', detail: 'a table of rejected load tenders', element: <><LoadTenderNav /><LoadTenderRejected /></> },
    // { feature:'2) Load Tender', path: '/loadtender/completed', name: 'Completed Load Tenders', detail: 'a table of completed load tenders', element: <><LoadTenderNav /><LoadTenderCompleted /></> },
    // { feature:'2) Load Tender', path: '/loadtender/response/:id', name: 'Load Tender Repsponses', detail: 'respond to a load tender request', element: <><LoadTenderNav /><LoadTenderResponse /></> },
    // { feature:'2) Load Tender', path: '/loadtender/view/:id', name: 'View Load Tender', detail: 'view load tender', element: <><LoadTenderNav /><LoadTenderView /></> },
    // { feature:'2) Load Tender', path: '/loadtender/usps-service-points', name: 'USPS Service Points Dashboard', detail: 'view USPS services points dashboard', element: <><USPSServicePointsDashboard /></> },

    // { feature:'5) Plan Dispatch', path: '/plan', name: 'Plan', detail: 'plan details', element: <><PlanNav /><Plan /></> },
    // { feature:'5) Plan Dispatch', path: '/plan/assignment', name: 'Assignments', detail: 'a table of assignments', element: <><PlanNav /><Assignment /></> },
    // { feature:'5) Plan Dispatch', path: '/plan/assignment-form', name: 'Assignment Form', detail: 'create a new assignment', element: <><PlanNav /><AssignmentForm /></> },
    // { feature:'5) Plan Dispatch', path: '/plan/assignment/:id', name: 'Assignment', detail: 'view/update assignments', element: <><PlanNav /><AssignmentUpdate /></> },
    // { feature:'5) Plan Dispatch', path: '/plan/contract', name: 'Contracts', detail: 'a table of contracts', element: <><PlanNav /><Contract /></> },
    // { feature:'5) Plan Dispatch', path: '/plan/contract-form', name: 'Contract Form', detail: 'create a contract', element: <><PlanNav /><ContractForm /></> },
    // { feature:'5) Plan Dispatch', path: '/plan/contract/:id', name: 'Contract', detail: 'view/update a contract', element: <><PlanNav /><ContractUpdate /></> },
    // { feature:'5) Plan Dispatch', path: '/plan/forecast', name: 'Forecast', detail: 'forecast all active routes ', element: <><PlanNav /><RoutePlanForecast /></> },
    // { feature:'5) Plan Dispatch', path: '/plan/frequency', name: 'Frequencies', detail: 'a table of frequencies ', element: <><PlanNav /><Frequency /></> },
    // { feature:'5) Plan Dispatch', path: '/plan/frequency-form', name: 'Frequency Form', detail: 'create a new freduency', element: <><PlanNav /><FrequencyForm /></> },
    // { feature:'5) Plan Dispatch', path: '/plan/frequency/:id', name: 'Frequency', detail: 'view/update a single frequency', element: <><PlanNav /><FrequencyUpdate /></> },
    // { feature:'5) Plan Dispatch', path: '/plan/location', name: 'Locations', detail: 'a table of locations', element: <><PlanNav /><Location /></> },
    // { feature:'5) Plan Dispatch', path: '/plan/location-form', name: 'Location Form', detail: 'create a new location', element: <><PlanNav /><LocationForm /></> },
    // { feature:'5) Plan Dispatch', path: '/plan/location/:id', name: 'Location', detail: 'view/update a single location', element: <><PlanNav /><LocationUpdate /></> },
    // { feature:'5) Plan Dispatch', path: '/plan/operator', name: 'Operators', detail: 'a table of operators', element: <><PlanNav /><Operator /></> },
    // { feature:'5) Plan Dispatch', path: '/plan/operator-form', name: 'Operator Form', detail: 'create a new operator', element: <><PlanNav /><OperatorForm /></> },
    // { feature:'5) Plan Dispatch', path: '/plan/operator/:id', name: 'Operator', detail: 'view/update a single operator', element: <><PlanNav /><OperatorUpdate /></> },
    // { feature:'5) Plan Dispatch', path: '/plan/routeplan', name: 'Routes', detail: 'a table of all routes', element: <><PlanNav /><RoutePlan /></> },
    // { feature:'5) Plan Dispatch', path: '/plan/routeplan-form', name: 'Route Form', detail: 'create a route', element: <><PlanNav /><RoutePlanForm /></> },
    // { feature:'5) Plan Dispatch', path: '/plan/routeplan/:id', name: 'Update Route', detail: 'view/update a route', element: <><PlanNav /><RoutePlanUpdate /></> },
    // { feature:'5) Plan Dispatch', path: '/plan/routeplan/:id/forecast', name: 'Forecast One', detail: 'forecast a single route', element: <><PlanNav /><RoutePlanForecast /></> },
    // { feature:'5) Plan Dispatch', path: '/plan/specialop', name: 'Special Ops', detail: 'a table of special operations', element: <><PlanNav /><SpecialOp /></> },
    // { feature:'5) Plan Dispatch', path: '/plan/specialop-form', name: 'Special Op Form', detail: 'create a new special operation', element: <><PlanNav /><SpecialOpForm /></> },
    // { feature:'5) Plan Dispatch', path: '/plan/specialop/:id', name: 'Special Op', detail: 'view/update a special operation', element: <><PlanNav /><SpecialOpUpdate /></> },
    // { feature:'5) Plan Dispatch', path: '/plan/trailer', name: 'Trailers', detail: 'a table of trailers', element: <><PlanNav /><Trailer /></> },
    // { feature:'5) Plan Dispatch', path: '/plan/trailer-form', name: 'Trailer Form', detail: 'create a new trailer', element: <><PlanNav /><TrailerForm /></> },
    // { feature:'5) Plan Dispatch', path: '/plan/trailer/:id', name: 'Trailer', detail: 'view/update a trailer', element: <><PlanNav /><TrailerUpdate /></> },
    // { feature:'5) Plan Dispatch', path: '/plan/vehicle', name: 'Vehicles', detail: 'a table of vehicles', element: <><PlanNav /><Vehicle /></> },
    // { feature:'5) Plan Dispatch', path: '/plan/vehicle-form', name: 'Vehicle Form', detail: 'create a new vehicle', element: <><PlanNav /><VehicleForm /></> },
    // { feature:'5) Plan Dispatch', path: '/plan/vehicle/:id', name: 'Vehicle', detail: 'view/update a vehicle', element: <><PlanNav /><VehicleUpdate /></> },
    // { feature:'5) Plan Dispatch', path: '/plan/issues', name: 'Issues', detail: 'See issues detected with Plan', element: <><PlanNav /><RoutePlanIssues/></> },


];


export default appRoutes;