import React, { useState, useEffect, useRef } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaArrowUp } from 'react-icons/fa';
import './scrollToTop.css';  // Add CSS for the button

// todo fix override "true", ui current fixed to window size which causes the error

const ScrollToTop = () => {
    const [isVisible, setIsVisible] = useState(true);
    const prevScrollY = useRef(0); // To track the previous scroll position

    // Function to handle the scroll event
    const toggleVisibility = () => {
        const currentScrollY = window.scrollY;
        console.log('Current scroll position:', currentScrollY);  // Debugging scroll position

        // Show the button if the user scrolls down and the scroll position is more than 100px
        if (currentScrollY > prevScrollY.current && currentScrollY > 100) {
            setIsVisible(true);
        } else {
            setIsVisible(false); // Optionally hide when scrolling up
        }

        // Update previous scroll position
        prevScrollY.current = currentScrollY;
    };

    // Scroll the window back to the element with id 'tmsTop'
    const scrollToTop = () => {
        const element = document.getElementById('tmsTop');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    // Add scroll event listener on mount and clean up on unmount
    useEffect(() => {
        const handleScroll = () => toggleVisibility();

        window.addEventListener('scroll', handleScroll);
        
        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []); // Empty dependency array ensures this runs only on mount/unmount

    return (
        <div>
            {isVisible && (
                <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip id="tooltip-top">Scroll to the top</Tooltip>}
                >
                    <div className="scroll-to-top" onClick={scrollToTop}>
                        <FaArrowUp />
                    </div>
                </OverlayTrigger>
            )}
        </div>
    );
};

export default ScrollToTop;
