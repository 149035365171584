import React, { useState, useEffect } from 'react';
import { Accordion, Button, Card, Col, Container, Form, Row, Spinner, Table } from 'react-bootstrap'
import AlertDisplay from '../../components/AlertDisplay'
import { useAuth } from '../../context/auth'
import Api from '../../utils/Api'
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';


const initialQuery = {
    page: 1,
    pageSize: 10
}

function objectToQueryString(obj) {
    return Object.keys(obj)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
        .join('&');
}

function Log() {
    const { idToken } = useAuth()
    const [set, didSet] = useState();
    const [logs, setLogs] = useState();

    const [alerts, setAlerts] = useState([])
    const alertState = { alerts, setAlerts }

    const [totalRecords, setTotalRecords] = useState(0);
    const [totalPages, setTotalPages] = useState(0);

    const [query, setQuery] = useState(() => {
        const savedQuery = localStorage.getItem(`@mctms-log-dashboard-query`);
        return savedQuery !== null ? JSON.parse(savedQuery) : initialQuery;
    });

    async function fetchLogs() {
        didSet(false)
        try {
            const queryStr = objectToQueryString(query)
            const data = await Api.get(`/logs?${queryStr}`, idToken)
            setTotalRecords(data.totalRecords)
            setTotalPages(data.totalPages)
            setLogs(data.records)
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured fetching logs' }]);
        } finally {
            didSet(true)
        }
    }

    // useEffect(() => {
    //     if (!set) {
    //         fetchLogs()
    //     }
    // }, [set])

    useEffect(() => {
        fetchLogs()
    }, [query])

    return (
        <Container fluid>
            <AlertDisplay alertState={alertState} />
            <Row>
                <Col xs="auto">
                    Records {totalRecords} <br /> Page {query.page} / {totalPages}
                </Col>
                <Col xs="auto">
                    Newer <br />
                    <Button variant={'primary'} onClick={() => setQuery({ ...query, page: query.page - 1 >= 1 ? query.page - 1 : query.page })}><FaArrowLeft /></Button>
                </Col>
                <Col xs="auto">
                    Older <br />
                    <Button variant={'primary'} onClick={() => setQuery({ ...query, page: query.page + 1 <= totalPages ? query.page + 1 : query.page })}><FaArrowRight /></Button>
                </Col>
                <Col xs="auto">
                    Page Size
                    <Form.Control as="select" value={query.pageSize} onChange={(e) => setQuery({ ...query, pageSize: e.target.value })}>
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                    </Form.Control>
                </Col>
                <Col xs="auto">
                    Type
                    <Form.Control as="select" value={query?.type} onChange={(e) => setQuery({ ...query, type: e.target.value })}>
                        <option value="">All</option>
                        <option>Load Tender Audit</option>
                        <option>Load Tender Pipeline</option>
                        <option>Load Tender Shipment Sync</option>
                        <option>Load Tender Timecards</option>
                        <option>Load Tender 204 Forwarding</option>
                        <option>Load Tender 214 Forwarding</option>
                        <option>Load Tender Forward Tracking to Fourkites</option>
                        <option>Reboot</option>
                        <option>Samsara Sync</option>
                    </Form.Control>
                </Col>
            </Row>
            <br />
            {!set ?
                <Spinner />
                :
                <Table responsive>
                    <thead>
                        <tr>
                            <th>Row</th>
                            <th>Subject</th>
                            <th>Message</th>
                        </tr>
                    </thead>
                    <tbody>
                        {logs ? logs.map((log, i) => (
                            <tr eventKey={`${i}`}>
                                <td>#{i + 1}</td>
                                <td>{log.subject}</td>
                                <td>
                                    <pre>{log.html}</pre>
                                </td>
                            </tr>
                        )) : <tr>
                            <td colSpan={'100%'}>
                                No logs...
                            </td>
                        </tr>}
                    </tbody>

                </Table>

            }
        </Container>)
}

export default Log;