import { Badge, Button, Card, Col, Container, Row } from "react-bootstrap";
import DataDisplayV3 from "../../components/DataDisplayV3";
import { Link } from "react-router-dom";
import { FaPlusCircle } from "react-icons/fa";
import { useEffect, useState } from "react";
import { useAuth } from "../../context/auth";
import Api from "../../utils/Api";
import AlertDisplay from "../../components/AlertDisplay";
import LogisticsLoading from "../../components/LogisticsLoading";


function Companies({ feature }) {
    const { idToken } = useAuth();
    const [didFetch, setDidFetch] = useState();
    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts };
    const [companies, setCompanies] = useState([]);
    const [query, setQuery] = useState({
        isActive: true
    });

    async function fetchCompanies() {
        try {
            const queryStr = new URLSearchParams(query).toString();
            const data = await Api.get(`/companies?${queryStr}`, idToken);
            const cleanData = data.map(company => ({
                _id: company._id,
                Name: company.name,
                Phone: company.phone,
                Email: company.email,
                Address: company.address,
                SCAC:company.scac,
                DOT:company.dotNumber,
                MC:company.mcNumber,
            }));
            setCompanies(cleanData)
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured fetching data.' }])
        } finally {
            setDidFetch(true)
        }
    }

    useEffect(() => {
        if (!didFetch) {
            fetchCompanies();
        }
    }, [didFetch])


    return (
        <Container fluid style={{ margin: 0, padding: 0 }}>
            <AlertDisplay alertState={alertState} />
            <Card style={{ border: 0, borderRadius: 0 }}>
                <Card.Header>
                    <Card.Title>
                        <Row>
                            <Col>
                                Companies
                            </Col>
                            <Col style={{ textAlign: 'center' }}>
                                <Badge
                                    bg={query.isActive ? 'success' : 'danger'}
                                    onClick={() => {
                                        setQuery({ ...query, isActive: !query.isActive });
                                        setDidFetch(false)
                                    }}
                                >
                                    {query.isActive ? 'Active' : 'Inactive'}
                                </Badge>
                            </Col>
                            <Col style={{ textAlign: 'right' }}>
                                <Link to={`/${feature}/company`}><FaPlusCircle /> Company</Link>
                            </Col>
                        </Row>
                    </Card.Title>
                </Card.Header>
                <Card.Body>
                    {didFetch ?
                        <DataDisplayV3
                            dataSource={companies} lsKey='@sybrtms-companies'
                            urlPath={`/${feature}/company/`}
                            urlKey={'_id'}
                            popKeys={['_id']}
                            sourceName={'Companies'}
                            addActions={[]}
                            addFunctions={[]}
                        />
                        :
                        <LogisticsLoading message="Loading"/>
                    }

                </Card.Body>
            </Card>
        </Container>
    )
}



export default Companies;