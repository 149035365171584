import { Button, Card, Col, Container, Row, Spinner } from "react-bootstrap";
import DataDisplayV3 from "../../components/DataDisplayV3";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import Api from "../../utils/Api";
import LogisticsLoading from "../../components/LogisticsLoading";
import AlertDisplay from "../../components/AlertDisplay";
import { FaArrowLeft, FaArrowRight, FaPlusCircle } from "react-icons/fa";
import { useAuth } from "../../context/auth";
import { IoTodayOutline } from "react-icons/io5";
import { adjustStringLength } from "../../utils/String.helper";
import { formatDateFromDateStr, formatDateStrToMMDDYYHHMM, getDayOfWeekFromDate } from "../../utils/DateTimeFormat.helper";
import { findUSPSNASSCode, findUSPSNASSCodeState, formatUSPSDateTime } from "../../context/uspsdata";
import { useSettings } from "../../context/settings";

const today = new Date();

function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Add leading zero if needed
    const day = String(date.getDate()).padStart(2, '0'); // Add leading zero if needed
    return `${year}-${month}-${day}`;
}

function objectToQueryString(obj) {
    return Object.keys(obj)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
        .join('&');
}

const initialQuery = {
    start: formatDate(today),
    end: formatDate(today),
    page: 1,
    pageSize: 10
}

function millisecondsToHHMM(milliseconds) {
    const hours = Math.floor(milliseconds / 3600000);
    milliseconds %= 3600000;
    const minutes = Math.floor(milliseconds / 60000);
    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
    return `${formattedHours}:${formattedMinutes}`;
}


function BillsOfLading() {
    const { idToken } = useAuth();
    const { settings } = useSettings();
    const [didLoad, setDidLoad] = useState(false);
    const [billsoflading, setBillsOfLading] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [alerts, setAlerts] = useState([])
    const alertState = { alerts, setAlerts }
    const [query, setQuery] = useState(() => {
        const savedQuery = localStorage.getItem(`@mctms-bols-query`);
        return savedQuery !== null ? JSON.parse(savedQuery) : initialQuery;
    });

    async function fetchData() {
        try {
            const queryStr = objectToQueryString(query)
            const response = await Api.get(`/bols?${queryStr}`, idToken);
            const schedule = adjustStringLength('S#', '2', '\u0020', false)
                + ' | ' + adjustStringLength('LOCATION', '25', '\u0020', true)
                + ' | ' + adjustStringLength('ARRIVE', '18', '\u0020', true)
                + ' | ' + adjustStringLength('DEPART', '18', '\u0020', true)
                + ' | ' + adjustStringLength('REASON', '18', '\u0020', true);


            const cleanedData = response?.data ? response?.data.map((load, i) => ({
                '#': (i + 1),
                'ShipmentId': load.ShipmentId,
                // 'Customer': `${load.Contract}-${load.Trip}`,
                'Start Time': `${formatDateStrToMMDDYYHHMM(load.StartTime)}`,
                'End Time': `${formatDateStrToMMDDYYHHMM(load.EndTime)}`,
                'SOP Time': `${millisecondsToHHMM(load.SOPmilli)}`,
                'Coverage': `${load?.Coverage.flatMap((innerArray) => innerArray.map((obj) => obj.name)).join('\n')}`,
                'Schedule': `${schedule + '\n' + load?.Stops[0].map(via => `${adjustStringLength(via?.StopNum, '2', '\u0020', false)
                    } | ${adjustStringLength((findUSPSNASSCode(via?.Name) + ', ' + findUSPSNASSCodeState(via?.Name)), '25', '\u0020', true)
                    } | ${adjustStringLength(formatUSPSDateTime(via?.Name, via?.RequestedDate, settings.timezone), '18', '\u0020', true)
                    } | ${adjustStringLength(formatUSPSDateTime(via?.Name, via?.LastRequestedDate, settings.timezone), '18', '\u0020', true)
                    } | ${adjustStringLength(via?.ReasonDesc, '18', ' ', true)
                    }`).join('\n')
                    }`,
                'Equipment': load.Equipment,
                'State': load.State,
                'StopCt': load.StopCt,
                'Date': `${formatDateFromDateStr(load.StartTime)}`,
                'Day of Week': `${getDayOfWeekFromDate(load.StartTime)}`,
            })) : [];
            setTotalRecords(response?.totalRecords)
            setTotalPages(response?.totalPages)
            setBillsOfLading(cleanedData)
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured.' }])
        } finally {
            setDidLoad(true)
        }
    }

    function incrementDate(dateStr) {
        const date = new Date(dateStr);
        // Add 24 hours instead of adding one day
        date.setTime(date.getTime() + 24 * 60 * 60 * 1000); // Add 24 hours in milliseconds
        const incrementedDate = date.toISOString().split('T')[0]; // Format back to YYYY-MM-DD
        return incrementedDate;
    }

    function decrementDate(dateStr) {
        const date = new Date(dateStr);
        // Subtract 24 hours instead of subtracting one day
        date.setTime(date.getTime() - 24 * 60 * 60 * 1000); // Subtract 24 hours in milliseconds
        const decrementedDate = date.toISOString().split('T')[0]; // Format back to YYYY-MM-DD
        return decrementedDate;
    }

    function resetDays() {
        const newQueryStart = formatDate(today);
        const newQueryEnd = formatDate(today);
        setQuery({ ...query, start: newQueryStart, end: newQueryEnd })
        setDidLoad(false)
    }

    function addDay() {
        const newQueryStart = incrementDate(query.start);
        const newQueryEnd = incrementDate(query.end);
        setQuery({ ...query, start: newQueryStart, end: newQueryEnd })
        setDidLoad(false)
    }

    function subtractDay() {
        const newQueryStart = decrementDate(query.start);
        const newQueryEnd = decrementDate(query.end);
        setQuery({ ...query, start: newQueryStart, end: newQueryEnd })
        setDidLoad(false)
    }

    useEffect(() => {
        if (!didLoad) {
            fetchData();
        }
    }, [didLoad, query])



    useEffect(() => {
        localStorage.setItem(`@mctms-bols-query`, JSON.stringify(query))
    }, [query])


    return (
        <Container fluid style={{ margin: 0, padding: 0 }}>
            <AlertDisplay alertState={alertState} />
            <Card style={{ border: 0, borderRadius: 0 }}>
                <Card.Header>
                    <Card.Title>
                        <Row>
                            <Col>
                                Bills of Lading
                            </Col>
                            <Col xs="auto">
                                <Link to={'/freight/bol'}><FaPlusCircle /> BOL</Link>
                            </Col>
                        </Row>
                    </Card.Title>
                    <Row>
                        <Col xs="auto">
                            Period
                            <input type="date" value={query.start} onChange={e => setQuery({ ...query, start: e.target.value })} />
                            <input type="date" value={query.end} onChange={e => setQuery({ ...query, end: e.target.value })} />
                            <button onClick={() => subtractDay()}><FaArrowLeft /></button>
                            <button onClick={() => resetDays()}><IoTodayOutline /></button>
                            <button onClick={() => addDay()}><FaArrowRight /></button>
                        </Col>
                        <Col xs="auto">
                            Records {totalRecords}
                        </Col>
                        <Col xs="auto">
                            Page {query.page} / {totalPages}
                            <button onClick={() => setQuery({ ...query, page: query.page - 1 >= 1 ? query.page - 1 : query.page })}><FaArrowLeft /></button>
                            <button onClick={() => setQuery({ ...query, page: query.page + 1 <= totalPages ? query.page + 1 : query.page })}><FaArrowRight /></button>
                        </Col>
                        <Col xs="auto">
                            Page Size
                            <select value={query.pageSize} onChange={(e) => setQuery({ ...query, pageSize: e.target.value })}>
                                <option value={-1}>All</option>
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                            </select>
                        </Col>

                    </Row>
                </Card.Header>
                <Card.Body>
                    {!didLoad ?
                        <LogisticsLoading message={'Loading'} />
                        :
                        <DataDisplayV3
                            dataSource={billsoflading} lsKey='@sybrtms-freight-bols'
                            urlPath={`/freight/bol/`}
                            urlKey={'ShipmentId'}
                            popKeys={[]}
                            sourceName={'Feight BOLs'}
                            addActions={[]}
                            addFunctions={[]}
                        />
                    }
                </Card.Body>
            </Card>
        </Container>
    )
}



export default BillsOfLading;