import { useEffect, useState } from "react";
import AlertDisplay from "../../components/AlertDisplay";
import Api from "../../utils/Api";
import { useAuth } from "../../context/auth";
import { Accordion, Badge, Button, Card, Col, Container, Form, Row, Spinner, Table } from "react-bootstrap";
import DataDisplayV3 from "../../components/DataDisplayV3";
import { findUSPSNASSCode, findUSPSNASSCodeState, formatUSPSDateTime } from "../../context/uspsdata";
import { Link } from "react-router-dom";
import { multidimensionalGroupBy } from "../../utils/Array.helper";
import { FaArrowLeft, FaArrowRight, FaCalendar, FaMinus, FaPlus } from "react-icons/fa";
import { formatDateFromDateStr, formatDateStrToMMDDYY, formatDateStrToMMDDYYHHMM, getDayOfWeekFromDate, millisecondsToHHMM } from "../../utils/DateTimeFormat.helper";
import { useSettings } from "../../context/settings";
import { adjustStringLength } from "../../utils/String.helper";

const today = new Date();
const tomorrow = new Date(today);
tomorrow.setDate(today.getDate() + 1);

const initialQuery = {
    contract: 'All',
    startdate: '',
    enddate: '',
};

function LoadTenderQuickCover({ feature }) {
    const { user, idToken } = useAuth();
    const { settings } = useSettings();
    const [set, didSet] = useState();
    const [didLoadData, setDidLoadData] = useState();
    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts }
    const [schedules, setSchedules] = useState([]);
    const [unscheduled, setUnscheduled] = useState([]);

    const [contracts, setContracts] = useState([]);

    const [query, setQuery] = useState(() => {
        const savedQuery = localStorage.getItem(`@mctms-edischedules-query`);
        return savedQuery !== null ? JSON.parse(savedQuery) : initialQuery;
    });

    function objectToUrlParams(obj) {
        return Object.keys(obj)
            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
            .join('&');
    }

    useEffect(() => {
        localStorage.setItem(`@mctms-edischedules-query`, JSON.stringify(query))
        const timer = setTimeout(() => {
            didSet(false);
        }, 1500);
        return () => clearTimeout(timer);
    }, [query]);




    function collapseUSPSContractTripData(data) {
        try {
            const items = data.split(', ').map(item => {
                const [prefix, number] = item.split(' ');
                return { prefix, number };
            });

            const result = [];
            let currentPrefix = items[0].prefix;
            let currentNumbers = [];

            items.forEach(({ prefix, number }, index) => {
                if (prefix !== currentPrefix) {
                    if (currentNumbers.length) {
                        result.push(`${currentPrefix} (${currentNumbers.join(' -> ')})`);
                    }
                    currentPrefix = prefix;
                    currentNumbers = [number];
                } else {
                    currentNumbers.push(number);
                }

                if (index === items.length - 1) {
                    result.push(`${currentPrefix} (${currentNumbers.join(' -> ')})`);
                }
            });

            return result.join('\n');
        } catch (error) {
            // console.error('An error occurred:', error);
            return '';
        }
    }

    async function fetchEdiSchedules() {
        try {

            const queryStr = new URLSearchParams(query).toString();
            const data = await Api.get(`/loadtenders/schedules?${queryStr}`, idToken);
            const cleanData = data.map((sched) => {
                console.log(sched.routes);
                return {
                    '_id': sched._id,
                    'Effective Date': sched.effectiveDate,
                    'Contract (Trips)': collapseUSPSContractTripData(sched.routes.map(route => `${route.contract} ${route.trip}`).join(', ')),
                    'Contract-Trip': sched.routes.map(route => `${route.contract} ${route.trip}`).join('\n'),
                    'Route': sched.routes.map(route => `${route.contract}-${route.trip}\n${(route.stops)}`).join('\n'),
                    'Schedule': sched.schedules.map((sch, w) => Object.keys(sch).map(dow => `WK${w + 1} | ${dow} - ${sch[dow].map(driver => `${driver.name}`)}`).join('\n')).join('\n'),
                    'Type': sched.type,
                }
            })
            setSchedules(cleanData)
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'Failed to fetch Load Schedules' }])
        } finally {
            didSet(true)
        }
    }


    async function fetchEdiUnscheduled() {
        try {
            const queryString = new URLSearchParams(query).toString()
            const data = await Api.get(`/loadtenders/unscheduled?${queryString}`, idToken);
            setUnscheduled(data)
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'Failed to fetch unscheduled loads' }])
        } finally {
            didSet(true)
        }
    }

    async function fetchScheduleConflicts() {
        try {
            // const queryString = new URLSearchParams(query).toString()
            // const data = await Api.get(`/loadtenders/unscheduled?${queryString}`, idToken);
            // const cleanData = data.map((shipment) => {
            //     return {
            //         '_id': shipment._id,
            //     }
            // })
            // setUnscheduled(cleanData)
            setAlerts([...alerts, { variant: 'success', message: 'Success' }])
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'Failed to fetch unscheduled loads' }])
        } finally {
            didSet(true)
        }
    }

    async function fetchData() {
        try {
            const ediContracts = await Api.get(`/loadtender/usps/contracts`, idToken);
            setContracts(ediContracts)
        } catch (err) {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured getting Contracts.' }])
        } finally {
            setDidLoadData(true)
        }
    }
    // Function to format a Date object into yyyy-mm-dd format
    function formatDate(date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }


    function setQueryForecastDates(val) {
        if (val === 0) {
            setQuery({ ...query, startdate: formatDate(today), enddate: formatDate(today), })
        } else {
            const newEnd = new Date(query.enddate);
            newEnd.setDate(newEnd.getDate() + val);
            const ed = formatDate(newEnd)
            setQuery({ ...query, enddate: ed, })
        }

    }


    function setQueryForecastForward() {
        const newStart = new Date(query.startdate);
        newStart.setDate(newStart.getDate() + 2);
        const newEnd = new Date(query.enddate);
        newEnd.setDate(newEnd.getDate() + 2);
        const sd = formatDate(newStart)
        const ed = formatDate(newEnd)
        setQuery({ ...query, startdate: sd, enddate: ed });
    }


    function setQueryForecastBackward() {
        const newStart = new Date(query.startdate);
        newStart.setDate(newStart.getDate());
        const newEnd = new Date(query.enddate);
        newEnd.setDate(newEnd.getDate());
        const sd = formatDate(newStart)
        const ed = formatDate(newEnd)
        setQuery({ ...query, startdate: sd, enddate: ed });
    }


    useEffect(() => {
        if (!didLoadData) {
            fetchData()
        }
        if (!set) {
            fetchSamsaraDrivers();
            fetchEdiUnscheduled()
        }
    }, [set, didLoadData])


    //
    const [toggleTableCard, setToggleTableCard] = useState(true);
    const [show, setShow] = useState(false);
    const [loads, setLoads] = useState([]);
    const [draggingShipment, setDraggingShipment] = useState(null);
    const [draggingFromLoad, setDraggingFromLoad] = useState(false);

    const handleDragStart = (shipment, fromLoad = false) => {
        setDraggingShipment(shipment);
        setDraggingFromLoad(fromLoad);
    };

    const handleDrop = (e, toLoad) => {
        e.preventDefault();
        if (draggingShipment) {
            if (toLoad) {
                if (!draggingFromLoad) {
                    setLoads([...loads, draggingShipment]);
                }
            } else {
                if (draggingFromLoad) {
                    setLoads(loads.filter(load => load !== draggingShipment));
                }
            }
            setDraggingShipment(null);
            setDraggingFromLoad(false);
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    function addToLoads(id) {
        const newShipment = unscheduled.find(shipment => shipment.ShipmentId === id);
        setLoads([...loads, newShipment]);
    };

    function removeFromLoads(id) {
        const updatedLoads = loads.filter((load) => load.ShipmentId !== id);
        setLoads(updatedLoads);
    };

    //
    const [operatorlocation, setOperatorLocation] = useState('All');
    const [samsaraDrivers, setSamsaraDrivers] = useState([]);
    const [samsaraDriversByLocation, setSamsaraDriversByLocation] = useState();
    const [coverage, setCoverage] = useState([]);

    const fetchSamsaraDrivers = async () => {
        try {
            const driversdata = await Api.get(`/samsara/drivers-active`, idToken);
            const drivers = driversdata?.data;
            const groupedData = drivers.reduce((groups, record) => {
                const homeTerminalName = record?.carrierSettings?.homeTerminalName;
                if (!groups[homeTerminalName]) {
                    groups[homeTerminalName] = [];
                }
                groups[homeTerminalName].push(record);
                return groups;
            }, {});

            const sortedKeys = Object.keys(groupedData).sort();

            const sortedGroupedData = {};
            for (const key of sortedKeys) {
                sortedGroupedData[key] = groupedData[key];
            }

            setSamsaraDrivers(drivers)
            setSamsaraDriversByLocation(sortedGroupedData)
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'Error fetching active drivers' }])
        }
    }

    async function handleScheduleLoads() {
        try {
            if (loads.length === 0 && coverage.length === 0) {
                throw new Error('Assign a driver, or multiple drivers. Add shipments to be covered.')
            }

            if (loads.length === 0) {
                throw new Error('Add shipments to the "Drag and Drop" box.')
            }

            if (coverage.length === 0) { // todo 
                throw new Error('To assign a driver, please select a driver.')
            }


            const edi204ShipmentsUpdate = {
                shipmentIds: loads.map(load => load.ShipmentId),
                operatortype: 'samsara',
                operators: coverage,
                assignedby: user.email,
                assignedreason: 'Needs Coverage'
            }

            await Api.patch(`/loadtender/shipments/coverage-assignments`, { edi204ShipmentsUpdate: edi204ShipmentsUpdate }, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Successfully assigned operators to shipments.' }])
            setLoads([])
            didSet(false)
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: error.message }])
        }
    }

    const buttonStyle = {
        margin: '0.1em',
        border: '1px solid #5182bd',
        backgroundColor: '#deeaf6'
    }

    return (
        <Container fluid style={{ margin: 0, padding: 0 }}>
            <AlertDisplay alertState={alertState} />
            <Card style={{ margin: 0, borderRadius: 0, border: 0 }}>
                <Card.Header>
                    <Row style={{ alignItems: 'center' }}>
                        <Col>
                            <Card.Title>
                                Quick Cover Open Shipments
                            </Card.Title>
                        </Col>
                        <Col xs="auto">
                            Contract
                            <select
                                style={buttonStyle}
                                value={query.contract}
                                onChange={e => setQuery({ ...query, contract: e.target.value })}
                            >
                                <option value="All">All</option>
                                {contracts.map((c, i) => (
                                    <option key={i + '-contracts'} value={c._id}>
                                        {c._id}
                                    </option>
                                ))}
                            </select>
                        </Col>
                        <Col xs="auto">
                            <Row>
                                <Col xs="auto">
                                    Start <input
                                        type="date"
                                        style={buttonStyle}
                                        value={query?.startdate}
                                        onChange={(e) => setQuery({ ...query, startdate: e.target.value })}
                                    // readOnly
                                    // disabled
                                    />
                                    End <input
                                        style={buttonStyle}
                                        type="date" value={query?.enddate}
                                        onChange={(e) => setQuery({ ...query, enddate: e.target.value })}
                                    // readOnly
                                    // disabled
                                    />
                                </Col>
                                <Col xs="auto">
                                    <button style={buttonStyle} onClick={() => setQueryForecastBackward()}><FaArrowLeft /></button>
                                    <button style={buttonStyle} onClick={() => setQueryForecastForward()}><FaArrowRight /></button>
                                    <button style={buttonStyle} onClick={() => setQueryForecastDates(0)}><FaCalendar /></button>
                                    <button style={buttonStyle} onClick={() => setQueryForecastDates(2)}>+1</button>
                                    <button style={buttonStyle} onClick={() => setQueryForecastDates(3)}>+3</button>
                                    <button style={buttonStyle} onClick={() => setQueryForecastDates(7)}>+7</button>
                                    <button style={buttonStyle} onClick={() => setQueryForecastDates(14)}>+14</button>
                                    <button style={buttonStyle} onClick={() => setQueryForecastDates(28)}>+28</button>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs="auto">
                            <Link to={'/loadtender/quickcover'}>Unscheduled</Link>
                        </Col>
                        <Col xs="auto">
                            <Link to={'/loadtender/schedules'}>Schedules</Link>
                        </Col>
                        <Col xs="auto">
                            <Link to={'/loadtender/scheduler'}>New Schedule</Link>
                        </Col>
                    </Row>
                </Card.Header>

                <Accordion style={{ borderRadius: 0 }}>
                    <Accordion.Item eventKey="0" style={{ borderRadius: 0 }}>
                        <Accordion.Header><b>How to use Quick Cover?</b></Accordion.Header>
                        <Accordion.Body>
                            <Card.Subtitle>
                                This interface is designed to allow you to quickly cover multiple trips at the same time.
                                <ul>
                                    <li>Select one or more drivers/carriers that will cover the shipments. You can do this by using the "Driver Coverage" section.</li>
                                    <li>Drag and Drop shipments from "Unscheduled Shipments" to "Schedule Shipments". </li>
                                </ul>
                                When you press, "Schedule" The selected drivers will be booked on the shipments.
                            </Card.Subtitle>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>


                <Card.Body>
                    {!set ? <Spinner /> :
                        <>
                            <Row>
                                <Col xs="12" md="6" lg="5" xl="4">
                                    <Card>
                                        <Card.Header>
                                            <Card.Title>Schedule Shipments (x{loads.length ?? 0})</Card.Title>
                                        </Card.Header>
                                        <Card.Body>
                                            <Col xs="auto">
                                                <Card.Title>Driver Coverage</Card.Title>
                                                <Form.Select onChange={(e) => setOperatorLocation(e.target.value)}>
                                                    <option>All</option>
                                                    {samsaraDriversByLocation && Object.keys(samsaraDriversByLocation).map((key, k) => (
                                                        <option key={k + '-drivers'} value={key}>{key ? key : 'Undefined'}</option>
                                                    ))}
                                                </Form.Select>
                                            </Col>
                                            <Col>
                                                {operatorlocation === 'All'
                                                    ?
                                                    <Form.Select
                                                        style={{ backgroundColor: '#eee' }}
                                                        onChange={(e) => e.target.value !== '' && setCoverage([...coverage, JSON.parse(e.target.value)])}>
                                                        <option value=''>Select...</option>
                                                        {samsaraDriversByLocation && Object.keys(samsaraDriversByLocation).sort((a, b) => a.localeCompare(b)).flatMap(record => record).map((key, si) => (
                                                            samsaraDriversByLocation[key].map((driver, sj) => (
                                                                <option key={si + '-' + sj + '-opLocation'} value={JSON.stringify(driver)}>{driver.name}</option>
                                                            ))
                                                        ))}
                                                    </Form.Select>
                                                    :
                                                    <Form.Select
                                                        style={{ backgroundColor: '#eee' }}
                                                        onChange={(e) => e.target.value !== '' && setCoverage([...coverage, JSON.parse(e.target.value)])}>
                                                        <option value=''>Select...</option>
                                                        {samsaraDriversByLocation[operatorlocation] && samsaraDriversByLocation[operatorlocation].sort((a, b) => a.name.localeCompare(b.name)).map((driver, di) => (
                                                            <option key={di + '-driverLocation'} value={JSON.stringify(driver)}>{driver.name}</option>
                                                        ))}
                                                    </Form.Select>
                                                }
                                            </Col>
                                            <Col>
                                                <div>
                                                    {coverage && coverage.map((driver, i) => (
                                                        <Row style={{ margin: '0.25em', padding: '0.1em', border: '1px dashed #ccc', }}>
                                                            <Col style={{ alignContent: 'center' }}>
                                                                {driver?.name}
                                                            </Col>
                                                            <Col xs="auto" style={{ padding: 0, margin: 0 }}>
                                                                <Button variant={'secondary'} onClick={() => setCoverage(coverage.filter((_, index) => index !== i))}>X</Button>
                                                            </Col>
                                                        </Row>
                                                    ))}
                                                </div>
                                            </Col>
                                        </Card.Body>
                                        <Card.Body>
                                            <i>The selected driver(s) will be booked on the shipments in the dashed box.</i>
                                            <div
                                                onDrop={(e) => handleDrop(e, true)}
                                                onDragOver={handleDragOver}
                                                style={{ minHeight: '200px', border: '1px dashed #ccc', fontSize: '1em', fontFamily: 'monospace' }}
                                            >
                                                {loads && loads.sort((a, b) => {
                                                    // Extract the numeric part after underscore from both identifiers
                                                    const [, numA] = a.Stops[0].References[0].Identifier.split('_');
                                                    const [, numB] = b.Stops[0].References[0].Identifier.split('_');

                                                    // Convert them to numbers for numeric comparison
                                                    const numAInt = parseInt(numA, 10);
                                                    const numBInt = parseInt(numB, 10);

                                                    // Compare the prefixes numerically
                                                    const prefixComparison = numAInt - numBInt;

                                                    // If prefixes are equal, compare the entire identifiers lexicographically
                                                    if (prefixComparison === 0) {
                                                        return a.Stops[0].References[0].Identifier.localeCompare(b.Stops[0].References[0].Identifier);
                                                    }

                                                    // Otherwise, return the numeric comparison result
                                                    return prefixComparison;
                                                }).map((shipment, index) => (
                                                    <>
                                                        <div
                                                            key={index}
                                                            draggable
                                                            onDragStart={() => handleDragStart(shipment, true)}
                                                            style={{ margin: '5px', border: '1px solid #ccc' }}
                                                        >
                                                            <div style={{ backgroundColor: '#eee', padding: '0.25em 0.1em' }}>
                                                                <Row>
                                                                    <Col>
                                                                        <Link to={`/${feature}/${shipment.ShipmentId}`} target={'_blank'}>
                                                                            {shipment.Stops[0].References[0].Identifier.split('_')[0]} {shipment.Stops[0].References[0].Identifier.split('_')[1]}<br />
                                                                        </Link>
                                                                    </Col>
                                                                    <Col xs="auto">
                                                                        {new Date(shipment.Stops[0].RequestedDate).toLocaleDateString('en-US', { weekday: 'short' })}
                                                                        {' '}{formatDateStrToMMDDYY(shipment.Stops[0].RequestedDate.split('T')[0])}
                                                                        {' '}{`${shipment.Stops[0].RequestedDate.split('T')[1].split(':')[0]}:${shipment.Stops[0].RequestedDate.split('T')[1].split(':')[1]}`}
                                                                        {' '}{`${shipment.Stops[shipment.Stops.length - 1].LastRequestedDate.split('T')[1].split(':')[0]}:${shipment.Stops[shipment.Stops.length - 1].LastRequestedDate.split('T')[1].split(':')[1]}`}
                                                                    </Col>
                                                                    <Col xs="auto">
                                                                        <button onClick={() => removeFromLoads(shipment.ShipmentId)} style={{ border: '1px solid black', padding: '0.25em', margin: '0.1em', fontSize: '0.8em', backgroundColor: '#f39c9c' }}><FaMinus /></button>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                            {show &&
                                                                <div style={{ backgroundColor: '#fff', padding: '0.25em 0.1em' }}>
                                                                    {shipment.Stops.map((via, viaIndex) => (
                                                                        <div key={viaIndex}>
                                                                            <Row>
                                                                                <Col xs="auto">{via?.RequestedDate && `${via.RequestedDate.split('T')[1].split(':')[0]}:${via.RequestedDate.split('T')[1].split(':')[1]}`}</Col>
                                                                                <Col xs="auto">{via?.LastRequestedDate && `${via.LastRequestedDate.split('T')[1].split(':')[0]}:${via.LastRequestedDate.split('T')[1].split(':')[1]}`}</Col>
                                                                                <Col xs="auto">{findUSPSNASSCode(via.Name)} - {via.City}, {via.State}</Col>
                                                                            </Row>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            }
                                                        </div>
                                                    </>

                                                ))}
                                            </div>
                                            <div>
                                                <Row>
                                                    <Col>
                                                        <Button variant="warning" onClick={() => setLoads([])}>Clear</Button>
                                                    </Col>
                                                    <Col xs="auto">
                                                        <Button variant="primary" onClick={() => handleScheduleLoads()} >Schedule</Button>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col xs="12" md="6" lg="7" xl="8">
                                    <Card>
                                        <Card.Header>
                                            <Row>
                                                <Col>
                                                    <Card.Title>Unscheduled Shipments (x{unscheduled.length ?? 0})</Card.Title>
                                                </Col>
                                                <Col xs="auto">
                                                    Toggle View <Button variant={'secondary'} onClick={() => setToggleTableCard(!toggleTableCard)}>{toggleTableCard ? 'Table' : 'Cards'}</Button>
                                                </Col>
                                                <Col xs="auto">
                                                    Show Detail <Button variant={show ? 'success' : 'warning'} onClick={() => setShow(!show)}>{show ? 'On' : 'Off'}</Button>
                                                </Col>
                                            </Row>
                                        </Card.Header>
                                        <Card.Body>
                                            {toggleTableCard ?
                                                <Table>
                                                    <thead>
                                                        <tr>
                                                            <th>+|-</th>
                                                            <th>Contract Trip</th>
                                                            <th>Date</th>
                                                            {show && <th>Schedule</th>}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {unscheduled.sort((a, b) => {
                                                            // Extract the numeric part after underscore from both identifiers
                                                            const [, numA] = a.Stops[0].References[0].Identifier.split('_');
                                                            const [, numB] = b.Stops[0].References[0].Identifier.split('_');

                                                            // Convert them to numbers for numeric comparison
                                                            const numAInt = parseInt(numA, 10);
                                                            const numBInt = parseInt(numB, 10);

                                                            // Compare the prefixes numerically
                                                            const prefixComparison = numAInt - numBInt;

                                                            // If prefixes are equal, compare the entire identifiers lexicographically
                                                            if (prefixComparison === 0) {
                                                                return a.Stops[0].References[0].Identifier.localeCompare(b.Stops[0].References[0].Identifier);
                                                            }

                                                            // Otherwise, return the numeric comparison result
                                                            return prefixComparison;
                                                        }).map((shipment, i) => (
                                                            <tr
                                                                xs="auto"
                                                                key={shipment.ShipmentId}
                                                                draggable
                                                                onDragStart={() => handleDragStart(shipment)}
                                                                // style={{ border: '1px solid #ccc', margin: '1em', padding: '0', fontSize: '1em', fontFamily: 'monospace' }}
                                                                style={
                                                                    (loads.length > 0 && loads.reduce((accumulator, load) => {
                                                                        accumulator.push(load.ShipmentId);
                                                                        return accumulator;
                                                                    }, []).includes(shipment.ShipmentId))
                                                                        ? { opacity: 0.25, backgroundColor: '#eee' }
                                                                        : { opacity: 1 }
                                                                }
                                                            >
                                                                <td>
                                                                    {
                                                                        (loads.length > 0 && loads.reduce((accumulator, load) => {
                                                                            accumulator.push(load.ShipmentId);
                                                                            return accumulator;
                                                                        }, []).includes(shipment.ShipmentId))
                                                                            ? <button onClick={() => removeFromLoads(shipment.ShipmentId)} style={{ border: '1px solid black', padding: '0.25em', margin: '0.1em', fontSize: '0.8em', backgroundColor: '#f39c9c' }}><FaMinus /></button>
                                                                            : <button onClick={() => addToLoads(shipment.ShipmentId)} style={{ border: '1px solid black', padding: '0.25em', margin: '0.1em', fontSize: '0.8em', backgroundColor: '#0d6efd', color: '#fff' }}><FaPlus /></button>
                                                                    }
                                                                </td>
                                                                <td>
                                                                    <Link to={`/${feature}/${shipment.ShipmentId}`} target={'_blank'}>
                                                                        {shipment.Stops[0].References[0].Identifier.split('_')[0]} {shipment.Stops[0].References[0].Identifier.split('_')[1]}<br />
                                                                    </Link>
                                                                </td>
                                                                <td>{new Date(shipment.Stops[0].RequestedDate).toLocaleDateString('en-US', { weekday: 'short' })}  {formatDateStrToMMDDYY(shipment.Stops[0].RequestedDate.split('T')[0])}</td>
                                                                {show &&
                                                                    <td>
                                                                        <div style={{ backgroundColor: '#fff', padding: '0.25em 0.1em' }}>
                                                                            {shipment.Stops.map((via, viaIndex) => (
                                                                                <div key={viaIndex} >
                                                                                    <Row>
                                                                                        <Col xs="auto">{via?.RequestedDate && `${via.RequestedDate.split('T')[1].split(':')[0]}:${via.RequestedDate.split('T')[1].split(':')[1]}`}</Col>
                                                                                        <Col xs="auto">{via?.LastRequestedDate && `${via.LastRequestedDate.split('T')[1].split(':')[0]}:${via.LastRequestedDate.split('T')[1].split(':')[1]}`}</Col>
                                                                                        <Col xs="auto">{findUSPSNASSCode(via.Name)} - {via.City}, {via.State}</Col>
                                                                                    </Row>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    </td>
                                                                }
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                                :
                                                <Row
                                                    onDrop={(e) => handleDrop(e, false)}
                                                    onDragOver={handleDragOver}
                                                    style={{ minHeight: '200px' }}
                                                >
                                                    {unscheduled.sort((a, b) => (a.Stops[0].References[0].Identifier.localeCompare(b.Stops[0].References[0].Identifier))).map((shipment, i) => (
                                                        <Col
                                                            xs="auto"
                                                            key={shipment.ShipmentId}
                                                            draggable
                                                            onDragStart={() => handleDragStart(shipment)}
                                                            style={{ border: '1px solid #ccc', margin: '1em', padding: '0', fontSize: '1em', fontFamily: 'monospace' }}

                                                        >
                                                            <div
                                                                style={
                                                                    (loads.length > 0 && loads.reduce((accumulator, load) => {
                                                                        accumulator.push(load.ShipmentId);
                                                                        return accumulator;
                                                                    }, []).includes(shipment.ShipmentId))
                                                                        ? { opacity: 0.25, backgroundColor: '#eee' }
                                                                        : { opacity: 1 }
                                                                }>
                                                                <div style={{ backgroundColor: '#eee', padding: '0.25em 0.1em' }}>
                                                                    <Row>
                                                                        <Col>
                                                                            <Link to={`/${feature}/${shipment.ShipmentId}`} target={'_blank'}>
                                                                                {shipment.Stops[0].References[0].Identifier.split('_')[0]} {shipment.Stops[0].References[0].Identifier.split('_')[1]}<br />
                                                                            </Link>
                                                                        </Col>
                                                                        <Col xs="auto">
                                                                            {new Date(shipment.Stops[0].RequestedDate).toLocaleDateString('en-US', { weekday: 'short' })}  {formatDateStrToMMDDYY(shipment.Stops[0].RequestedDate.split('T')[0])}
                                                                        </Col>
                                                                        <Col xs="auto">
                                                                            {
                                                                                (loads.length > 0 && loads.reduce((accumulator, load) => {
                                                                                    accumulator.push(load.ShipmentId);
                                                                                    return accumulator;
                                                                                }, []).includes(shipment.ShipmentId))
                                                                                    ? <button onClick={() => removeFromLoads(shipment.ShipmentId)} style={{ border: '1px solid black', padding: '0.25em', margin: '0.1em', fontSize: '0.8em', backgroundColor: '#f39c9c' }}><FaMinus /></button>
                                                                                    : <button onClick={() => addToLoads(shipment.ShipmentId)} style={{ border: '1px solid black', padding: '0.25em', margin: '0.1em', fontSize: '0.8em', backgroundColor: '#0d6efd', color: '#fff' }}><FaPlus /></button>
                                                                            }
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                                {show ?
                                                                    <>
                                                                        <div style={{ backgroundColor: '#fff', padding: '0.25em 0.1em' }}>
                                                                            {shipment.Stops.map((via, viaIndex) => (
                                                                                <div key={viaIndex} >
                                                                                    <Row>
                                                                                        <Col xs="auto">{via?.RequestedDate && `${via.RequestedDate.split('T')[1].split(':')[0]}:${via.RequestedDate.split('T')[1].split(':')[1]}`}</Col>
                                                                                        <Col xs="auto">{via?.LastRequestedDate && `${via.LastRequestedDate.split('T')[1].split(':')[0]}:${via.LastRequestedDate.split('T')[1].split(':')[1]}`}</Col>
                                                                                        <Col xs="auto">{findUSPSNASSCode(via.Name)} - {via.City}, {via.State}</Col>
                                                                                    </Row>

                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    </>
                                                                    : ''}
                                                            </div>
                                                        </Col>
                                                    ))}
                                                </Row>
                                            }
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </>
                    }
                </Card.Body>
            </Card>
        </Container>
    )
}


export default LoadTenderQuickCover;