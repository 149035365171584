import React, { useState, useEffect } from 'react'
import { SubscriptionProvider } from './context/subscription';
import { AuthProvider } from './context/auth';
import { ThemeProvider } from './context/theme';
import { SettingsProvider } from './context/settings';
import AppPrivate from './AppPrivate';
import RoleProvider from './context/role';
import VerifyEmail from './views/VerifyEmail';
import { LocationHistoryProvider } from './context/navhistory';
import { MdOutlineSignalWifiConnectedNoInternet4 } from 'react-icons/md';

function App() {
  const currentRoute = window.location.pathname;
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);

  // Check network status when the component mounts and whenever it updates
  useEffect(() => {
    const handleOnlineStatusChange = () => {
      setIsOnline(window.navigator.onLine);

      if (window.navigator.onLine) {
        window.location.reload();
      }

    };

    window.addEventListener('online', handleOnlineStatusChange);
    window.addEventListener('offline', handleOnlineStatusChange);

    return () => {
      window.removeEventListener('online', handleOnlineStatusChange);
      window.removeEventListener('offline', handleOnlineStatusChange);
    };
  }, []);


  if (!isOnline) {
    return (
      <>
        <div
          style={{
            width: '100%',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#3366cc',
            color: 'white',
          }}
        >
          <div style={{ textAlign: 'center' }}>
            <h3>Connect to the Internet</h3>
            <p>Check your network connection and try again.</p>
            <h1 style={{ fontSize: '70px' }}><MdOutlineSignalWifiConnectedNoInternet4 /></h1>
          </div>
        </div>
      </>
    )
  }

  return (
    <LocationHistoryProvider>
      <SettingsProvider>
        <ThemeProvider>
          <AuthProvider>
            {currentRoute === '/verify' ?
              <VerifyEmail />
              :
              <RoleProvider>
                <SubscriptionProvider>
                  <AppPrivate />
                </SubscriptionProvider>
              </RoleProvider>
            }
          </AuthProvider>
        </ThemeProvider>
      </SettingsProvider>
    </LocationHistoryProvider>
  )
}

export default App;
