import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState } from "react";
import { Container, Card, Button, Form, Alert, Row, Col, Tabs, Tab } from "react-bootstrap";
import { useAuth } from '../context/auth';
import ReCAPTCHA from "react-google-recaptcha";
import sybrLogo from '../assets/sybr-logo-lg.png'
import { BsMicrosoft } from 'react-icons/bs';

function Login() {
    const { sendEmailVerificationLink, handleMicrosoftLogin } = useAuth();
    const [email, setEmail] = useState('');
    const [token, setToken] = useState('');
    const [alerts, setAlerts] = useState([]);

    const onReCAPTCHAChange = (value) => {
        setToken(value);
    };

    // const handleMicrosoftLoginClick = async () => {
    //     try {
    //         await handleMicrosoftLogin();
    //     } catch (error) {
    //         setAlerts([...alerts, {variant:'warning', message: JSON.stringify(error)}]);
    //     }
    // }

    const handlePreSignedURL = async () => {
        try {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (email.trim() !== '' && emailRegex.test(email)) {
                await sendEmailVerificationLink(email);
                setAlerts([...alerts, {variant:'success',message:`Successfully sent. Check your email/spam.`}]);
                localStorage.setItem(process.env.REACT_APP_FIREBASE_VERIFY_EMAIL_LS_KEY, email);
            } else {
                setAlerts([...alerts, {variant:'warning',message:`Please enter an email address.`}]);
            }

        } catch (error) {
            setAlerts([...alerts, {variant:'warning',message: JSON.stringify(error)}]);
        }
    }

    const CenteredHr = ({text}) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '20px 0' }}>
            <span style={{ flex: 1, height: '1px', background: '#000', margin: '0 10px' }}></span>
            <i>{text}</i>
            <span style={{ flex: 1, height: '1px', background: '#000', margin: '0 10px' }}></span>
          </div>
        );
      }
      

    return (
        <Container className="d-flex align-items-center justify-content-center vh-100 m-0 p-0" fluid style={{ backgroundColor: '#3366cc' }}>
            <Card style={{ textAlign: 'center', width:'350px'}}>
                <Card.Img variant="top" src={sybrLogo} style={{width:'100%'}}/>
                <Card.Body>
                    <h3>Welcome to SYBR's TMS</h3>
                    <p>
                        Please enter your email address to get access.
                    </p>
                </Card.Body>
                <Card.Body className="d-flex align-items-center justify-content-center">
                    <ReCAPTCHA
                        sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
                        onChange={onReCAPTCHAChange}
                    />
                </Card.Body>
                {token && (
                    <>
                        <Card.Body>
                            <Row>
                                <Col>
                                    <Form.Control type="email" value={email} onChange={e => setEmail(e.target.value)} placeholder="email" />
                                </Col>
                            </Row>
                            <br/>
                            <Row>
                                <Col>
                                    <Button variant="primary" onClick={handlePreSignedURL}>Email me a Sign-in Link</Button>
                                </Col>
                            </Row>
                        </Card.Body>
                        {alerts.length > 0 && 
                            alerts.map((alrt,i)=>(
                                <Card.Body key={i} >
                                    <Alert variant={alrt.variant} 
                                    onClose={() => setAlerts(alerts.filter((index) => index !== i))} dismissible>
                                        {alrt.message}
                                    </Alert>
                                </Card.Body>
                            ))
                        }
                   </>
                )}
            </Card>
        </Container>
    );
}

export default Login;
