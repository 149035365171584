import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';

const VirtualizedTable = ({ uspsLocations }) => {
    const [startIndex, setStartIndex] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');
    const visibleRowCount = 15; // Fixed number of visible rows
    const rowHeight = 35;
    const tableRef = useRef(null);

    const handleScroll = () => {
        if (tableRef.current) {
            const newStartIndex = Math.floor(tableRef.current.scrollTop / rowHeight);
            setStartIndex(newStartIndex);
        }
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setStartIndex(0); // Reset to the start when a new search is made
    };

    const filteredLocations = uspsLocations.filter(location =>
        location['Location ID'].toLowerCase().includes(searchQuery.toLowerCase()) ||
        location['Location Name'].toLowerCase().includes(searchQuery.toLowerCase()) ||
        location['Address'].toLowerCase().includes(searchQuery.toLowerCase())
    );

    const endIndex = startIndex + visibleRowCount;
    const visibleData = filteredLocations.slice(startIndex, endIndex);

    const generateGoogleMapsLink = (address) => {
        return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`;
    };

    const generateGoogleMapsLinkFromCoordinates = (lat, lng) => {
        return `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;
    };

    return (
        <div>
            <input
                type="text"
                value={searchQuery}
                onChange={handleSearchChange}
                placeholder="Search by Location Name or Address"
                style={{ marginBottom: '10px', padding: '8px', width: '100%' }}
            />
            <div
                ref={tableRef}
                onScroll={handleScroll}
                style={{
                    height: `${visibleRowCount * rowHeight}px`, // Fixed height based on number of visible rows
                    overflowY: 'auto',
                    border: '1px solid #ddd',
                }}
            >
                <table style={{ width: '100%', tableLayout: 'fixed' }}>
                    <thead>
                        <tr>
                            <th style={{ width: '15%' }}>Location ID</th>
                            <th style={{ width: '25%' }}>Location Name</th>
                            <th style={{ width: '15%' }}>Time Zone Offset (Hours)</th>
                            <th style={{ width: '25%' }}>Address</th>
                            <th style={{ width: '20%' }}>Latitude, Longitude</th>
                        </tr>
                    </thead>
                    <tbody style={{ position: 'relative', height: `${filteredLocations.length * rowHeight}px` }}>
                        {visibleData.map((location, index) => (
                            <tr
                                key={location['Location ID']}
                                style={{
                                    position: 'absolute',
                                    top: `${(startIndex + index) * rowHeight}px`,
                                    height: `${rowHeight}px`,
                                    width: '100%',
                                    display: 'flex',
                                }}
                            >
                                <td style={{ flexBasis: '15%', padding: '8px', border: '1px solid #ddd', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                    <Link to={`/data/location/usps/${location['Location ID']}`}>{location['Location ID']}</Link>
                                </td>
                                <td style={{ flexBasis: '25%', padding: '8px', border: '1px solid #ddd', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{location['Location Name']}</td>
                                <td style={{ flexBasis: '15%', padding: '8px', border: '1px solid #ddd', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{location['Time Zone Offset (Hours)']}</td>
                                <td style={{ flexBasis: '25%', padding: '8px', border: '1px solid #ddd', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                    <a href={generateGoogleMapsLink(location['Address'])} target="_blank" rel="noopener noreferrer">{location['Address']}</a>
                                </td>
                                <td style={{ flexBasis: '20%', padding: '8px', border: '1px solid #ddd', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                    <a href={generateGoogleMapsLinkFromCoordinates(location['Latitude'], location['Longitude'])} target="_blank" rel="noopener noreferrer">
                                        {location['Latitude']}, {location['Longitude']}
                                    </a>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default VirtualizedTable;
