import React from 'react'
import { Container, Card, Row, Col,} from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useRole } from "../context/role";
import { Link } from 'react-router-dom';
import { FaBell, FaBox, FaCalendar, FaCarCrash,  FaCheck, FaPlus, FaTruck, FaUserInjured, FaUsers } from 'react-icons/fa';
import { GrScorecard } from 'react-icons/gr';
import { CgProfile } from 'react-icons/cg';


function AlarmPage() {

    const { userRole, checkNavPermissions } = useRole();

    const features = [
        { href: "/alarms/reports", icon: <FaCalendar/>, text: "Reports", detail:'List of alarms types',},
        { href: "/alarms", icon: <FaBell/>, text: "Notifications", detail:'Reports scheduled to send',},
        { href: "/alarms-form", icon: <FaPlus/>, text: "Add Notification", detail:'Create a alarms', },
        { href: "/alarms/groups", icon: <FaUsers/>, text: "Groups", detail:'Groups to be notified' },
        { href: "/alarms/group-form", icon:  <FaPlus/>, text: "Add Group", detail:'Create a group',},
    ]
    
    const allowedFeatures = checkNavPermissions(userRole, features)

    const cardStyle = {
        textAlign:'center',
        width: '250px',
        height: '250px'
    }

    const colStyle = {
        margin: '0.25em',
    }

    return (
        <Container fluid style={{ margin: 0, padding: 0 }}>
            <Card style={{ border: 0, borderRadius: 0 }}>
                <Card.Header>
                    <Card.Title>Notification Management</Card.Title>
                </Card.Header>
                <Card.Body>
                    <Row style={{justifyContent:'space-evenly'}}>
                        {allowedFeatures.map((feature, i) => (
                            <Col xs="auto" style={colStyle}>
                                <Card style={cardStyle}>
                                    <Card.Header>
                                        <b>{feature.text}</b>
                                    </Card.Header>
                                    <Card.Body key={i}>
                                        <Row>
                                            <Col style={{ marginBottom: '1em' }}>
                                                <h1>{feature.icon}</h1>
                                            </Col>
                                        </Row>
                                        <Card.Text>
                                            {feature.detail}
                                        </Card.Text>
                                        <Link to={feature.href}>Go to {feature.text}</Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}

                    </Row>
                </Card.Body>
            </Card>
        </Container>
    )
}

export default AlarmPage;

