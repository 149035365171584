import { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { FaPlusCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
import DataDisplayV3 from "../../components/DataDisplayV3";
import LogisticsLoading from "../../components/LogisticsLoading";
import Api from "../../utils/Api";
import { useAuth } from "../../context/auth";
import AlertDisplay from "../../components/AlertDisplay";

function LoadTenderForwardingRules({ feature }) {
    const { idToken } = useAuth();
    const [forwardingRules, setForwardingRules] = useState([]);
    const [didFetch, setDidFetch] = useState();
    const [query, setQuery] = useState({
        isActive: true
    });
    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts };

    async function fetchForwardingRules() {
        try {
            const queryStr = new URLSearchParams(query).toString();
            const data = await Api.get(`/loadtender/forwarding?${queryStr}`, idToken);
            const cleanData = data.map(company => ({
                _id: company._id,
                Name: company.name,
            }));
            setForwardingRules(cleanData)
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured fetching data.' }])
        } finally {
            setDidFetch(true)
        }
    }

    useEffect(() => {
        if (!didFetch) {
            fetchForwardingRules();
        }
    }, [didFetch])
    
    return (
        <Container fluid style={{ margin: 0, padding: 0 }}>
            <AlertDisplay alertState={alertState} />
            <Card style={{ border: 0, borderRadius: 0 }}>
                <Card.Header>
                    <Card.Title>
                        <Row>
                            <Col>
                                Load Tender Forwarding Rules
                            </Col>
                            <Col xs="auto">
                                <Link to={`/${feature}/forwarding-new`}><FaPlusCircle /> Forwarding Rule</Link>
                            </Col>
                        </Row>
                    </Card.Title>
                </Card.Header>
                <Card.Body>
                    {didFetch ?
                        <DataDisplayV3
                            dataSource={forwardingRules}
                            lsKey='@sybrtms-forwarding-rules'
                            urlPath={`/${feature}/forwarding/`}
                            urlKey={'_id'}
                            popKeys={['_id']}
                            sourceName={'Forwarding Rules'}
                            addActions={[]}
                            addFunctions={[]}
                        />
                        :
                        <LogisticsLoading message="Loading" />
                    }
                </Card.Body>
            </Card>
        </Container>
    )
}


export default LoadTenderForwardingRules;