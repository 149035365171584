import { Container, Card, Row, Col, Form, Button, Alert, Spinner } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect, useRef } from "react";
import Api from "../../utils/Api";
import { useAuth } from '../../context/auth';
import { useParams } from 'react-router-dom';
import AddressAutocomplete from "../../components/AddressAutocomplete";
import AlertDisplay from "../../components/AlertDisplay";

const initialFormState = {
    email: '',
    firstname: '',
    lastname: '',
    role: '',
}

function AccessProfileForm() {
    const [set, didSet] = useState();
    const { idToken } = useAuth();
    const { uid } = useParams();
    const [roles, setRoles] = useState();
    const [user, setUser] = useState();
    const [formdata, setFormdata] = useState(initialFormState)
    const [alerts, setAlerts] = useState([]);
    const alertState = {alerts, setAlerts}
    const addressAutocompleteRef = useRef(null);
    const [fullAddress, setFullAddress] = useState('');
    const fullAddressState = { fullAddress, setFullAddress }

    useEffect(() => {
        setFormdata({ ...formdata, fullAddress: fullAddress })
    }, [fullAddress])

    async function handlePost() {
        try {
            const missingFields = Object.keys(initialFormState).filter((field) => formdata[field] === null || formdata[field] === undefined || formdata[field] === '');

            if (missingFields.length > 0) {
                const missingFieldNames = missingFields.map((field) =>
                    field.toUpperCase()
                );
                const missingFieldsMessage = missingFieldNames.join(", ");
                setAlerts([
                    ...alerts,
                    {
                        variant: "warning",
                        message: `Please fill in the required fields: ${missingFieldsMessage}`,
                    },
                ]);
                return;
            }

            const res = await Api.post('/access/profiles', { uid: uid, email: user.email, ...formdata }, idToken)
            setAlerts([
                ...alerts,
                {
                    variant: "success",
                    message: `${JSON.stringify(res)}`,
                },
            ]);
        } catch (error) {
            console.log(error)
            setAlerts([
                ...alerts,
                {
                    variant: "warning",
                    message: `Error: ${JSON.stringify(error)}`,
                },
            ]);
        }
    }

    async function handleBlock() {
        try {
            const data = { ...formdata, 'uid': uid };
            const res = await Api.post('/access/block', data, idToken)
            setAlerts([
                ...alerts,
                {
                    variant: "success",
                    message: `${JSON.stringify(res)}`,
                },
            ]);
        } catch (error) {
            setAlerts([
                ...alerts,
                {
                    variant: "warning",
                    message: `Error: ${JSON.stringify(error)}`,
                },
            ]);
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const r = await Api.get('/access/roles/shared', idToken);
                setRoles(r)
                const u = await Api.get(`/firebase/user/${uid}`, idToken);
                setUser(u)

            } catch (error) {
                setAlerts([
                    ...alerts,
                    {
                        variant: "warning",
                        message: `Error: ${JSON.stringify(error)}`,
                    },
                ]);
            }
            didSet(true)
        }
        if (!set) {
            fetchData()
        }
    }, [idToken, set, uid, alerts])

    return (
        <Container fluid style={{ margin: 0, padding: 0 }}>
            <AlertDisplay alertState={alertState} />
            <Card style={{border:0,  borderRadius: 0 }}>
                <Card.Header><b>Profile Form</b></Card.Header>
                {(set && user) ?
                    <Card.Body>
                        <Row>
                            <Col>
                                Role
                                <Form.Select
                                    type="text"
                                    value={JSON.stringify(formdata.role)}
                                    onChange={e => e.target.value !== '' && setFormdata({ ...formdata, role: JSON.parse(e.target.value) })}
                                >
                                    <option></option>
                                    {roles && roles.map((role, i) => (
                                        <option key={i} value={JSON.stringify(role)}>{role.name}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                            <Col>
                                Email <br />
                                <Form.Control
                                    type="text"
                                    value={user.email}
                                    readOnly
                                    disabled
                                />
                            </Col>
                            <Col>
                                First Name <br />
                                <Form.Control
                                    type="text"
                                    value={formdata.firstname}
                                    onChange={e => setFormdata({ ...formdata, firstname: e.target.value })}
                                />
                            </Col>
                            <Col>
                                Last Name <br />
                                <Form.Control
                                    type="text"
                                    value={formdata.lastname}
                                    onChange={e => setFormdata({ ...formdata, lastname: e.target.value })}
                                />
                            </Col>
                            <Col>
                                Phone <br />
                                <Form.Control
                                    type="text"
                                    value={formdata.phoneNumber}
                                    onChange={e => setFormdata({ ...formdata, phoneNumber: e.target.value })}
                                />
                            </Col>
                            <Col>
                                Birthday <br />
                                <Form.Control
                                    type="text"
                                    value={formdata.birthday}
                                    onChange={e => setFormdata({ ...formdata, birthday: e.target.value })}
                                />
                            </Col>
                            <Col>
                                Address
                                <AddressAutocomplete ref={addressAutocompleteRef} fullAddressState={fullAddressState} />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col>
                                <Button variant="primary" onClick={() => handlePost()}>Create</Button>
                            </Col>
                            <Col>
                                <Button style={{ float: 'right' }} variant="danger" onClick={() => handleBlock()}>Block</Button>
                            </Col>
                        </Row>
                    </Card.Body>
                    :
                    <Card.Body>
                        <Spinner />
                    </Card.Body>
                }
            </Card>
        </Container>
    );
}

export default AccessProfileForm;