import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect } from 'react'
import { Container, Card, Spinner, Col, Row, } from "react-bootstrap";
import Api from '../../utils/Api';
import { FaCheck, FaPlusCircle } from 'react-icons/fa'
import { useAuth } from '../../context/auth';
import { useSettings } from '../../context/settings';
import { utcToTimezone } from '../../utils/DateTimeFormat.helper';
import DataDisplay from '../../components/DataDisplay';
import { Link } from 'react-router-dom';
import AlertDisplay from '../../components/AlertDisplay';
import DataDisplayV3 from '../../components/DataDisplayV3';

function Alarm() {
    const { idToken } = useAuth();
    const { settings } = useSettings();
    const [set, didSet] = useState();
    const [notifications, setNotifications] = useState([]);
    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts }

    function pad(value, width) {
        if (typeof value === 'boolean') {
            value = value ? 'YES' : '-';
        }
        return value.toString() + ' '.repeat(width - value.toString().length);
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await Api.get('/notifications', idToken);
                const cleanNotifications = data.map(notification => {
                    const scheduleHeader = `${pad("Time",7)}${pad("Zone",7)}${pad("Sun",7)}${pad("Mon",7)}${pad("Tue",7)}${pad("Wed",7)}${pad("Thu",7)}${pad("Fri",7)}${pad("Sat",7)}`;
                    const scheduleRows = notification.schedules.map(schedule => {
                        return `${pad(schedule.time,7)}${pad(schedule.timeZone,7)}${pad(schedule.sunday,7)}${pad(schedule.monday,7)}${pad(schedule.tuesday,7)}${pad(schedule.wednesday,7)}${pad(schedule.thursday,7)}${pad(schedule.friday,7)}${pad(schedule.saturday,7)}`;
                    }).join('\n');

                    return {
                        _id: notification._id,
                        'Report': notification.report,
                        'Members': (notification.groups.map(grp => grp.members.map(_ => _).join('\n'))).join('\n'),
                        'Schedules': `${scheduleHeader}\n${scheduleRows}`,
                    }
                })
                setNotifications(cleanNotifications)
            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: JSON.stringify(error) }]);
            }
            didSet(true)
        }
        if (!set) {
            fetchData()
        }
    }, [idToken, set, alerts, settings])

    return (
        <Container fluid style={{ margin: 0, padding: 0 }}>
            <AlertDisplay alertState={alertState} />
            <Card style={{ borderRadius: 0, border: 0 }}>
                <Card.Header>
                    <Row>
                        <Col>
                            <Card.Title>Alarms</Card.Title>
                        </Col>
                    </Row>
                </Card.Header>
                {set ?
                    <>
                        <Card.Body>
                            {notifications.length > 0 ?
                                <>
                                    <DataDisplayV3
                                        dataSource={notifications}
                                        lsKey={'@mctms-notifications-display'}
                                        urlPath={'/alarms/'}
                                        urlKey={'_id'}
                                        popKeys={['_id']}
                                    />
                                </>
                                :
                                <>
                                    No data.
                                </>
                            }
                        </Card.Body>
                    </>
                    :
                    <Card.Body>
                        <Spinner />
                    </Card.Body>
                }

            </Card>
        </Container>
    )
}

export default Alarm;