import React, { useState, useEffect, useRef } from "react";
import { Container, Card, Row, Col, Button, Form, Alert, Spinner, Accordion, Figure, Image, } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useAuth } from "../context/auth";
import Api from "../utils/Api";
import AlertDisplay from "../components/AlertDisplay";
import AddressAutocomplete from "../components/AddressAutocomplete";

function Profile() {
    const { user, idToken } = useAuth();
    const [load, didLoad] = useState();
    const [profile, setProfile] = useState();
    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts }
    const addressAutocompleteRef = useRef(null);
    const [fullAddress, setFullAddress] = useState('');
    const fullAddressState = {fullAddress, setFullAddress}

    useEffect(()=>{
        setProfile({...profile, fullAddress: fullAddress})
    },[fullAddress])


    async function handleUpdate() {
        try {
            const result = await Api.patch(`/access/profile/${profile._id}`,
                {
                    ...profile,
                    email: user.email,
                }, idToken)
            setAlerts([...alerts, { variant: 'success', message: JSON.stringify(result) }])
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: JSON.stringify(error) }])
        }
    }



    useEffect(() => {
        try {
            const fetchData = async () => {
                const data = await Api.get(`/access/profile/uid/${user.uid}`, idToken);
                setProfile(data)
                setFullAddress(data.fullAddress)
                didLoad(true)
            }

            if (!load && user) {
                fetchData();
            }

        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'Request error' }])
        }
    }, [user, load, alerts, idToken])

    return (
        <Container fluid>
            <br />
            <AlertDisplay alertState={alertState} />
            <Card>
                <Card.Header><b>Profile</b></Card.Header>
                <Card.Body>
                    {load ?
                        <>
                            <Row>
                                {/* <Col sm={12} md={2} style={{ textAlign: 'center' }}>
                                    Profile Image
                                    <Form.Control type="file" accept="image/*" onChange={updateAvatarImg} />
                                    <Figure>
                                            <Image
                                                src={profileImg ? URL.createObjectURL(profileImg) : AVATAR_IMG}
                                                alt={'Profile Image'}
                                                style={{ height: '200px', margin: '0.5em' }}
                                                fit="contain"
                                                thumbnail
                                            />
                                    </Figure>
                                </Col> */}
                                <Col sm={12} md={10}>
                                    <Row>
                                        <Col sm={12} md={6} lg={3}>
                                            Role
                                            <Form.Control
                                                type="text"
                                                value={profile.role.name}
                                                readOnly={true}
                                                disabled
                                            />
                                        </Col>
                                        <Col sm={12} md={6} lg={3}>
                                            Email
                                            <Form.Control
                                                type="text"
                                                value={user.email}
                                                readOnly={true}
                                                disabled
                                            />
                                        </Col>
                                        <Col sm={12} md={6} lg={3}>
                                            First Name
                                            <Form.Control
                                                type="text"
                                                value={profile.firstname}
                                                onChange={e => setProfile({ ...profile, firstname: e.target.value })}
                                            />
                                        </Col>
                                        <Col sm={12} md={6} lg={3}>
                                            Last Name
                                            <Form.Control
                                                type="text"
                                                value={profile.lastname}
                                                onChange={e => setProfile({ ...profile, lastname: e.target.value })}
                                            />
                                        </Col>
                                        <Col sm={12} md={6} lg={3}>
                                            Phone
                                            <Form.Control
                                                type="phone"
                                                value={profile.phoneNumber}
                                                onChange={e => setProfile({ ...profile, phoneNumber: e.target.value })}
                                            />
                                        </Col>
                                        <Col sm={12} md={6} lg={3}>
                                            Birthday
                                            <Form.Control
                                                type="date"
                                                value={profile.birthday}
                                                onChange={e => setProfile({ ...profile, birthday: e.target.value })}
                                            />
                                        </Col>
                                        <Col>
                                            Address
                                            <AddressAutocomplete ref={addressAutocompleteRef} fullAddressState={fullAddressState} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={2} md={2} lg={2}>
                                            <br />
                                            <Button variant="primary" onClick={() => handleUpdate()}>Update</Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </>
                        :
                        <Spinner />
                    }
                </Card.Body>
            </Card>
        </Container>
    );
}

export default Profile;