import React, { useState, useEffect } from 'react'
import { Card, Col, Container, Dropdown, Row, Table } from 'react-bootstrap'
import Api from '../../utils/Api'
import { useAuth } from '../../context/auth';
import LogisticsLoading from '../../components/LogisticsLoading'
import { Link } from "react-router-dom";
import { formatDateStrToMMDDYYHHMM, formatDateStrToMMDDYYHHMMTZ } from '../../utils/DateTimeFormat.helper';
import { FaArrowLeft, FaArrowRight, FaFileExport } from 'react-icons/fa';
import AlertDisplay from '../../components/AlertDisplay';
import { generateExcel, generatePDF } from '../../utils/FileGenerator';
import { BsFiletypePdf, BsFiletypeXlsx } from 'react-icons/bs';
import { IoTodayOutline } from "react-icons/io5";


function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Add leading zero if needed
    const day = String(date.getDate()).padStart(2, '0'); // Add leading zero if needed
    return `${year}-${month}-${day}`;
}

const today = new Date();  // today based on the clock

const initialQuery = {
    start: formatDate(today),
    end: formatDate(today),
    report: 'All',
    contract: 'All',
    page: 1,
    pageSize: 10
}

function objectToQueryString(obj) {
    return Object.keys(obj)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
        .join('&');
}


function millisecondsToHHMM(milliseconds) {
    const hours = Math.floor(milliseconds / 3600000);
    milliseconds %= 3600000;
    const minutes = Math.floor(milliseconds / 60000);
    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
    return `${formattedHours}:${formattedMinutes}`;
}

const headerLeftStyle = {
    textAlign: 'left',
    padding: '0px 0.5em',
    backgroundColor: '#ccc',
}


const headerCenterStyle = {
    textAlign: 'center',
    padding: '0px 0.5em',
    backgroundColor: '#ccc',
}

const headerRightStyle = {
    textAlign: 'right',
    padding: '0px 0.5em',
    backgroundColor: '#ccc',
}

const cellCenterStyle = {
    textAlign: 'center',
    padding: '0px 0.5em'
}

const cellRightStyle = {
    textAlign: 'right',
    padding: '0px 0.5em'
}

const rowEvenStyle = {
    backgroundColor: '#fff',
    border: '1px solid #ccc'
}

const rowOddStyle = {
    backgroundColor: '#e9ecef',
    border: '1px solid #ccc'
}

function LoadTenderReports() {
    const { idToken } = useAuth();
    const [set, didSet] = useState();
    const [data, setData] = useState();
    const [printableData, setPrintableData] = useState();
    const [query, setQuery] = useState(() => {
        const savedQuery = localStorage.getItem(`@mctms-loadtender-reports-query`);
        return savedQuery !== null ? JSON.parse(savedQuery) : initialQuery;
    });

    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts }

    const [contracts, setContracts] = useState([]);

    async function fetchFilterData() {
        try {
            const ediContracts = await Api.get(`/edi204/contracts`, idToken);
            setContracts(ediContracts)
        } catch (err) {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured getting Contracts.' }])
        }
    }

    function formatDataForPrint(data) {
        return data.map((load, i) => ({
            'Shipment Id': `${load.ShipmentId}`,
            'Contract-Trip': `${load.Contract}-${load.Trip}`,
            'Coverage': `${load.coverage.flatMap(operator => operator.name).join('\n')}`,
            'Start Date': `${formatDateStrToMMDDYYHHMM(load.StartTime)}`,
            'End Date': `${formatDateStrToMMDDYYHHMM(load.EndTime)}`,
            'SOP Time': `${millisecondsToHHMM(load.SOPmilli)}`,
            'Stops': `${load.edi204[0].Stops.length}`,
            '204 Versions': `${load.edi204.length}`,
            '214 Updates': `${load.edi214.length}`,
            'Fourkite Updates': `${load.fourkiteShipmentUpdates.length}`,
            'Status': `${load.Status}`,
        }))
    }

    async function fetchReport() {
        didSet(false)
        try {
            localStorage.setItem(`@mctms-loadtender-reports-query`, JSON.stringify(query))
            const queryStr = objectToQueryString(query)
            const data = await Api.get(`/loadtenders/shipments/report?${queryStr}`, idToken)
            if(data.length > 0){
                setData(data)
                const printable = formatDataForPrint(data)
                setPrintableData(printable)
            } else {
                setData([])
            }
        } catch {
            console.log('error')
        } finally {
            didSet(true)
        }
    }

    function incrementDate(dateStr) {
        const date = new Date(dateStr);
        date.setDate(date.getDate() + 1); // Add one day
        const incrementedDate = date.toISOString().split('T')[0]; // Format back to YYYY-MM-DD
        return incrementedDate;
    }
    function decrementDate(dateStr) {
        const date = new Date(dateStr);
        date.setDate(date.getDate() - 1); // Add one day
        const incrementedDate = date.toISOString().split('T')[0]; // Format back to YYYY-MM-DD
        return incrementedDate;
    }

    function resetDays() {
        const date = new Date(today);
        const newDate = date.toISOString().split('T')[0];
        setQuery({ ...query, startDate: newDate, endDate: newDate })
    }

    function addDay() {
        const newQueryStart = incrementDate(query.startDate);
        const newQueryEnd = incrementDate(query.endDate);
        setQuery({ ...query, startDate: newQueryStart, endDate: newQueryEnd })
    }

    function subtractDay() {
        const newQueryStart = decrementDate(query.startDate);
        const newQueryEnd = decrementDate(query.endDate);
        setQuery({ ...query, startDate: newQueryStart, endDate: newQueryEnd })
    }

    useEffect(() => {
        fetchReport();
    }, [query])

    useEffect(() => {
        if (!set) {
            fetchFilterData();
        }
    }, [set])

    return (
        <Container fluid style={{ margin: '0', padding: '0' }}>
            <AlertDisplay alertState={alertState} />
            <Card style={{ margin: '0', padding: '0', borderRadius: '0', border:0 }}>
                <Card.Header>
                    <Row style={{ alignItems: 'center', }}>
                        <Col xs="auto">
                            <b>Load Tender Reports</b>
                        </Col>
                        <Col xs="auto">
                            Period
                            <input type="date" value={query.startDate} onChange={e => setQuery({ ...query, startDate: e.target.value })} />
                            <input type="date" value={query.endDate} onChange={e => setQuery({ ...query, endDate: e.target.value })} />
                            <button onClick={() => subtractDay()}><FaArrowLeft /></button>
                            <button onClick={() => resetDays()}><IoTodayOutline /></button>
                            <button onClick={() => addDay()}><FaArrowRight /></button>
                        </Col>
                        <Col xs="auto">
                            Report <select value={query.report} onChange={e => setQuery({ ...query, report: e.target.value })} >
                                <option>All</option>
                                <option>No Coverage</option>
                                <option>No EDI214 Updates</option>
                                <option>No Fourkite Updates</option>
                            </select>
                        </Col>
                        <Col xs="auto">
                            Contract
                            <select
                                value={query.contract}
                                onChange={e => setQuery({ ...query, contract: e.target.value })}
                            >
                                <option value="All">All</option>
                                {contracts.sort().map((contract, i) => (
                                    <option key={i}>{contract}</option>
                                ))}
                            </select>
                        </Col>
                        <Col xs="auto">
                            Records: {data ? data.length : 0}
                        </Col>
                        <Col>
                        </Col>
                        <Col xs="auto">
                            <Dropdown>
                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                    <FaFileExport />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => generateExcel(printableData, `MCTMS_Load_Tender_Report_Contract_${query.contract}_${query.startDate}_${query.endDate}`)}><BsFiletypeXlsx />Export to Excel</Dropdown.Item>
                                    <Dropdown.Item onClick={() => generatePDF(printableData, `MCTMS_Load_Tender_Report_Contract_${query.contract}_${query.startDate}_${query.endDate}`)}><BsFiletypePdf />Export to PDF</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body>
                    {!set ?
                        <LogisticsLoading message={'Loading report...'} />
                        :
                        data.length > 0 ?
                            <pre>
                                <Table responsive striped>
                                    <thead>
                                        <tr style={{ fontWeight: 'bold' }}>
                                            <td style={cellCenterStyle}></td>
                                            <td style={cellCenterStyle}>Shipments</td>
                                            <td style={cellCenterStyle}></td>
                                            <td style={cellCenterStyle}>Coverage</td>
                                            <td style={cellCenterStyle}>Vehicles</td>
                                            <td style={cellCenterStyle}>Min Start</td>
                                            <td style={cellCenterStyle}>Max End</td>
                                            <td style={cellRightStyle}>Total SOP Hours</td>
                                            <td style={cellCenterStyle}>Total Stops</td>
                                            <td style={cellCenterStyle}>Avg. 204 Ct.</td>
                                            <td style={cellCenterStyle}>EDI Tracking</td>
                                            <td style={cellCenterStyle}>Fourkites Tracking</td>
                                            <td style={cellCenterStyle}></td>
                                        </tr>
                                        <tr style={{ fontWeight: 'bold' }}>
                                            <td style={cellCenterStyle}></td>
                                            <td style={cellCenterStyle}>{data.length}</td>
                                            <td style={cellCenterStyle}></td>
                                            <td style={cellCenterStyle}>{((data.reduce((a, b) => a + (b.coverage.length > 0 ? 1 : 0), 0) / data.length) * 100).toFixed(0)}%</td>
                                            <td style={cellCenterStyle}></td>
                                            <td style={cellCenterStyle}>{formatDateStrToMMDDYYHHMM(data[0].StartTime)}</td>
                                            <td style={cellCenterStyle}>{formatDateStrToMMDDYYHHMM(data[data.length - 1].StartTime)}</td>
                                            <td style={cellRightStyle}>{millisecondsToHHMM(data.reduce((a, b) => a + b.SOPmilli, 0))}</td>
                                            <td style={cellCenterStyle}>{((data.reduce((a, b) => a + Number(b.edi204[0].Stops.length), 0)))}</td>
                                            <td style={cellCenterStyle}>{((data.reduce((a, b) => a + (b.edi204.length), 0) / data.length)).toFixed(1)}</td>
                                            <td style={cellCenterStyle}>{((data.reduce((a, b) => a + (b.edi214.length > 0 ? 1 : 0), 0) / data.length) * 100).toFixed(0)}%</td>
                                            <td style={cellCenterStyle}>{((data.reduce((a, b) => a + (b.fourkiteShipmentUpdates.length > 0 ? 1 : 0), 0) / data.length) * 100).toFixed(0)}%</td>
                                            <td style={cellCenterStyle}></td>
                                        </tr>
                                    </thead>
                                    <thead style={{ fontWeight: 'bold' }}>
                                        <tr>
                                            <th style={headerCenterStyle}>#</th>
                                            <th style={headerCenterStyle}>Shipment Id</th>
                                            <th style={headerCenterStyle}>Contract-Trip</th>
                                            <th style={headerCenterStyle}>Coverage</th>
                                            <th style={headerCenterStyle}>Vehicles</th>
                                            <th style={headerCenterStyle}>Start Date</th>
                                            <th style={headerCenterStyle}>End Date</th>
                                            <th style={headerRightStyle}>SOP Time</th>
                                            <th style={headerCenterStyle}>Stops</th>
                                            <th style={headerCenterStyle}>204 Versions</th>
                                            <th style={headerCenterStyle}>214 Updates</th>
                                            <th style={headerCenterStyle}>Fourkite Updates</th>
                                            <th style={headerCenterStyle}>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map((load, i) => (
                                            <tr key={i} style={i % 2 === 0 ? rowEvenStyle : rowOddStyle}>
                                                <td>{i + 1}</td>
                                                <td style={cellCenterStyle}>
                                                    <Link to={`/loadtender/${load.ShipmentId}`} target='_blank'>
                                                        {load.ShipmentId}
                                                    </Link>
                                                </td>
                                                <td style={cellCenterStyle}>{load.Contract}-{load.Trip}</td>
                                                <td style={cellCenterStyle}>
                                                    <pre>
                                                        {load.coverage.flatMap(operator => operator.name).join('\n')}
                                                    </pre>
                                                </td>
                                                <td style={cellCenterStyle}></td>
                                                <td style={cellCenterStyle}>{formatDateStrToMMDDYYHHMM(load.StartTime)}</td>
                                                <td style={cellCenterStyle}>{formatDateStrToMMDDYYHHMM(load.EndTime)}</td>
                                                <td style={cellRightStyle}>{millisecondsToHHMM(load.SOPmilli)}</td>
                                                <td style={cellCenterStyle}>{load.edi204[0].Stops.length}</td>
                                                <td style={cellCenterStyle}>{load.edi204.length}</td>
                                                <td style={cellCenterStyle}>{load.edi214.length}</td>
                                                <td style={cellCenterStyle}>{load.fourkiteShipmentUpdates.length}</td>
                                                <td style={cellCenterStyle}>{load.Status}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </pre>

                            :
                            <h3>No data</h3>
                    }

                </Card.Body>
            </Card>
        </Container>
    )
}

export default LoadTenderReports;