import { useEffect, useState } from "react";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import { FaPlusCircle } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import DataDisplayV3 from "../../components/DataDisplayV3";
import LogisticsLoading from "../../components/LogisticsLoading";
import Api from "../../utils/Api";
import { useAuth } from "../../context/auth";
import AlertDisplay from "../../components/AlertDisplay";

function LoadTenderForwardingRule({ feature }) {
    const { idToken } = useAuth();
    const { id } = useParams();
    const [forwardingRule, setForwardingRule] = useState({});
    const [didFetch, setDidFetch] = useState();
    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts };

    const [shippers, setShippers] = useState();
    const [companies, setCompanies] = useState();
    const [tradingPartners, setTradingPartners] = useState();


    async function fetchForwardingRule() {
        try {
            const data = await Api.get(`/loadtender/forwarding/${id}`, idToken);
            const cleanData = data.map(company => ({
                _id: company._id,
                Name: company.name,
            }));
            setForwardingRule(cleanData)
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured fetching data.' }])
        } finally {
            setDidFetch(true)
        }
    }

    useEffect(() => {
        if (!didFetch && id) {
            fetchForwardingRule();
        }
    }, [didFetch])

    return (
        <Container fluid style={{ margin: 0, padding: 0 }}>
            <AlertDisplay alertState={alertState} />
            <Card style={{ border: 0, borderRadius: 0 }}>
                <Card.Header>
                    <Card.Title>
                        <Row>
                            <Col>
                                Load Tender Forwarding Rule
                            </Col>
                        </Row>
                    </Card.Title>
                </Card.Header>
                <Card.Body>
                    <Row>
                        <Col xs="auto">
                            Enable Forwarding
                            <Form.Check value={forwardingRule.forward} />
                        </Col>
                        <Col>
                            Rule Name:
                            <Form.Control
                                type="text"
                                value={forwardingRule.rulename
                                    || `When "customerISA" sends "companySCAC" a new load tender matching "identifier", forward it to "trading partner SCAC"`}
                                readOnly
                                disabled
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            From Shipper
                            <Form.Control as="select" value={forwardingRule.customer} />
                        </Col>
                        <Col>
                            To Company
                            <Form.Control as="select" value={forwardingRule.SCAC} />
                        </Col>
                        <Col>
                            Forward To
                            <Form.Control as="select" value={forwardingRule.tradingPartnerSCAC} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            Forwarding Identifier
                            <Form.Control type="text" value={forwardingRule.identifier} />
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </Container>
    )
}


export default LoadTenderForwardingRule;