import React, { useEffect, useState } from 'react'
import { Container, Card, Row, Col, Table, Button,} from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useRole } from "../context/role";
import { Link } from 'react-router-dom';
import { FaCalendar, FaCarCrash,  FaCheck, FaTruck, FaUserInjured } from 'react-icons/fa';
import { GrScorecard } from 'react-icons/gr';
import { CgProfile } from 'react-icons/cg';


function DriverPortalPage() {

    const { userRole, checkNavPermissions } = useRole();
    const [toggle, setToggle] = useState(false);
    useEffect(() => {
        const savedToggle = localStorage.getItem('@tms-featurepage-toggle');
        if (savedToggle !== null) {
            setToggle(JSON.parse(savedToggle));
        }
    }, []);
    useEffect(() => {
        localStorage.setItem('@tms-featurepage-toggle', JSON.stringify(toggle));
    }, [toggle]);

    const features = [
        { href: "/driver/score-card", icon: <GrScorecard />, text: "Score Card", detail:'Load tender performance'},
        { href: "/driver/schedule", icon: <FaCalendar />, text: "Schedule", detail:'Load tender schedule' },
        { href: "/driver/accident-form", icon: <FaCarCrash />, text: "Accident Form", detail:'Report an accident to safety.' },
        { href: "/driver/injury-form", icon: <FaUserInjured />, text: "Injury Form" , detail:'Report an injury to safety.'},
        { href: "/driver/timecards", icon: <FaCheck />, text: "Timecards" , detail:'My timecards'},
    ]
    const allowedFeatures = checkNavPermissions(userRole, features)

    const cardStyle = {
        textAlign:'center',
        width: '250px',
        height: '250px'
    }

    const colStyle = {
        margin: '0.25em',
    }

    return (
        <Container fluid style={{ margin: 0, padding: 0 }}>
            <Card style={{ border: 0, borderRadius: 0 }}>
                <Card.Header>
                    <Row>
                        <Col>
                            <Card.Title>Driver Portal</Card.Title>
                        </Col>
                        <Col xs="auto">
                            <Button variant='primary' onClick={() => setToggle(!toggle)}>Toggle {!toggle ? 'Card' : 'Table'}</Button>
                        </Col>
                    </Row>
                </Card.Header>
                {toggle ?
                    <Card.Body>
                        <Row>
                            {allowedFeatures.map((feature, i) => (
                                <Col xs="auto" style={colStyle}>
                                    <Card style={cardStyle}>
                                        <Card.Header>
                                            <b>{feature.text}</b>
                                        </Card.Header>
                                        <Card.Body key={i}>
                                            <Row>
                                                <Col style={{ marginBottom: '1em' }}>
                                                    <h1>{feature.icon}</h1>
                                                </Col>
                                            </Row>
                                            <Card.Text>
                                                {feature.detail}
                                            </Card.Text>
                                            <Link to={feature.href}>Go to {feature.text}</Link>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    </Card.Body>
                    :
                    <Card.Body>
                        <Table responsive>
                            <tbody>
                                {allowedFeatures.map((feature, i) => (
                                    <tr>
                                        <td>
                                            <h3>{feature.icon}</h3>
                                        </td>
                                        <td> <Link to={feature.href}>{feature.text}</Link></td>
                                        <td>{feature.text}</td>
                                        <td>{feature.detail}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Card.Body>
                }
            </Card>
        </Container>
    )
}

export default DriverPortalPage;

