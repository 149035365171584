import { useEffect, useState } from "react";
import AlertDisplay from "../../components/AlertDisplay";
import Api from "../../utils/Api";
import { useAuth } from "../../context/auth";
import { Accordion, Badge, Button, Card, Col, Container, Form, Row, Spinner, Table } from "react-bootstrap";
import DataDisplayV3 from "../../components/DataDisplayV3";
import { Link } from "react-router-dom";

const today = new Date();

function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Add leading zero if needed
    const day = String(date.getDate()).padStart(2, '0'); // Add leading zero if needed
    return `${year}-${month}-${day}`;
}

const initialQuery = {
    contract: 'All',
    startdate: formatDate(today),
    enddate: formatDate(today),
};


function LoadTenderSchedules({ feature }) {
    const { idToken } = useAuth();
    const [set, didSet] = useState();
    const [didLoadData, setDidLoadData] = useState();
    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts }
    const [schedules, setSchedules] = useState([]);

    const [contracts, setContracts] = useState([]);

    const [query, setQuery] = useState(() => {
        const savedQuery = localStorage.getItem(`@mctms-edischedules-query`);
        return savedQuery !== null ? JSON.parse(savedQuery) : initialQuery;
    });

    useEffect(() => {
        localStorage.setItem(`@mctms-edischedules-query`, JSON.stringify(query))
        const timer = setTimeout(() => {
            didSet(false);
        }, 1500);
        return () => clearTimeout(timer);
    }, [query]);

    function collapseUSPSContractTripData(data) {
        try {
            const items = data.split(', ').map(item => {
                const [prefix, number] = item.split(' ');
                return { prefix, number };
            });

            const result = [];
            let currentPrefix = items[0].prefix;
            let currentNumbers = [];

            items.forEach(({ prefix, number }, index) => {
                if (prefix !== currentPrefix) {
                    if (currentNumbers.length) {
                        result.push(`${currentPrefix} (${currentNumbers.join(' -> ')})`);
                    }
                    currentPrefix = prefix;
                    currentNumbers = [number];
                } else {
                    currentNumbers.push(number);
                }

                if (index === items.length - 1) {
                    result.push(`${currentPrefix} (${currentNumbers.join(' -> ')})`);
                }
            });

            return result.join('\n');
        } catch (error) {
            return '';
        }
    }

    async function fetchEdiSchedules() {
        try {
            const queryStr = new URLSearchParams(query).toString();
            const data = await Api.get(`/loadtenders/schedules?${queryStr}`, idToken);
            const cleanData = data.map((sched) => {
                return {
                    '_id': sched._id,
                    'Name': sched.name,
                    'Type': sched.type,
                    'Effective Date': sched.effectiveDate,
                    'Route-Schedule': sched.routes.map((rt, w) => {
                        return Object.keys(rt).map(dow => {
                            const routeInfo = rt[dow].map(trip => `${trip.contract} ${trip.trip}`).join(', ');
                            const scheduleInfo = sched.schedules[w][dow].map(driver => `${driver.name}`).join(', ');
                            return `WK${w + 1} | ${dow.toUpperCase().slice(0, 3)} - Trips: ${routeInfo} | Coverage: ${scheduleInfo}`;
                        }).join('\n');
                    }).join('\n\n'),
                }
            })
            setSchedules(cleanData)
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'Failed to fetch Load Schedules' }])
        } finally {
            didSet(true)
        }
    }

    async function fetchData() {
        try {
            const ediContracts = await Api.get(`/edi204/contracts`, idToken);
            setContracts(ediContracts)
        } catch (err) {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured getting Contracts.' }])
        } finally {
            setDidLoadData(true)
        }
    }

    useEffect(() => {
        if (!didLoadData) {
            fetchData()
        }
        if (!set) {
            fetchEdiSchedules()
        }
    }, [set, didLoadData])




    const buttonStyle = {
        margin: '0.1em',
        border: '1px solid #5182bd',
        backgroundColor: '#deeaf6'
    }

    return (
        <Container fluid style={{ margin: 0, padding: 0 }}>
            <AlertDisplay alertState={alertState} />

            <Card style={{ margin: 0, borderRadius: 0, border: 0 }}>
                <Card.Header>
                    <Row style={{ alignItems: 'center' }}>
                        <Col>
                            <Card.Title>
                                Schedules
                            </Card.Title>
                        </Col>
                        <Col xs="auto">
                            Contract
                            <select
                                style={buttonStyle}
                                value={query.contract}
                                onChange={e => setQuery({ ...query, contract: e.target.value })}
                            >
                                <option value="All">All</option>
                                {contracts.sort().map((contract, i) => (
                                    <option key={i}>{contract}</option>
                                ))}
                            </select>
                        </Col>
                        <Col xs="auto">
                            <Link to={'/loadtender/quickcover'}>Unscheduled</Link>
                        </Col>
                        <Col xs="auto">
                            <Link to={'/loadtender/schedules'}>Schedules</Link>
                        </Col>
                        <Col xs="auto">
                            <Link to={'/loadtender/scheduler'}>New Schedule</Link>
                        </Col>
                    </Row>
                </Card.Header>
                <Accordion style={{ borderRadius: 0 }}>
                    <Accordion.Item eventKey="0" style={{ borderRadius: 0 }}>
                        <Accordion.Header><b>How are schedules used?</b></Accordion.Header>
                        <Accordion.Body>
                            Schedules are used to manually cover shipments, and are also used to automatically schedule coverage for routine service.
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>

                <Card.Body>
                    {!set ? <Spinner /> :
                        <>
                            <DataDisplayV3
                                lsKey={`@mctms-schedules-data-display`}
                                sourceName={`TMS-Schedules`}
                                dataSource={schedules}
                                urlKey="_id"
                                urlPath={`/${feature}/schedule/`}
                                popKeys={['_id']}
                            />
                        </>
                    }
                </Card.Body>
            </Card>
        </Container>
    )
}


export default LoadTenderSchedules;