import { Container, Card, Row, Col, Form, Spinner, Alert, Button, Table, Badge } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect } from 'react'
import Api from "../../utils/Api";
import { useAuth } from '../../context/auth';
import { TIMEZONE_OPTIONS } from "../../context/appdata";
import { FORM_REPORTS } from "../../context/appreports";
import { useParams } from 'react-router-dom';
import AlertDisplay from "../../components/AlertDisplay";
import { FaTrash } from "react-icons/fa";


function JobUpdate() {
    const { id } = useParams();
    const { idToken } = useAuth();
    const [set, didSet] = useState();
    const [emails, setEmails] = useState([]);
    const [report, setReport] = useState({});
    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts }

    const runJob = async () => {
        try {
            await Api.get(`/jobs/agenda/${id}/run`, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Job ran successfully' }])
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: JSON.stringify(error) }])
        }
    }

    useEffect(() => {
        const handleFetch = async () => {
            try {
                const rpt = await Api.get(`/jobs/agenda/${id}`, idToken)
                const distro = rpt?.data?.emails ? [...rpt?.data?.emails] : [`${rpt?.data?.email}`];
                setEmails(distro)
                setReport(rpt)
            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: 'An error occured requesting data.' }])
            }
            didSet(true)
        }

        if (!set) {
            handleFetch();
        }
    }, [set, idToken, id, alerts, emails, report])

    return (
        <Container fluid>
            <br />
            <Card>
                <Card.Header>
                    <b>Job Detail</b>
                </Card.Header>
                {alerts.length > 0 &&
                    <AlertDisplay alertState={alertState} />
                }
                {set ?
                    < Card.Body >
                        <Row>
                            <Col sm={12} md={6} lg={4} xl={3}>
                                <label for="type">Id:</label>
                                {report?._id}
                            </Col>
                            <Col sm={12} md={6} lg={4} xl={3}>
                                <label for="type">Name:</label>
                                {report?.name}
                            </Col>
                            <Col sm={12} md={6} lg={4} xl={3}>
                                <label for="type">Type:</label>
                                {report?.type}
                            </Col>
                            <Col sm={12} md={6} lg={4} xl={3}>
                                <label for="report">Report:</label>
                                {report?.data?.email}
                            </Col>
                            <Col sm={12} md={6} lg={4} xl={3}>
                                <label for="report">Report:</label>
                                {report?.data?.report}
                            </Col>
                            <Col sm={12} md={6} lg={4} xl={3}>
                                <label for="priority">Priority:</label>
                                {report?.priority}
                            </Col>
                            <Col sm={12} md={6} lg={4} xl={3}>
                                <label for="nextRunAt">Next Run At:</label>
                                {report?.nextRunAt}
                            </Col>
                            <Col sm={12} md={6} lg={4} xl={3}>
                                <label for="repeatInterval">Repeat Interval (Cron format):</label>
                                {report?.repeatInterval}
                            </Col>
                            <Col sm={12} md={6} lg={4} xl={3}>
                                <label for="shouldSaveResult">Should Save Result:</label>
                                {report?.shouldSaveResult}
                            </Col>
                            <Col sm={12} md={6} lg={4} xl={3}>
                                <label for="repeatInterval">Skip Days: </label>
                                {report?.skipDays}
                            </Col>
                            <Col sm={12} md={6} lg={4} xl={3}>
                                <label for="shouldSaveResult">Start Date: </label>
                                {report?.startDate}
                            </Col>
                        </Row>
                        <div style={{marginTop:'1em'}}>
                            <Button variant="secondary" onClick={() => runJob()} style={{marginRigth:'0.5em'}}>Run</Button>
                        </div>

                    </Card.Body>
                    :
                    <Card.Body>
                        <Spinner />
                    </Card.Body>
                }
            </Card>
        </Container >
    );
}

export default JobUpdate;