import { Container, Card, Row, Col, Spinner, Button, Modal, ModalBody, ModalHeader, ModalFooter, Accordion, Table, } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import Api from '../../utils/Api';
import React, { useState, useEffect } from 'react'
import { useAuth } from '../../context/auth';
import { useSettings } from '../../context/settings'
import { FaArrowLeft, FaArrowRight, FaChartPie, FaEye, FaInfo, FaSearch, FaSync, FaTable } from "react-icons/fa";
import { MdCircle, MdHideSource } from "react-icons/md";
import { findUSPSNASSCode, findUSPSNASSCodeState, formatUSPSDateTime } from "../../context/uspsdata";
import { adjustStringLength } from "../../utils/String.helper";
import AlertDisplay from "../../components/AlertDisplay";
import { formatDateFromDateStr, formatDateStrToDayOfWeek, formatDateStrToMMDDYY, formatDateStrToMMDDYYHHMM, getDayOfWeekFromDate } from "../../utils/DateTimeFormat.helper";
import {
    EDI_214_SHIPMENT_STATUS_CODES_MAIL_PICKUP_SPECIFIC,
    EDI_214_SHIPMENT_STATUS_CODES_MAIL_DELIVERY_SPECIFIC
} from "../../context/klienschmidtdata";
import LOAD_TENDER_REPORTS from './LoadTender.reports.json'
import { multidimensionalGroupBy } from "../../utils/Array.helper";
import PrintPDF from "../../components/PrintPDF";
import { Chart } from 'react-google-charts';
import LogisticsLoading from "../../components/LogisticsLoading";
import { Link } from "react-router-dom";
import DataDisplayV3 from "../../components/DataDisplayV3";
import { IoTodayOutline } from "react-icons/io5";
import ShipmentGanttChartV3 from "../../components/ShipmentGanttChartV3";


const LOAD_TENDER_GROUPS = multidimensionalGroupBy(LOAD_TENDER_REPORTS, ['group']);

//todo dedeup
const today = new Date();  // today based on the clock
const tomorrow = new Date(today); // based on today value
const yesterday = new Date(today); // based on today value
const minusSeven = new Date(today); // minus 7 days
const minusFourteen = new Date(today); // minus 14 days
const minusThirty = new Date(today); // minus 30 days
const minusSixty = new Date(today); // minus 30 days
const minusNinty = new Date(today); // minus 30 days
const plusSeven = new Date(today); // based on today value, plus 7 days
const plusFourteen = new Date(today); // based on today value, plus 14 days
const plusThirty = new Date(today); // based on today value, plus 30 days
const plusSixty = new Date(today); // based on today value, plus 30 days
const plusNinty = new Date(today); // based on today value, plus 30 days
const lastSunday = new Date(today); // based on today value, the last week's Sunday
const lastSaturday = new Date(today);// based on today value, the last week's Saturday
const thisSunday = new Date(today); // based on today value, the current week's Sunday
const thisSaturday = new Date(today);// based on today value, the current week's Saturday
const nextSunday = new Date(today);// based on today value, the next week's Sunday
const nextSaturday = new Date(today);// based on today value, the next week's Saturday
const newyears = new Date(today); // current calendar's first day of the year
const newyearseve = new Date(today); // current calendar's last day of the year
const currentYear = today.getFullYear();
const currentMonth = today.getMonth();
const nextMonth = new Date(today);
nextMonth.setMonth(currentMonth + 1);
const firstdayofthemonth = new Date(currentYear, currentMonth, 1);
const lastdayofthemonth = new Date(currentYear, currentMonth + 1, 0);
const firstdayoflastmonth = new Date(currentYear, currentMonth - 1, 1);
const lastdayoflastmonth = new Date(currentYear, currentMonth, 0);
const firstdayofnextmonth = new Date(currentYear, currentMonth + 1, 1);
const lastDayOfNextMonth = new Date(nextMonth);
lastDayOfNextMonth.setMonth(nextMonth.getMonth() + 1, 0);

tomorrow.setDate(today.getDate() + 1);
yesterday.setDate(today.getDate() - 1);
minusSeven.setDate(today.getDate() - 7);
minusFourteen.setDate(today.getDate() - 14);
minusThirty.setDate(today.getDate() - 30);
minusSixty.setDate(today.getDate() - 60);
minusNinty.setDate(today.getDate() - 90);
plusSeven.setDate(today.getDate() + 7);
plusFourteen.setDate(today.getDate() + 14);
plusThirty.setDate(today.getDate() + 30);
plusSixty.setDate(today.getDate() + 60);
plusNinty.setDate(today.getDate() + 90);
const year = new Date().getFullYear(); // Current year
const fiscalQuarters = {
    q1: { start: new Date(year, 0, 1), end: new Date(year, 2, 31) },
    q2: { start: new Date(year, 3, 1), end: new Date(year, 5, 30) },
    q3: { start: new Date(year, 6, 1), end: new Date(year, 8, 30) },
    q4: { start: new Date(year, 9, 1), end: new Date(year, 11, 31) }
};


// Calculate the current day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
const currentDayOfWeek = today.getDay();

// Calculate the date for the last week's Sunday and Saturday
lastSunday.setDate(today.getDate() - (currentDayOfWeek + 7));
lastSaturday.setDate(today.getDate() - (currentDayOfWeek + 1));

// Calculate the date for the current week's Sunday and Saturday
thisSunday.setDate(today.getDate() - currentDayOfWeek);
thisSaturday.setDate(today.getDate() + (6 - currentDayOfWeek));

// Calculate the date for the next week's Sunday and Saturday
nextSunday.setDate(today.getDate() + (7 - currentDayOfWeek));
nextSaturday.setDate(today.getDate() + (13 - currentDayOfWeek));

// Calculate the first day and last day of the current year
newyears.setMonth(0); // January (0 index)
newyears.setDate(1);

newyearseve.setMonth(11); // December (0 index)
newyearseve.setDate(31);

function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Add leading zero if needed
    const day = String(date.getDate()).padStart(2, '0'); // Add leading zero if needed
    return `${year}-${month}-${day}`;
}

const initialQuery = {
    start: formatDate(today),
    end: formatDate(today),
    report: 'All',
    loadtenderLifeCycleStatus: 'All',
    contract: 'All',
    state: 'All',
    city: 'All',
    driver: 'All',
    page: 1,
    pageSize: 10
}

function objectToQueryString(obj) {
    return Object.keys(obj)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
        .join('&');
}

function extractUniqueValues(arr) {
    const uniqueValues = new Set();

    arr.forEach(subArray => {
        subArray.forEach(value => {
            uniqueValues.add(value);
        });
    });

    return Array.from(uniqueValues);
}

function extractPrefixes(arr) {
    return arr.map(item => {
        const parts = item.split("_"); // Split the string into parts using the underscore character
        if (parts.length >= 2) {
            return parts.slice(0, 2).join("_"); // Combine the first two parts with an underscore
        } else {
            return item; // If there are fewer than 2 parts, return the original item
        }
    });
}

function millisecondsToHHMM(milliseconds) {
    const hours = Math.floor(milliseconds / 3600000);
    milliseconds %= 3600000;
    const minutes = Math.floor(milliseconds / 60000);
    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
    return `${formattedHours}:${formattedMinutes}`;
}

function googleChartDataKeyCount(shipments = [], key = [], headers = []) {
    const Groups = multidimensionalGroupBy(shipments, key);
    const GraphData = Object.keys(Groups).sort().map(key => ([`${key} (${Groups[key].length})`, Groups[key].length]));
    const ChartData = [headers, ...GraphData];
    return ChartData
}

function LoadTenderDashboardFreight() {
    const { idToken } = useAuth();
    const { settings } = useSettings();

    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts }
    const [view, setView] = useState(localStorage.getItem('view') || 'Table');



    const [set, didSet] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [loadtenders, setLoadTenders] = useState([])
    const [loadtenderTimelineData, setLoadTenderTimelineData] = useState();
    const [loadtenderCoverageChartData, setLoadtenderCoverageChartData] = useState();
    const [contractChartData, setContractChartData] = useState();
    const [equipmentChartData, setEquipmentChartData] = useState();
    const [stateChartData, setStateChartData] = useState();
    const [dateChartData, setDateChartData] = useState();
    const [dowChartData, setDowChartData] = useState();

    const [search, setSearch] = useState();
    const [query, setQuery] = useState(() => {
        const savedQuery = localStorage.getItem(`@mctms-loadtender-dashboard-query`);
        return savedQuery !== null ? JSON.parse(savedQuery) : initialQuery;
    });



    const [contracts, setContracts] = useState([]);
    const [places, setPlaces] = useState([]);
    const [drivers, setDrivers] = useState([]);

    function incrementDate(dateStr) {
        const date = new Date(dateStr);
        date.setDate(date.getDate() + 1); // Add one day
        const incrementedDate = date.toISOString().split('T')[0]; // Format back to YYYY-MM-DD
        return incrementedDate;
    }
    function decrementDate(dateStr) {
        const date = new Date(dateStr);
        date.setDate(date.getDate() - 1); // Add one day
        const incrementedDate = date.toISOString().split('T')[0]; // Format back to YYYY-MM-DD
        return incrementedDate;
    }

    function resetDays() {
        const newQueryStart = formatDate(today);
        const newQueryEnd = formatDate(today);
        setQuery({ ...query, start: newQueryStart, end: newQueryEnd })
    }

    function addDay() {
        const newQueryStart = incrementDate(query.start);
        const newQueryEnd = incrementDate(query.end);
        setQuery({ ...query, start: newQueryStart, end: newQueryEnd })
    }

    function subtractDay() {
        const newQueryStart = decrementDate(query.start);
        const newQueryEnd = decrementDate(query.end);
        setQuery({ ...query, start: newQueryStart, end: newQueryEnd })
    }

    function setQueryPlace(value) {
        if (value === 'All') {
            setQuery({ ...query, state: 'All', city: 'All' })
        } else {
            setQuery({ ...query, state: value.state, city: value.city })
        }
    }

    async function searchShipment() {
        if(search.trim() !== ''){
            const targetUrl = '/loadtender/' + search;
            window.open(targetUrl, '_blank');
        } else {
            setAlerts([...alerts, {variant:'warning', message:'Search cannot be empty.'}])
        }

    }

    async function fetchFilterData() {
        try {
            const ediContracts = await Api.get(`/edi204/contracts`, idToken);
            setContracts(ediContracts)
        } catch (err) {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured getting Contracts.' }])
        }
        try {
            const ediPlaces = await Api.get(`/edi204/places`, idToken);
            setPlaces(ediPlaces)
        } catch (err) {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured getting Places.' }])
        }
        try {
            const ediDrivers = await Api.get(`/edi204/operators`, idToken);
            setDrivers(ediDrivers)
        } catch (err) {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured getting Drivers.' }])
        }
    }

    function cleanLoadData(data) {

        const schedule = adjustStringLength('S#', '2', '\u0020', false)
            + ' | ' + adjustStringLength('LOCATION', '25', '\u0020', true)
            + ' | ' + adjustStringLength('ARRIVE', '18', '\u0020', true)
            + ' | ' + adjustStringLength('DEPART', '18', '\u0020', true)
            + ' | ' + adjustStringLength('REASON', '18', '\u0020', true);


        const cleanedData = data.map((load, i) => ({
            '#': (i + 1),
            'ShipmentId': load.ShipmentId,
            'Status': `${load.Status}`,
            'Contract': load.Contract,
            'Trip': load.Trip,
            'Contract-Trip': `${load.Contract}-${load.Trip}`,
            'Start Time': `${formatDateStrToMMDDYYHHMM(load.StartTime)}`,
            'End Time': `${formatDateStrToMMDDYYHHMM(load.EndTime)}`,
            'SOP Time': `${millisecondsToHHMM(load.SOPmilli)}`,
            'Coverage': `${load?.Coverage.flatMap((innerArray) => innerArray.map((obj) => obj.name)).join('\n')}`,
            'Schedule': `${schedule + '\n' + load?.Stops[0].map(via => `${adjustStringLength(via?.StopNum, '2', '\u0020', false)
                } | ${adjustStringLength((findUSPSNASSCode(via?.Name) + ', ' + findUSPSNASSCodeState(via?.Name)), '25', '\u0020', true)
                } | ${adjustStringLength(formatUSPSDateTime(via?.Name, via?.RequestedDate, settings.timezone), '18', '\u0020', true)
                } | ${adjustStringLength(formatUSPSDateTime(via?.Name, via?.LastRequestedDate, settings.timezone), '18', '\u0020', true)
                } | ${adjustStringLength(via?.ReasonDesc, '18', ' ', true)
                }`).join('\n')
                }`,
            'Equipment': load.Equipment,
            'State': load.State,
            'StopCt': load.StopCt,
            'Date':`${formatDateFromDateStr(load.StartTime)}`,
            'Day of Week':`${getDayOfWeekFromDate(load.StartTime)}`,
        }));

        return cleanedData;
    }


    function loadtenderContractTripTimeline(shipments) {
        const data = [];
        for (let i = 0; i < shipments.length; i++) {
            const shipment = shipments[i];
            data.push({
                contract: shipment['Contract'],
                trip: shipment['Trip'],
                startdate: (shipment['Start Time']),
                enddate: (shipment['End Time']),
                ShipmentId: shipment['ShipmentId'],
                Coverage: shipment['Coverage'] !== ''
            });
        }
        return data;
    }


    function loadTenderCoverageChart(shipments) {
        let unassigned = 0, covered = 0;

        for (const record of shipments) {
            if (record['Coverage'].trim() !== '') {
                covered++;
            } else {
                unassigned++;
            }
        }

        const chartData = [['Coverage', 'Load Tender Ct.'], [`Open (${unassigned})`, unassigned], [`Covered (${covered})`, covered]];
        return chartData;
    }

    async function fetchLoadTenders() {
        try {
            const queryStr = objectToQueryString(query)
            const loads = await Api.get(`/loadtenders?${queryStr}`, idToken)
            setTotalRecords(loads.totalRecords)
            setTotalPages(loads.totalPages)
            const cleanData = cleanLoadData(loads.data)
            setLoadTenders(cleanData)
            const contractTripTimeline = loadtenderContractTripTimeline(cleanData)
            setLoadTenderTimelineData(contractTripTimeline)
            const loadtenderCoverageChart = loadTenderCoverageChart(cleanData);
            setLoadtenderCoverageChartData(loadtenderCoverageChart)
            const contractChart = googleChartDataKeyCount(cleanData, ['Contract'], ['Contract', 'Contract Ct.'])
            setContractChartData(contractChart)
            const equipmentChart = googleChartDataKeyCount(cleanData, ['Equipment'], ['Equipment', 'Equipment Ct.'])
            setEquipmentChartData(equipmentChart)
            const stateChart = googleChartDataKeyCount(cleanData, ['State'], ['State', 'State Ct.'])
            setStateChartData(stateChart)
            const dateChart = googleChartDataKeyCount(cleanData, ['Date'], ['Date', 'Date Ct.'])
            setDateChartData(dateChart)
            const dowChart = googleChartDataKeyCount(cleanData, ['Day of Week'], ['Day of Week', 'Day of Week Ct.']);
            const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
            
            const sortedDowChart = dowChart.slice(1).sort((a, b) => {
                const dayA = a[0].split(' ')[0]; 
                const dayB = b[0].split(' ')[0]; 
                return daysOfWeek.indexOf(dayA) - daysOfWeek.indexOf(dayB);
            });
            
            setDowChartData([['Day of Week', 'Day of Week Ct.'], ...sortedDowChart]);
        } catch {
            setAlerts([...alerts, {variant:'warning', message:'An error occured fetching shipments.'}])
        } finally {
            didSet(true)
        }
    }

    useEffect(() => {
        didSet(false)
        fetchLoadTenders()
        localStorage.setItem(`@mctms-loadtender-dashboard-query`, JSON.stringify(query))
    }, [query])

    useEffect(() => {
        if (!set) {
            fetchLoadTenders();
            fetchFilterData();
        }
    }, [set])

    useEffect(() => {
        setQuery({ ...query, page: 1 })
    }, [query.pageSize])

    useEffect(() => {
        localStorage.setItem('view', view);
    }, [view]);


    const containerStyle = {
        margin: '0px',
        padding: '0px',
    }

    const cardStyle = {
        margin: '0px',
        border: '0px',
        borderRadius: '0px'
    }

    const selectStyle = {
        fontSize: '1em',
        border: '1px solid #2b5499',
        color: '#2b5499',
        margin: '0.2em'
    }

    const selectActiveStyle = {
        fontSize: '1em',
        border: '1px solid red',
        color: '#2b5499',
        margin: '0.2em'
    }

    const buttonStyle = {
        fontSize: '1em',
        border: '1px solid #2b5499',
        color: '#2b5499',
        margin: '0.2em'
    }

    const buttonActiveStyle = {
        fontSize: '1em',
        border: '1px solid #2b5499',
        background: '#2055a7',
        color: 'white',
        margin: '0.2em'
    }

    const tableStyle = {
        borderCollapse: 'collapse',
        border: '1px solid #111'
    }

    const thStyle = {
        textAlign: 'left',
        verticalAlign: 'top',
        border: '1px solid #aaa',
        padding: '0 .2em'
    }
    const tdOddStyle = {
        textAlign: 'left',
        verticalAlign: 'top',
        border: '1px solid #ccc',
        backgroundColor: '#f9f9f9',
        padding: '0 .2em'

    }
    const tdStyle = {
        textAlign: 'left',
        verticalAlign: 'top',
        border: '1px solid #ccc',
        padding: '0 .2em'
    }


    return (
        <Container fluid style={containerStyle}>
            <AlertDisplay alertState={alertState} />
            <Card style={cardStyle}>
                <Card.Header>
                    <Row style={{ alignItems: 'center', }}>
                        <Col>
                            <b>Load Tender Dashboard</b>
                        </Col>
                        <Col xs="auto">
                            Records {totalRecords}
                        </Col>
                        <Col xs="auto">
                            Page {query.page} / {totalPages}
                            <button style={buttonStyle} onClick={() => setQuery({ ...query, page: query.page - 1 >= 1 ? query.page - 1 : query.page })}><FaArrowLeft /></button>
                            <button style={buttonStyle} onClick={() => setQuery({ ...query, page: query.page + 1 <= totalPages ? query.page + 1 : query.page })}><FaArrowRight /></button>
                        </Col>
                        <Col xs="auto">
                            Page Size
                            <select style={selectStyle} value={query.pageSize} onChange={(e) => setQuery({ ...query, pageSize: e.target.value })}>
                                <option value={-1}>All</option>
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                            </select>
                        </Col>
                        <Col xs="auto">
                            <button style={view === 'Graphs' ? buttonActiveStyle : buttonStyle} onClick={() => setView('Graphs')}>Graphs</button>
                            <button style={view === 'Table' ? buttonActiveStyle : buttonStyle} onClick={() => setView('Table')}>Table</button>
                            <button style={view === 'Timeline' ? buttonActiveStyle : buttonStyle} onClick={() => setView('Timeline')}>Timeline</button>
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Header>
                    <Row style={{ alignItems: 'center', }}>
                        <Col xs="auto">
                            Period
                            <input type="date" style={selectStyle} value={query.start} onChange={e => setQuery({ ...query, start: e.target.value })} />
                            <input type="date" style={selectStyle} value={query.end} onChange={e => setQuery({ ...query, end: e.target.value })} />
                            <button style={buttonStyle} onClick={() => subtractDay()}><FaArrowLeft /></button>
                            <button style={buttonStyle} onClick={() => resetDays()}><IoTodayOutline /></button>
                            <button style={buttonStyle} onClick={() => addDay()}><FaArrowRight /></button>
                        </Col>
                        <Col xs="auto">
                            Report
                            <select style={query.report === 'All' ? selectStyle : selectActiveStyle} value={query.report} onChange={(e) => setQuery({ ...query, report: e.target.value })}>
                                <option>All</option>
                                <option>Open</option>
                                <option>Covered</option>
                            </select>
                        </Col>
                        <Col xs="auto">
                            Contract
                            <select
                                style={query.contract === 'All' ? selectStyle : selectActiveStyle}
                                value={query.contract}
                                onChange={e => setQuery({ ...query, contract: e.target.value })}
                            >
                                <option value="All">All</option>
                                {contracts.sort().map((contract, i) => (
                                    <option key={i}>{contract}</option>
                                ))}
                            </select>
                        </Col>
                        <Col xs="auto">
                            State
                            <select
                                style={query.state === 'All' ? selectStyle : selectActiveStyle}
                                value={query.state}
                                onChange={e => setQuery({ ...query, state: e.target.value })}
                            >
                                <option value="All">All</option>
                                {Array.from(new Set(places.map(place => place.state)))
                                    .sort()
                                    .map((state, i) => (
                                        <option key={i} value={state}>{state}</option> // Step 4: Mapping to <option> elements
                                    ))}
                            </select>
                        </Col>
                        <Col xs="auto">
                            City
                            <select
                                style={query.state === 'All' ? selectStyle : selectActiveStyle}
                                value={JSON.stringify({ city: query.city, state: query.state })}
                                onChange={e => e.target.value === 'All'
                                    ? setQueryPlace('All')
                                    : setQueryPlace(JSON.parse(e.target.value))
                                }
                            >
                                <option value="All">All</option>
                                {places.sort((a, b) => {
                                    if (a.state < b.state) return -1;
                                    if (a.state > b.state) return 1;
                                    if (a.city < b.city) return -1;
                                    if (a.city > b.city) return 1;
                                    return 0;
                                }).map((place, i) => (
                                    query?.state === 'All'
                                        ?
                                        <option key={i} value={JSON.stringify(place)}>{place.city}, {place.state}</option>
                                        :
                                        query?.state === place.state &&
                                        <option key={i} value={JSON.stringify(place)}>{place.city}, {place.state}</option>
                                ))}
                            </select>
                        </Col>
                        <Col xs="auto">
                            Driver
                            <select
                                style={query.driver === 'All' ? selectStyle : selectActiveStyle}
                                value={JSON.stringify(query.driver)}
                                onChange={e => e.target.value === 'All'
                                    ? setQuery({ ...query, driver: 'All' })
                                    : setQuery({ ...query, driver: JSON.parse(e.target.value) })
                                }
                            >
                                <option value="All">All</option>
                                {drivers.sort((a, b) => {
                                    if (a < b) return -1;
                                    if (a > b) return 1;
                                    return 0;
                                }).map((driver, i) => (
                                    <option key={i} value={JSON.stringify(driver)}>{driver}</option>
                                ))}
                            </select>
                        </Col>
                        <Col xs="auto">
                            <input style={selectStyle} type="search" placeholder="Search ShipmentId..." onChange={(e) => setSearch(e.target.value)} />
                            <button style={buttonStyle} onClick={() => searchShipment()}><FaSearch /></button>
                        </Col>
                    </Row>
                </Card.Header>

                <>
                    {!set ?
                        <LogisticsLoading message={'Loading...'} />
                        :
                        <>
                            {view === 'Graphs' &&
                                <Card.Body>
                                    <Row>
                                        <Col xs="auto">
                                            <Chart
                                                chartType={'PieChart'}
                                                data={loadtenderCoverageChartData}
                                                options={{ title: `Load Tender Coverage` }}
                                                width="500px"
                                                height="500px"
                                            />
                                        </Col>
                                        {dowChartData.length > 4 ?
                                            <>
                                                <Col sm={6}>
                                                    <Chart
                                                        chartType={'Bar'}
                                                        data={dowChartData}
                                                        options={{
                                                            chart: {
                                                                title: "Day of Week",
                                                                subtitle: "",
                                                            },
                                                            chartArea: { width: '50%' },
                                                            hAxis: {
                                                                title: "Day of Week Count",
                                                                minValue: 0,
                                                            },
                                                            vAxis: {
                                                                title: "Day of Week",
                                                                slantedText: true,
                                                                slantedTextAngle: 45,
                                                            },
                                                            legend: { position: "none" },
                                                        }}
                                                        width="100%"
                                                        height="500px"
                                                    />
                                                </Col>
                                            </>
                                            :
                                            <Col xs="auto">
                                                <Chart
                                                    chartType={'PieChart'}
                                                    data={dowChartData}
                                                    options={{ title: `Day of Week` }}
                                                    width="500px"
                                                    height="500px"
                                                />
                                            </Col>
                                        }
                                        {dateChartData.length > 4 ?
                                            <>
                                                <Col sm={12}>
                                                    <Chart
                                                        chartType={'Bar'}
                                                        data={dateChartData}
                                                        options={{
                                                            chart: {
                                                                title: "Date",
                                                                subtitle: "",
                                                            },
                                                            chartArea: { width: '50%' },
                                                            hAxis: {
                                                                title: "Date Count",
                                                                minValue: 0,
                                                            },
                                                            vAxis: {
                                                                title: "Date",
                                                                slantedText: true,
                                                                slantedTextAngle: 45,
                                                            },
                                                            legend: { position: "none" },
                                                        }}
                                                        width="100%"
                                                        height="500px"
                                                    />
                                                </Col>
                                            </>
                                            :
                                            <Col xs="auto">
                                                <Chart
                                                    chartType={'PieChart'}
                                                    data={dateChartData}
                                                    options={{ title: `Date` }}
                                                    width="500px"
                                                    height="500px"
                                                />
                                            </Col>
                                        }
                                        {stateChartData.length > 7 ?
                                            <>
                                                <Col sm={12}>
                                                    <Chart
                                                        chartType={'Bar'}
                                                        data={stateChartData}
                                                        options={{
                                                            chart: {
                                                                title: "State",
                                                                subtitle: "",
                                                            },
                                                            chartArea: { width: '50%' },
                                                            hAxis: {
                                                                title: "State Count",
                                                                minValue: 0,
                                                            },
                                                            vAxis: {
                                                                title: "State",
                                                                slantedText: true,
                                                                slantedTextAngle: 45,
                                                            },
                                                            legend: { position: "none" },
                                                        }}
                                                        width="100%"
                                                        height="500px"
                                                    />
                                                </Col>
                                            </>
                                            :
                                            <Col xs="auto">
                                                <Chart
                                                    chartType={'PieChart'}
                                                    data={stateChartData}
                                                    options={{ title: `State` }}
                                                    width="500px"
                                                    height="500px"
                                                />
                                            </Col>
                                        }
                                        {contractChartData.length > 7 ?
                                            <Col sm={12}>
                                                <Chart
                                                    chartType={'Bar'}
                                                    data={contractChartData}
                                                    options={{
                                                        chart: {
                                                            title: "Contract Trip Count",
                                                            subtitle: "",
                                                        },
                                                        chartArea: { width: '50%' },
                                                        hAxis: {
                                                            title: "Count of Trips",
                                                            minValue: 0,
                                                        },
                                                        vAxis: {
                                                            title: "Contract",
                                                        },
                                                        legend: { position: "none" },
                                                    }}

                                                    width="100%"
                                                    height="500px"
                                                />
                                            </Col>
                                            :
                                            <Col xs="auto">
                                                <Chart
                                                    chartType={'PieChart'}
                                                    data={contractChartData}
                                                    options={{ title: `Contract Trip Count` }}
                                                    width="500px"
                                                    height="500px"
                                                />
                                            </Col>
                                        }
                                        {equipmentChartData.length > 7 ?
                                            <>
                                                <Col sm={12}>
                                                    <Chart
                                                        chartType={'Bar'}
                                                        data={equipmentChartData}
                                                        options={{
                                                            chart: {
                                                                title: "Equipment Used",
                                                                subtitle: "",
                                                            },
                                                            chartArea: { width: '50%' },
                                                            hAxis: {
                                                                title: "Equipment Count",
                                                                minValue: 0,
                                                            },
                                                            vAxis: {
                                                                title: "Equipment",
                                                                slantedText: true,
                                                                slantedTextAngle: 45,
                                                            },
                                                            legend: { position: "none" },
                                                        }}
                                                        width="100%"
                                                        height="500px"
                                                    />
                                                </Col>
                                            </>
                                            :
                                            <Col xs="auto">
                                                <Chart
                                                    chartType={'PieChart'}
                                                    data={equipmentChartData}
                                                    options={{ title: `Equipment Used` }}
                                                    width="500px"
                                                    height="500px"
                                                />
                                            </Col>
                                        }
                              
                                    </Row>
                                </Card.Body>
                            }
                            {view === 'Table' &&
                                <Card.Body>
                                    <DataDisplayV3
                                        lsKey={`@mctms-loadtender-dashboard-v3`}
                                        sourceName={`MCTMS-Load-Tender-Dashboard`}
                                        dataSource={loadtenders}
                                        urlKey="ShipmentId"
                                        urlPath="/loadtender/"
                                    />
                                </Card.Body>
                            }
                            {view === 'Timeline' &&
                                <>
                                    <Card.Header>
                                        <Row>
                                            <Col xs="auto">
                                                <i><FaInfo /> Shipments over time.</i>
                                            </Col>
                                            <Col xs="auto">
                                                <b>Color Key</b>
                                            </Col>
                                            <Col xs="auto">
                                                <div style={{ background: 'rgba(251,183,14,0.75)', padding: '0.1em', fontWeight: 'bold' }}>
                                                    Open Load
                                                </div>
                                            </Col>
                                            <Col xs="auto">
                                                <div style={{ background: 'rgba(110,182,255,0.75)', padding: '0.1em', fontWeight: 'bold' }}>
                                                    Covered Load
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card.Header>
                                    <Card.Body>
                                        <ShipmentGanttChartV3 loadtenderTimelineData={loadtenderTimelineData} />
                                    </Card.Body>
                                </>

                            }
                        </>

                    }

                </>
            </Card>
        </Container>
    )
}

export default LoadTenderDashboardFreight;