import { Container, Card, Row, Col, Accordion, Table } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react'
import {
    EDI_214_SHIPMENT_STATUS_CODES,
    EDI_214_SHIPMENT_STATUS_CODES_MAIL_SPECIFIC,
    EDI_214_SHIPMENT_STATUS_CODES_MAIL_PICKUP_SPECIFIC,
    EDI_214_SHIPMENT_STATUS_CODES_MAIL_DELIVERY_SPECIFIC,
    EDI_204_210_STOP_OFF_DETAIL_REASON_CODES,
    EDI_TRANSACTION_SET_PURPOSE_CODES,
    EDI_TIMEZONE_CODES,
    EDI_WEIGHT_CODES,
    EDI_EQUIPMENT_CODES,
    EDI_BUSINESS_INSTRUCTIONS_REFERENCE_NO_CODES,
    EDI_SHIPMENT_METHOD_OF_PAYMENT,
    EDI_STOP_REASON_CODE
} from '../../context/klienschmidtdata'
import DataDisplay from "../../components/DataDisplay";


function LoadTender() {
    return (
        <Container fluid style={{ margin: '0', padding: '0' }}>
            <Card style={{ borderRadius: '0', }}>
                <Card.Header>
                    <b>Load Tender Info</b>
                </Card.Header>
                <Accordion>
                    <Accordion.Item eventKey="13">
                        <Accordion.Header>Load Tender Navigation</Accordion.Header>
                        <Accordion.Body>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Tab</th>
                                        <th>Description</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Info</td>
                                        <td>Information about load tendering, terminology, and codes.</td>
                                    </tr>
                                    <tr>
                                        <td>IoT</td>
                                        <td>Internet of Things web connections.</td>
                                    </tr>
                                    <tr>
                                        <td>Active</td>
                                        <td>These tenders are active</td>
                                    </tr>

                                </tbody>
                            </Table>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="16">
                        <Accordion.Header>EDI Status</Accordion.Header>
                        <Accordion.Body>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Status</th>
                                        <th>Description</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Pending</td>
                                        <td>These are load tenders which are pending a response.</td>
                                    </tr>
                                    <tr>
                                        <td>Rejected</td>
                                        <td>Load tenders in this status have been rejected and service has not been provided.</td>
                                    </tr>
                                    <tr>
                                        <td>Accepted</td>
                                        <td>Load tenders in this status have been agreed to, and service will be provided.</td>
                                    </tr>
                                    <tr>
                                        <td>Completed</td>
                                        <td>These tenders have been marked as completed.</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="14">
                        <Accordion.Header>EDI Message Types</Accordion.Header>
                        <Accordion.Body>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Message</th>
                                        <th>Description</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>204 : Motor Carrier Load Tender</td>
                                        <td>
                                            used by shippers or their agents to request a motor carrier to transport goods from one location to another.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>214 : Transportation Carrier Shipment Status Message</td>
                                        <td>used by carriers to provide shippers with information about the status of their shipments, including pickup and delivery times.</td>
                                    </tr>
                                    <tr>
                                        <td>990 : Response to a Load Tender</td>
                                        <td>used by motor carriers to acknowledge receipt of a load tender message from a shipper and to provide a response indicating whether they accept or reject the shipment request.</td>
                                    </tr>
                                    <tr>
                                        <td>210 : Motor Carrier Freight Invoice</td>
                                        <td>used by motor carriers to invoice shippers for the transportation of goods.</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="15">
                        <Accordion.Header>DOT Inspection Types</Accordion.Header>
                        <Accordion.Body>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Inspection Type</th>
                                        <th>Common Name</th>
                                        <th>Definition</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Level I Inspection</td>
                                        <td>North American Standard Inspection</td>
                                        <td>
                                            This is the most comprehensive and rigorous inspection. It includes a thorough examination of both the CMV and the driver. It covers items such as driver credentials, hours-of-service compliance, vehicle documentation, braking system, tires, lights, cargo securement, fuel system, exhaust system, and more.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Level II Inspection</td>
                                        <td>Walk-Around Driver/Vehicle Inspection</td>
                                        <td>
                                            This inspection focuses on the driver and the vehicle's components that can be checked without physically getting under the CMV. It includes items like driver's license, medical card, seat belt usage, alcohol and drug-related violations, and general vehicle condition.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Level III Inspection</td>
                                        <td>Driver-Only Inspection</td>
                                        <td>
                                            This inspection focuses solely on the driver's credentials and compliance with driver-related regulations. It includes items like driver's license, medical card, hours-of-service documentation, record of duty status, and other applicable driver-related requirements.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Level IV Inspection</td>
                                        <td>Special Inspection</td>
                                        <td>
                                            This inspection is typically conducted on specific items or components of the CMV that require specialized expertise. It can include inspections of items such as cargo tanks, hazardous materials (Hazmat) documentation, passenger vehicle requirements, or other specialized areas.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Level V Inspection</td>
                                        <td>Vehicle-Only Inspection</td>
                                        <td>
                                            This inspection focuses on the vehicle's components, equipment, and overall mechanical condition. It includes items such as brakes, tires, lights, fuel system, exhaust system, cargo securement, and other related vehicle components.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Level VI Inspection</td>
                                        <td>Enhanced NAS Inspection for Radioactive Shipments</td>
                                        <td>
                                            This inspection is specific to CMVs carrying radioactive materials. It includes additional checks related to the transportation of radioactive substances, including the vehicle's radiation shielding, warning devices, shipping papers, and other relevant requirements.
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>EDI 214 - SHIPMENT STATUS CODES : USPS MAIL SPECIFIC (x{EDI_214_SHIPMENT_STATUS_CODES_MAIL_SPECIFIC.length})</Accordion.Header>
                        <Accordion.Body>
                            <DataDisplay
                                dataSource={EDI_214_SHIPMENT_STATUS_CODES_MAIL_SPECIFIC}
                                sourceName={'EDI-214-Shipment-Status-Codes-Mail'}
                                lsKey={'@mctms-edi-214-codes-mail'}
                            />
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header>EDI 214 - SHIPMENT STATUS CODES : USPS MAIL - DELIVERY SPECIFIC (x{EDI_214_SHIPMENT_STATUS_CODES_MAIL_DELIVERY_SPECIFIC.length})</Accordion.Header>
                        <Accordion.Body>
                            <DataDisplay
                                dataSource={EDI_214_SHIPMENT_STATUS_CODES_MAIL_DELIVERY_SPECIFIC}
                                sourceName={'EDI-214-Shipment-Status-Codes-Mail-Delivery'}
                                lsKey={'@mctms-edi-214-codes-mail-delivery'}
                            />
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>EDI 214 - SHIPMENT STATUS CODES : USPS MAIL APPOINTMENT SPECIFIC (x{EDI_214_SHIPMENT_STATUS_CODES_MAIL_PICKUP_SPECIFIC.length})</Accordion.Header>
                        <Accordion.Body>
                            <DataDisplay
                                dataSource={EDI_214_SHIPMENT_STATUS_CODES_MAIL_PICKUP_SPECIFIC}
                                sourceName={'EDI-214-Shipment-Status-Codes-Mail-Pickup'}
                                lsKey={'@mctms-edi-214-codes'}
                            />
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>EDI 214 - SHIPMENT STATUS CODES (x{EDI_214_SHIPMENT_STATUS_CODES.length})</Accordion.Header>
                        <Accordion.Body>
                            <DataDisplay
                                dataSource={EDI_214_SHIPMENT_STATUS_CODES}
                                sourceName={'EDI-214-Shipment-Status-Codes'}
                                lsKey={'@mctms-edi-214-codes'}
                            />
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                        <Accordion.Header>EDI 204 & 210 - STOP OFF DETAIL REASON CODES (x{EDI_204_210_STOP_OFF_DETAIL_REASON_CODES.length})</Accordion.Header>
                        <Accordion.Body>
                            <DataDisplay
                                dataSource={EDI_204_210_STOP_OFF_DETAIL_REASON_CODES}
                                sourceName={'EDI-204-210-Stop-Off-Codes'}
                                lsKey={'@mctms-edi-204-210-Stop-Off-codes'}
                            />
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                        <Accordion.Header>EDI TRANSACTION SET PURPOSE CODES (x{EDI_TRANSACTION_SET_PURPOSE_CODES.length})</Accordion.Header>
                        <Accordion.Body>
                            <DataDisplay
                                dataSource={EDI_TRANSACTION_SET_PURPOSE_CODES}
                                sourceName={'EDI-Transaction-Set-Purpose-Codes'}
                                lsKey={'@mctms-edi-transaction-set-purpose-codes'}
                            />
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="6">
                        <Accordion.Header>EDI TIMEZONE CODES (x{EDI_TIMEZONE_CODES.length})</Accordion.Header>
                        <Accordion.Body>
                            <DataDisplay
                                dataSource={EDI_TIMEZONE_CODES}
                                sourceName={'EDI-Timezone-Codes'}
                                lsKey={'@mctms-edi-timezone-codes'}
                            />

                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="7">
                        <Accordion.Header>EDI WEIGHT CODES (x{EDI_WEIGHT_CODES.length})</Accordion.Header>
                        <Accordion.Body>
                            <DataDisplay
                                dataSource={EDI_WEIGHT_CODES}
                                sourceName={'EDI-Weight-Codes'}
                                lsKey={'@mctms-edi-weight-codes'}
                            />
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="8">
                        <Accordion.Header>EDI EQUIPMENT CODES (x{EDI_EQUIPMENT_CODES.length})</Accordion.Header>
                        <Accordion.Body>
                            <DataDisplay
                                dataSource={EDI_EQUIPMENT_CODES}
                                sourceName={'EDI-Equipment-Codes'}
                                lsKey={'@mctms-edi-equipment-codes'}
                            />
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="9">
                        <Accordion.Header>EDI BUSINESS INSTRUCTIONS REFERENCE NO CODES (x{EDI_BUSINESS_INSTRUCTIONS_REFERENCE_NO_CODES.length})</Accordion.Header>
                        <Accordion.Body>
                            <DataDisplay
                                dataSource={EDI_BUSINESS_INSTRUCTIONS_REFERENCE_NO_CODES}
                                sourceName={'EDI-Business-Instruction-Codes'}
                                lsKey={'@mctms-edi-business-instruction-codes'}
                            />
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="10">
                        <Accordion.Header>EDI SHIPMENT METHOD OF PAYMENT (x{EDI_SHIPMENT_METHOD_OF_PAYMENT.length})</Accordion.Header>
                        <Accordion.Body>
                            <DataDisplay
                                dataSource={EDI_SHIPMENT_METHOD_OF_PAYMENT}
                                sourceName={'EDI-Shipment-Method-Codes'}
                                lsKey={'@mctms-edi-shipment-method-codes'}
                            />
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="11">
                        <Accordion.Header>EDI STOP REASON CODE (x{EDI_STOP_REASON_CODE.length})</Accordion.Header>
                        <Accordion.Body>
                            <DataDisplay
                                dataSource={EDI_STOP_REASON_CODE}
                                sourceName={'EDI-Stop-Codes'}
                                lsKey={'@mctms-edi-stop-codes'}
                            />
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Card>
        </Container>
    );
}

export default LoadTender;