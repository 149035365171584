import { Container, Card, Row, Col, Form, Button, Spinner, Alert, Tooltip, OverlayTrigger, } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect } from 'react'
import Api from "../../utils/Api";
import { useParams } from 'react-router-dom';
import { FaInfoCircle } from "react-icons/fa";
import { useAuth } from '../../context/auth';
import { TRAILER_CATEGORIES, TRAILER_DOOR_CATEGORIES } from "../../context/appdata";
import AlertDisplay from "../../components/AlertDisplay";

function TrailerUpdate() {
    const { idToken } = useAuth();
    const { id } = useParams();
    const [set, didSet] = useState()
    const [trailer, setTrailer] = useState({});
    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts }
    const [assignments, setAssignments] = useState([]);
    const [domiciles, setDomiciles] = useState([]);


    async function handleUpdate() {
        try {
            await Api.patch(`/trailer/${id}`, trailer, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Success' }])

        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'Error updating: ' + error.error }])
        }

    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const trailer = await Api.get(`/trailer/${id}`, idToken)
                setTrailer(trailer)

                const assignments = await Api.get('/assignments/live', idToken);
                setAssignments(assignments)

                const domiciles = await Api.get('/locations/live/Domicile', idToken)
                setDomiciles(domiciles)
            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: 'Request error' }])
            }
            didSet(true)
        }
        if (!set) {
            fetchData()
        }
    }, [set, idToken, id, alerts])



    return (
        <Container fluid style={{margin:0,padding:0}}>
            <Card style={{ borderRadius: 0, border:0 }}>
                <Card.Header><b>Trailer Update</b></Card.Header>
                {alerts.length > 0 &&
                    <AlertDisplay alertState={alertState} />
                }
                {set ?
                    <Card.Body>
                        <Row>
                            <Col>
                                ID : {trailer._id}
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col sm={12} md={6} lg={3}>
                                Unit Number
                                <Form.Control
                                    type="text"
                                    value={trailer.unitnumber}
                                    onChange={e => setTrailer({ ...trailer, unitnumber: e.target.value })}
                                    disabled={trailer?.provider ? true : false}
                                />
                            </Col>
                            <Col sm={12} md={6} lg={3}>
                                VIN
                                <Form.Control
                                    type="text"
                                    value={trailer.vin}
                                    onChange={e => setTrailer({ ...trailer, vin: e.target.value })}
                                    disabled={trailer?.provider ? true : false}
                                />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col sm={12} md={6} lg={3}>
                                Assignment
                                <Form.Select value={JSON.stringify(trailer.assignment)} onChange={e => setTrailer({ ...trailer, assignment: JSON.parse(e.target.value) })}>
                                    <option>Select</option>
                                    {assignments && assignments.map(assignment => (
                                        <option key={assignment._id} value={JSON.stringify(assignment)}>{assignment.name}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                            <Col sm={12} md={6} lg={3}>
                                Domicile
                                <Form.Select value={JSON.stringify(trailer.domicile)} onChange={e => setTrailer({ ...trailer, domicile: JSON.parse(e.target.value) })}>
                                    <option>Select</option>
                                    {domiciles && domiciles.map(domicile => (
                                        <option key={domicile._id} value={JSON.stringify(domicile)}>{domicile.name}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col sm={12} md={6} lg={3}>
                                Year
                                <Form.Control type="number" value={trailer.year} onChange={e => setTrailer({ ...trailer, year: e.target.value })} />
                            </Col>
                            <Col sm={12} md={6} lg={3}>
                                Make
                                <Form.Control type="text" value={trailer.make} onChange={e => setTrailer({ ...trailer, make: e.target.value })} />
                            </Col>
                            <Col sm={12} md={6} lg={3}>
                                Model
                                <Form.Control type="text" value={trailer.model} onChange={e => setTrailer({ ...trailer, model: e.target.value })} />
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col sm={12} md={6} lg={3}>
                                Category
                                <Form.Select value={trailer.category} onChange={e => setTrailer({ ...trailer, category: e.target.value })}>
                                    <option>Select</option>
                                    {TRAILER_CATEGORIES.map((cat, i) => (
                                        <option key={i}>{cat.name}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                            <Col sm={12} md={6} lg={3}>
                                Door Type
                                <Form.Select value={trailer.doortype} onChange={e => setTrailer({ ...trailer, doortype: e.target.value })}>
                                    <option>Select</option>
                                    {TRAILER_DOOR_CATEGORIES.map((door, i) => (
                                        <option key={i}>{door.name}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                            <Col sm={12} md={6} lg={3}>
                                Length (ft)
                                <Form.Control type="text" value={trailer.unitlength} onChange={e => setTrailer({ ...trailer, unitlength: e.target.value })} />
                            </Col>
                            <Col sm={12} md={6} lg={3}>
                                Volume (cubic ft)
                                <Form.Control type="text" value={trailer.volume} onChange={e => setTrailer({ ...trailer, volume: e.target.value })} />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col xs="auto">
                                <Button variant="primary" onClick={() => handleUpdate()}>Update</Button>
                            </Col>
                            <Col xs="auto">
                                <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={
                                        <Tooltip id="button-tooltip">
                                            Active - determines whether or not the record should be
                                            used by the software application.
                                        </Tooltip>
                                    }
                                >
                                    <div>
                                        Active <FaInfoCircle /> : <Button variant={trailer.isActive ? 'success' : 'danger'} onClick={() => { setTrailer({ ...trailer, isActive: (trailer.isActive ? false : true) }) }}>{trailer.isActive ? 'True' : 'False'}</Button>
                                    </div>
                                </OverlayTrigger>
                            </Col>
                        </Row>
                    </Card.Body>
                    :
                    <Card.Body>
                        <Spinner />
                    </Card.Body>
                }

            </Card>
        </Container>
    );
}

export default TrailerUpdate;