import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Table, Button, Form, Row, Col, ListGroup, ListGroupItem, Container, Dropdown, Badge } from 'react-bootstrap';
import { FaArrowLeft, FaArrowRight, FaEdit, FaFileExport, FaFilter, FaSyncAlt, FaTable } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { FiDelete } from 'react-icons/fi';
import { generateExcel, generateCSV, generatePDF } from '../utils/FileGenerator';
import { BsFiletypeXlsx, BsFiletypeCsv, BsFiletypePdf } from 'react-icons/bs';
import { Modal } from 'react-bootstrap';
import { GoNote } from "react-icons/go";
import { CiViewColumn } from "react-icons/ci";
import { RiDraggable } from "react-icons/ri";

const DataDisplayV3 = ({ dataSource, lsKey, urlPath = '', urlKey = '', popKeys = [], sourceName, addActions = [], addFunctions = [] }) => {

    const [filteredData, setFilteredData] = useState([]);
    const [appliedFilters, setAppliedFilters] = useState({});

    const [useCardStyle, setUseCardStyle] = useState(() => {
        const saved = localStorage.getItem(`${lsKey}-dataDisplay`);
        return saved ? saved : false;
    });

    const [columns, setColumns] = useState(() => {
        const saved = localStorage.getItem(`${lsKey}`);
        return saved ? JSON.parse(saved) : [];
    });

    const [searchText, setSearchText] = useState(() => {
        const saved = localStorage.getItem(`${lsKey}-searchText`);
        return saved !== null ? saved : '';
    });

    const [includeColumnsInTable, setIncludeColumnsInTable] = useState(() => {
        const saved = localStorage.getItem(`${lsKey}-includeColumns`);
        return saved ? JSON.parse(saved) : [];
    });

    const [sortColumn, setSortColumn] = useState(null);
    const [sortOrder, setSortOrder] = useState('asc');

    const [showCardColumnSort, setShowCardColumnSort] = useState(false)

    // Effect for initializing state from local storage
    useEffect(() => {
        const savedSortState = localStorage.getItem(`${lsKey}-columnSort`);
        if (savedSortState) {
            const { column, order } = JSON.parse(savedSortState);
            if (column) setSortColumn(column);
            if (order) setSortOrder(order);
        }
    }, [lsKey]);

    // Effect for updating local storage when sortColumn or sortOrder changes
    useEffect(() => {
        const sortState = { column: sortColumn, order: sortOrder };
        localStorage.setItem(`${lsKey}-columnSort`, JSON.stringify(sortState));
    }, [lsKey, sortColumn, sortOrder]);

    // Function to handle sorting (this is just a placeholder function)
    const handleSort = (column) => {
        if (sortColumn === column) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortColumn(column);
            setSortOrder('asc');
        }
    };

    // Calculate pagination

    const currentItems = filteredData;

    const [showModal, setShowModal] = useState(false);

    const resetLocalStorage = useCallback(() => {
        setAppliedFilters({})
        localStorage.removeItem(lsKey);
        localStorage.removeItem(`${lsKey}-searchText`);
        localStorage.removeItem(`${lsKey}-currentPage`);
        localStorage.removeItem(`${lsKey}-itemsPerPage`);
        localStorage.removeItem(`${lsKey}-includeColumns`)
        localStorage.removeItem(`${lsKey}-columnSort`)
        setSearchText('')
        setColumns(Object.keys(dataSource[0] || {}));
        setIncludeColumnsInTable(Object.keys(dataSource[0]))
    }, [dataSource, lsKey]);

    const extractTextFromComponent = useCallback((component) => {
        if (!component) {
            return '';
        }

        if (typeof component === 'string' || typeof component === 'number') {
            return String(component);
        }

        if (Array.isArray(component)) {
            return component.map(extractTextFromComponent).join(' ');
        }

        if (component.props && component.props.children) {
            return extractTextFromComponent(component.props.children);
        }

        return '';
    }, []);

    const resetTableToDefault = () => {
        setUseCardStyle(false)
        setSortColumn('#')
        setSortOrder('asc')
        setAppliedFilters({})
        setSearchText('')
    }

    const handleColumnReorder = (dragIndex, dropIndex) => {
        const updatedColumns = [...columns];
        const [draggedColumn] = updatedColumns.splice(dragIndex, 1);
        updatedColumns.splice(dropIndex, 0, draggedColumn);
        setColumns(updatedColumns);
    };

    const renderDraggableTableHeader = () => {
        return (
            <tr>
                {(urlPath && urlKey) &&
                    <th></th>
                }
                {columns.map((column, index) => (
                    (!popKeys.includes(column) && includeColumnsInTable.includes(column)) &&
                    <th key={index} style={{ whiteSpace: 'nowrap' }}>
                        <div
                            className="d-flex align-items-center"
                            draggable
                            onDragStart={(e) => e.dataTransfer.setData('text/plain', index)}
                            onDragOver={(e) => e.preventDefault()}
                            onDrop={(e) => {
                                const dragIndex = parseInt(e.dataTransfer.getData('text/plain'));
                                const dropIndex = index;
                                handleColumnReorder(dragIndex, dropIndex);
                            }}
                        >
                            <RiDraggable />
                            <span onClick={() => handleSort(column)}>
                                {(Object.keys(appliedFilters).includes(column) && appliedFilters[column] !== '') && <span style={{ color: 'red' }}>*</span>}
                                {column}
                            </span>
                            {sortColumn === column && (
                                <span className="sort-arrow">{sortOrder === 'asc' ? '↑' : '↓'}</span>
                            )}
                        </div>
                    </th>
                ))}
            </tr>
        );
    };

    const renderDraggableCardHeader = () => {
        return (
            showCardColumnSort &&
            <tr>
                <th style={{ border: 0 }}>
                    {columns.map((column, i) => (
                        (!popKeys.includes(column) && includeColumnsInTable.includes(column)) &&
                        <React.Fragment key={i} >
                            <Col style={{ border: '1px solid #eee', boxSizing: 'border-box', padding: '0px', margin: '0px' }}>
                                <div
                                    className="d-flex align-items-center"
                                    draggable
                                    onDragStart={(e) => e.dataTransfer.setData('text/plain', i)}
                                    onDragOver={(e) => e.preventDefault()}
                                    onDrop={(e) => {
                                        const dragIndex = parseInt(e.dataTransfer.getData('text/plain'));
                                        const dropIndex = i;
                                        handleColumnReorder(dragIndex, dropIndex);
                                    }}
                                    style={{ border: '1px solid #ccc', padding: '0.1em' }}
                                >
                                    <RiDraggable />
                                    <span onClick={() => handleSort(column)}>
                                        {(appliedFilters[column] !== undefined && appliedFilters[column] !== '') && <span style={{ color: 'red' }}>*</span>}
                                        {column}
                                    </span>
                                    {sortColumn === column && (
                                        <span className="sort-arrow">{sortOrder === 'asc' ? '↑' : '↓'}</span>
                                    )}
                                </div>
                            </Col>
                        </React.Fragment>
                    ))}
                </th>
            </tr>
        );
    };

    const renderDraggableCheckBoxes = () => {
        return (
            <Row>
                {(urlPath && urlKey) &&
                    <th></th>
                }
                {columns.map((column, index) => (
                    !popKeys.includes(column) &&
                    <th key={index} style={{ whiteSpace: 'nowrap' }}>
                        <div
                            className="d-flex align-items-center"
                            draggable
                            onDragStart={(e) => e.dataTransfer.setData('text/plain', index)}
                            onDragOver={(e) => e.preventDefault()}
                            onDrop={(e) => {
                                const dragIndex = parseInt(e.dataTransfer.getData('text/plain'));
                                const dropIndex = index;
                                handleColumnReorder(dragIndex, dropIndex);
                            }}
                        >
                            <RiDraggable />
                            <Form.Check
                                value={column}
                                // label={column}
                                checked={includeColumnsInTable.includes(column)}
                                onChange={() => handleCheckboxChange(column)}
                            />
                            <span onClick={() => handleSort(column)}>
                                {(Object.keys(appliedFilters).includes(column) && appliedFilters[column] !== '') && <span style={{ color: 'red', padding: '0.5em' }}>{` * `}</span>}
                                {' '}{column}
                            </span>
                            {sortColumn === column && (
                                <span className="sort-arrow">{sortOrder === 'asc' ? '↑' : '↓'}</span>
                            )}
                        </div>

                    </th>
                ))}
            </Row>
        );
    };

    const renderCellValue = (value) => {
        if (typeof value === 'object') {
            if (React.isValidElement(value)) {
                return value;
            }
            return JSON.stringify(value);
        }
        return value;
    };

    const renderTableRows = () => {
        return currentItems.map((item, index) => (
            <tr key={index}>
                {(urlPath && urlKey) &&
                    <td>
                        <Row>
                            <Col>
                                <Link to={urlPath + item[urlKey]}>
                                    <FaEdit />
                                </Link>
                            </Col>
                            {(addActions && addActions.length > 0) &&
                                addActions.map((action, i) => (
                                    <Col xs="auto" key={i}>
                                        <Link key={i} to={action.urlPath + item[action.urlKey]}>
                                            {action.icon}
                                        </Link>
                                    </Col>
                                ))
                            }
                            {(addFunctions && addFunctions.length > 0) &&
                                addFunctions.map((addFunc, i) => (
                                    <Col xs="auto" key={i}>
                                        <Link onClick={() => addFunc.function(item[addFunc.urlKey])}>
                                            {addFunc.icon}
                                        </Link>
                                    </Col>
                                ))
                            }
                        </Row>
                    </td>
                }
                {
                    columns.map((column, idx) => (
                        (!popKeys.includes(column) && includeColumnsInTable.includes(column)) &&
                        <td key={idx}>
                            {renderCellValue(item[column])}
                        </td>
                    ))}
            </tr>
        ));
    };

    const renderTableRowCards = () => {
        return currentItems.map((item, i) => (
            <tr key={i} style={{ border: 0 }}>
                <td style={{ border: 0, padding: 0, paddingBottom: '1em' }}>
                    <ListGroup>
                        {(urlPath && urlKey) &&
                            <ListGroupItem style={{ backgroundColor: '#f9f9f9' }}>
                                <Link to={urlPath + item[urlKey]}>
                                    <FaEdit />
                                </Link>
                            </ListGroupItem>
                        }
                        {columns.map((column, j) => (
                            (!popKeys.includes(column) && includeColumnsInTable.includes(column)) &&
                            <ListGroupItem key={j} style={{ padding: '0px', margin: '0px' }}>
                                <Row style={{ padding: '1px', margin: '0px' }}>
                                    <Col sm={12} md={2}>
                                        <b>{column}</b>
                                    </Col>
                                    <Col sm={12} md={10}>
                                        {renderCellValue(item[column])}
                                    </Col>
                                </Row>
                            </ListGroupItem>
                        ))}
                    </ListGroup>
                </td>
            </tr>
        ));
    };

    const toggleDisplayStyle = () => {
        setUseCardStyle(!useCardStyle);
    };

    const handleCheckboxChange = (column) => {
        setIncludeColumnsInTable(prev => {
            if (prev.includes(column)) {
                // If the column is already in the array, remove it
                return prev.filter(item => item !== column);
            } else {
                // If the column is not in the array, add it
                return [...prev, column];
            }
        });
    };

    const includeAllColumns = () => {
        setIncludeColumnsInTable(Object.keys(dataSource[0]))
    }

    const excludeAllColumns = () => {
        setIncludeColumnsInTable(['_id'])
    }


    useEffect(() => {
        const handleScreenSizeChange = () => {
            const saved = localStorage.getItem(`${lsKey}-dataDisplay`);
            const shouldUseCardStyle = saved === 'true' ? true : false;
            setUseCardStyle(shouldUseCardStyle);
        };

        handleScreenSizeChange();

        window.addEventListener('resize', handleScreenSizeChange);

        return () => {
            window.removeEventListener('resize', handleScreenSizeChange);
        };

    }, [lsKey]);

    useEffect(() => {
        if (dataSource.length > 0) {
            const keysMatchLocalStorage = () => {
                const savedColumns = localStorage.getItem(`${lsKey}`);
                if (!savedColumns) return false; // if there are no saved columns, they don't match
                const storedKeys = JSON.parse(savedColumns);
                const currentKeys = Object.keys(dataSource[0] || {});
                return JSON.stringify(storedKeys.sort()) === JSON.stringify(currentKeys.sort());
            };
            const matchingKeys = keysMatchLocalStorage();
            if (!matchingKeys) {
                resetLocalStorage();
            }

            localStorage.setItem(`${lsKey}`, JSON.stringify(columns));

            const applyFiltersAndSearch = () => {
                let filteredDataSource = dataSource;

                if (searchText.trim() !== '') {
                    filteredDataSource = dataSource.filter((item) => {
                        return columns.some((column) => {
                            const value = item[column];
                            if (value === null || value === undefined) {
                                return false;
                            }

                            const valueString = extractTextFromComponent(value);

                            return valueString.toLowerCase().includes(searchText.toLowerCase());
                        });
                    });
                }

                setFilteredData(filteredDataSource);
            };


            applyFiltersAndSearch();
        }

        const saved = localStorage.getItem(`${lsKey}-columnSort`);
        const options = JSON.parse(saved)
        const column = options?.column ? options?.column : null;
        const order = options?.order ? options?.order : 'asc';
        setSortColumn(column)
        setSortOrder(order)

    }, [dataSource, lsKey, columns, resetLocalStorage, searchText, extractTextFromComponent]);

    useEffect(() => {
        localStorage.setItem(`${lsKey}-searchText`, searchText);
    }, [searchText, lsKey]);

    useEffect(() => {
        localStorage.setItem(`${lsKey}-includeColumns`, JSON.stringify(includeColumnsInTable));
    }, [includeColumnsInTable, lsKey,]);

    useEffect(() => {
        localStorage.setItem(`${lsKey}-dataDisplay`, useCardStyle);
    }, [useCardStyle, lsKey,]);

    useEffect(() => {
        const sortState = {
            column: sortColumn,
            order: sortOrder
        };
        localStorage.setItem(`${lsKey}-columnSort`, JSON.stringify(sortState));
    }, [sortColumn, sortOrder, dataSource, lsKey]);


    filteredData.sort((a, b) => {
        if (a[sortColumn] < b[sortColumn]) return sortOrder === 'asc' ? -1 : 1;
        if (a[sortColumn] > b[sortColumn]) return sortOrder === 'asc' ? 1 : -1;
        return 0;
    });

    const selectColumnsModalRef = useRef(null);

    return (
        <Container fluid style={{ margin: '0px', padding: '0px' }}>
            <Modal show={showModal} onHide={() => setShowModal(false)} ref={selectColumnsModalRef}>
                <Modal.Header closeButton>
                    <Modal.Title>Include Columns</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {renderDraggableCheckBoxes()}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => includeAllColumns()}>
                        Include All
                    </Button>
                    <Button onClick={() => excludeAllColumns()}>
                        Exlcude All
                    </Button>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <pre style={{ margin: '0px', padding: '0px', overflow: 'hidden' }}>
                <Row className="justify-content-start align-items-center ">
                    <Col xs="auto">
                        Records : {dataSource.length}
                    </Col>
                    <Col style={{ textAlign: 'center' }} xs='auto' >
                        <Button variant="link" className="auto" onClick={toggleDisplayStyle}>
                            {!useCardStyle ?
                                <><GoNote /> Card</>
                                :
                                <><FaTable /> Table</>
                            }
                        </Button>
                    </Col>
                    <Col xs='auto' >
                        <Button variant="link" className="auto" onClick={() => setShowModal(true)}>
                            <CiViewColumn /> Columns
                        </Button>
                    </Col>
                    <Col xs='auto' >
                        <Button variant="link" className="auto" onClick={resetTableToDefault}>
                            <FaSyncAlt /> Reset
                        </Button>
                    </Col>
                    <Col></Col>
                    <Col xs='auto'>
                        <Form.Control
                            type="search"
                            placeholder={"🔎 Search... "}
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                            style={{ float: 'left', minWidth: '300px' }}
                        />
                    </Col>
                    <Col xs='auto' >
                        <Dropdown>
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                <FaFileExport />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => generateExcel(dataSource, sourceName)}><BsFiletypeXlsx />Export to Excel</Dropdown.Item>
                                <Dropdown.Item onClick={() => generatePDF(dataSource, sourceName)}><BsFiletypePdf />Export to PDF</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Table
                            striped={useCardStyle ? false : true}
                            bordered={useCardStyle ? false : true}
                            hover={useCardStyle ? false : true}
                            responsive={true}
                        >
                            <thead>{dataSource.length > 0 && (useCardStyle ? renderDraggableCardHeader() : renderDraggableTableHeader())}</thead>
                            <tbody>
                                {dataSource.length > 0
                                    ? (useCardStyle ? renderTableRowCards() : renderTableRows())
                                    :
                                    <tr>
                                        <td>
                                            No data.
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </pre>
        </Container >
    );
};

export default DataDisplayV3;