import { Container, Card, Row, Col, Form, Button, Spinner, Alert, Tooltip, OverlayTrigger, Badge, Table } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect, useRef } from 'react'
import Api from "../../utils/Api";
import { useParams } from 'react-router-dom';
import { FaInfoCircle, FaThumbsDown, FaThumbsUp } from "react-icons/fa";
import { useAuth } from '../../context/auth';
import { LOCATION_CATEGORIES } from "../../context/appdata";
import AlertDisplay from "../../components/AlertDisplay";
import GeofencePlotter from "../../components/GeofencePlotter";
import AddressAutocomplete from "../../components/AddressAutocomplete";
import { generateGoogleMapsLink, generateGoogleMapsLinkFromCoordinates } from "../../utils/googleMapLinks";



function LocationUpdate({ feature }) {

    const { id, nasscode } = useParams();
    const { user, idToken } = useAuth();

    const [set, didSet] = useState();

    const [location, setLocation] = useState({});

    const [uspsLocation, setUSPSLocation] = useState({});

    const [votes, setVotes] = useState([]);

    const [contracts, setContracts] = useState();

    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts }

    const [geofences, setGeofences] = useState([]);
    const geofenceState = { geofences, setGeofences };

    const [circleGeofence, setCircleGeofence] = useState([]);
    const circleGeofenceState = { circleGeofence, setCircleGeofence };

    const addressAutocompleteRef = useRef(null);
    const [fullAddress, setFullAddress] = useState('');
    const fullAddressState = { fullAddress, setFullAddress }

    useEffect(() => {
        setLocation({ ...location, fullAddress: fullAddress })
    }, [fullAddress])


    async function handleUpdate() {
        try {
            await Api.patch(`/location/${id}`, { ...location, geofences, circleGeofence }, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Updated' }])
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'Update error' }])
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await Api.get(`/location/${id}`, idToken)

                setFullAddress(data.fullAddress)

                if (data?.geofence && data?.geofence?.polygon) {

                    const fixFormat = data.geofence.polygon.vertices.map(fix => {
                        return {
                            lat: fix.latitude,
                            lng: fix.longitude,
                        }
                    })

                    setGeofences([...geofences, {
                        "id": new Date(),
                        "name": "Geofence 1",
                        "type": "polygon",
                        "vertices": fixFormat ?? [],
                        "color": "#ff0000"
                    }])
                }

                if (data?.geofence && data?.geofence?.circle) {
                    setCircleGeofence({
                        "center": [
                            data?.geofence?.circle?.latitude,
                            data?.geofence?.circle?.longitude
                        ],
                        "radius": data?.geofence?.circle?.radiusMeters,
                        "color": "#ffa100"
                    })
                }

                if(data?.geofences && data.geofences.length > 0){
                    setGeofences(data.geofences);
                }   
                if(data?.circleGeofence && Object.keys(data.circleGeofence).length > 0){
                    setCircleGeofence(data.circleGeofence);
                }
                
                setLocation(data)

                const contracts = await Api.get(`/contracts/live`, idToken)
                setContracts(contracts)
            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: 'Request error' }])
            }

            didSet(true)
        }

        const fetchUSPSData = async () => {
            try {
                const data = await Api.get(`/usps/servicepoint/${nasscode}`, idToken)
                setUSPSLocation(data)
                const queryStr = new URLSearchParams({ id: nasscode, provider: 'USPS' }).toString();
                const locationVotes = await Api.get(`/location/votes?${queryStr}`, idToken)
                setVotes(locationVotes)
            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: 'Error fetching votes.' }])
            }

            didSet(true)
        }

        if (!set && feature === 'data') {
            fetchData()
        }

        if (!set && feature === 'usps') {
            fetchUSPSData()
        }

    }, [set, idToken, alerts, id])


    async function sendLocationDownVote() {
        try {
            const vote = {
                provider: 'USPS',
                locationId: nasscode,
                vote: -1,
                user: user.email,
            }
            await Api.post('/locations/votes', vote, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Succesfully voted', }])
            didSet(false)

        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'Error sending down vote', }])
        }
    }

    async function sendLocationUpVote() {
        try {
            const vote = {
                provider: 'USPS',
                locationId: nasscode,
                vote: 1,
                user: user.email,
            }
            await Api.post('/locations/votes', vote, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Succesfully voted', }])
            didSet(false)
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'Error sending up vote', }])
        }
    }

    if (feature === 'usps') {
        return (
            <Container fluid style={{ margin: 0, padding: 0 }}>
                <AlertDisplay alertState={alertState} />
                <Card style={{ borderRadius: 0, border: 0 }}>
                    <Card.Header>
                        USPS Location
                    </Card.Header>
                    <Card.Body>
                        <ul>
                            <li><u>USPS NASSCODE : {nasscode}</u></li>
                            <li><u>{uspsLocation['Location Name']} </u></li>
                            <li>
                                <a href={generateGoogleMapsLink(uspsLocation['Address'])} target="_blank" rel="noopener noreferrer">{uspsLocation['Address']}</a>
                            </li>
                            <li><a href={generateGoogleMapsLinkFromCoordinates(uspsLocation['Latitude'], uspsLocation['Longitude'])} target="_blank" rel="noopener noreferrer">{uspsLocation['Latitude']}, {uspsLocation['Longitude']}</a></li>
                        </ul>
                        <Row>
                            <Col xs="auto">
                                <Badge bg="success" onClick={() => sendLocationUpVote(nasscode)}>
                                    <FaThumbsUp />
                                </Badge>
                            </Col>
                            <Col xs="auto">
                                <Badge bg="danger" onClick={() => sendLocationDownVote(nasscode)}>
                                    <FaThumbsDown />
                                </Badge>
                            </Col>
                            <Col xs="auto">
                                Trend : {votes.reduce((a, b) => a + b.vote, 0)}
                            </Col>
                        </Row>
                    </Card.Body>
                    <Card.Body>
                        <Card.Subtitle>Down vote the location if the address or its GPS position are wrong.</Card.Subtitle>
                    </Card.Body>
                    <Card.Body>
                        {votes.length > 0 ?
                            <table>
                                <thead>
                                    <tr>
                                        <th>Vote</th>
                                        <th>User</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {votes.map((vote, i) => (
                                        <tr key={i}>
                                            <td>
                                                {vote.vote === 1 ?
                                                    <Badge bg="success">
                                                        <FaThumbsUp />
                                                    </Badge>
                                                    :
                                                    <Badge bg="danger">
                                                        <FaThumbsDown />
                                                    </Badge>
                                                }
                                            </td>
                                            <td>{vote.user}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            :
                            <h4>No votes.</h4>
                        }
                    </Card.Body>
                </Card>
            </Container>
        )
    }

    if (feature === 'data') {
        return (
            <Container fluid style={{ margin: 0, padding: 0 }}>
                <Card style={{ borderRadius: 0, border: 0 }}>
                    <Card.Header>
                        <b>Location Update</b>
                    </Card.Header>

                    <AlertDisplay alertState={alertState} />


                    {set ?
                        <Card.Body>
                            <Row>
                                <Col>
                                    ID : {location._id}
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col sm={6} md={4}>
                                    Name
                                    <Form.Control type="text" value={location.name} onChange={e => setLocation({ ...location, name: e.target.value })} disabled={location?.provider ? true : false} />
                                </Col>
                                <Col xs={6} lg={4}>
                                    Address
                                    <AddressAutocomplete ref={addressAutocompleteRef} fullAddressState={fullAddressState} />
                                </Col>
                                <Col sm={6} md={4}>
                                    Type
                                    <Form.Select value={location.type} onChange={e => setLocation({ ...location, type: e.target.value })}>
                                        <option>Select</option>
                                        {LOCATION_CATEGORIES.map((cat, i) => (
                                            <option key={i} value={cat.name}>{cat.name} - {cat.detail}</option>
                                        ))}
                                    </Form.Select>
                                </Col>
                                {location?.provider &&
                                    <Col sm={6} md={4}>
                                        Provider
                                        <Form.Control type="text" value={location.provider} disabled={true} />
                                    </Col>
                                }
                                {/* {location.type === 'Service Point' &&
                                <Col sm={6} md={4}>
                                    Contract Assignment
                                    <Form.Select value={location.assignedto} onChange={e => setLocation({ ...location, assignedto: e.target.value })} >
                                        <option>Select</option>
                                        {contracts.map(contract => (
                                            <option>{contract.name}</option>
                                        ))}
                                    </Form.Select>
                                </Col>
                            } */}
                            </Row>
                            <br />
                            <Row>
                                <Col xs="auto">
                                    <Button variant="primary" onClick={() => handleUpdate()}>Update</Button>
                                </Col>
                                <Col xs="auto">
                                    <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={
                                            <Tooltip id="button-tooltip">
                                                Active - determines whether or not the record should be
                                                used by the software application.
                                            </Tooltip>
                                        }
                                    >
                                        <div>
                                            Active <FaInfoCircle /> : <Button variant={location.isActive ? 'success' : 'danger'} onClick={() => { setLocation({ ...location, isActive: (location.isActive ? false : true) }) }}>{location.isActive ? 'True' : 'False'}</Button>
                                        </div>
                                    </OverlayTrigger>
                                </Col>
                            </Row>
                            <br />
                            <GeofencePlotter
                                readOnly={true}
                                geofenceState={geofenceState}
                                circleGeofenceState={circleGeofenceState}
                            />
                        </Card.Body>
                        :
                        <Card.Body>
                            <Spinner />
                        </Card.Body>
                    }
                </Card>
            </Container>
        );
    }
}

export default LocationUpdate;