
function RateConfirmation() {
    return (
        <div>
            <h3></h3>
        </div>
    )
}



export default RateConfirmation;