import { DISPATCH_STATUSES, LICENSE_TYPES } from "../../context/appdata";
import { Container, Card, Row, Col, Form, Button, Spinner, Tooltip, OverlayTrigger, Badge } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect } from 'react'
import Api from "../../utils/Api";
import { useParams } from 'react-router-dom';
// import AVATAR_IMG from '../../assets/default/avatar.png';
import { FaInfoCircle } from "react-icons/fa";
import { useAuth } from '../../context/auth';
import DataDisplay from "../../components/DataDisplay";
import AlertDisplay from "../../components/AlertDisplay";
import ConfirmationModal from "../../components/ConfirmationModal";
import { getTimezoneCode } from "../../utils/Clock";

function OperatorUpdate() {
    const { id } = useParams();
    const { idToken } = useAuth();
    const [set, didSet] = useState();
    const [operator, setOperator] = useState({ name: '' });
    const [assignments, setAssignments] = useState([]);
    const [domiciles, setDomiciles] = useState([]);
    const [alerts, setAlerts] = useState([])
    const alertState = { alerts, setAlerts }
    const [schedule, setSchedule] = useState([])
    const [routeplans, setRoutePlans] = useState([])

    const [isModalOpen1, setModalOpen1] = useState(false);
    const [isModalOpen2, setModalOpen2] = useState(false);


    async function handleUpdate() {
        try {
            await Api.patch(`/operator/${id}`, operator, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Successfully updated.' }])
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured while trying to updated.' }])
        }
    }

    async function removeFromRoutePlans() {
        try {
            await Api.patch(`/routeplans/remove/${id}`, operator, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Successfully removed from route plans.' }])
            didSet(false)
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured while removing from route plans.' }])
        }
    }

    async function removeFromDispatches() {
        try {
            await Api.patch(`/dispatch/remove/${id}`, operator, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Successfully removed from dispatch.' }])
            didSet(false)
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured while removing from dispatch.' }])
        }
    }

    function getStatusVariantByName(statusName) {
        const statusObject = DISPATCH_STATUSES.find(status => (status.name).toLocaleLowerCase() === (statusName).toLocaleLowerCase());
        return statusObject ? statusObject.variant : null;
    }

    const formatDriver = (driver) => {
        return driver.lastname && driver.firstname
            ? <Badge bg={'secondary'}>{driver.lastname}, {driver.firstname}</Badge>
            : <Badge bg={'warning'}>{driver.name}</Badge> || <Badge bg={'dander'}>{'Error'}</Badge>;
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                function getFirstAndLastDayOfMonth() {
                    const today = new Date();
                    const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
                    const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);

                    const formatDate = (date) => {
                        const year = date.getFullYear();
                        const month = String(date.getMonth() + 1).padStart(2, '0');
                        const day = String(date.getDate()).padStart(2, '0');
                        return `${year}-${month}-${day}`;
                    };

                    return {
                        start: formatDate(firstDay),
                        end: formatDate(lastDay)
                    };
                }
                const { start, end } = getFirstAndLastDayOfMonth()

                const [operator, assignments, domiciles, scheduledDispatch, routeplanCoverage] = await Promise.all([
                    Api.get(`/operator/${id}`, idToken),
                    Api.get('/assignments/live', idToken),
                    Api.get('/locations/live/Domicile', idToken),
                    Api.get(`/dispatch/schedule/${id}/${start}/${end}`, idToken),
                    Api.get(`/routeplans/coverage/${id}`, idToken)
                ]);

                setOperator(operator);
                setAssignments(assignments);
                setDomiciles(domiciles);

                const cleanDispatch = scheduledDispatch.map(dispatch => {
                    return {
                        '_id': dispatch._id,
                        'Status': <Badge bg={getStatusVariantByName(dispatch.status)}>{dispatch.status}</Badge>,
                        'Service Date': dispatch.servicedate,
                        'Start': dispatch.routeplan.stops[0].arrive,
                        'End': dispatch.routeplan.stops[dispatch.routeplan.stops.length - 1].depart,
                        'Route': dispatch.routeplan.name,
                        'Service Type': dispatch.servicetype,
                        'Assignment': dispatch.routeplan.assignment.name,
                        'Domicile': dispatch.routeplan.domicile.name,
                        'Crew': dispatch.routeplan.crew,
                        'Coverage': <>{dispatch.coverage.map((driver, i) => <span key={i}>{formatDriver(driver)}</span>)}</>
                    };
                });

                const cleanRoutePlans = routeplanCoverage.map((routeplan) => {
                    return {
                        _id: routeplan._id,
                        'Name': <span style={{ whiteSpace: 'nowrap' }}>{routeplan.name}</span>,
                        'Service Type': routeplan.servicetype,
                        'Contract': routeplan.contract.name,
                        'Assignment': routeplan.assignment.name,
                        'Domicile': routeplan.domicile.name,
                        'Stops': <>{routeplan.stops.map(stp => <div style={{ whiteSpace: 'nowrap', fontFamily: 'monospace' }}> A: {stp.arrive} | D: {stp.depart} | {getTimezoneCode(stp.timezone)} | {stp.location.name} </div>)}</>,
                        'Miles': routeplan.routemiles,
                        'Hours': routeplan.sophours,
                        'RPH': routeplan.payrateperhour,
                        'Pay By': routeplan.payby,
                        'Est Pay': routeplan.payest,
                        'Block Pay': routeplan.blockpay ? "True" : "False",
                        'BP Hrs.': routeplan.blockpayhours,
                        'Vehicle': routeplan.vehiclecat,
                        'Trailer': routeplan.trailercat,
                        'Frequency': <div style={{ width: '400px', whiteSpace: 'pre-wrap' }}>{routeplan.frequency.name} - {routeplan.frequency.detail}</div>,
                        'Active': routeplan.isActive ? '✅' : '❌',
                    }
                })

                setSchedule(cleanDispatch);
                setRoutePlans(cleanRoutePlans);

            } catch (error) {
                setAlerts([...alerts, { variant: 'warning', message: 'An error occured.' }])
            }
            didSet(true)
        }
        if (!set) {
            fetchData()
        }
    }, [set, id, idToken, alerts])

    return (
        <Container fluid style={{margin:0,padding:0}}>
            <AlertDisplay alertState={alertState} />
            <ConfirmationModal
                show={isModalOpen1}
                onHide={() => setModalOpen1(false)}
                onConfirm={() => { removeFromDispatches(); setModalOpen1(false) }}
                message={`Are you sure you want to remove ${operator.firstname} ${operator.lastname} from all future dispatches?`}
            />

            <ConfirmationModal
                show={isModalOpen2}
                onHide={() => setModalOpen2(false)}
                onConfirm={() => { removeFromRoutePlans(); setModalOpen2(false) }}
                message={`Are you sure you want to remove ${operator.firstname} ${operator.lastname} from all route plans?`}
            />
            {set ?
                <>
                    <Card style={{borderRadius:0,border:0}}>
                        <Card.Header><b>Driver Update</b></Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                    ID : {operator._id}
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col>
                                    <Row>
                                        <Col sm={12} md={6} lg={3}>
                                            First Name
                                            <Form.Control
                                                type="text"
                                                value={operator.firstname}
                                                onChange={e => setOperator({ ...operator, firstname: e.target.value })}
                                                disabled={operator?.provider ? true : false}
                                            />
                                        </Col>
                                        <Col sm={12} md={6} lg={3}>
                                            Last Name
                                            <Form.Control
                                                type="text"
                                                value={operator.lastname}
                                                onChange={e => setOperator({ ...operator, lastname: e.target.value })}
                                                disabled={operator?.provider ? true : false}
                                            />
                                        </Col>
                                        {operator?.provider &&
                                            <Col sm={12} md={6} lg={3}>
                                                Provider
                                                <Form.Control
                                                    type="text"
                                                    value={operator.provider}
                                                    disabled={true}
                                                />
                                            </Col>
                                        }
                                    </Row>
                                    <br />
                                    <Row>
                                        <Col sm={12} md={6} lg={3}>
                                            License Type
                                            <Form.Select
                                                value={operator.licensetype}
                                                onChange={e => setOperator({ ...operator, licensetype: e.target.value })}
                                            >
                                                <option>Select</option>
                                                {LICENSE_TYPES.map((code, i) => (
                                                    <option key={i} value={code.name}>{code.name}</option>
                                                ))}
                                            </Form.Select>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col xs="auto">
                                    <Button variant="primary" onClick={() => handleUpdate()}>Update</Button>
                                </Col>
                                <Col xs="auto">
                                    <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={
                                            <Tooltip id="button-tooltip">
                                                Active - determines whether or not the record should be
                                                used by the software application.
                                            </Tooltip>
                                        }
                                    >
                                        <div style={{ marginLeft: '1em' }}>
                                            <FaInfoCircle /> : <Button variant={operator.isActive ? 'success' : 'danger'} onClick={() => { setOperator({ ...operator, isActive: (operator.isActive ? false : true) }) }}>{operator.isActive ? 'True' : 'False'}</Button>
                                        </div>
                                    </OverlayTrigger>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </>
                :
                <Spinner />
            }

        </Container>
    );
}

export default OperatorUpdate;