import { Badge, Button, Card, Col, Container, Row } from "react-bootstrap";
import DataDisplayV3 from "../../components/DataDisplayV3";
import { Link } from "react-router-dom";
import { FaPlusCircle } from "react-icons/fa";
import { useEffect, useState } from "react";
import { useAuth } from "../../context/auth";
import Api from "../../utils/Api";
import AlertDisplay from "../../components/AlertDisplay";
import LogisticsLoading from "../../components/LogisticsLoading";



function FreightCarriers({ feature }) {

    const { idToken } = useAuth();
    const [didFetch, setDidFetch] = useState();
    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts };
    const [carriers, setCarriers] = useState([]);
    const [query, setQuery] = useState({
        isActive: true
    });

    async function fetchCarriers() {
        try {
            const queryStr = new URLSearchParams(query).toString();
            const data = await Api.get(`/carriers?${queryStr}`, idToken);
            const cleanData = data.map(carrier => ({
                _id: carrier._id,
                Name: carrier.name,
                'Trading Partner': carrier.tradingPartner ? 'Yes' : 'No',
                Address: carrier.address,
                Contacts: carrier.contacts.map(contact => `Name: ${contact.name}\tPhone: ${contact.phone}\tEmail: ${contact.email}`).join('\n')
            }));
            setCarriers(cleanData)
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured fetching data.' }])
        } finally {
            setDidFetch(true)
        }
    }
    

    useEffect(() => {
        if (!didFetch) {
            fetchCarriers();
        }
    }, [didFetch])

    return (
        <Container fluid style={{ margin: 0, padding: 0 }}>
            <AlertDisplay alertState={alertState} />
            <Card style={{ border: 0, borderRadius: 0 }}>
                <Card.Header>
                    <Card.Title>
                        <Row>
                            <Col>
                                3PL Carriers
                            </Col>
                            <Col style={{ textAlign: 'center' }}>
                                <Badge
                                    bg={query.isActive ? 'success' : 'danger'}
                                    onClick={() => {
                                        setQuery({ ...query, isActive: !query.isActive });
                                        setDidFetch(false)
                                    }}
                                >
                                    {query.isActive ? 'Active' : 'Inactive'}
                                </Badge>
                            </Col>
                            <Col style={{ textAlign: 'right' }}>
                                <Link to={`/${feature}/carrier`}><FaPlusCircle /> Carrier</Link>
                            </Col>
                        </Row>
                    </Card.Title>
                </Card.Header>
                <Card.Body>
                    {didFetch ?
                        <DataDisplayV3
                            dataSource={carriers} lsKey='@sybrtms-freight-carriers'
                            urlPath={`/${feature}/carrier/`}
                            urlKey={'_id'}
                            popKeys={['_id']}
                            sourceName={'Feight Carriers'}
                            addActions={[]}
                            addFunctions={[]}
                        />
                        :
                        <LogisticsLoading message="Loading" />
                    }
                </Card.Body>
            </Card>
        </Container>
    )
}



export default FreightCarriers;