import React, { useState, useEffect } from 'react'
import { Button, Card, Col, Container, Dropdown, Form, OverlayTrigger, Row, Spinner, Table, Tooltip } from 'react-bootstrap'
import Api from '../../utils/Api'
import { useAuth } from '../../context/auth';
import LogisticsLoading from '../../components/LogisticsLoading'
import { Link } from "react-router-dom";
import { formatDateStrToMMDDYYHHMM, formatDateStrToMMDDYYHHMMTZ } from '../../utils/DateTimeFormat.helper';
import { FaArrowLeft, FaArrowRight, FaFileExport } from 'react-icons/fa';
import AlertDisplay from '../../components/AlertDisplay';
import DataDisplayV3 from '../../components/DataDisplayV3';
import { generateExcel, generatePDF } from '../../utils/FileGenerator';
import { BsFiletypePdf, BsFiletypeXlsx } from 'react-icons/bs';
import { IoTodayOutline } from "react-icons/io5";
import Chart from 'react-google-charts';
import ScatterPlot from '../../components/LoadTenderScatterPlot';
import { MdArrowBackIos, MdArrowForwardIos, MdKeyboardArrowDown, MdKeyboardArrowUp, MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight } from 'react-icons/md';
import { LoadTenderFinancialSummary } from '../../components/LoadTenderFinancialSummary';
import Select from 'react-select';

function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Add leading zero if needed
    const day = String(date.getDate()).padStart(2, '0'); // Add leading zero if needed
    return `${year}-${month}-${day}`;
}

const today = new Date();  // today based on the clock

const initialQuery = {
    startDate: formatDate(today),
    endDate: formatDate(today),
    report: 'All',
    contract: 'All',
    driver: 'All',
    page: 1,
    pageSize: 10
}

function millisecondsToHHMM(milliseconds) {
    const hours = Math.floor(milliseconds / 3600000);
    milliseconds %= 3600000;
    const minutes = Math.floor(milliseconds / 60000);
    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
    return `${formattedHours}:${formattedMinutes}`;
}

function formatCentsToDollars(cents) {
    const dollars = cents / 100;
    return dollars.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
}


const headerCenterStyle = {
    textAlign: 'center',
    padding: '0px 0.5em',
    backgroundColor: '#ccc',
}

const headerRightStyle = {
    textAlign: 'right',
    padding: '0px 0.5em',
    backgroundColor: '#ccc',
}

const cellCenterStyle = {
    textAlign: 'center',
    padding: '0px 0.5em'
}

const cellCenterStyleGreen = {
    textAlign: 'center',
    padding: '0px 0.5em',
    backgroundColor: '#bcdbbc'
}

const cellCenterStyleRed = {
    textAlign: 'center',
    padding: '0px 0.5em',
    backgroundColor: '#f39c9c'
}

const cellCenterStyleYellow = {
    textAlign: 'center',
    padding: '0px 0.5em',
    backgroundColor: '#f8f39c'
}

const callCenterOrangeStyle = {
    textAlign: 'center',
    padding: '0px 0.5em',
    backgroundColor: '#ffc107'
}

const cellRightStyle = {
    textAlign: 'right',
    padding: '0px 0.5em'
}

const rowEvenStyle = {
    backgroundColor: '#fff',
    border: '1px solid #ccc'
}

const rowOddStyle = {
    backgroundColor: '#e9ecef',
    border: '1px solid #ccc'
}

function LoadTenderReports({ ReportType }) {
    const { idToken } = useAuth();
    const [set, didSet] = useState();
    const [data, setData] = useState([]);
    const [scatterData, setScatterData] = useState([]);
    const [printableData, setPrintableData] = useState();
    const [query, setQuery] = useState(() => {
        const savedQuery = localStorage.getItem(`@mctms-loadtender-reports-query`);
        return savedQuery !== null ? JSON.parse(savedQuery) : initialQuery;
    });

    const [view, setView] = useState('table');
    // const [drivers, setDrivers] = useState([]);
    // const [driversFetched, setDriversFetched] = useState([]);

    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts }

    const [contracts, setContracts] = useState([]);
    const [contractsFetched, setContractsFetched] = useState([]);


    const [sortBy, setSortBy] = useState(null);
    const [sortDirection, setSortDirection] = useState('asc');


    async function fetchContracts() {
        setContractsFetched(false)
        try {
            const queryStr = new URLSearchParams({ start: query?.startDate, end: query?.endDate }).toString()
            const ediContracts = await Api.get(`/edi204/contracts?${queryStr}`, idToken);
            const cleanContracts = [...new Set(ediContracts.filter(Contract => String(Contract).length === 5).map(Contract => Contract))]
            setContracts(cleanContracts)
        } catch (err) {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured getting Contracts.' }])
        } finally {
            setContractsFetched(true)
        }
    }

    // async function fetchDrivers() {
    //     setDriversFetched(false)
    //     try {
    //         const queryStr = new URLSearchParams({ start: query?.startDate, end: query?.endDate }).toString()
    //         const ediDrivers = await Api.get(`/edi204/operators?${queryStr}`, idToken);
    //         setDrivers(ediDrivers)
    //     } catch (err) {
    //         setAlerts([...alerts, { variant: 'warning', message: 'An error occured getting Drivers.' }])
    //     } finally {
    //         setDriversFetched(true)
    //     }
    // }

    function formatDataForPrint(data) {
        return data.map((load, i) => ({
            'Shipment Id': `${load.ShipmentId}`,
            'Contract-Trip': `${load.Contract}-${load.Trip}`,
            'Coverage': `${load.coverage.flatMap(operator => operator.name).join('\n')}`,
            'Start Date': `${formatDateStrToMMDDYYHHMM(load.StartTime)}`,
            'End Date': `${formatDateStrToMMDDYYHHMM(load.EndTime)}`,
            'SOP Time': `${millisecondsToHHMM(load.SOPmilli)}`,
            'Stops': `${load.edi204[0].Stops.length}`,
            '204 Ct': `${load.edi204.length}`,
            '214 Ct': `${load.edi214.length}`,
            // 'Fourkite Updates': `${load.fourkiteShipmentUpdates.length}`,
        }))
    }

    async function fetchReport() {
        didSet(false)
        try {
            localStorage.setItem(`@mctms-loadtender-reports-query`, JSON.stringify(query))
            if (ReportType === 'problems') {
                query.report = 'Problems'
            }
            const queryStr = new URLSearchParams(query).toString();
            const data = await Api.get(`/loadtenders/shipments/report?${queryStr}`, idToken)
            // console.log(data[0])
            if (data.length > 0) {
                setData(data)

                // const cleanContracts = [...new Set(data.filter(load => String(load.Contract).length === 5).map(load => load.Contract))]
                // setContracts(cleanContracts)
                // setContractsFetch(true)

                // const cleanDrivers = [...new Set(data.filter(load => load.Coverage.length !== 0).map(load => load.Coverage))]
                // setDrivers(cleanDrivers)
                // setDriversFetch(true)

                const printable = formatDataForPrint(data)
                setPrintableData(printable)
            } else {
                setData([])
            }
        } catch(err) {
            console.log(err)
        } finally {
            didSet(true)
        }
    }

    function incrementDate(dateStr, days) {
        const date = new Date(dateStr);
        // Add 24 hours instead of adding one day
        date.setTime(date.getTime() + 24 * 60 * 60 * 1000 * days); // Add 24 hours in milliseconds
        const incrementedDate = date.toISOString().split('T')[0]; // Format back to YYYY-MM-DD
        return incrementedDate;
    }

    function decrementDate(dateStr, days) {
        const date = new Date(dateStr);
        // Subtract 24 hours instead of subtracting one day
        date.setTime(date.getTime() - 24 * 60 * 60 * 1000 * days); // Subtract 24 hours in milliseconds
        const decrementedDate = date.toISOString().split('T')[0]; // Format back to YYYY-MM-DD
        return decrementedDate;
    }

    function resetDays() {
        const newQueryStart = formatDate(today);
        const newQueryEnd = formatDate(today);
        setQuery({ ...query, startDate: newQueryStart, endDate: newQueryEnd })
    }

    function addDay() {
        const newQueryStart = incrementDate(query.startDate, 1);
        const newQueryEnd = incrementDate(query.endDate, 1);
        setQuery({ ...query, startDate: newQueryStart, endDate: newQueryEnd })
    }

    function subtractDay() {
        const newQueryStart = decrementDate(query.startDate, 1);
        const newQueryEnd = decrementDate(query.endDate, 1);
        setQuery({ ...query, startDate: newQueryStart, endDate: newQueryEnd })
    }

    function addWeek() {
        const newQueryStart = incrementDate(query.startDate, 7);
        const newQueryEnd = incrementDate(query.endDate, 7);
        setQuery({ ...query, startDate: newQueryStart, endDate: newQueryEnd })
    }

    function subtractWeek() {
        const newQueryStart = decrementDate(query.startDate, 7);
        const newQueryEnd = decrementDate(query.endDate, 7);
        setQuery({ ...query, startDate: newQueryStart, endDate: newQueryEnd })
    }

    function daySpanUp() {
        const maxDifference = 6; // Maximum difference between end and start
        const difference = (new Date(query.endDate) - new Date(query.startDate)) / (24 * 60 * 60 * 1000);
        if (difference >= maxDifference) return;

        const newQueryEnd = incrementDate(query.endDate, 1);
        setQuery({ ...query, endDate: newQueryEnd });
    }

    function daySpanDown() {
        const minDifference = 0; // Minimum difference between end and start
        const difference = (new Date(query.endDate) - new Date(query.startDate)) / (24 * 60 * 60 * 1000);
        if (difference <= minDifference) return;
        const newQueryEnd = incrementDate(query.endDate, -1);
        setQuery({ ...query, endDate: newQueryEnd });
    }

    function checkAudit(load) {
        let total = 0;
        if (load.coverage.length === 0) {
            total = total + load.edi204[load.edi204.length - 1].Stops.length
        } else {
            const audits = Object.keys(load.ediAudit);
            for (let i = 0; i < audits.length; i++) {
                const stopKeys = Object.keys(load.ediAudit[audits[i]].ediStops)
                if (stopKeys.length === 0) {
                    total = total + load.edi204[load.edi204.length - 1].Stops.length
                }
            }
        }


        return total;
    }

    function calculateEarly(load) {
        let total = 0;
        const audits = Object.keys(load.ediAudit);
        for (let i = 0; i < audits.length; i++) {
            const stopKeys = Object.keys(load.ediAudit[audits[i]].ediStops)
            for (let j = 0; j < stopKeys.length; j++) {
                const via = load.ediAudit[audits[i]].ediStops[stopKeys[j]]
                if (Number(via.eldOnPremiseArriveDiffMin) >= 0) {
                    total++;
                }
            }
        }

        return total;
    }

    function calculateLate(load) {
        let total = 0;
        const audits = Object.keys(load.ediAudit);
        for (let i = 0; i < audits.length; i++) {
            const stopKeys = Object.keys(load.ediAudit[audits[i]].ediStops)
            for (let j = 0; j < stopKeys.length; j++) {
                const via = load.ediAudit[audits[i]].ediStops[stopKeys[j]]
                if (Number(via.eldOnPremiseArriveDiffMin) < 0) {
                    total++;
                }
            }
        }

        return total;
    }

    function calculateMissed(load) {
        let total = 0;
        const audits = Object.keys(load.ediAudit);
        for (let i = 0; i < audits.length; i++) {
            const stopKeys = Object.keys(load.ediAudit[audits[i]].ediStops)
            for (let j = 0; j < stopKeys.length; j++) {
                const via = load.ediAudit[audits[i]].ediStops[stopKeys[j]]
                if ((!via?.eldOnPremiseArriveDiffMin)) {
                    total++;
                }
            }
        }

        return total;
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            fetchReport();
        }, 1000);

        return () => clearTimeout(timer);
    }, [query]);

    useEffect(() => {
        if (!set) {
            fetchContracts()
            // fetchDrivers()
        }
    }, [set])


    // chart functions
    function loadTenderCoverageChart(shipments) {
        let unassigned = 0, covered = 0;

        for (const record of shipments) {
            if (record['coverage'].length !== 0) {
                covered++;
            } else {
                unassigned++;
            }
        }

        const chartData = [
            ['Coverage', 'Load Tender Ct.', { role: 'style' }],
            [`Open (${unassigned})`, unassigned, '#F39C9C'],
            [`Covered (${covered})`, covered, '#BCDBBC']
        ];

        return chartData;
    }

    function loadTenderStopPerformance(shipments) {
        let check = 0, early = 0, late = 0, missed = 0;

        for (const load of shipments) {
            check = check + checkAudit(load)
            early = early + calculateEarly(load)
            late = late + calculateLate(load)
            missed = missed + calculateMissed(load)
        }
        const chartData = [
            ['Coverage', 'Load Tender Ct.'],
            [`Check (${check})`, check],
            [`Early (${early})`, early],
            [`Late (${late})`, late],
            [`Not Detected (${missed})`, missed],
        ];

        return chartData;
    }

    function getColorForPoint(arrive, depart) {
        if (arrive >= 0 && depart >= 0) {
            return 'green'; // early, early
        } else if (arrive < 0 && depart < 0) {
            return 'red'; // late, late
        } else if (arrive >= 0 && depart < 0) {
            return 'blue'; // in early, out late
        } else if (arrive < 0 && depart >= 0) {
            return 'orange'; // in late, out early
        }
    }

    function loadTenderStopScatterChart(shipments) {
        const goal = [0, 0, 'gold'];
        const chartData = [['Arrival', 'Arrival, Departure', { role: 'style' }], goal];
        for (const load of shipments) {
            if (load?.ediAudit && load?.ediAudit[0]?.ediStops) {
                const keys = Object.keys(load?.ediAudit[0]?.ediStops)
                if (keys.length > 0) {
                    for (const key of keys) {
                        const via = load?.ediAudit[0]?.ediStops[key]
                        const { eldOnPremiseArriveDiffMin, eldOnPremiseDepartDiffMin } = via;
                        const arrive = Number(eldOnPremiseArriveDiffMin);
                        const depart = Number(eldOnPremiseDepartDiffMin);

                        // Check if arrive and depart are valid numbers
                        if (!isNaN(arrive) && !isNaN(depart)) {
                            // Determine the color based on arrive and depart values
                            const color = getColorForPoint(arrive, depart);
                            chartData.push([arrive, depart, color]);
                        }
                    }
                }
            }
        }

        return chartData;
    }


    function loadTenderScatterChartData(shipments) {
        const chartData = [];
        for (const load of shipments) {
            if (load?.ediAudit && load?.ediAudit[0]?.ediStops) {
                const keys = Object.keys(load?.ediAudit[0]?.ediStops)
                if (keys.length > 0) {
                    for (const key of keys) {
                        const ShipmentId = load?.ediAudit[0]?.ShipmentId;
                        const Contract = load?.ediAudit[0]?.Contract;
                        const Trip = load?.ediAudit[0]?.Trip;
                        const via = load?.ediAudit[0]?.ediStops[key]
                        const { eldOnPremiseArriveDiffMin, eldOnPremiseDepartDiffMin } = via;
                        const arrive = Number(eldOnPremiseArriveDiffMin);
                        const depart = Number(eldOnPremiseDepartDiffMin);



                        // Check if arrive and depart are valid numbers
                        if (!isNaN(arrive) && !isNaN(depart)) {
                            // Determine the color based on arrive and depart values
                            chartData.push({
                                arrivalTimeDifference: arrive,
                                departureTimeDifference: depart,
                                contractNumber: Contract,
                                tripNumber: Trip,
                                shipmentId: ShipmentId
                            });
                        }
                    }
                }
            }
        }

        return chartData;
    }

    const buttonStyle = {
        fontSize: '1em',
        border: '1px solid #2b5499',
        color: '#2b5499',
        margin: '0.2em'
    }

    const selectStyle = {
        fontSize: '1em',
        border: '1px solid #2b5499',
        color: '#2b5499',
        margin: '0.2em'
    }

    const selectActiveStyle = {
        fontSize: '1em',
        border: '1px solid red',
        color: '#2b5499',
        margin: '0.2em'
    }

    function compare204Documents(doc1, doc2, keysToInclude = []) {
        const diffs = {};

        for (const key of keysToInclude) {
            if (typeof doc1[key] === 'object' && typeof doc2[key] === 'object') {
                const nestedDiffs = compare204Documents(doc1[key], doc2[key]);
                Object.assign(diffs, nestedDiffs);
            } else if (doc1[key] !== doc2[key]) {
                diffs[key] = [doc1[key], doc2[key]];
            }
        }

        return diffs;
    }

    function calculate204RateComparison(doc1, doc2, keysToInclude = []) {
        const diffs = compare204Documents(doc1, doc2, keysToInclude)

        const { Rate } = diffs

        return Rate && (Number(Rate[0]) / 100 - Number(Rate[1]) / 100).toFixed(2);
    }

    const handleSort = (key) => {
        if (sortBy === key) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortBy(key);
            setSortDirection('asc');
        }
    };



    const sortedData = [...data].sort((a, b) => {
        if (sortBy) {
            // Ensure that a[sortBy] and b[sortBy] are strings before using replace
            let aValue = typeof a[sortBy] === 'string' ? parseFloat(a[sortBy].replace(/[^\d.]/g, '')) : a[sortBy];
            let bValue = typeof b[sortBy] === 'string' ? parseFloat(b[sortBy].replace(/[^\d.]/g, '')) : b[sortBy];

            // Check if the parsed values are valid numbers
            const aValueIsValid = !isNaN(aValue);
            const bValueIsValid = !isNaN(bValue);

            // If one of the values is not a valid number, handle accordingly
            if (!aValueIsValid && !bValueIsValid) return 0; // Both values are not valid numbers
            if (!aValueIsValid) return 1; // aValue is not a valid number
            if (!bValueIsValid) return -1; // bValue is not a valid number

            // Perform numerical comparison
            if (aValue < bValue) {
                return sortDirection === 'asc' ? -1 : 1;
            }
            if (aValue > bValue) {
                return sortDirection === 'asc' ? 1 : -1;
            }
        }

        return 0; // Default: no sorting
    });


    const reportOptions = [
        { value: 'All', label: 'All Records' },
        { value: 'Problems', label: 'Problems' },
        { value: 'No Coverage', label: 'No Coverage' },
        { value: 'No EDI214 Updates', label: 'No EDI214 Updates' },
    ];

    const contractOptions = [
        { value: 'All', label: 'All Contracts' },
        { value: '_TOPS_', label: 'USPS FA' },
        ...contracts.map(contract => ({ value: contract, label: contract }))
    ];

    // const driverOptions = [
    //     { value: 'All', label: 'All Drivers' },
    //     ...drivers.sort((a, b) => a.localeCompare(b)).map(driver => ({
    //         value: driver,
    //         label: driver,
    //     }))
    // ];


    return (
        <Container fluid style={{ margin: '0', padding: '0' }}>
            <AlertDisplay alertState={alertState} />
            <Card style={{ margin: '0', padding: '0', borderRadius: '0', border: 0 }}>
                <Card.Header>
                    <Row style={{ alignItems: 'center', }}>
                        <Col>
                            <b> {`Load Tender ${ReportType}`.toUpperCase()}</b>
                        </Col>
                        <Col xs="auto">
                            <button onClick={() => setView('graph')}>Graph</button>
                            {/* {ReportType === 'accounting' &&
                                <button onClick={() => setView('summary')}>Summary</button>
                            } */}
                            <button onClick={() => setView('table')}>Table</button>
                        </Col>
                        <Col xs="auto">
                            Records: {data ? data.length : 0}
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Header>
                    <Row style={{ alignItems: 'center', }}>
                        <Col xs="auto" style={{ textAlign: 'center' }}>
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>
                                    Days in view.
                                </Tooltip>}
                            >
                                <span style={{ margin: '0 1em' }}>Period: ({((new Date(query?.endDate) - new Date(query?.startDate)) / (24 * 60 * 60 * 1000)) + 1}) Days</span>
                            </OverlayTrigger>
                            <Row style={{ textAlign: 'center' }}>
                                <Col style={{ margin: 0, padding: 0 }}>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip>
                                            Go backwards a week
                                        </Tooltip>}
                                    >
                                        <button style={buttonStyle} onClick={() => subtractWeek()}><MdKeyboardDoubleArrowLeft /></button>
                                    </OverlayTrigger>
                                </Col>
                                <Col style={{ margin: 0, padding: 0 }}>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip>
                                            Go backwards a day
                                        </Tooltip>}
                                    >
                                        <button style={buttonStyle} onClick={() => subtractDay()}><MdArrowBackIos /></button>
                                    </OverlayTrigger>
                                </Col>
                                <Col style={{ margin: 0, padding: 0 }}>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip>
                                            Go to today
                                        </Tooltip>}
                                    >
                                        <button style={buttonStyle} onClick={() => resetDays()}><IoTodayOutline /></button>
                                    </OverlayTrigger>
                                </Col>
                                <Col style={{ margin: 0, padding: 0 }}>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip>
                                            Go forward a day
                                        </Tooltip>}
                                    >
                                        <button style={buttonStyle} onClick={() => addDay()}><MdArrowForwardIos /></button>
                                    </OverlayTrigger>
                                </Col>
                                <Col style={{ margin: 0, padding: 0 }}>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip>
                                            Go forward a week
                                        </Tooltip>}
                                    >
                                        <button style={buttonStyle} onClick={() => addWeek()}><MdKeyboardDoubleArrowRight /></button>
                                    </OverlayTrigger>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs="auto">
                            <Row style={{ alignItems: 'center', flexWrap: 'nowrap' }}>
                                <Col xs="auto" style={{ marginRight: 0, paddingRight: 0 }}>
                                    <span style={{ fontSize: '0.75em', display: 'inline-block', transform: 'rotate(-90deg)' }}>Start</span>
                                </Col>
                                <Col style={{ marginLeft: 0, paddingLeft: 0 }}>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip>
                                            Start date
                                        </Tooltip>}
                                    >
                                        <Form.Control type="date" style={selectStyle} value={query.startDate} onChange={e => setQuery({ ...query, startDate: e.target.value })}  />
                                    </OverlayTrigger>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs="auto">
                            <Row style={{ alignItems: 'center', flexWrap: 'nowrap' }} >
                                <Col xs="auto" style={{ marginRight: 0, paddingRight: 0 }}>
                                    <span style={{ fontSize: '0.75em', display: 'inline-block', transform: 'rotate(-90deg)' }}>End</span>
                                </Col>
                                <Col style={{ marginLeft: 0, paddingLeft: 0 }}>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip>
                                            End date
                                        </Tooltip>}
                                    >
                                        <Form.Control type="date" style={selectStyle} value={query.endDate} onChange={e => setQuery({ ...query, endDate: e.target.value })}  />
                                    </OverlayTrigger>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs="auto">
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>
                                    Filter by report
                                </Tooltip>}
                            >
                                <Select
                                    value={reportOptions.find(option => option.value === query.report)}
                                    onChange={selectedOption => setQuery({ ...query, report: selectedOption.value })}
                                    options={reportOptions.sort((a, b) => a.label.localeCompare(b.label))}
                                    styles={{
                                        control: styles => ({ ...styles, ...(query.report === 'All' ? selectStyle : selectActiveStyle) }),
                                    }}
                                />
                            </OverlayTrigger>
                        </Col>
                        <Col xs="auto">
                            {!contractsFetched ?
                                <Spinner size='sm' />
                                :
                                <>
                                    <>
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip>
                                                Filter by contract
                                            </Tooltip>}
                                        >
                                            <Select
                                                value={contractOptions.find(option => option.value === query.contract)}
                                                onChange={selectedOption => setQuery({ ...query, contract: selectedOption.value })}
                                                options={contractOptions.sort((a, b) => a.label.localeCompare(b.label))}
                                                styles={{
                                                    control: styles => ({ ...styles, ...(query.contract === 'All' ? selectStyle : selectActiveStyle) }),
                                                }}
                                            />
                                        </OverlayTrigger>
                                    </>
                                </>}
                        </Col>
                        {/* <Col xs="auto">
                            {!driversFetched ?
                                <Spinner size='sm' />
                                :
                                <>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip>
                                            Filter by drivers
                                        </Tooltip>}
                                    >
                                        <Select
                                            value={driverOptions.find(option => option.value === query.driver) || driverOptions[0]}
                                            onChange={selectedOption => {
                                                if (selectedOption.value === 'All') {
                                                    setQuery({ ...query, driver: 'All' });
                                                } else {
                                                    setQuery({ ...query, driver: selectedOption.value });
                                                }
                                            }}


                                            options={driverOptions}
                                            styles={{
                                                control: (styles) => ({
                                                    ...styles,
                                                    ...(query.driver === 'All' ? selectStyle : selectActiveStyle),
                                                }),
                                            }}
                                        />
                                    </OverlayTrigger>
                                </>}
                        </Col> */}
                        <Col xs="auto">
                            <Dropdown>
                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                    <FaFileExport />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => generateExcel(printableData, `MCTMS_Load_Tender_Report_Contract_${query.contract}_${query.startDate}_${query.endDate}`)}><BsFiletypeXlsx />Export to Excel</Dropdown.Item>
                                    <Dropdown.Item onClick={() => generatePDF(printableData, `MCTMS_Load_Tender_Report_Contract_${query.contract}_${query.startDate}_${query.endDate}`)}><BsFiletypePdf />Export to PDF</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body>
                    {!set ?
                        <LogisticsLoading message={'Loading report...'} />
                        :
                        data.length > 0 ?
                            <>
                                {view === 'table' &&
                                    <pre>
                                        <Table responsive striped>
                                            <thead>
                                                <tr style={{ fontWeight: 'bold' }}>
                                                    <td style={cellCenterStyle}></td>
                                                    <td style={cellCenterStyle}>
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={<Tooltip id="tooltip-top">
                                                                Count of ShipmentIds
                                                            </Tooltip>}
                                                        >
                                                            <span>Shipments</span>
                                                        </OverlayTrigger>
                                                    </td>
                                                    <td style={cellCenterStyle}></td>
                                                    {ReportType === 'performance' && <td style={cellCenterStyle}>Coverage</td>}
                                                    {/* {ReportType === 'performance' && <td style={cellCenterStyle}>Vehicles</td>} */}
                                                    <td style={cellCenterStyle}>First Start</td>
                                                    <td style={cellCenterStyle}>Last End</td>
                                                    <td style={cellRightStyle}>
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={<Tooltip id="tooltip-top">
                                                                Sum of SOP Time
                                                            </Tooltip>}
                                                        >
                                                            <span>Sum HH:MM</span>
                                                        </OverlayTrigger>
                                                    </td>
                                                    {/* {ReportType === 'performance' &&
                                                        <td style={cellRightStyle}>
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={<Tooltip id="tooltip-top">
                                                                    Sum of labor hours
                                                                </Tooltip>}
                                                            >
                                                                <span>Sum HH:MM</span>
                                                            </OverlayTrigger>
                                                        </td>
                                                    } */}
                                                    {ReportType === 'accounting' && <td style={cellRightStyle}>
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={<Tooltip id="tooltip-top">
                                                                Sum of original rate
                                                            </Tooltip>}
                                                        >
                                                            <span>Sum Org Rate</span>
                                                        </OverlayTrigger>
                                                    </td>}
                                                    {ReportType === 'accounting' && <td style={cellRightStyle}>
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={<Tooltip id="tooltip-top">
                                                                Sum of adjusted rate
                                                            </Tooltip>}
                                                        >
                                                            <span>Sum Adj Rate</span>
                                                        </OverlayTrigger>
                                                    </td>}
                                                    {ReportType === 'accounting' && <td style={cellRightStyle}>
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={<Tooltip id="tooltip-top">
                                                                Sum of Rate Change
                                                            </Tooltip>}
                                                        >
                                                            <span>Sum R/C</span>
                                                        </OverlayTrigger>
                                                    </td>}
                                                    {ReportType === 'accounting' && <td style={cellRightStyle}>
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={<Tooltip id="tooltip-top">
                                                                Sum of Paid Fuel
                                                            </Tooltip>}
                                                        >
                                                            <span>Sum Pd Fuel</span>
                                                        </OverlayTrigger>
                                                    </td>}

                                                    {ReportType === 'accounting' && <td style={cellRightStyle}>
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={<Tooltip id="tooltip-top">
                                                                Sum of Fuel Used
                                                            </Tooltip>}
                                                        >
                                                            <span>Sum Fuel Ud</span>
                                                        </OverlayTrigger>
                                                    </td>}
                                                    {ReportType === 'accounting' && <td style={cellRightStyle}>
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={<Tooltip id="tooltip-top">
                                                                Sum of Rate
                                                            </Tooltip>}
                                                        >
                                                            <span>Fuel Cost</span>
                                                        </OverlayTrigger>
                                                    </td>}
                                                    {ReportType === 'accounting' && <td style={cellRightStyle}>
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={<Tooltip id="tooltip-top">
                                                                Sum of driver wages
                                                            </Tooltip>}
                                                        >
                                                            <span>Wages</span>
                                                        </OverlayTrigger>
                                                    </td>}
                                                    {ReportType === 'accounting' && <td style={cellRightStyle}>
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={<Tooltip id="tooltip-top">
                                                                Sum of expenses, rate confirmations, etc.
                                                            </Tooltip>}
                                                        >
                                                            <span>Expenses</span>
                                                        </OverlayTrigger>
                                                    </td>}
                                                    {ReportType === 'accounting' && <td style={cellRightStyle}>
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={<Tooltip id="tooltip-top">
                                                                Net profit based on previous column deductions.
                                                            </Tooltip>}
                                                        >
                                                            <span>Net</span>
                                                        </OverlayTrigger>
                                                    </td>}
                                                    {ReportType === 'accounting' && <td style={cellRightStyle}>
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={<Tooltip id="tooltip-top">
                                                                Average margin = rate / net
                                                            </Tooltip>}
                                                        >
                                                            <span>Average</span>
                                                        </OverlayTrigger>
                                                    </td>}
                                                    {ReportType === 'performance' && <td style={cellCenterStyle}>
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={<Tooltip id="tooltip-top">
                                                                This is average 204 received per shipment.
                                                            </Tooltip>}
                                                        >
                                                            <span>204 Avg</span>
                                                        </OverlayTrigger>
                                                    </td>}
                                                    {ReportType === 'performance' && <td style={cellCenterStyle}>
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={<Tooltip id="tooltip-top">
                                                                This is a count of 204s received for a shipment.
                                                            </Tooltip>}
                                                        >
                                                            <span>Stop Ct</span>
                                                        </OverlayTrigger>
                                                    </td>}
                                                    {ReportType === 'performance' && <td style={cellCenterStyle}>
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={<Tooltip id="tooltip-top">
                                                                This orange cells should be checked for accuracy. Wrong driver, not logged in, etc.
                                                            </Tooltip>}
                                                        >
                                                            <span>Check</span>
                                                        </OverlayTrigger>
                                                    </td>}
                                                    {ReportType === 'performance' && <td style={cellCenterStyle}>
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={<Tooltip id="tooltip-top">
                                                                Green cells indicate that a driver arrived early to a stop.
                                                            </Tooltip>}
                                                        >
                                                            <span>On Time</span>
                                                        </OverlayTrigger>
                                                    </td>}
                                                    {ReportType === 'performance' && <td style={cellCenterStyle}>
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={<Tooltip id="tooltip-top">
                                                                Yellow cells indicate that a driver arrived late to a stop.
                                                            </Tooltip>}
                                                        >
                                                            <span>Late</span>
                                                        </OverlayTrigger>
                                                    </td>}
                                                    {ReportType === 'performance' && <td style={cellCenterStyle}>
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={<Tooltip id="tooltip-top">
                                                                Red cells indicate that a driver was not detected at a stop. This could be issues like bad geofence, deadzone, etc.
                                                            </Tooltip>}
                                                        >
                                                            <span>Not Detected</span>
                                                        </OverlayTrigger>
                                                    </td>}
                                                    {ReportType === 'performance' && <td style={cellCenterStyle}>
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={<Tooltip id="tooltip-top">
                                                                This percentage represents the total percentage of shipment tracked.
                                                            </Tooltip>}
                                                        >
                                                            <span>Tracking</span>
                                                        </OverlayTrigger>
                                                    </td>}
                                                    {/* {ReportType === 'performance' && <td style={cellCenterStyle}>
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={<Tooltip id="tooltip-top">
                                                                This percentage represents the total percentage of shipment updates reported to Blue Yonder.
                                                            </Tooltip>}
                                                        >
                                                            <span>Blue Yonder</span>
                                                        </OverlayTrigger>
                                                    </td>} */}
                                                    {/* {ReportType === 'performance' && <td style={cellCenterStyle}>
                                                        <OverlayTrigger
                                                            placement="top"
                                                            overlay={<Tooltip id="tooltip-top">
                                                                This percentage represents the total percentage of shipment tracking reported to Fourkites.
                                                            </Tooltip>}
                                                        >
                                                            <span>Fourkites</span>
                                                        </OverlayTrigger>
                                                    </td>} */}
                                                </tr>
                                                <tr style={{ fontWeight: 'bold' }}>
                                                    <td style={cellCenterStyle}></td>
                                                    <td style={cellCenterStyle}>{data.length}</td>
                                                    {ReportType === 'performance' && <td style={cellCenterStyle}></td>}
                                                    {ReportType === 'performance' && <td style={cellCenterStyle}>{((data.reduce((a, b) => a + (b.coverage.length > 0 ? 1 : 0), 0) / data.length) * 100).toFixed(1)}%</td>}
                                                    {/* <td style={cellCenterStyle}></td> */}
                                                    <td style={cellCenterStyle}>{formatDateStrToMMDDYYHHMM(data[0].StartTime)}</td>
                                                    <td style={cellCenterStyle}>{formatDateStrToMMDDYYHHMM(data[data.length - 1].EndTime)}</td>
                                                    <td style={cellRightStyle}>{millisecondsToHHMM(data.reduce((a, b) => a + b.SOPmilli, 0))}</td>
                                                    {/* {ReportType === 'performance' &&
                                                        <td style={cellRightStyle}></td>
                                                    } */}
                                                    {ReportType === 'accounting' && <td style={cellRightStyle}>{formatCentsToDollars(data.reduce((a, b) => a + Number(b.Rate), 0))}</td>}
                                                    {ReportType === 'accounting' && <td style={cellRightStyle}>
                                                        {formatCentsToDollars(data.reduce((a, load) => a + Number(load.edi204[load.edi204.length - 1].Rate), 0))}
                                                    </td>}
                                                    {ReportType === 'accounting' && <td style={cellRightStyle}>
                                                        {
                                                            formatCentsToDollars(data.reduce((a, load) => a + Number(load.edi204[load.edi204.length - 1].Rate), 0) - data.reduce((a, b) => a + Number(b.Rate), 0))
                                                        }
                                                    </td>}
                                                    {ReportType === 'performance' && <td style={cellCenterStyle}>{((data.reduce((a, b) => a + (b.edi204.length), 0) / data.length)).toFixed(1)}</td>}
                                                    {ReportType === 'performance' && <td style={cellCenterStyle}>{((data.reduce((a, b) => a + Number(b.edi204[0].Stops.length), 0)))}</td>}
                                                    {ReportType === 'performance' && <td style={cellCenterStyle}>{(((data.reduce((a, b) => a + checkAudit(b), 0))) / ((data.reduce((a, b) => a + Number(b.edi204[0].Stops.length), 0))) * 100).toFixed(1)}%</td>}
                                                    {ReportType === 'performance' && <td style={cellCenterStyle}>{(((data.reduce((a, b) => a + calculateEarly(b), 0))) / ((data.reduce((a, b) => a + Number(b.edi204[0].Stops.length), 0))) * 100).toFixed(1)}%</td>}
                                                    {ReportType === 'performance' && <td style={cellCenterStyle}>{(((data.reduce((a, b) => a + calculateLate(b), 0))) / ((data.reduce((a, b) => a + Number(b.edi204[0].Stops.length), 0))) * 100).toFixed(1)}%</td>}
                                                    {ReportType === 'performance' && <td style={cellCenterStyle}>{(((data.reduce((a, b) => a + calculateMissed(b), 0))) / ((data.reduce((a, b) => a + Number(b.edi204[0].Stops.length), 0))) * 100).toFixed(1)}%</td>}
                                                    {ReportType === 'performance' && <td style={cellCenterStyle}>
                                                        {
                                                            ((((data.reduce((a, b) => a + calculateEarly(b), 0))) / ((data.reduce((a, b) => a + Number(b.edi204[0].Stops.length), 0))) * 100) +
                                                                (((data.reduce((a, b) => a + calculateLate(b), 0))) / ((data.reduce((a, b) => a + Number(b.edi204[0].Stops.length), 0))) * 100)).toFixed(1)
                                                        }%
                                                    </td>}
                                                    {/* {ReportType === 'performance' && <td style={cellCenterStyle}>{((data.reduce((a, b) => a + (b.edi214.length > 0 ? 1 : 0), 0) / data.length) * 100).toFixed(1)}%</td>} */}
                                                    {/* {ReportType === 'performance' && <td style={cellCenterStyle}>{((data.reduce((a, b) => a + (b.fourkiteShipmentUpdates.length > 0 ? 1 : 0), 0) / data.length) * 100).toFixed(1)}%</td>} */}
                                                </tr>
                                            </thead>
                                            <thead style={{ fontWeight: 'bold' }}>
                                                <tr>
                                                    <th style={headerCenterStyle}>#</th>
                                                    <th style={headerCenterStyle} onClick={() => handleSort('ShipmentId')}>Shipment Id</th>
                                                    <th style={headerCenterStyle} onClick={() => handleSort('Contract')}>Contract-Trip</th>
                                                    {ReportType === 'performance' && <th style={headerCenterStyle} onClick={() => handleSort('Coverage')}>Coverage</th>}
                                                    {/* {ReportType === 'performance' && <th style={headerCenterStyle} onClick={() => handleSort('Vehicles')}>Vehicles</th>} */}
                                                    <th style={headerCenterStyle} onClick={() => handleSort('StartTime')}>Start Date</th>
                                                    <th style={headerCenterStyle} onClick={() => handleSort('EndTime')}>End Date</th>
                                                    <th style={headerRightStyle} onClick={() => handleSort('SOPmilli')}>SOP Time</th>
                                                    {/* {ReportType === 'performance' &&
                                                        <th style={headerRightStyle}>Ops Time</th>
                                                    } */}
                                                    {ReportType === 'accounting' && <th style={headerRightStyle} onClick={() => handleSort('Rate')}>Org Rate</th>}
                                                    {ReportType === 'accounting' && <th style={headerRightStyle}>Adj Rate</th>}
                                                    {ReportType === 'accounting' && <th style={headerRightStyle}>Rate Change</th>}
                                                    {ReportType === 'accounting' && <th style={headerRightStyle}>Paid Fuel</th>}
                                                    {ReportType === 'accounting' && <th style={headerRightStyle}>Fuel Used</th>}
                                                    {ReportType === 'accounting' && <th style={headerRightStyle}>Fuel Cost</th>}
                                                    {ReportType === 'accounting' && <th style={headerRightStyle}>Wages</th>}
                                                    {ReportType === 'accounting' && <th style={headerRightStyle}>Expenses</th>}
                                                    {ReportType === 'accounting' && <th style={headerRightStyle}>Net</th>}
                                                    {ReportType === 'accounting' && <th style={headerRightStyle}>Margin</th>}
                                                    {ReportType === 'performance' && <th style={headerCenterStyle}>204 Versions</th>}
                                                    {ReportType === 'performance' && <th style={headerCenterStyle}>Stops</th>}
                                                    {ReportType === 'performance' && <th style={headerCenterStyle}>Check</th>}
                                                    {ReportType === 'performance' && <th style={headerCenterStyle}>OnTime</th>}
                                                    {ReportType === 'performance' && <th style={headerCenterStyle}>Late</th>}
                                                    {ReportType === 'performance' && <th style={headerCenterStyle}>Not Detected</th>}
                                                    {ReportType === 'performance' && <th style={headerCenterStyle}>Tracking</th>}
                                                    {/* {ReportType === 'performance' && <th style={headerCenterStyle}>214 Updates</th>} */}
                                                    {/* {ReportType === 'performance' && <th style={headerCenterStyle}>Fourkite Updates</th>} */}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {sortedData.map((load, i) => (
                                                    <tr key={i} style={i % 2 === 0 ? rowEvenStyle : rowOddStyle}>
                                                        <td>{i + 1}</td>
                                                        <td style={cellCenterStyle}>
                                                            <Link to={`/loadtender/${load.ShipmentId}`} target='_blank'>
                                                                {load.ShipmentId}
                                                            </Link>
                                                        </td>
                                                        <td style={cellCenterStyle}>{load.Contract}-{load.Trip}</td>
                                                        {ReportType === 'performance' &&
                                                            <td style={cellCenterStyle}>
                                                                <pre>
                                                                    {load.coverage.flatMap(operator => operator.name).join('\n')}
                                                                </pre>
                                                            </td>}
                                                        {/* {ReportType === 'performance' &&
                                                            <td style={cellCenterStyle}>
                                                                <pre>
                                                                </pre>
                                                            </td>} */}
                                                        <td style={cellCenterStyle}>{formatDateStrToMMDDYYHHMM(load.StartTime)}</td>
                                                        <td style={cellCenterStyle}>{formatDateStrToMMDDYYHHMM(load.EndTime)}</td>
                                                        <td style={cellRightStyle}>{millisecondsToHHMM(load.SOPmilli)}</td>
                                                        {/* {ReportType === 'performance' &&
                                                            <td style={cellRightStyle}></td>
                                                        } */}
                                                        {ReportType === 'accounting' && <td style={cellRightStyle}>{formatCentsToDollars(load.Rate)}</td>}
                                                        {ReportType === 'accounting' && <td style={cellRightStyle}>{formatCentsToDollars(load.edi204[load.edi204.length - 1].Rate)}</td>}
                                                        {ReportType === 'accounting' && <td style={cellRightStyle}>
                                                            {
                                                                (load.edi204 && load.edi204.map((_, index, array) => (
                                                                    (array.length > 1 && index < array.length - 1)
                                                                        ? calculate204RateComparison(array[index + 1], array[index], ['Rate'])
                                                                        : 0
                                                                )))
                                                            }
                                                        </td>}
                                                        {ReportType === 'accounting' && <td style={cellRightStyle}>-</td>}
                                                        {ReportType === 'accounting' && <td style={cellRightStyle}>-</td>}
                                                        {ReportType === 'accounting' && <td style={cellRightStyle}>-</td>}
                                                        {ReportType === 'accounting' && <td style={cellRightStyle}>-</td>}
                                                        {ReportType === 'accounting' && <td style={cellRightStyle}>-</td>}
                                                        {ReportType === 'accounting' && <td style={cellRightStyle}>-</td>}
                                                        {ReportType === 'accounting' && <td style={cellRightStyle}>-</td>}
                                                        {ReportType === 'performance' && <td style={cellCenterStyle}>{load.edi204.length}</td>}
                                                        {ReportType === 'performance' && <td style={cellCenterStyle}>{load.edi204[0].Stops.length}</td>}
                                                        {ReportType === 'performance' && <td style={checkAudit(load) > 0 ? callCenterOrangeStyle : cellCenterStyle}>{checkAudit(load)}</td>}
                                                        {ReportType === 'performance' && <td style={calculateEarly(load) > 0 ? cellCenterStyleGreen : cellCenterStyle}>{calculateEarly(load)}</td>}
                                                        {ReportType === 'performance' && <td style={calculateLate(load) > 0 ? cellCenterStyleYellow : cellCenterStyle}>{calculateLate(load)}</td>}
                                                        {ReportType === 'performance' && <td style={calculateMissed(load) > 0 ? cellCenterStyleRed : cellCenterStyle}>{calculateMissed(load)}</td>}
                                                        {ReportType === 'performance' && <td style={cellCenterStyle}>
                                                            {calculateEarly(load) + calculateLate(load)}
                                                        </td>}
                                                        {/* {ReportType === 'performance' && <td style={cellCenterStyle}>{load.edi214.length}</td>} */}
                                                        {/* {ReportType === 'performance' && <td style={cellCenterStyle}>{load.fourkiteShipmentUpdates.length}</td>} */}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </pre>
                                }
                                {view === 'graph' &&
                                    <Row>
                                        <Col xs="auto">
                                            <ScatterPlot redirect={'/loadtender'} data={loadTenderScatterChartData(data)} width={400} height={300} />
                                        </Col>
                                        <Col xs="auto">
                                            <Chart
                                                chartType={'PieChart'}
                                                data={loadTenderStopPerformance(data)}
                                                options={{
                                                    title: `Load Tender Stop Performance`,
                                                    colors: ['orange', 'green', 'yellow', 'red'],
                                                }}
                                                width="500px"
                                                height="500px"
                                            />
                                        </Col>
                                        <Col xs="auto">
                                            <Chart
                                                chartType={'PieChart'}
                                                data={loadTenderCoverageChart(data)}
                                                options={{
                                                    title: `Load Tender Coverage`,
                                                    colors: ['orange', 'green'],
                                                }}
                                                width="500px"
                                                height="500px"
                                            />
                                        </Col>

                                    </Row>
                                }
                                {view === 'summary' &&
                                    <Row>
                                        <Col>
                                            <LoadTenderFinancialSummary
                                                financialSummary={{
                                                    uniqueLoads: data?.length || 0,
                                                    grossRevenue: formatCentsToDollars(data.reduce((a, b) => a + Number(b.Rate), 0)),
                                                    grossRateChanges:
                                                        data.reduce((accumulator, load) => (
                                                            accumulator + (load.edi204 ? load.edi204.map((_, index, array) => (
                                                                (array.length > 1 && index < array.length - 1)
                                                                    ? calculate204RateComparison(array[index + 1], array[index], ['Rate'])
                                                                    : 0
                                                            )).reduce((a, b) => a + Number(b) || 0) : 0)
                                                        ), 0).toFixed(2)
                                                    ,
                                                    netRevenue: 1,
                                                    wageSum: 1,
                                                    rateconSum: 1,
                                                    fuelSum: 1,
                                                    fuelGallons: 1,
                                                    fuelCpg: 1,
                                                    otherSum: 1,
                                                    margin: 1
                                                }}
                                                customerContractSummary={[
                                                    {
                                                        customer: 'John Doe',
                                                        contract: '123456',
                                                        tripCount: 10,
                                                        gross: 1000,
                                                        wageSum: 500,
                                                        rateconSum: 500,
                                                        fuelSum: 200,
                                                        otherSum: 100,
                                                        estNetRevenue: 200,
                                                        margin: 5
                                                    },
                                                    {
                                                        customer: 'Jane Doe',
                                                        contract: '654321',
                                                        tripCount: 5,
                                                        gross: 500,
                                                        wageSum: 850,
                                                        rateconSum: 550,
                                                        fuelSum: 300,
                                                        otherSum: 150,
                                                        estNetRevenue: 100,
                                                        margin: 10
                                                    },
                                                ]}
                                            />
                                        </Col>
                                    </Row>
                                }
                            </>
                            :
                            <h3>No data</h3>
                    }
                </Card.Body>
                {/* <pre>
                    {data && JSON.stringify(data[0], "", 2)}
                </pre> */}
            </Card>
        </Container>
    )
}

export default LoadTenderReports;