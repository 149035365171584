import { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, OverlayTrigger, Row, Spinner, Tooltip } from "react-bootstrap";
import AlertDisplay from "../../components/AlertDisplay";
import { useAuth } from "../../context/auth";
import Api from "../../utils/Api";
import { Link, useParams } from "react-router-dom";
import { FaInfoCircle } from "react-icons/fa";


const initialCompany = {
    name: '',
    address: '',
    address1: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    phone: '',
    email:'',
    scac: '',
    dotNumber: '',
    mcNumber: '',
    samsaraApiKey: '',
    fourkitesApiKey: '',
    breakthroughfuelUsername: '',
    breakthroughfuelPassword: '',
}

function Company({ method }) {
    const { idToken } = useAuth();
    const { id } = useParams();
    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts };
    const [didFetch, setDidFetch] = useState();
    const [company, setCompany] = useState(initialCompany);
    const [companyNotFound, setCompanyNotFound] = useState();

    async function handleCreate() {
        try {
            await Api.post('/companies', company, idToken);
            setAlerts([...alerts, { variant: 'success', message: 'Created', }])
            setCompany(initialCompany)
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured', }])
        }
    }

    async function handleUpdate() {
        try {
            await Api.patch(`/company/${id}`, company, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Updated', }])
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured', }])
        }
    }

    async function fetchCompany() {
        try {
            const data = await Api.get(`/company/${id}`, idToken);
            setCompany(data)
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured fetching company.' }])
            setCompanyNotFound(true)
        } finally {
            setDidFetch(true)
        }
    }

    useEffect(() => {
        if (id && !didFetch) {
            fetchCompany()
        }
    }, [id, didFetch])

    return (
        <Container fluid style={{ margin: 0, padding: 0 }}>
            <AlertDisplay alertState={alertState} />
            <Card style={{ border: 0, borderRadius: 0 }}>
                <Card.Header>
                    <Card.Title>
                        {`${method} Company`.toUpperCase()}
                    </Card.Title>
                </Card.Header>
                {companyNotFound ?
                    <Card.Body>
                        Company Not Found <Link to={'/data/company'} onClick={() => setCompanyNotFound(false)}>Create Company</Link>
                    </Card.Body>
                    :
                    <>
                        <Card.Body>
                            <Row>
                                <Col>
                                    Company Name
                                    <Form.Control
                                        placeholder="Name"
                                        value={company.name}
                                        onChange={(e) => setCompany({ ...company, name: e.target.value })}
                                    />
                                </Col>
                                <Col>
                                    Email
                                    <Form.Control
                                        type="email"
                                        placeholder="Email"
                                        value={company.email}
                                        onChange={(e) => setCompany({ ...company, email: e.target.value })}
                                    />
                                </Col>
                                <Col>
                                    Phone
                                    <Form.Control
                                        type="telephone"
                                        placeholder="Phone"
                                        value={company.phone}
                                        onChange={(e) => setCompany({ ...company, phone: e.target.value })}
                                    />
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col xs="12" lg="8">
                                    Address
                                    <Form.Control
                                        placeholder="Address"
                                        value={company.address1}
                                        onChange={(e) => setCompany({ ...company, address1: e.target.value })}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12" md="6" lg="2">
                                    City
                                    <Form.Control
                                        placeholder="City"
                                        value={company.city}
                                        onChange={(e) => setCompany({ ...company, city: e.target.value })}
                                    />
                                </Col>
                                <Col xs="12" md="6" lg="2">
                                    State
                                    <Form.Control
                                        placeholder="State"
                                        value={company.state}
                                        onChange={(e) => setCompany({ ...company, state: e.target.value })}
                                    />
                                </Col>
                                <Col xs="12" md="6" lg="2">
                                    Zip
                                    <Form.Control
                                        placeholder="Zip"
                                        value={company.zip}
                                        onChange={(e) => setCompany({ ...company, zip: e.target.value })}
                                    />
                                </Col>
                                <Col xs="12" md="6" lg="2">
                                    Country
                                    <Form.Control
                                        placeholder="Country"
                                        value={company.country}
                                        onChange={(e) => setCompany({ ...company, country: e.target.value })}
                                    />
                                </Col>
                            </Row>
                            <br />
                            <Row style={{ alignItems: 'center' }}>
                                <Col xs="auto">
                                    <Col xs="auto">
                                        DOT Number       
                                        {company?.dotNumber.length === 7 &&
                                            <a target="_blank" without rel="noreferrer" href={`https://safer.fmcsa.dot.gov/query.asp?searchtype=ANY&query_type=queryCarrierSnapshot&query_param=USDOT&query_string=${company.dotNumber}`}>
                                               FMCSA Safer
                                            </a>
                                        }
                                        <Form.Control
                                            placeholder="DOT Number"
                                            value={company.dotNumber}
                                            onChange={(e) => setCompany({ ...company, dotNumber: e.target.value })}
                                        />
                                    </Col>

                                </Col>
                                <Col xs="auto">
                                    MC Number
                                    <Form.Control
                                        placeholder="MC Number"
                                        value={company.mcNumber}
                                        onChange={(e) => setCompany({ ...company, mcNumber: e.target.value })}
                                    />
                                </Col>
                                <Col xs="auto">
                                    SCAC
                                    <Form.Control
                                        placeholder="SCAC"
                                        value={company.scac}
                                        onChange={(e) => setCompany({ ...company, scac: e.target.value })}
                                    />
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col xs="auto">
                                    Samsara API Key
                                    <Form.Control
                                        placeholder="Samsara API Key"
                                        value={company.samsaraApiKey}
                                        onChange={(e) => setCompany({ ...company, samsaraApiKey: e.target.value })}
                                    />
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col xs="auto">
                                    Fourkites API Key
                                    <Form.Control
                                        placeholder="Fourkites API Key"
                                        value={company.fourkitesApiKey}
                                        onChange={(e) => setCompany({ ...company, fourkitesApiKey: e.target.value })}
                                    />
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col xs="auto">
                                    Blue Yonder API Key
                                    <Form.Control
                                        placeholder="Blue Yonder API Key"
                                        value={company.blueyonderApiKey}
                                        onChange={(e) => setCompany({ ...company, blueyonderApiKey: e.target.value })}
                                    />
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col xs="auto">
                                    Kleinschmidt API Key
                                    <Form.Control
                                        placeholder="Kleinschmidt API Key"
                                        value={company.kleinschmidtApiKey}
                                        onChange={(e) => setCompany({ ...company, kleinschmidtApiKey: e.target.value })}
                                    />
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col xs="auto">
                                    LoadCnxn API Key
                                    <Form.Control
                                        placeholder="Lambda Load Tender API Key"
                                        value={company.loadcxnxApiKey}
                                        onChange={(e) => setCompany({ ...company, loadcxnxApiKey: e.target.value })}
                                    />
                                </Col>
                            </Row>
                        </Card.Body>
                        <Card.Body>
                            {method === 'create' && <Button onClick={() => handleCreate()}>Create</Button>}
                            {method === 'update' &&
                                <Row>
                                    <Col xs="auto">
                                        <Button variant="primary" onClick={() => handleUpdate()}>Update</Button>
                                    </Col>
                                    <Col xs="auto">
                                        <OverlayTrigger
                                            placement="top"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={
                                                <Tooltip id="button-tooltip">
                                                    Active - determines whether or not the record should be
                                                    used by the software application.
                                                </Tooltip>
                                            }
                                        >
                                            <div>
                                                Active <FaInfoCircle /> : <Button variant={company.isActive ? 'success' : 'danger'} onClick={() => { setCompany({ ...company, isActive: (company.isActive ? false : true) }) }}>{company.isActive ? 'True' : 'False'}</Button>
                                            </div>
                                        </OverlayTrigger>
                                    </Col>
                                </Row>

                            }
                        </Card.Body>
                    </>
                }
            </Card>
        </Container>
    )
}

export default Company;