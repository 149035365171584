import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

export const D3PieChart = ({ data }) => {

    useEffect(() => {
        const filteredData = {
            wageSum: data?.wageSum,
            rateconSum: data?.rateconSum,
            fuelSum: data?.fuelSum,
            otherSum: data?.otherSum
        }
        // const filteredData = [
        //     { label: 'Wages', value: data[0].wageSum },
        //     { label: 'Ratecon', value: data[0].rateconSum },
        //     { label: 'Fuel', value: data[0].fuelSum },
        //     { label: 'Other', value: data[0].otherSum }
        // ];

        // Remove any existing SVG elements
        d3.select('#piechart').selectAll("*").remove();

        // Get dimensions of the container
        const width = 350;
        const height = 350;
        const margin = 60;
        const radius = Math.min(width, height) / 2 - margin;

        // Create SVG
        const svg = d3.select('#piechart')
            .append("svg")
                .attr("width", width)
                .attr("height", height)
            .append("g")
                .attr("transform", `translate(${width / 2}, ${height / 2})`);

        // Color scale
        const color = d3.scaleOrdinal()
            // .domain(filteredData.map(d => d.label))
            .range(["#1f77b4", "#aec7e8", "#6baed6", "#3182bd", "#08519c"]);

        // Pie function
        const pie = d3.pie()
            .value(function(d) { return d[1]; });
        const data_ready = pie(Object.entries(filteredData));

        // Create pie chart
        const arcGenerator = d3.arc()
            .innerRadius(0)
            .outerRadius(radius);

        svg
            .selectAll('slices')  
            .data(data_ready)
            .join('path')
                .attr('d', arcGenerator)
                .attr('fill', function(d){ return(color(d.data[0])) })
                .attr("stroke", "black")
                .style("stroke-width", "2px")
                .style("opacity", 0.7);

        // Add text labels outside the pie chart slices
        svg
            .selectAll('slices')
            .data(data_ready)
            .join('text')
                .text(function(d){ return d.data[0]})
                .attr("transform", function(d) {
                    const pos = arcGenerator.centroid(d);
                    const distance = radius * 1.3; // Adjust this factor to control label distance
                    const angle = Math.atan2(pos[1], pos[0]);
                    const x = Math.cos(angle) * distance;
                    const y = Math.sin(angle) * distance;
                    return `translate(${x}, ${y})`;
                })
                .style("text-anchor", "middle")
                .style("font-size", 17);

    }, []);

    return (
        <div id='piechart' style={{textAlign: 'center'}}>
        </div>
    )
}