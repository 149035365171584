import { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, OverlayTrigger, Row, Spinner, Tooltip } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import Api from "../../utils/Api";
import { useAuth } from "../../context/auth";
import AlertDisplay from "../../components/AlertDisplay";
import { FaInfoCircle } from "react-icons/fa";

const initialCustomer = {
    "name": "",
    "address": "",
    cisa:'',
    address1: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    "contacts": [],
    "uspsContract": false,
}


function Customer({ method }) {
    const { idToken } = useAuth();
    const { id } = useParams();
    const [didFetch, setDidFetch] = useState();
    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts };
    const [customer, setCustomer] = useState(initialCustomer);
    const [customerNotFound, setCustomerNotFound] = useState();

    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const updatedCustomer = { ...customer };
        if (name === 'name' || name === 'phone' || name === 'email') {
            updatedCustomer.contacts[index][name] = value;
        } else {
            updatedCustomer[name] = value;
        }
        setCustomer(updatedCustomer);
    };

    const addContact = () => {
        const updatedCustomer = { ...customer };
        updatedCustomer.contacts.push({ name: '', phone: '', email: '' });
        setCustomer(updatedCustomer);
    };

    const removeContact = (index) => {
        const updatedCustomer = { ...customer };
        updatedCustomer.contacts.splice(index, 1);
        setCustomer(updatedCustomer);
    };

    async function handleCreate() {
        try {
            await Api.post('/customers', customer, idToken);
            setAlerts([...alerts, { variant: 'success', message: 'Create success.' }])
            setCustomer(initialCustomer)
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured.' }])
        }
    }

    async function handleUpdate() {
        try {
            await Api.patch(`/customer/${id}`, customer, idToken);
            setAlerts([...alerts, { variant: 'success', message: 'Update successful.' }])
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured.' }])
        }
    }

    async function fetchCustomer() {
        try {
            const data = await Api.get(`/customer/${id}`, idToken);
            setCustomer(data)
        } catch {
            setAlerts([...alerts, { variant: 'warning', message: 'An error occured fetching carrier.' }])
            setCustomerNotFound(true)
        } finally {
            setDidFetch(true)
        }
    }

    useEffect(() => {
        if (id && !didFetch) {
            fetchCustomer()
        }
    }, [id, didFetch])

    return (
        <Container fluid style={{ margin: 0, padding: 0 }}>
            <AlertDisplay alertState={alertState} />
            <Card style={{ border: 0, borderRadius: 0 }}>
                <Card.Header>
                    <Row>
                        <Card.Title>{`${method} Customer`.toUpperCase()}</Card.Title>
                    </Row>
                </Card.Header>
                {customerNotFound ?
                    <Card.Body>
                        Customer Not Found <Link to={'/data/customer'} onClick={() => setCustomerNotFound(false)}>Create Customer</Link>
                    </Card.Body>
                    :
                    <>
                        <Card.Body>
                            <Row>
                                <Col xs="auto">
                                    <Form.Group controlId="cisa">
                                        <Form.Label>CustomerISA</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="cisa"
                                            value={customer.cisa}
                                            onChange={e => setCustomer({ ...customer, cisa: e.target.value })}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs="auto">
                                    <Form.Group controlId="name">
                                        <Form.Label>Customer Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="name"
                                            value={customer.name}
                                            onChange={e => setCustomer({ ...customer, name: e.target.value })}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12" lg="8">
                                    Address
                                    <Form.Control
                                        placeholder="Address"
                                        value={customer.address1}
                                        onChange={(e) => setCustomer({ ...customer, address1: e.target.value })}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12" md="6" lg="2">
                                    City
                                    <Form.Control
                                        placeholder="City"
                                        value={customer.city}
                                        onChange={(e) => setCustomer({ ...customer, city: e.target.value })}
                                    />
                                </Col>
                                <Col xs="12" md="6" lg="2">
                                    State
                                    <Form.Control
                                        placeholder="State"
                                        value={customer.state}
                                        onChange={(e) => setCustomer({ ...customer, state: e.target.value })}
                                    />
                                </Col>
                                <Col xs="12" md="6" lg="2">
                                    Zip
                                    <Form.Control
                                        placeholder="Zip"
                                        value={customer.zip}
                                        onChange={(e) => setCustomer({ ...customer, zip: e.target.value })}
                                    />
                                </Col>
                                <Col xs="12" md="6" lg="2">
                                    Country
                                    <Form.Control
                                        placeholder="Country"
                                        value={customer.country}
                                        onChange={(e) => setCustomer({ ...customer, country: e.target.value })}
                                    />
                                </Col>
                            </Row>
                            <Form.Group controlId="contacts">
                                <Row>
                                    <Col>
                                        <Form.Label>Contacts</Form.Label>
                                    </Col>
                                </Row>
                                {customer?.contacts && customer?.contacts.map((contact, index) => (
                                    <div key={index}>
                                        <Row>
                                            <Col>
                                                <Form.Control
                                                    type="text"
                                                    name="name"
                                                    placeholder="Name"
                                                    value={contact.name}
                                                    onChange={(e) => handleInputChange(e, index)}
                                                />
                                            </Col>
                                            <Col>
                                                <Form.Control
                                                    type="text"
                                                    name="phone"
                                                    placeholder="Phone"
                                                    value={contact.phone}
                                                    onChange={(e) => handleInputChange(e, index)}
                                                />
                                            </Col>
                                            <Col>
                                                <Form.Control
                                                    type="text"
                                                    name="email"
                                                    placeholder="Email"
                                                    value={contact.email}
                                                    onChange={(e) => handleInputChange(e, index)}
                                                />
                                            </Col>
                                            <Col>
                                                <Button variant="danger" onClick={() => removeContact(index)}>Remove</Button>
                                            </Col>
                                        </Row>
                                    </div>
                                ))}
                                <Button variant="secondary" onClick={() => addContact()}>Add Contact</Button>
                            </Form.Group>
                        </Card.Body>
                        <Card.Body>
                            <Row>
                                {method === 'create' &&
                                    <Col xs="auto">
                                        <Button variant="primary" onClick={() => handleCreate()}>Create</Button>
                                    </Col>
                                }
                                {method === 'update' &&
                                    <>
                                        <Col xs="auto">
                                            <Button variant="primary" onClick={() => handleUpdate()}>Update</Button>
                                        </Col>
                                        <Col xs="auto">
                                            <OverlayTrigger
                                                placement="top"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={
                                                    <Tooltip id="button-tooltip">
                                                        Active - determines whether or not the record should be
                                                        used by the software application.
                                                    </Tooltip>
                                                }
                                            >
                                                <div>

                                                    Active <FaInfoCircle /> : <Button variant={customer.isActive ? 'success' : 'danger'} onClick={() => { setCustomer({ ...customer, isActive: (customer.isActive ? false : true) }) }}>{customer.isActive ? 'True' : 'False'}</Button>
                                                </div>
                                            </OverlayTrigger>
                                        </Col>
                                    </>

                                }
                            </Row>
                        </Card.Body>
                    </>
                }
            </Card>
        </Container>
    );
}



export default Customer;