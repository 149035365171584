import React, { useState, useEffect } from 'react'
import { Container, Card, Button, Row, Col, Spinner, Alert, ListGroupItem, Table, } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useRole } from "../context/role";
import { Link } from 'react-router-dom';
import { FaAtlas, FaBookmark, FaChartBar, FaCubes, FaDatabase, FaFastForward, FaHandshake, FaMapPin, FaPlusCircle, FaStore, FaTag, FaTrailer, FaTruck } from 'react-icons/fa';
import { FaBuildingFlag, FaTruckFront } from 'react-icons/fa6';
import { HiOutlineDocumentReport, HiOutlineIdentification } from 'react-icons/hi';
import { FiRepeat } from 'react-icons/fi';
import { TbNetwork } from 'react-icons/tb';
import { RiDashboard3Line } from 'react-icons/ri';
import { AiOutlineSchedule } from 'react-icons/ai';
import { MdAlarm, MdEditCalendar, MdOutlineSensors, MdWarning } from 'react-icons/md';


function LoadTenderPage() {

    const [toggle, setToggle] = useState(false);
    useEffect(() => {
      const savedToggle = localStorage.getItem('@tms-featurepage-toggle');
      if (savedToggle !== null) {
        setToggle(JSON.parse(savedToggle));
      }
    }, []);
    useEffect(() => {
      localStorage.setItem('@tms-featurepage-toggle', JSON.stringify(toggle));
    }, [toggle]);

    const { userRole, checkNavPermissions } = useRole();

    const features = [
        { href: "/loadtender/quickcover", icon: <MdEditCalendar />, text: "Open Shipments", detail: 'Quickly book shipment coverage' },
        { href: "/loadtender/problems", icon: <MdWarning />, text: "Problems", detail: 'System Reported Errors', },
        { href: "/loadtender/dashboard", icon: <RiDashboard3Line />, text: "Dashboard", detail: 'All Loads/Shipments/Trips', },
        { href: "/loadtender/brokered", icon: <FaHandshake />, text: "Brokered Loads", detail: '3rd Party Coverage', },
        { href: "/loadtender/schedules", icon: <AiOutlineSchedule />, text: "Schedules", detail: 'Build routines for repeat service' },
        { href: "/loadtender/scheduler", icon: <FaCubes />, text: "New Schedule", detail: 'Build a New Schedule', },
        { href: "/loadtender/atlas", icon: <FaAtlas />, text: "Route Atlas", detail: 'View All Routes', },
        { href: "/loadtender/new-load", icon: <FaPlusCircle />, text: "New Route", detail: 'Create a Route', },
        { href: "/loadtender/performance", icon: <FaChartBar />, text: "Performance", detail: 'Load Timeliness', },
        { href: "/loadtender/accounting", icon: <HiOutlineDocumentReport />, text: "Accounting", detail: 'Income, Expenses, Margins', },
        { href: "/loadtender/iot", icon: <MdOutlineSensors />, text: "IoT", detail: 'Integrated Devices', },
    ]
    const allowedFeatures = checkNavPermissions(userRole, features)

    const cardStyle = {
        textAlign: 'center',
        width: '250px',
        height: '250px'
    }

    const colStyle = {
        margin: '0.25em',
    }

    return (
        <Container fluid style={{ margin: 0, padding: 0 }}>
            <Card style={{ border: 0, borderRadius: 0 }}>
                <Card.Header>
                    <Row>
                        <Col>
                            <Card.Title>Load Tender</Card.Title>
                        </Col>
                        <Col xs="auto">
                            <Button variant='primary' onClick={() => setToggle(!toggle)}>Toggle {!toggle ? 'Card' : 'Table'}</Button>
                        </Col>
                    </Row>
                </Card.Header>
                {toggle ?
                    <Card.Body>
                        <Row style={{justifyContent:'space-evenly'}}>
                            {allowedFeatures.map((feature, i) => (
                                <Col xs="auto" style={colStyle}>
                                    <Card style={cardStyle}>
                                        <Card.Header>
                                            <b>{feature.text}</b>
                                        </Card.Header>
                                        <Card.Body key={i}>
                                            <Row>
                                                <Col style={{ marginBottom: '1em' }}>
                                                    <h1>{feature.icon}</h1>
                                                </Col>
                                            </Row>
                                            <Card.Text>
                                                {feature.detail}
                                            </Card.Text>
                                            <Link to={feature.href}>Go to {feature.text}</Link>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    </Card.Body>
                    :
                    <Card.Body>
                        <Table responsive>
                            <tbody>
                                {allowedFeatures.map((feature, i) => (
                                    <tr>
                                        <td>
                                            <h3>{feature.icon}</h3>
                                        </td>
                                        <td> <Link to={feature.href}>{feature.text}</Link></td>
                                        <td>{feature.text}</td>
                                        <td>{feature.detail}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Card.Body>
                }

            </Card>
        </Container>
    )
}

export default LoadTenderPage;

