import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useRef, useState } from "react";
import { Container, Card, Row, Col, Form, Button, Alert } from "react-bootstrap";
import Api from '../../utils/Api';
import { useAuth } from '../../context/auth';
import { LOCATION_CATEGORIES } from "../../context/appdata";
import AlertDisplay from "../../components/AlertDisplay";
import GeofencePlotter from "../../components/GeofencePlotter";
import AddressAutocomplete from "../../components/AddressAutocomplete";

const initialFormState = {
    type: '',
    name: '',
    fullAddress: '',
    latitude: '-',
    longitude: '-',
    proximity: '-',
    circleGeofence: [],
    geofences: []
}

function LocationForm({feature}) {
    const { idToken } = useAuth();
    const [geofences, setGeofences] = useState([]);
    const geofenceState = { geofences, setGeofences };

    const [circleGeofence, setCircleGeofence] = useState();
    const circleGeofenceState = { circleGeofence, setCircleGeofence };

    const [formdata, setFormdata] = useState(initialFormState)
    const [alerts, setAlerts] = useState([]);

    const addressAutocompleteRef = useRef(null);
    const [fullAddress, setFullAddress] = useState('');
    const fullAddressState = { fullAddress, setFullAddress }
    
    useEffect(() => {
        setFormdata({ ...formdata, fullAddress: fullAddress })
    }, [fullAddress])


    async function handlePost() {
        try {
            const newLocation = { ...formdata, geofences, circleGeofence };
            await Api.post('/locations', newLocation, idToken)
            setAlerts([...alerts, { variant: 'success', message: 'Success' }]);
            setFormdata(initialFormState)
        } catch (error) {
            console.log(error)
            setAlerts([...alerts, { variant: 'warning', message: `Error: ${error}`}]);
        }
    }

    return (
        <Container fluid style={{margin:0, padding:0}}>
            <AlertDisplay alertState={{ alerts, setAlerts }} />
            <Card  style={{ borderRadius: 0, border:0 }}>
                <Card.Header><Card.Title>Location Form</Card.Title></Card.Header>
                <Card.Body>
                    <Row>
                        <Col xs={6} lg={4}>
                            Name
                            <Form.Control type="text"
                                value={formdata.name}
                                onChange={e => setFormdata({ ...formdata, name: e.target.value })}
                            />
                        </Col>
                        <Col xs={6} lg={4}>
                            Address
                            <AddressAutocomplete ref={addressAutocompleteRef} fullAddressState={fullAddressState} />
                        </Col>
                        <Col xs={6} lg={4}>
                            Type
                            <Form.Select value={formdata.type} onChange={e => setFormdata({ ...formdata, type: e.target.value })} >
                                <option>Select</option>
                                {LOCATION_CATEGORIES.map((category, i) => (
                                    <option key={i} value={category.name}>{category.name} - {category.detail}</option>
                                ))}
                            </Form.Select>
                        </Col>
                    </Row>
                    <br />
                    <GeofencePlotter
                        fullAddress={fullAddress}
                        geofenceState={geofenceState}
                        circleGeofenceState={circleGeofenceState}
                    />
                    <br />
                    <Row>
                        <Col>
                            <Button variant="primary" onClick={() => handlePost()}>Create</Button>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            {/* <pre>
                {JSON.stringify(formdata,"",2)}
            </pre> */}
        </Container>
    );
}

export default LocationForm;