import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { IoTodayOutline } from "react-icons/io5";

const { useState, useMemo, useEffect } = require("react");
const { default: Api } = require("../../utils/Api");
const { Container, Row, Col, Form, Button, Card, Spinner } = require("react-bootstrap");
const { useAuth } = require("../../context/auth");
const { default: AlertDisplay } = require("../../components/AlertDisplay");


const today = new Date();  // today based on the clock


function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Add leading zero if needed
    const day = String(date.getDate()).padStart(2, '0'); // Add leading zero if needed
    return `${year}-${month}-${day}`;
}


const initialQuery = {
    start: formatDate(today),
    end: formatDate(today),
    name: '',
    page: 1,
    pageSize: 10
}

function objectToQueryString(obj) {
    return Object.keys(obj)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
        .join('&');
}

function ManagerCarrierScoreCard() {

    const { idToken } = useAuth();
    const [set, didSet] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [loadData, setLoadData] = useState(false);
    const [alerts, setAlerts] = useState([]);
    const alertState = { alerts, setAlerts }
    const [totalRecords, setTotalRecords] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [query, setQuery] = useState(() => {
        const savedQuery = localStorage.getItem(`@mctms-loadtender-manager-scorecards-query`);
        return savedQuery !== null ? JSON.parse(savedQuery) : initialQuery;
    });

    const [samsaraDrivers, setSamsaraDrivers] = useState();
    const [samsaraDriversByLocation, setSamsaraDriversByLocation] = useState();
    const [searchTerm, setSearchTerm] = useState('');

    const [operatorlocation, setOperatorLocation] = useState('All');
    const [operatoroption, setOperatorOption] = useState();

    const filteredSamsaraDrivers = useMemo(() => {
        if (samsaraDrivers && samsaraDrivers.length > 0) {
            return samsaraDrivers.filter(item =>
                item.name.toLowerCase().includes(searchTerm.toLowerCase())
            );
        } else {
            return [];
        }
    }, [samsaraDrivers, searchTerm]);

    const [driverShipments, setDriverShipments] = useState([]);

    function incrementDate(dateStr) {
        const date = new Date(dateStr);
        date.setDate(date.getDate() + 1); // Add one day
        const incrementedDate = date.toISOString().split('T')[0]; // Format back to YYYY-MM-DD
        return incrementedDate;
    }
    function decrementDate(dateStr) {
        const date = new Date(dateStr);
        date.setDate(date.getDate() - 1); // Add one day
        const incrementedDate = date.toISOString().split('T')[0]; // Format back to YYYY-MM-DD
        return incrementedDate;
    }
    function resetDays() {
        const date = new Date(today);
        const newDate = date.toISOString().split('T')[0];
        setQuery({ ...query, start: newDate, end: newDate })
    }
    function addDay() {
        const newQueryStart = incrementDate(query.start);
        const newQueryEnd = incrementDate(query.end);
        setQuery({ ...query, start: newQueryStart, end: newQueryEnd })
    }
    function subtractDay() {
        const newQueryStart = decrementDate(query.start);
        const newQueryEnd = decrementDate(query.end);
        setQuery({ ...query, start: newQueryStart, end: newQueryEnd })
    }
    const fetchSamsaraDrivers = async () => {
        try {
            const driversdata = await Api.get(`/samsara/drivers-active`, idToken);
            const drivers = driversdata?.data;
            const groupedData = drivers.reduce((groups, record) => {
                const homeTerminalName = record?.carrierSettings?.homeTerminalName;
                if (!groups[homeTerminalName]) {
                    groups[homeTerminalName] = [];
                }
                groups[homeTerminalName].push(record);
                return groups;
            }, {});

            const sortedKeys = Object.keys(groupedData).sort();

            const sortedGroupedData = {};
            for (const key of sortedKeys) {
                sortedGroupedData[key] = groupedData[key];
            }

            setSamsaraDrivers(drivers)
            setSamsaraDriversByLocation(sortedGroupedData)
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'Error fetching active drivers' }])
        } finally {
            didSet(true)
        }
    }
    const fetchDriverData = async () => {
        setIsLoading(true)
        try {
            const q = { ...query, name: operatoroption.name }
            setQuery(q)
            const queryStr = objectToQueryString(q)
            const loads = await Api.get(`/loadtenders/driver-shipments?${queryStr}`, idToken)
            setTotalRecords(loads.totalRecords)
            setTotalPages(loads.totalPages)
            setDriverShipments(loads.data)
        } catch (error) {
            setAlerts([...alerts, { variant: 'warning', message: 'Error fetching driver data' }])
        } finally {
            setIsLoading(false)
            setLoadData(false)
        }
    }

    useEffect(() => {
        if (!set) {
            fetchSamsaraDrivers();
        }
    }, [set])

    useEffect(() => {
        setLoadData(true)
    }, [operatoroption, query])

    useEffect(() => {
        if (loadData) {
            fetchDriverData()
        }
    }, [loadData])

    return (
        <Container fluid>
            <AlertDisplay alertState={alertState} />
            <Row>
                <Col xs="auto">
                    Location
                    <Form.Select onChange={(e) => setOperatorLocation(e.target.value)}>
                        <option>All</option>
                        {samsaraDriversByLocation && Object.keys(samsaraDriversByLocation).map((key, k) => (
                            <option key={k} value={key}>{key ? key : 'Undefined'}</option>
                        ))}
                    </Form.Select>
                </Col>
                <Col xs="auto">
                    Driver
                    {operatorlocation === 'All'
                        ?
                        <Row>
                            <Col>
                                <Form.Control
                                    type="text"
                                    placeholder="Search..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                                {searchTerm.trim('') !== '' &&
                                    <div style={{ maxHeight: '200px', overflowY: 'auto', margin: '0.5em', padding: '0.1em' }}>
                                        {filteredSamsaraDrivers.map((item, i) => (
                                            <Row key={i} style={{ margin: '0.1em' }}>
                                                <Button variant="secondary" onClick={() => {
                                                    setOperatorOption(item);
                                                    setSearchTerm(item.name);
                                                    setLoadData(true)
                                                }}
                                                >{item.name}</Button>
                                            </Row>
                                        ))}
                                    </div>
                                }
                            </Col>
                            {searchTerm.trim('') !== '' &&
                                <Col xs="auto">
                                    <Button variant="warning" onClick={() => setSearchTerm('')}>
                                        X
                                    </Button>
                                </Col>
                            }
                        </Row>
                        :
                        <Form.Select onChange={(e) => e.target.value !== '' && setOperatorOption(JSON.parse(e.target.value))}>
                            <option value=''>Select...</option>
                            {samsaraDriversByLocation[operatorlocation] && samsaraDriversByLocation[operatorlocation].sort((a, b) => a.name.localeCompare(b.name)).map((driver, i) => (
                                <option key={i} value={JSON.stringify(driver)}>{driver.name}</option>
                            ))}
                        </Form.Select>
                    }
                </Col>
                <Col xs="auto">
                    Period
                    <Row>
                        <Col xs="auto"><Form.Control type="date" value={query.start} onChange={e => setQuery({ ...query, start: e.target.value })} /></Col>
                        <Col xs="auto"><Form.Control type="date" value={query.end} onChange={e => setQuery({ ...query, end: e.target.value })} /></Col>
                        <Col xs="auto"><Button variant="secondary" onClick={() => subtractDay()}><FaArrowLeft /></Button></Col>
                        <Col xs="auto"><Button variant="secondary" onClick={() => resetDays()}><IoTodayOutline /></Button></Col>
                        <Col xs="auto"><Button variant="secondary" onClick={() => addDay()}><FaArrowRight /></Button></Col>
                    </Row>
                </Col>
                <Col xs="auto">
                    Records <br /> {totalRecords}
                </Col>
                <Col xs="auto">
                    Page {query.page} / {totalPages}
                    <Row>
                        <Col xs="auto">
                            <Button variant="secondary" onClick={() => setQuery({ ...query, page: query.page - 1 >= 1 ? query.page - 1 : query.page })}><FaArrowLeft /></Button>
                        </Col>
                        <Col xs="auto">
                            <Button variant="secondary" onClick={() => setQuery({ ...query, page: query.page + 1 <= totalPages ? query.page + 1 : query.page })}><FaArrowRight /></Button>
                        </Col>
                    </Row>
                </Col>
                <Col xs="auto">
                    Page Size
                    <Form.Select value={query.pageSize} onChange={(e) => setQuery({ ...query, pageSize: e.target.value })}>
                        <option value={-1}>All</option>
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                    </Form.Select>
                </Col>
            </Row>
            {operatoroption &&
                <Card>
                    <Card.Header>
                        <Card.Title>{operatoroption.name}</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        {isLoading ?
                            <Spinner />
                            :
                            <>
                                <pre>
                                    Shipments : {JSON.stringify(driverShipments.length, "", 2)}
                                </pre>
                            </>
                        }
                    </Card.Body>
                </Card>
            }

        </Container>
    )
}


export default ManagerCarrierScoreCard;