import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect } from "react";
import { Navbar, Nav, Offcanvas, Dropdown, Button, Image, Row, Col, Tab, Tabs, Container, NavDropdown } from 'react-bootstrap';
import {
    FaRoute, FaCalendarAlt, FaRegIdCard, FaTrailer, FaBookmark, FaTruck, FaSearchLocation, FaFileContract, FaLink,
    FaGasPump, FaInfo, FaFileExcel, FaWpforms, FaMoneyCheck, FaUserLock, FaFilePdf, FaUserPlus, FaCloud, FaBell, FaWifi, FaBug, FaChartPie, FaArrowLeft, FaArrowRight, FaTrafficLight, FaCalendar, FaUser, FaChartBar, FaExclamationTriangle, FaUserCog, FaMicrochip, FaClipboard, FaHandshake, FaDollarSign, FaMapPin, FaFileInvoiceDollar, FaEye, FaDashcube, FaBlog, FaArrowDown, FaCaretDown, FaCaretRight, FaExclamation, FaUserClock, FaUpload, FaMailBulk, FaPassport, FaTag, FaFileInvoice, FaShippingFast, FaShoppingCart, FaStore, FaDatabase, FaPlusCircle,
    FaVest,
    FaMap,
    FaUserInjured,
    FaAtlas,
    FaIdBadge,
    FaCog
} from 'react-icons/fa';
import { FiSettings } from 'react-icons/fi';
import { AiFillHome, AiFillSchedule, AiOutlineSchedule, } from 'react-icons/ai';
import { TbReportAnalytics } from 'react-icons/tb';
import { BsBoxes, BsCalendar, BsCalendar3, BsChatLeft } from 'react-icons/bs';
import { MdAlarm, MdGroupAdd, MdHelp, MdNotificationAdd, MdOutlineSensors } from 'react-icons/md';
import { TbNetwork } from 'react-icons/tb'
import { CgProfile } from "react-icons/cg";
import { useRole } from "../context/role";
import { FiRepeat, } from 'react-icons/fi';
import { FaCubes } from 'react-icons/fa';
import { FaUmbrellaBeach, FaPlaneDeparture, FaRoad, FaCarCrash } from 'react-icons/fa';
import { GoReport } from 'react-icons/go';
import { BsCashCoin } from 'react-icons/bs'
import { AiOutlineAlert } from 'react-icons/ai'
import { TbPlusMinus } from 'react-icons/tb';
import { HiOutlineTag } from 'react-icons/hi';
import { FaDownload, FaHome, } from 'react-icons/fa';
import { ImFilePdf } from 'react-icons/im';
import { BsCashStack } from 'react-icons/bs';
import { MdAttachMoney } from 'react-icons/md';
import { BsActivity, BsCheckLg, BsInbox, BsXLg } from "react-icons/bs";
import { FaTruckLoading } from "react-icons/fa";
import { Link, useNavigate } from 'react-router-dom';
import { HiUserGroup } from "react-icons/hi";
import { MdOutlineBadge } from "react-icons/md";
import { useAuth } from '../context/auth';
import { AiOutlineLogout } from "react-icons/ai";
import ConfirmationModal from '../components/ConfirmationModal';
import MCTMS_LOGO from '../assets/mctms-logo.png'
import { FaBuildingFlag, FaMagnifyingGlass, FaTruckFront } from 'react-icons/fa6';
import { HiOutlineDocumentReport } from "react-icons/hi";
import { CiBoxList, CiSquareQuestion, CiViewTable } from "react-icons/ci";
import { MdOutlinePersonalInjury } from "react-icons/md";
import { GiPoliceBadge } from "react-icons/gi";
import { FaBuildingUser } from "react-icons/fa6";
import { FaUsps } from "react-icons/fa";
import { GoContainer } from "react-icons/go";
import { CgTemplate } from "react-icons/cg";
import { GiWoodenCrate } from "react-icons/gi";
import { RiDashboard3Line, RiMoneyDollarCircleLine } from "react-icons/ri";
import { CiPercent } from "react-icons/ci";
import { HiOutlineIdentification } from "react-icons/hi2";
import { useLocation } from 'react-router-dom';
import { GrHomeOption, GrScorecard } from 'react-icons/gr';
import { IoBusiness, IoReturnUpForwardSharp } from 'react-icons/io5';
import { multidimensionalGroupBy } from '../utils/Array.helper';
import LOGO from '../assets/logo.png'
import SYBR_LOGO from '../assets/sybr-logo.png'

const appFeatureGroups = [
    { feature: 'Home', href: "/", icon: <AiFillHome />, text: "Home" },
    { feature: 'Home', href: "/profile", icon: <FaUserCog />, text: "Profile" },
    { feature: 'Home', href: "/settings", icon: <FaCog />, text: "Settings" },
    { feature: 'Home', href: "/bug-report", icon: <FaBug />, text: "Bug Report" },
    { feature: 'Home', href: "/feature-request", icon: <FaExclamation />, text: "Feature Request" },
    { feature: 'Home', href: "/help", icon: <MdHelp />, text: "Help" },
    { feature: 'Home', href: "/iot", icon: <MdOutlineSensors />, text: "IoT Integrations" },

    { feature: 'Access', href: "/access", icon: <FaUserLock />, text: "Access" },
    { feature: 'Access', }, // represents a line or break
    { feature: 'Access', href: "/access/roles", icon: <MdOutlineBadge />, text: "Roles" },
    { feature: 'Access', href: "/access/profiles", icon: <CgProfile />, text: "Users" },
    { feature: 'Access', },
    { feature: 'Access', href: "/access/new-users", icon: <FaUserPlus />, text: "New Users" },
    { feature: 'Access', href: "/access/active-users", icon: <FaUserClock />, text: "Active Users" },
    { feature: 'Access', href: "/access/stale-users", icon: <FaUserCog />, text: "Stale Users" },

    { feature: 'Alarms', href: "/alarms", icon: <FaBell />, text: "Alarms" },
    { feature: 'Alarms', href: "/alarms-form", icon: <MdNotificationAdd />, text: "New Alarm" },
    { feature: 'Alarms', href: "/alarms/groups", icon: <HiUserGroup />, text: "Groups" },
    { feature: 'Alarms', href: "/alarms/info", icon: <GrHomeOption />, text: "Options" },
    { feature: 'Alarms', href: "/alarms/reports", icon: <HiOutlineDocumentReport />, text: "Reports" },

    { feature: 'Data', href: "/data", icon: <FaDatabase />, text: "Data" },
    { feature: 'Data', href: "/data/assignments", icon: "", text: "Assignments" },
    { feature: 'Data', href: "/data/companies", icon: <FaBuildingFlag />, text: "Companies" },
    { feature: 'Data', href: "/data/customers", icon: <FaStore />, text: "Customers" },
    { feature: 'Data', href: "/data/carriers", icon: <FaTruckFront />, text: "Carriers" },
    { feature: 'Data' },
    { feature: 'Data', href: "/data/drivers", icon: <HiOutlineIdentification />, text: "Drivers" },
    { feature: 'Data', href: "/data/vehicles", icon: <FaTruck />, text: "Vehicles" },
    { feature: 'Data', href: "/data/trailers", icon: <FaTrailer />, text: "Trailers" },
    { feature: 'Data', href: "/data/location", icon: <FaMapPin />, text: "Locations" },
    { feature: 'Data' },
    { feature: 'Data', href: "/data/frequency", icon: <FiRepeat />, text: "Frequency" },
    { feature: 'Data', href: "/data/specialop", icon: <FaBookmark />, text: "Special Ops" },
    { feature: 'Data' },
    { feature: 'Data', href: "/data/tags", icon: <FaTag />, text: "Tags" },

    // { feature:'', href: "/dispatch", icon: <FaTruck />, text: "Dispatch" },
    // { feature:'', href: "/dispatch/builder", icon: <FaCubes />, text: "Builder" },
    // { feature:'', href: "/dispatch/calendar", icon: <FaCalendar />, text: "Calendar" },
    // { feature:'', href: "/dispatch/dashboard", icon: <FaChartPie />, text: "Dashboard" },
    // { feature:'', href: "/dispatch/open", icon: <FaUser />, text: "Open" },
    // { feature: 'Driver', href: "/driver/loadtenders", icon: <FaTruck />, text: "Driver Load Tenders" },

    { feature: 'Driver', href: "/driverportal", icon: <FaIdBadge />, text: "Driver" },
    { feature: 'Driver', href: "/driver/accident-form", icon: <FaCarCrash />, text: "Accident Form" },
    { feature: 'Driver', href: "/driver/accident-form", icon: <FaCarCrash />, text: "Accident Form" },
    { feature: 'Driver', href: "/driver/injury-form", icon: <FaUserInjured />, text: "Injury Form" },
    { feature: 'Driver', href: "/driver/profile", icon: <CgProfile />, text: "Driver Profile" },
    { feature: 'Driver', href: "/driver/score-card", icon: <GrScorecard />, text: "Driver Score Card" },

    { feature: 'Forms', href: "/forms", icon: <FaWpforms />, text: "Forms" },
    { feature: 'Forms', href: "/form/dailyreport", icon: <GoReport />, text: "Daily Report" },
    { feature: 'Forms', href: "/form/employeereimbursement", icon: <BsCashCoin />, text: "Emp. Reimbusement" },
    { feature: 'Forms', href: "/form/pto", icon: <FaUmbrellaBeach />, text: "PTO" },
    { feature: 'Forms', href: "/form/purchaseorder", icon: <HiOutlineTag />, text: "Purchase Order" },
    { feature: 'Forms', href: "/form/roadtest", icon: <FaRoad />, text: "Road Test" },
    { feature: 'Forms', href: "/form/thirdpartyvehicle", icon: <TbPlusMinus />, text: "3rd Party Vehicles" },

    { feature: 'Jobs', href: "/job", icon: <TbReportAnalytics />, text: "Jobs" },

    { feature: 'Load Tender', href: "/loadtender", icon: <TbNetwork />, text: "Load Tender" },
    { feature: 'Load Tender', href: "/loadtender/dashboard", icon: <RiDashboard3Line />, text: "Dashboard" },
    { feature: 'Load Tender', href: "/loadtender/quickcover", icon: <AiOutlineSchedule />, text: "Unscheduled" },
    { feature: 'Load Tender' },
    { feature: 'Load Tender', href: "/loadtender/accounting", icon: <HiOutlineDocumentReport />, text: "Accounting" },
    { feature: 'Load Tender', href: "/loadtender/performance", icon: <FaChartBar />, text: "Performance" },
    { feature: 'Load Tender' },
    { feature: 'Load Tender', href: "/loadtender/scheduler", icon: <FaCubes />, text: "New Schedule" },
    { feature: 'Load Tender', href: "/loadtender/schedules", icon: <AiOutlineSchedule />, text: "Schedules" },
    { feature: 'Load Tender', href: "/loadtender/forwarding", icon: <IoReturnUpForwardSharp />, text: "Forwarding" },
    { feature: 'Load Tender' },
    { feature: 'Load Tender', href: "/loadtender/atlas", icon: <FaAtlas />, text: "Load Atlas" },
    { feature: 'Load Tender', href: "/loadtender/new-load", icon: <FaPlusCircle />, text: "New Load" },
    // { feature: 'Load Tender', href: "/loadtender/iot", icon: <MdOutlineSensors />, text: "IoT" },
    { feature: 'Logs', href: "/log", icon: <CiBoxList />, text: "Log" },
    { feature: 'Logs', href: "/log/notifications", icon: <FaBell />, text: "EDI Msg Received" },

    { feature: 'Manager', href: "/manager", icon: <FaBuildingUser />, text: "Manager Portal" },
    { feature: 'Manager', href: "/manager/carrier-score-cards", icon: <FaBuildingUser />, text: "Carrier Score Card" },
    { feature: 'Manager', href: "/manager/carrier-schedules", icon: <FaCalendar />, text: "Carrier Schedules" },
    { feature: 'Manager', href: "/manager/contract-score-cards", icon: <FaFileContract />, text: "Contract Score Cards" },
    { feature: 'Manager', href: "/manager/driver-score-cards", icon: <CgProfile />, text: "Driver Score Card" },
    { feature: 'Manager', href: "/manager/driver-schedules", icon: <FaCalendar />, text: "Driver Schedules" },

    { feature: 'Notifications', href: "/notification", icon: <FaBell />, text: "Notifications" },
    { feature: 'Notifications', href: "/notification-form", icon: <MdNotificationAdd />, text: "New Notification" },
    { feature: 'Notifications', href: "/notification/group-form", icon: <MdGroupAdd />, text: "New Group" },
    { feature: 'Notifications', href: "/notification/groups", icon: <HiUserGroup />, text: "Groups" },
    { feature: 'Notifications', href: "/notification/info", icon: <GrHomeOption />, text: "Options" },
    { feature: 'Notifications', href: "/notification/reports", icon: <HiOutlineDocumentReport />, text: "Reports" },
    // { feature:'', href: "/plan", icon: <AiOutlineSchedule />, text: "Plan Operations" },
    // { feature:'', href: "/plan/assignment", icon: <FaLink />, text: "Assignment" },
    // { feature:'', href: "/plan/contract", icon: <FaFileContract />, text: "Contract" },
    // { feature:'', href: "/plan/frequency", icon: <FiRepeat />, text: "Frequency" },
    // { feature:'', href: "/plan/location", icon: <FaSearchLocation />, text: "Location" },
    // { feature:'', href: "/plan/operator", icon: <FaRegIdCard />, text: "Operator" },
    // { feature:'', href: "/plan/routeplan", icon: <FaRoute />, text: "Route" },
    // { feature:'', href: "/plan/specialop", icon: <FaBookmark />, text: "Special Op" },
    // { feature:'', href: "/plan/trailer", icon: <FaTrailer />, text: "Trailer" },
    // { feature:'', href: "/plan/vehicle", icon: <FaTruck />, text: "Vehicle" },
    { feature: 'Safety', href: "/safety", icon: <FaVest />, text: "Safety" },
    { feature: 'Safety', href: "/safety/dashboard", icon: <GiPoliceBadge />, text: "Safety Dashboard" },
    { feature: 'Safety' },
    { feature: 'Safety', href: "/safety/accidents/data-output", icon: <FaCarCrash />, text: "Accident Graphs" },
    { feature: 'Safety', href: "/safety/accidents/list-of-forms", icon: <FaCarCrash />, text: "Accident List" },
    { feature: 'Safety', href: "/safety/accidents/new-form", icon: <FaCarCrash />, text: "New Accident" },
    { feature: 'Safety', href: "/safety/accidents/upload", icon: <FaUpload />, text: "Upload Accidents" },
    { feature: 'Safety' },
    { feature: 'Safety', href: "/safety/injuries/data-output", icon: <MdOutlinePersonalInjury />, text: "Injury Graphs" },
    { feature: 'Safety', href: "/safety/injuries/list-of-forms", icon: <MdOutlinePersonalInjury />, text: "Injury List" },
    { feature: 'Safety', href: "/safety/injuries/new-form", icon: <MdOutlinePersonalInjury />, text: "New Injury" },
    { feature: 'Safety', href: "/safety/injuries/upload", icon: <FaUpload />, text: "Upload Injuries" },
];

export {
    appFeatureGroups
}