import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route, useNavigate, } from "react-router-dom";
import { Row, Col, Container, Alert, Button } from "react-bootstrap";
import { useAuth } from './context/auth';
import BannerBar from './views/BannerBar';
import Loading from './components/Loading';
import Login from './views/Login'
import Navigation from './views/Navigation'
import NotFound from './views/NotFound'
import ServerOffline from './components/SeverOffline';
import appRoutes from './context/approutes';
import { NavigateHistory } from './context/navhistory';
import FeatureBar from './views/FeatureBar';
import { FaArrowUp } from 'react-icons/fa';
import './scrollToTop.css';
import ScrollToTop from './ScrollToTop';

const lsKey = '@mctms-loadtender-nav-toggle';
const historylsKey = '@mctms-loadtender-history-toggle';

function AppPrivate() {
  const { user, idToken } = useAuth();
  const [loading, isLoading] = useState(true);
  const [online, isOnline] = useState();
  const [error, setError] = useState();


  const [showHistory, setShowHistory] = useState(() => {
    const savedToggle = localStorage.getItem(`${historylsKey}`);
    return savedToggle !== null ? savedToggle : false;
  });

  const showHistoryState = { showHistory, setShowHistory }

  const [toggle, setToggle] = useState(() => {
    const savedToggle = localStorage.getItem(`${lsKey}`);
    return savedToggle !== null ? savedToggle : false;
  });

  const toggleState = { toggle, setToggle };


  useEffect(() => {
    localStorage.setItem(`${lsKey}`, toggle);
  }, [toggle]);


  const compiledRoutes = appRoutes.map((appRoute, i) => (
    <Route key={i} path={appRoute.path} element={appRoute.element} />
  ));

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/healthcheck`);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        if (data.status === "OK") {
          isOnline(true);
        } else {
          isOnline(false);
        }
      } catch (error) {
        setError('Offline, check back soon.');
      } finally {
        isLoading(false);
      }
    }

    fetchData()
  }, [user, idToken])

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1000) {
        setToggle(false);
      }
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [toggle]);

  if (loading) {
    return (
      <Container>
        <Loading />
      </Container>
    )
  }

  if (error) {
    return (
      <Container fluid style={{ height: '100vh', width: '100vw', padding: "0px", margin: "0px" }}>
        <Alert variant='warning'>
          {JSON.stringify(error)}
        </Alert>
      </Container>
    );
  }

  if (!online) {
    return <ServerOffline />;
  }

  if (!user || !idToken) {
    return <Login />;
  }

  const uiContainerStyle = {
    minHeight: '100vh',
    maxHeight: '100vh',
    minWidth: '100vw',
    maxWidth: '100vw',
    padding: "0px",
    margin: "0px",
    overflowX: 'hidden',
    '@media print': {
      display: 'none',
    }
  }

  return (
    <Router>
      <Container fluid style={uiContainerStyle}>
        <Row style={{ padding: "1px", margin: "0px", borderTop: '1px solid #bbb', color: '#fcb716', fontSize: '11px', fontWeight: 'bold', background: '#233f7c' }}>
          <Col style={{ textAlign: 'center', }}>
            <span id="tmsTop">SYBR ® - All Rights Reserved</span>
          </Col>
        </Row>
        <Row style={{ padding: "0px", margin: "0px", }}>
          <Col style={{ padding: "0px", margin: "0px", minHeight: '93vh', borderLeft: '1px solid #ccc' }}>
            <FeatureBar />
            <Routes>
              {compiledRoutes}
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Col>
        </Row>
      </Container>
      <ScrollToTop />
    </Router>
  );
}



export default AppPrivate;
